import React, { useEffect } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import Select from 'react-select';
import { LoadingButton } from '@mui/lab';
import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const validation = Yup.object().shape({
  state: Yup.string().required('Required Field'),
  cancellation_reason: Yup.string().when(['state'], {
    is: state => {
      return state === 'Cancelled' || state === 'Rejected';
    },
    then: schema => schema.required('Required Field'),
    otherwise: schema => schema.notRequired(),
  }),
});
function SelectModal({
  show,
  confirm,
  handleClose,
  title,
  select,
  single,
  validate,
  setValidate,
  formData,
  setFormData,
  actionStatus,
}) {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    defaultValues: {
      state: formData.state,
      cancellation_reason: formData.cancellation_reason,
    },
    resolver: yupResolver(validation),
  });

  useEffect(() => {
    reset(formData);
    return () => {
      reset({});
    };
  }, [formData, reset]);

  const onSubmit = data => {
    confirm(data);
  };
  return (
    <Modal
      size="lg"
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        style={{ background: `var(--primary_main)` }}
        className="modal_header"
        closeButton
      >
        <Modal.Title className="modal_title">{title}</Modal.Title>
      </Modal.Header>
      <div className="form-block">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <div>
              <Form.Group style={{ marginBottom: '10px' }}>
                <Controller
                  name="state"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      className="react-select"
                      classNamePrefix="react-select"
                      styles={{
                        control: styles => ({
                          ...styles,
                          fontSize: '14px',
                        }),
                        option: (styles, { isSelected }) => ({
                          ...styles,
                          fontSize: '14px',
                          ':active': {
                            ...styles[':active'],
                            backgroundColor: null,
                          },
                        }),
                      }}
                      theme={theme => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: `var(--primary_light)`,
                          primary: `var(--primary_main)`,
                        },
                      })}
                      options={select}
                      getOptionLabel={i => i.status || ''}
                      getOptionValue={i => i.value || ''}
                      value={select.find(i => i.value === field.value)}
                      onChange={val => setValue('state', val.value)}
                    />
                  )}
                />
              </Form.Group>
              {(watch('state') === 'Cancelled' ||
                watch('state') === 'Rejected') && (
                <>
                  <Form.Label>
                    Cancellation reasons
                    <span style={{ color: 'red' }}> *</span>
                  </Form.Label>
                  <Controller
                    name="cancellation_reason"
                    control={control}
                    rules={{ required: true, maxLength: 4000 }}
                    render={({ field }) => (
                      <Form.Control
                        as="textarea"
                        rows={3}
                        {...field}
                        isInvalid={errors.cancellation_reason}
                      />
                    )}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.cancellation_reason?.message}
                  </Form.Control.Feedback>
                  <div className="requried max_style">
                    Max length of description is 4000 characters
                  </div>
                </>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="previewButtons">
              <LoadingButton
                type="submit"
                size="small"
                className="login_submit_btn btn btn-primary "
                loadingPosition="center"
                variant="contained"
                loading={actionStatus === 'pending'}
              >
                <span>{actionStatus === 'pending' ? '' : 'Confirm'}</span>
              </LoadingButton>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
            </div>
          </Modal.Footer>
        </Form>
      </div>
    </Modal>
  );
}

export default SelectModal;
