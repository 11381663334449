import React, { useEffect, useState } from 'react';
import './admin_side_bar.scss';
import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css';
import { List, ListItemIcon, ListItemButton, Collapse } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearSearchAction } from '../../store/actions/search';
import { toast } from 'react-toastify';
import { logOut } from '../../store/actions/users';

const SideBar = ({ page, setActive, active, active1, setActive1 }) => {
  const accessList = useSelector(store => store?.users?.accessList);
  const blockedPage = useSelector(store => store.search.blockedPage);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const params = useLocation();
  const location = useLocation();
  const handleClick = e => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(!open);
  };

  const userLogOut = () => {
    navigate('/login');
    dispatch(logOut());
  };
  return (
    <>
      <ListItemButton
        className="side_bar_tab"
        onClick={e => {
          if (page?.path == '/login') {
            dispatch(clearSearchAction());
            return userLogOut();
          }
          if (page.children) {
            handleClick(e);
          }

          if (!page.children) {
            if (!blockedPage) {
              setActive(page.id);
              setActive1(page.id);
              navigate(page.path);
              dispatch(clearSearchAction());
            } else {
              if (blockedPage) {
                toast.error(
                  'You have unsaved changes on this form. Please save them or click "Reset" to undo.',
                  {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 10000,
                  },
                );
              }
            }
          }
        }}
        selected={
          // active1 ? active1 === page.id :
          location.pathname.includes(page.path)
        }
      >
        <ListItemIcon className="icon_block">
          {page.icon && page.icon}
        </ListItemIcon>
        <ListItemText className="side_bar_text" primary={page.title} />
        {page.children?.length ? open ? <ExpandLess /> : <ExpandMore /> : null}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {page.children?.length > 0 && (
          <>
            {page.children.map(
              child =>
                child.side_bar && (
                  <ListItemButton
                    className="side_bar_tab"
                    key={child.id}
                    sx={{ pl: 4 }}
                    onClick={() => {
                      if (!blockedPage) {
                        setActive1(page.id);
                        setActive(child.id);
                        navigate(child.path);
                        dispatch(clearSearchAction());
                      } else {
                        toast.error(
                          'You have unsaved changes on this form. Please save them or click "Reset" to undo.',
                          {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 10000,
                          },
                        );
                      }
                    }}
                    selected={location.pathname.includes(child.path)}
                  >
                    <ListItemIcon className="icon_block">
                      {child.icon && child.icon}
                    </ListItemIcon>
                    <ListItemText
                      className="side_bar_text"
                      primary={child.title}
                    />
                  </ListItemButton>
                ),
            )}
          </>
        )}
      </Collapse>
    </>
  );
};

export default SideBar;
