import React from 'react';
import './footer.scss';

function Footer() {
  return (
    <div className="footer_block">
      <div className="main_container">
        <div className="footer_content">
          <p className="footer_text">
            Powered by
            <a
              className="footer_link"
              target="blank"
              href="https://armitechnologies.com/"
              style={{ color: 'var(--primary_dark)' }}
            >
              <span> Armi Technologies </span>
            </a>
            LLC
          </p>
          <p className="footer_version_text">v3.2.0.4</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
