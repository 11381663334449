import React, { useState } from 'react';
import './header.scss';
import { useDispatch, useSelector } from 'react-redux';
import { GiHamburgerMenu } from 'react-icons/gi';
import { useNavigate, NavLink } from 'react-router-dom';
import ProfileMenu from '../menu_profile/ProfileMenu';
import Account from '../../services/Account';
import { isEmpty } from 'lodash';
import { Typography } from '@mui/material';
import { logOut } from '../../store/actions/users';

function Header({ toggleDrawer }) {
  const settings = useSelector(store => store.settings.settings);
  const theme = useSelector(store => store.settings.theme);
  const account = useSelector(store => store.users.account);
  const [showProfile, setShowProfile] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userLogOut = () => {
    navigate('/login', { replace: true });
    dispatch(logOut());
  };
  const myProfile = async () => {
    setShowProfile(!showProfile);
  };
  const handleNavigate = path => {
    navigate(path);
    setShowProfile(false);
  };

  return (
    <div className="header_block" style={{ backgroundColor: 'primary.light' }}>
      <div className=" main_container">
        <div className="header_content">
          <div className="header_logo">
            <Typography
              component="h1"
              variant="h4"
              style={{ color: '#fff', fontWeight: 700 }}
            >
              TravelPath
            </Typography>
          </div>
          <div className="logout_block">
            <ProfileMenu
              account={account}
              userLogOut={userLogOut}
              handleNavigate={handleNavigate}
            />
          </div>

          <div
            className="burger"
            onClick={e => {
              toggleDrawer(e);
            }}
          >
            <GiHamburgerMenu
              style={{
                fontSize: '42px',
                fill: '#fff',
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
