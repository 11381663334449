import React, { useState } from 'react';
import './webWrapper.scss';
import WebHeader from '../webHeader/WebHeader';
import WebFooter from '../webFooter/WebFooter';
import { Outlet } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';

export default function WebWrapper() {
  const [history, setHistory] = useState([]);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>AllCreteTours</title>
          {/* <link rel="icon" href="%PUBLIC_URL%/images/favicon_web.ico" /> */}
          <link rel="icon" href="./images/favicon_web.ico" />
        </Helmet>
      </HelmetProvider>
      <div>
        <WebHeader />
        <Outlet history={history} />
        <WebFooter />
      </div>
    </>
  );
}
