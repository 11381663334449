import { takeLatest, call, put } from 'redux-saga/effects';
import {
  GET_ADVERTISEMENTS_REQUEST,
  GET_ADVERTISEMENTS_SUCCESS,
  GET_ADVERTISEMENTS_FAIL,
  ADD_ADVERTISEMENT_REQUEST,
  ADD_ADVERTISEMENT_SUCCESS,
  ADD_ADVERTISEMENT_FAIL,
  DELETE_ADVERTISEMENT_REQUEST,
  DELETE_ADVERTISEMENT_SUCCESS,
  DELETE_ADVERTISEMENT_FAIL,
  GET_SINGLE_ADVERTISEMENT_REQUEST,
  GET_SINGLE_ADVERTISEMENT_SUCCESS,
  GET_SINGLE_ADVERTISEMENT_FAIL,
  UPDATE_ADVERTISEMENT_REQUEST,
  UPDATE_ADVERTISEMENT_SUCCESS,
  UPDATE_ADVERTISEMENT_FAIL,
  DELETE_SELECTED_ADVERTISEMENTS_REQUEST,
  DELETE_SELECTED_ADVERTISEMENTS_SUCCESS,
  DELETE_SELECTED_ADVERTISEMENTS_FAIL,
  EXPORT_ADVERTISEMENTS_REQUEST,
  EXPORT_ADVERTISEMENTS_SUCCESS,
  EXPORT_ADVERTISEMENTS_FAIL,
  UPDATE_STATUS_ADVERTISEMENT_REQUEST,
  UPDATE_STATUS_ADVERTISEMENT_SUCCESS,
  UPDATE_STATUS_ADVERTISEMENT_FAIL,
  GET_ALL_ADVERTISEMENTS_REQUEST,
  GET_ALL_ADVERTISEMENTS_SUCCESS,
  GET_ALL_ADVERTISEMENTS_FAIL,
} from '../actions/advertisements';
import Advertisements from '../../api/advertisements';

export default function* watcher() {
  yield takeLatest(GET_ADVERTISEMENTS_REQUEST, getAdvertisements);
  yield takeLatest(DELETE_ADVERTISEMENT_REQUEST, deleteAdvertisement);
  yield takeLatest(ADD_ADVERTISEMENT_REQUEST, addAdvertisement);
  yield takeLatest(GET_SINGLE_ADVERTISEMENT_REQUEST, getSingleAdvertisement);
  yield takeLatest(UPDATE_ADVERTISEMENT_REQUEST, updateAdvertisement);
  yield takeLatest(
    DELETE_SELECTED_ADVERTISEMENTS_REQUEST,
    deleteSelectedAdvertisements,
  );
  yield takeLatest(EXPORT_ADVERTISEMENTS_REQUEST, handleExportExcel);
  yield takeLatest(UPDATE_STATUS_ADVERTISEMENT_REQUEST, handleStatusUpdate);
  yield takeLatest(GET_ALL_ADVERTISEMENTS_REQUEST, getAllAdvertisements);
}
function* addAdvertisement(action) {
  try {
    const { formData } = action.payload;
    const { data } = yield call(Advertisements.addAdvertisement, formData);
    yield put({
      type: ADD_ADVERTISEMENT_SUCCESS,
      payload: {
        data: data.result,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    console.log(e);
    yield put({
      type: ADD_ADVERTISEMENT_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}
function* getAdvertisements(action) {
  try {
    const { page, limit, sort, search, filter } = action.payload?.searchConfig;
    const { data } = yield call(
      Advertisements.getAdvertisements,
      page,
      limit,
      search,
      filter,
      sort,
    );
    yield put({
      type: GET_ADVERTISEMENTS_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: GET_ADVERTISEMENTS_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}
function* getAllAdvertisements(action) {
  try {
    const { data } = yield call(Advertisements.getAllAdvertisements);
    yield put({
      type: GET_ALL_ADVERTISEMENTS_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: GET_ALL_ADVERTISEMENTS_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}
function* deleteAdvertisement(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(Advertisements.deleteAdvertisement, id);
    yield put({
      type: DELETE_ADVERTISEMENT_SUCCESS,
      payload: {
        data: data.result,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    console.log(e);
    yield put({
      type: DELETE_ADVERTISEMENT_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}
function* deleteSelectedAdvertisements(action) {
  try {
    const { selectedRows } = action.payload;
    const { data } = yield call(
      Advertisements.deleteSelectedAdvertisements,
      selectedRows,
    );
    yield put({
      type: DELETE_SELECTED_ADVERTISEMENTS_SUCCESS,
      payload: {
        data: data.result,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    console.log(e);
    yield put({
      type: DELETE_SELECTED_ADVERTISEMENTS_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}
function* getSingleAdvertisement(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(Advertisements.getSingleAdvertisement, id);
    yield put({
      type: GET_SINGLE_ADVERTISEMENT_SUCCESS,
      payload: {
        data: data.result,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    console.log(e);
    yield put({
      type: GET_SINGLE_ADVERTISEMENT_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}
function* updateAdvertisement(action) {
  try {
    const { formData } = action.payload;
    const { data } = yield call(Advertisements.updateAdvertisement, formData);
    yield put({
      type: UPDATE_ADVERTISEMENT_SUCCESS,
      payload: {
        data: data.result,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: UPDATE_ADVERTISEMENT_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}
function* handleExportExcel(action) {
  try {
    const { page, search, filter, sort } = action.payload?.searchConfig;
    const { data } = yield call(
      Advertisements.handleExportAdvertisementsExcel,
      page,
      search,
      filter,
      sort,
    );
    yield put({
      type: EXPORT_ADVERTISEMENTS_SUCCESS,
    });
    const blob = new Blob([data], {
      type: 'application/vnd.ms-excel',
    });
    saveAs(blob, `Advertisements.xlsx`);
  } catch (e) {
    console.log(e);
    yield put({
      type: EXPORT_ADVERTISEMENTS_FAIL,
      message: e.message,
    });
    if (action.payload.cb) {
      action.payload.cb(e.message, null);
    }
  }
}

function* handleStatusUpdate(action) {
  try {
    const { id } = action.payload;

    const { data } = yield call(Advertisements.updateStatus, id);
    yield put({
      type: UPDATE_STATUS_ADVERTISEMENT_SUCCESS,
      payload: data,
    });

    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    console.warn(e.response);
    yield put({
      type: UPDATE_STATUS_ADVERTISEMENT_FAIL,
      message: e.message,
      payload: e.response.data,
    });
    if (action.payload.cb) {
      action.payload.cb(e.response.data, null);
    }
  }
}
