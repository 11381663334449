import React from 'react';
import './webHeader.scss';
import logo from './images/logo.png';
import Grid from '@mui/material/Unstable_Grid2';
import { Link } from 'react-router-dom';

export default function WebHeader() {
  return (
    <div className="web_header_block">
      <div className="web_container">
        <Grid container spacing={0} className="web_header_grid">
          <Grid md={3} sm={3} xs={6} className="web_header_grid_item first">
            <div className="logo_block">
              <img className="logo_img" src={logo} alt="" />
            </div>
          </Grid>
          <Grid md={6} sm={6} xs={15} className="web_header_grid_item second">
            <div className="nav_bar">
              <ul className="nav_ul">
                <li className="nav_li">
                  <a target="_blank" href="https://www.allcretetours.com">
                    Home
                  </a>
                </li>
                <li className="nav_li">
                  <a target="_blank" href="https://www.allcretetours.com/">
                    Tours
                  </a>
                </li>
                <li className="nav_li">
                  <a
                    target="_blank"
                    href="https://www.allcretetours.com/contact"
                  >
                    Contact
                  </a>
                </li>
                <li className="nav_li">
                  <Link target="_blank" to="/voucher">
                    Tour Vouchers
                  </Link>
                </li>
                <li className="nav_li">
                  <Link target="_blank" to="/transfer">
                    Transfer Vouchers
                  </Link>
                </li>
              </ul>
            </div>
          </Grid>
          <Grid md={3} sm={3} xs={6} className="web_header_grid_item last">
            <div className="button_block">
              <a href="https://www.allcretetours.com/" target="_blank">
                <button className="button">{'Book Now'}</button>
              </a>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
