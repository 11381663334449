import { GridActionsCellItem } from '@mui/x-data-grid';
import {
  MdDelete,
  MdEdit,
  MdMyLocation,
  MdOutlineEditLocation,
} from 'react-icons/md';
import { FaToggleOff, FaToggleOn } from 'react-icons/fa';
import React from 'react';
import moment from 'moment';
import { TbTableExport } from 'react-icons/tb';

export const getColumns = ({
  redirectToPreview,
  handleDelete,
  handleUpdateStatus,
  redirectToPreviewTransferVoucher,
  openCommentModal,
  openCopyModal,
  openEmailModal,
  role,
  navigate,
  handleOpenDriverModal,
}) => {
  return [
    {
      field: 'id',
      headerName: '#',
      width: 70,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 100,
    },
    {
      field: 'transfer_name',
      headerName: 'Transfer Name',
      width: 200,
    },
    {
      field: 'from_location',
      headerName: 'From',
      width: 200,
    },
    {
      field: 'to_location',
      headerName: 'To',
      width: 200,
    },
    {
      field: 'service_date',
      headerName: 'Service Date',
      width: 200,
      renderCell: ({ row }) => {
        if (row?.service_date) {
          return moment(row.service_date).format('MM/DD/YYYY');
        }
      },
    },
    {
      field: 'driverUserId.name',
      headerName: 'Driver',
      width: 200,
      renderCell: ({ row }) => {
        if (row?.driverUserId?.name) {
          if (role === 'Admin') {
            return (
              <span
                onClick={ev => navigate(`/admin/users/${row.driverUserId?.id}`)}
                className="link-span-in-table"
              >
                {row?.driverUserId?.name || ''}
              </span>
            );
          } else {
            return row?.driverUserId?.name;
          }
        }
      },
    },
    {
      field: 'pick_up_point.pick_up_name',
      headerName: 'Starting Point',
      width: 200,
      renderCell: ({ row }) => {
        if (row?.pick_up_point?.pick_up_name) {
          if (role === 'Admin') {
            return (
              <span
                onClick={ev =>
                  navigate(`/admin/pick_up_points/${row?.pick_up_point?.id}`)
                }
                className="link-span-in-table"
              >
                {row?.pick_up_point?.pick_up_name || ''}
              </span>
            );
          } else {
            return row?.pick_up_point?.pick_up_name;
          }
        }
      },
    },
    {
      field: 'duration',
      headerName: 'Duration (h)',
      width: 200,
    },
    {
      field: 'notes',
      headerName: 'Notes',
      width: 200,
    },
    {
      field: 'start_time',
      headerName: 'Start Time',
      width: 200,
      renderCell: ({ row }) => {
        if (row?.start_time) {
          const formattedTime = moment(row.start_time, 'HH:mm').format(
            'hh:mm A',
          );
          return <span>{formattedTime}</span>;
        }
      },
    },
    {
      field: 'end_time',
      headerName: 'End Time',
      width: 200,
      renderCell: ({ row }) => {
        if (row?.end_time) {
          const formattedTime = moment(row.end_time, 'HH:mm').format('hh:mm A');
          return <span>{formattedTime}</span>;
        }
      },
    },
    {
      field: 'emergency_number',
      headerName: 'Emergency Number',
      width: 200,
      renderCell: ({ row }) => {
        return row.emergency_number
          ? `${
              row.emergency_number.startsWith('+')
                ? row.emergency_number
                : '+' + row.emergency_number
            }`
          : '';
      },
    },
    {
      field: 'transfer_type',
      headerName: 'Transfer Type',
      width: 200,
      renderCell: params => params?.row?.transfer_type?.type,
      //     renderCell: ({row}) => {
      //         if (row?.transfer_type?.type) {
      //             if (role === "Supplier") {
      //                 return row?.transfer_type?.type
      //             } else {
      //                 return (
      //                     <span
      //                         onClick={ev =>
      //                             navigate(`/admin/transfer_types/${row?.transfer_type?.id}`)
      //                         }
      //                         className="link-span-in-table"
      //                     >
      //         {row?.transfer_type?.type || ''}
      //       </span>
      //                 );
      //             }
      //
      //         }
    },
    {
      field: 'including_service_info',
      headerName: 'Including Service',
      width: 200,
    },
    {
      field: 'including_service_type',
      headerName: 'Including Service Type',
      width: 200,
    },
    {
      field: 'driver_location_url',
      headerName: 'Driver Location Url',
      width: 200,
    },
    {
      access: ['Admin', 'Supplier'],
      field: 'adultSum',
      headerName: 'Adults',
      width: 80,
    },
    {
      access: ['Admin', 'Supplier'],
      field: 'childSum',
      headerName: 'Children',
      width: 80,
    },
    {
      access: ['Admin', 'Supplier'],
      field: 'infantSum',
      headerName: 'Infants',
      width: 80,
    },
    {
      access: ['Admin', 'Supplier'],
      field: 'allPassengers',
      headerName: 'Total Passengers',
      width: 100,
    },
    
    {
      field: 'created_at',
      headerName: 'Created Date',
      width: 200,
      renderCell: params =>
        moment(params?.created_at || params?.row.created_at).format(
          'MM/DD/YYYY HH:mm:ss',
        ),
    },
    {
      field: 'createdBy.name',
      headerName: 'Created By',
      width: 150,
      renderCell: ({ row }) => row?.createdBy?.name || '',
    },
    {
      field: 'updated_at',
      headerName: 'Modified Date',
      width: 200,
      renderCell: params =>
        moment(params?.updated_at || params?.row.updated_at).format(
          'MM/DD/YYYY HH:mm:ss',
        ),
    },
    {
      field: 'updatedBy.name',
      headerName: 'Modified By',
      width: 150,
      renderCell: ({ row }) => row?.updatedBy?.name || '',
    },
    {
      headerName: 'Actions',
      field: 'actions',
      cellClassName: 'actions',
      type: 'actions',
      access: ['Admin', 'Supplier'],
      width: 200,
      getActions: params =>
        [
          <GridActionsCellItem
            onClick={() =>
              redirectToPreviewTransferVoucher(params?.row?.id || params?.id)
            }
            className="test1"
            icon={<TbTableExport size={21} style={{ fill: 'none' }} />}
            label="Edit"
            title="Transfers"
            access={['Admin', 'Driver']}
            width={50}
          />,
          <GridActionsCellItem
            onClick={() =>
              handleEditPickUpPoint(
                params?.row?.pick_up_point_id,
                params?.row?.id,
              )
            }
            icon={<MdOutlineEditLocation size={19} />}
            label="Edit Pick-Up Point"
            title="Edit Pick-Up Point"
            className="test1"
            access={['Supplier']}
          />,
          <GridActionsCellItem
            onClick={() => redirectToPreview(params?.row?.id || params?.id)}
            className="test1"
            icon={<MdEdit />}
            label="Edit"
            title="View/Edit"
            access={['Admin']}
            width={50}
          />,
          <GridActionsCellItem
            onClick={() => handleOpenDriverModal(params?.row)}
            icon={<MdMyLocation size={19} />}
            label="Edit Driver Location Url"
            title="Edit Driver Location Url"
            className="test1"
            access={['Driver']}
          />,
          <GridActionsCellItem
            style={
              // params.status || params?.row.status?
              {
                cursor: 'pointer',
              }
              // : {
              //     cursor: 'auto',
              //     opacity: 0.4,
              //   }
            }
            onClick={ev => handleUpdateStatus(params?.row || params)}
            icon={
              params?.status === 'Active' ||
              params?.row?.status === 'Active' ? (
                <FaToggleOn />
              ) : (
                <FaToggleOff />
              )
            }
            label="Status"
            title="Change Status"
            access={['Admin']}
            width={50}
          />,
          <GridActionsCellItem
            icon={<MdDelete />}
            label="Delete"
            title="Delete"
            onClick={ev => handleDelete(ev, params?.row || params)}
            access={['Admin']}
            width={50}
          />,
        ].filter(({ props }) => {
          return props.access.includes(role);
        }),
    },
  ];
};
