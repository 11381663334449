import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import BasicLineChart from './charts/Chart';
import { isEmpty } from 'lodash';
import Select from 'react-select';
import moment from 'moment';
import { Skeleton } from '@mui/joy';

const customStyles = {
  control: provided => ({
    ...provided,
    fontSize: '14px',
  }),
  option: provided => ({
    ...provided,
    fontSize: '14px',
  }),
};

function DashboardCard({ name, iconComponent, tours, rowStatus }) {
  const [filteredTours, setFilteredTours] = useState([]);
  const [selectedYear, setSelectedYear] = useState(2024);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [minYear, setMinYear] = useState(2024);

  useEffect(() => {
    if (!isEmpty(tours)) {
      filterTours(selectedYear, selectedMonth);
      const years = tours.map(tour => moment(tour.created_at).year());
      const min = Math.min(...years);
      setMinYear(min);
    }
  }, [tours, selectedYear, selectedMonth]);

  const filterTours = (year, month) => {
    let filtered = tours.filter(tour => {
      const tourDate = moment(tour.created_at);
      return (
        tourDate.year() === year &&
        (!month || tourDate.month() === moment().month(month).month())
      );
    });
    setFilteredTours(filtered);
  };

  const handleYearChange = selectedOption => {
    setSelectedYear(selectedOption.value);
  };

  const handleMonthChange = selectedOption => {
    setSelectedMonth(selectedOption ? selectedOption.value : null);
  };
  return (
    <>
      <Card className="dashboardCard">
        <CardContent style={{ marginLeft: '2%', color: '#3e5674' }}>
          <div className="d-flex dashboard-title justify-content-between">
            <div>
              <Typography variant="h6" component="div" className="dashboard_h6">
                {name} - {filteredTours.length || 0}
              </Typography>
            </div>
            <div className="d-flex date_component">
              <div style={{ marginRight: '15px' }}>
                <span>Year</span>
                <Select
                  id={'year'}
                  size="small"
                  className="row_control"
                  value={{ value: selectedYear }}
                  onChange={handleYearChange}
                  getOptionLabel={i => i.value}
                  getOptionValue={i => i.value}
                  options={Array.from(
                    { length: moment().year() - minYear + 1 },
                    (_, i) => ({ value: minYear + i }),
                  ).reverse()}
                  isSearchable={false}
                  styles={customStyles}
                  hideSelectedOptions={true}
                />
              </div>
              <div>
                <span>Month</span>
                <Select
                  id={'month'}
                  size="small"
                  className="row_control"
                  value={selectedMonth ? { value: selectedMonth } : null}
                  onChange={handleMonthChange}
                  getOptionLabel={i => i.value}
                  getOptionValue={i => i.value}
                  options={moment.months().map(m => ({ value: m }))}
                  isSearchable={false}
                  styles={customStyles}
                  hideSelectedOptions={true}
                  isClearable={true}
                />
              </div>
            </div>
          </div>
          <Typography
            style={{ marginLeft: '1%', fontSize: '16px' }}
            variant="body2"
            color="text.secondary"
          ></Typography>
        </CardContent>
        <BasicLineChart
          data={!isEmpty(filteredTours) ? filteredTours : []}
          selectedMonth={selectedMonth}
          selectedYear={selectedYear}
        />
      </Card>
    </>
  );
}

export default DashboardCard;
