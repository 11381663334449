import React, { useState } from 'react';
import './search.scss';
import { Form, Button, InputGroup } from 'react-bootstrap';

export default function Search({ searchChange, searchValue }) {
  return (
    <div
      className="search_comp"
      onSubmit={e => {
        handleSearch(e);
      }}
    >
      <InputGroup className="mb-1" style={{ height: '100%' }}>
        <Form.Control
          placeholder="Search"
          aria-label="Search"
          aria-describedby="basic-addon2"
          // defaultValue={searchConfig.search}
          value={searchValue}
          onChange={searchChange}
        />
      </InputGroup>
    </div>
  );
}
