import {takeLatest, call, put} from 'redux-saga/effects';
import {
    GET_TOUR_VOUCHERS_REQUEST,
    GET_TOUR_VOUCHERS_SUCCESS,
    GET_TOUR_VOUCHERS_FAIL,
    ADD_TOUR_VOUCHER_REQUEST,
    ADD_TOUR_VOUCHER_SUCCESS,
    ADD_TOUR_VOUCHER_FAIL,
    DELETE_TOUR_VOUCHER_REQUEST,
    DELETE_TOUR_VOUCHER_SUCCESS,
    DELETE_TOUR_VOUCHER_FAIL,
    GET_SINGLE_TOUR_VOUCHER_REQUEST,
    GET_SINGLE_TOUR_VOUCHER_SUCCESS,
    GET_SINGLE_TOUR_VOUCHER_FAIL,
    UPDATE_TOUR_VOUCHER_REQUEST,
    UPDATE_TOUR_VOUCHER_SUCCESS,
    UPDATE_TOUR_VOUCHER_FAIL,
    DELETE_SELECTED_TOUR_VOUCHERS_REQUEST,
    DELETE_SELECTED_TOUR_VOUCHERS_SUCCESS,
    DELETE_SELECTED_TOUR_VOUCHERS_FAIL,
    GET_ALL_TOUR_VOUCHERS_REQUEST,
    GET_ALL_TOUR_VOUCHERS_SUCCESS,
    GET_ALL_TOUR_VOUCHERS_FAIL,
    EXPORT_TOUR_VOUCHERS_REQUEST,
    EXPORT_TOUR_VOUCHERS_SUCCESS,
    EXPORT_TOUR_VOUCHERS_FAIL,
    CHANGE_TOUR_VOUCHER_STATUS_REQUEST,
    CHANGE_TOUR_VOUCHER_STATUS_SUCCESS,
    CHANGE_TOUR_VOUCHER_STATUS_FAIL,
    ADD_COMMENT_TOUR_VOUCHER_REQUEST,
    ADD_COMMENT_TOUR_VOUCHER_SUCCESS,
    ADD_COMMENT_TOUR_VOUCHER_FAIL,
    COPY_TOUR_VOUCHER_REQUEST,
    COPY_TOUR_VOUCHER_SUCCESS,
    COPY_TOUR_VOUCHER_FAIL,
    SEND_EMAIL_TOUR_VOUCHER_REQUEST,
    SEND_EMAIL_TOUR_VOUCHER_SUCCESS,
    SEND_EMAIL_TOUR_VOUCHER_FAIL,
    CHECK_VOUCHER_REQUEST,
    CHECK_VOUCHER_SUCCESS,
    CHECK_VOUCHER_FAIL,
    DOWNLOAD_VOUCHER_REQUEST,
    DOWNLOAD_VOUCHER_SUCCESS,
    DOWNLOAD_VOUCHER_FAIL,
    FETCH_TOUR_VOUCHER_REQUEST,
    FETCH_TOUR_VOUCHER_SUCCESS,
    FETCH_TOUR_VOUCHER_FAIL,
    GET_TOUR_PREVIEW_VOUCHERS_REQUEST,
    GET_TOUR_PREVIEW_VOUCHERS_SUCCESS,
    GET_TOUR_PREVIEW_VOUCHERS_FAIL,
    GET_VOUCHER_LOCATION_REQUEST,
    GET_VOUCHER_LOCATION_SUCCESS,
    GET_VOUCHER_LOCATION_FAIL,
    SHARE_MY_LOCATION_REQUEST,
    SHARE_MY_LOCATION_SUCCESS,
    SHARE_MY_LOCATION_FAIL,
    UPDATE_TOUR_VOUCHER_STATE_REQUEST,
    DOWNLOAD_TOUR_VOUCHERS_REPORT_REQUEST,
    DOWNLOAD_TOUR_VOUCHERS_REPORT_SUCCESS,
    DOWNLOAD_TOUR_VOUCHERS_REPORT_FAIL,
    DOWNLOAD_TOUR_VOUCHERS_KPI_REPORT_REQUEST,
    DOWNLOAD_TOUR_VOUCHERS_KPI_REPORT_SUCCESS,
    DOWNLOAD_TOUR_VOUCHERS_KPI_REPORT_FAIL,
    GET_TOUR_VOUCHERS_FOR_KPI_REPORT_REQUEST,
    GET_TOUR_VOUCHERS_FOR_KPI_REPORT_SUCCESS,
    GET_TOUR_VOUCHERS_FOR_KPI_REPORT_FAIL,
} from '../actions/tourVouchers';
import TourVouchers from '../../api/tourVouchers';
import {saveAs} from 'file-saver';
import dayjs from "dayjs";

export default function* watcher() {
    yield takeLatest(GET_TOUR_VOUCHERS_REQUEST, getTourVouchers);
    yield takeLatest(GET_TOUR_PREVIEW_VOUCHERS_REQUEST, getPreviewTourVouchers);
    yield takeLatest(FETCH_TOUR_VOUCHER_REQUEST, fetchTourVouchers);
    yield takeLatest(CHANGE_TOUR_VOUCHER_STATUS_REQUEST, handleChangeStatus);
    yield takeLatest(DELETE_TOUR_VOUCHER_REQUEST, deleteTourVoucher);
    yield takeLatest(ADD_TOUR_VOUCHER_REQUEST, addTourVoucher);
    yield takeLatest(GET_SINGLE_TOUR_VOUCHER_REQUEST, getSingleTourVoucher);
    yield takeLatest(UPDATE_TOUR_VOUCHER_REQUEST, updateTourVoucher);
    yield takeLatest(UPDATE_TOUR_VOUCHER_STATE_REQUEST, updateTourVoucherState);
    yield takeLatest(GET_VOUCHER_LOCATION_REQUEST, getTourVoucherLocation);
    yield takeLatest(ADD_COMMENT_TOUR_VOUCHER_REQUEST, addCommentTour);
    yield takeLatest(COPY_TOUR_VOUCHER_REQUEST, copyTourVoucher);
    yield takeLatest(SEND_EMAIL_TOUR_VOUCHER_REQUEST, handleSendEmail);
    yield takeLatest(
        DELETE_SELECTED_TOUR_VOUCHERS_REQUEST,
        deleteSelectedTourVouchers,
    );
    yield takeLatest(GET_ALL_TOUR_VOUCHERS_REQUEST, getAllTourVouchers);
    yield takeLatest(EXPORT_TOUR_VOUCHERS_REQUEST, handleExportExcel);
    yield takeLatest(CHECK_VOUCHER_REQUEST, checkVoucher);
    yield takeLatest(DOWNLOAD_VOUCHER_REQUEST, downloadVoucher);
    yield takeLatest(SHARE_MY_LOCATION_REQUEST, shareLocation);
    yield takeLatest(
        DOWNLOAD_TOUR_VOUCHERS_REPORT_REQUEST,
        downloadTourVouchersReport,
    );
    yield takeLatest(
        DOWNLOAD_TOUR_VOUCHERS_KPI_REPORT_REQUEST,
        downloadTourVouchersKPIReport,
    );
    yield takeLatest(
        GET_TOUR_VOUCHERS_FOR_KPI_REPORT_REQUEST,
        getTourVouchersForKPIReport,
    );

}

function* addTourVoucher(action) {
    try {
        const {formData} = action.payload;
        const {data} = yield call(TourVouchers.addTourVoucher, formData);
        yield put({
            type: ADD_TOUR_VOUCHER_SUCCESS,
            payload: {
                data: data.result,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(null, data);
        }
    } catch (e) {
        yield put({
            type: ADD_TOUR_VOUCHER_FAIL,
            payload: {
                massage: e.massage,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(e, null);
        }
    }
}

function* getTourVouchers(action) {
    try {
        const {page, limit, sort, search, filter} = action.payload?.searchConfig;
        const {data} = yield call(
            TourVouchers.getTourVouchers,
            page,
            limit,
            search,
            filter,
            sort,
        );

        yield put({
            type: GET_TOUR_VOUCHERS_SUCCESS,
            payload: {
                data: data,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(null, data);
        }
    } catch (e) {
        yield put({
            type: GET_TOUR_VOUCHERS_FAIL,
            payload: {
                massage: e.massage,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(e, null);
        }
    }
}

function* getPreviewTourVouchers(action) {
    try {
        const {page, limit, sort, search, filter} = action.payload?.searchConfig;
        const {data} = yield call(
            TourVouchers.getPreviewVoucher,
            action.payload.id,
            page,
            limit,
            search,
            filter,
            sort,
        );

        yield put({
            type: GET_TOUR_PREVIEW_VOUCHERS_SUCCESS,
            payload: {
                data: data,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(null, data);
        }
    } catch (e) {
        yield put({
            type: GET_TOUR_PREVIEW_VOUCHERS_FAIL,
            payload: {
                massage: e.massage,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(e, null);
        }
    }
}

function* deleteTourVoucher(action) {
    try {
        const {id} = action.payload;
        const {data} = yield call(TourVouchers.deleteTourVoucher, id);
        yield put({
            type: DELETE_TOUR_VOUCHER_SUCCESS,
            payload: {
                data: data.result,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(null, data);
        }
    } catch (e) {
        console.log(e);
        yield put({
            type: DELETE_TOUR_VOUCHER_FAIL,
            payload: {
                massage: e.massage,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(e, null);
        }
    }
}

function* deleteSelectedTourVouchers(action) {
    try {
        const {selectedRows} = action.payload;
        const {data} = yield call(
            TourVouchers.deleteSelectedTourVouchers,
            selectedRows,
        );
        yield put({
            type: DELETE_SELECTED_TOUR_VOUCHERS_SUCCESS,
            payload: {
                data: data.result,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(null, data);
        }
    } catch (e) {
        console.log(e);
        yield put({
            type: DELETE_SELECTED_TOUR_VOUCHERS_FAIL,
            payload: {
                massage: e.massage,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(e, null);
        }
    }
}

function* getSingleTourVoucher(action) {
    try {
        const {id} = action.payload;
        const {data} = yield call(TourVouchers.getSingleTourVoucher, id);
        yield put({
            type: GET_SINGLE_TOUR_VOUCHER_SUCCESS,
            payload: {
                data: data.result,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(null, data);
        }
    } catch (e) {
        console.log(e);
        yield put({
            type: GET_SINGLE_TOUR_VOUCHER_FAIL,
            payload: {
                massage: e.massage,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(e, null);
        }
    }
}

function* getTourVoucherLocation(action) {
    try {
        const {id} = action.payload;
        const {data} = yield call(TourVouchers.getTourVoucherLoc, id);
        yield put({
            type: GET_VOUCHER_LOCATION_SUCCESS,
            payload: {
                data: data.result,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(null, data?.result);
        }
    } catch (e) {
        console.log(e);
        yield put({
            type: GET_VOUCHER_LOCATION_FAIL,
            payload: {
                massage: e.massage,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(e, null);
        }
    }
}

function* updateTourVoucher(action) {
    try {
        const {formData} = action.payload;
        const {data} = yield call(TourVouchers.updateTourVoucher, formData);
        yield put({
            type: UPDATE_TOUR_VOUCHER_SUCCESS,
            payload: {
                data: data.result,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(null, data);
        }
    } catch (e) {
        yield put({
            type: UPDATE_TOUR_VOUCHER_FAIL,
            payload: {
                massage: e.massage,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(e, null);
        }
    }
}

function* updateTourVoucherState(action) {
    try {
        const {formData} = action.payload;
        const {data} = yield call(TourVouchers.updateTourVoucherState, formData);
        yield put({
            type: UPDATE_TOUR_VOUCHER_SUCCESS,
            payload: {
                data: data.result,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(null, data);
        }
    } catch (e) {
        yield put({
            type: UPDATE_TOUR_VOUCHER_FAIL,
            payload: {
                massage: e.massage,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(e, null);
        }
    }
}

function* getAllTourVouchers(action) {
    try {
        const {data} = yield call(TourVouchers.getAllTourVouchers);
        yield put({
            type: GET_ALL_TOUR_VOUCHERS_SUCCESS,
            payload: {
                data: data,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(null, data);
        }
    } catch (e) {
        yield put({
            type: GET_ALL_TOUR_VOUCHERS_FAIL,
            payload: {
                massage: e.massage,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(e, null);
        }
    }
}

function* handleExportExcel(action) {
    try {
        const {page, search, filter, sort} = action.payload?.searchConfig;
        const {data} = yield call(
            TourVouchers.handleExportExcel,
            page,
            search,
            filter,
            sort,
        );
        yield put({
            type: EXPORT_TOUR_VOUCHERS_SUCCESS,
        });
        const blob = new Blob([data], {
            type: 'application/vnd.ms-excel',
        });
        saveAs(blob, `TourVouchers.xlsx`);
    } catch (e) {
        yield put({
            type: EXPORT_TOUR_VOUCHERS_FAIL,
            message: e.message,
        });
        if (action.payload.cb) {
            action.payload.cb(e.message, null);
        }
    }
}

function* handleChangeStatus(action) {
    try {
        const {id} = action.payload;
        const {data} = yield call(TourVouchers.changeTourVoucherStatus, id);
        yield put({
            type: CHANGE_TOUR_VOUCHER_STATUS_SUCCESS,
            payload: {
                data: data,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(null, data);
        }
    } catch (e) {
        console.log(e);
        yield put({
            type: CHANGE_TOUR_VOUCHER_STATUS_FAIL,
            payload: {
                massage: e.massage,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(e, null);
        }
    }
}

function* addCommentTour(action) {
    try {
        const {comment, id} = action.payload;
        const {data} = yield call(TourVouchers.addComment, id, comment);
        yield put({
            type: ADD_COMMENT_TOUR_VOUCHER_SUCCESS,
            payload: {
                data: data,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(null, data);
        }
    } catch (e) {
        console.log(e);
        yield put({
            type: ADD_COMMENT_TOUR_VOUCHER_FAIL,
            payload: {
                massage: e.massage,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(e, null);
        }
    }
}

function* copyTourVoucher(action) {
    try {
        const {id} = action.payload;
        const {data} = yield call(TourVouchers.copy, id);
        yield put({
            type: COPY_TOUR_VOUCHER_SUCCESS,
            payload: {
                data: data,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(null, data);
        }
    } catch (e) {
        console.log(e);
        yield put({
            type: COPY_TOUR_VOUCHER_FAIL,
            payload: {
                massage: e.massage,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(e, null);
        }
    }
}

function* handleSendEmail(action) {
    try {
        const {id, formData} = action.payload;
        const {data} = yield call(
            TourVouchers.sendTourVoucherEmail,
            id,
            formData,
        );
        yield put({
            type: SEND_EMAIL_TOUR_VOUCHER_SUCCESS,
            payload: {
                data: data,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(null, data);
        }
    } catch (e) {
        yield put({
            type: SEND_EMAIL_TOUR_VOUCHER_FAIL,
            payload: {
                massage: e.massage,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(e, null);
        }
    }
}

function* checkVoucher(action) {
    try {
        const {formData} = action.payload;
        const {data} = yield call(TourVouchers.checkTourVoucher, formData);
        yield put({
            type: CHECK_VOUCHER_SUCCESS,
            payload: {
                data: data,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(null, data);
        }
    } catch (e) {
        yield put({
            type: CHECK_VOUCHER_FAIL,
            payload: {
                massage: e.massage,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(e.response?.data?.message, null);
        }
    }
}

function* downloadVoucher(action) {
    try {
        const {id, ag_ref_number, page, role} = action.payload;
        const {data} = yield call(TourVouchers.downloadVoucher, {id, role});
        const blob = new Blob([data], {type: 'application/pdf; charset=utf-8'});
        yield put({
            type: DOWNLOAD_VOUCHER_SUCCESS,
            payload: {
                data: data,
            },
        });

        saveAs(blob, `${page}_${ag_ref_number}.pdf`);
        if (action.payload.cb) {
            action.payload.cb(null, data);
        }
    } catch (e) {
        yield put({
            type: DOWNLOAD_VOUCHER_FAIL,
            payload: {
                massage: e.massage,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(e, null);
        }
    }
}

function* fetchTourVouchers(action) {
    try {
        const {data} = yield call(TourVouchers.fetchTourVouchers);

        yield put({
            type: FETCH_TOUR_VOUCHER_SUCCESS,
            payload: {
                data: data,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(null, data);
        }
    } catch (e) {
        yield put({
            type: FETCH_TOUR_VOUCHER_FAIL,
            payload: {
                massage: e.massage,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(e?.response?.data?.message, null);
        }
    }
}

function* shareLocation(action) {
    try {
        const location = action.payload;
        const {data} = yield call(TourVouchers.shareMyLocation, location);

        yield put({
            type: SHARE_MY_LOCATION_SUCCESS,
            payload: {
                data: data,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(null, data);
        }
    } catch (e) {
        yield put({
            type: SHARE_MY_LOCATION_FAIL,
            payload: {
                massage: e.massage,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(e?.response?.data?.message, null);
        }
    }
}

function* downloadTourVouchersReport(action) {
    try {
        const {group_field, user_id, service_date_from, service_date_to} =
            action.payload?.data;

        const {data} = yield call(
            TourVouchers.handleDownloadReport,
            group_field,
            user_id,
            service_date_from,
            service_date_to,
        );
        yield put({
            type: DOWNLOAD_TOUR_VOUCHERS_REPORT_SUCCESS,
        });
        const blob = new Blob([data], {
            type: 'application/vnd.ms-excel',
        });
        if (action.payload.cb) {
            action.payload.cb(null, blob);
        }
        if (user_id?.id === 'all') {
            saveAs(
                blob,
                service_date_from && service_date_to
                    ? `TourVouchers_All_${group_field?.name}s_${service_date_from} - ${service_date_to}.xlsx`
                    : !service_date_from && service_date_to
                        ? `TourVouchers_All_${group_field?.name}s_${service_date_to}.xlsx`
                        : service_date_from && !service_date_to
                            ? `TourVouchers_All_${group_field?.name}s_${service_date_from}.xlsx`
                            : !service_date_from && !service_date_to && `TourVouchers_All_${group_field?.name}s.xlsx`,
            );
        } else {
            saveAs(
                blob,
                service_date_from && service_date_to
                    ? `TourVouchers_${group_field?.name}s_${user_id?.name}_${service_date_from} - ${service_date_to}.xlsx`
                    : !service_date_from && service_date_to
                        ? `TourVouchers_${group_field?.name}s_${user_id?.name}_${service_date_to}.xlsx`
                        : service_date_from && !service_date_to
                            ? `TourVouchers_${group_field?.name}s_${user_id?.name}_${service_date_from}.xlsx`
                            : !service_date_from && !service_date_to && `TourVouchers_${group_field?.name}s_${user_id?.name}.xlsx`,
            );
        }
    } catch (e) {
        yield put({
            type: DOWNLOAD_TOUR_VOUCHERS_REPORT_FAIL,
            message: e.message,
        });
        if (action.payload.cb) {
            action.payload.cb(e.message, null);
        }
    }
}

function* getTourVouchersForKPIReport(action) {
    try {
        const {data} = yield call(
            TourVouchers.handleGetVouchersForKPIReport,
        );
        yield put({
            type: GET_TOUR_VOUCHERS_FOR_KPI_REPORT_SUCCESS,
        });

        if (action.payload.cb) {
            action.payload.cb(null, data);
        }
    } catch (e) {
        yield put({
            type: GET_TOUR_VOUCHERS_FOR_KPI_REPORT_FAIL,
            message: e.message,
        });
        if (action.payload.cb) {
            action.payload.cb(e.message, null);
        }
    }
}

function* downloadTourVouchersKPIReport(action) {
    try {
        const {group_field, user_id, year} =
            action.payload?.data;
        const {data} = yield call(
            TourVouchers.handleDownloadKPIReport,
            group_field,
            user_id,
            year,
        );
        yield put({
            type: DOWNLOAD_TOUR_VOUCHERS_KPI_REPORT_SUCCESS,
        });
        const blob = new Blob([data], {
            type: 'application/vnd.ms-excel',
        });
        if (action.payload.cb) {
            action.payload.cb(null, blob);
        }
        if (user_id?.id === 'all') {
            saveAs(
                blob,
                dayjs().format('YYYY') > year.value
                    ? `KPI_Report_${group_field?.name}_All_${year.value}.xlsx`
                    : dayjs().format('YYYY') == year.value
                    && `KPI_Report_${group_field?.name}_All_${year.value}.xlsx`,
            );
        } else {
            saveAs(
                blob,
                dayjs().format('YYYY') > year?.value
                    ? `KPI_Report_${group_field?.name}_${user_id?.name}_${year?.value}.xlsx`
                    : dayjs().format('YYYY') == year?.value
                    && `KPI_Report_${group_field?.name}_${user_id?.name}_${year?.value}.xlsx`
            );
        }
    } catch (e) {
        yield put({
            type: DOWNLOAD_TOUR_VOUCHERS_KPI_REPORT_FAIL,
            message: e.message,
        });
        if (action.payload.cb) {
            action.payload.cb(e.message, null);
        }
    }
}
