import React from 'react';
import { IoArrowBackCircle } from 'react-icons/io5';
import { Link, useNavigate } from 'react-router-dom';
import { RiRadioButtonLine } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Cookie from 'js-cookie';

function PageTitle({ parentTitle, childTitle, parentLink, status, formColor }) {
  const blockedPage = useSelector(store => store.search.blockedPage);
  const navigate = useNavigate();

  return (
    <>
      {parentLink ? (
        <div className="admin_tables_title">
          <div className="admin_tables_title_div">
            <span className="pageTitle">
              <Link to={parentLink} className="parrentPage">
                {parentTitle}
              </Link>{' '}
              / {childTitle}
            </span>
            {status && (
              <>
                <span
                  className="status_icon"
                  style={{
                    color: formColor?.color,
                    borderColor: formColor?.color,
                  }}
                >
                  {status}
                </span>
                <RiRadioButtonLine
                  className="online"
                  style={{
                    color: formColor?.color,
                  }}
                />
              </>
            )}
          </div>
          <span
            className="backSection"
            onClick={() => {
              if (!blockedPage) {
                if (+Cookie.get('history')) {
                  navigate(-1);
                } else {
                  navigate(parentLink);
                }
              } else {
                toast.error(
                  'You have unsaved changes on this form. Please save them or click "Reset" to undo.',
                  {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 10000,
                  },
                );
              }
            }}
          >
            <span>
              <IoArrowBackCircle /> <span className="go_back">Go Back</span>
            </span>
          </span>
        </div>
      ) : (
        <div className="admin_tables_title">
          <span className="pageTitle">{parentTitle}</span>
        </div>
      )}
    </>
  );
}

export default PageTitle;
