import React from 'react';
import {GoogleMap, LoadScript, Marker} from '@react-google-maps/api';

function LocationMap({usersLocations, coordinates}) {

    // const apiKey = 'AIzaSyDDyaA6llK5Ghq1HeHz5o2ik_Mmfd1F_30';
    const apiKey = 'AIzaSyBHw0bPMraOWu_rJY3hwWTbIAZbh0AwJGg';

    const mapContainerStyle = {
        width: '100%',
        height: '500px',
    };

    return (
        <LoadScript googleMapsApiKey={apiKey}>
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={{
                    lat: +coordinates.lat,
                    lng: +coordinates.lng
                }}
                zoom={18}
            >
                {coordinates.isPickUpPoint ? <Marker
                    position={{lat: +coordinates?.lat, lng: +coordinates?.lng}}
                    label={"P"}
                /> : null}

                {usersLocations?.map((location, index) => (
                    <Marker
                        key={index}
                        position={{lat: +location?.latitude, lng: +location?.longitude}}
                        label={location.id.toString()}
                    />
                ))}
            </GoogleMap>
        </LoadScript>
    );
}

export default LocationMap;
