import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  authUserAction,
  exchangeCodeAction,
  loginRequest,
} from '../store/actions/users';

function Login() {
  const dispatch = useDispatch();
  const [lock, setLock] = useState(true);
  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [query, setQuery] = useState({});
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const handleChange = (key, val) => {
    setFormData({ ...formData, [key]: val });
  };
  const getQueryParams = search => {
    return Object.fromEntries(new URLSearchParams(search));
  };

  useEffect(() => {
    const queryObj = getQueryParams(location.search);
    if (queryObj?.code) {
      dispatch(exchangeCodeAction(queryObj));
    }
  }, []);

  window?.history.pushState(null, '', window?.location?.href);
  window?.addEventListener('popstate', function (event) {
    window?.history?.pushState(null, document?.title, window?.location.href);
  });

  useEffect(() => {
    window.onpopstate = () => {
      window.history.pushState(null, '', window.location.href);
    };
  }, []);

  const handleLock = () => {
    setLock(!lock);
  };

  const handleSubmit = event => {
    event.preventDefault();
    const queryParams = getQueryParams(location.search);
    dispatch(
      authUserAction(queryParams, (err, data) => {
        console.log(err);
      }),
    );
  };

  return (
    <div className="login-page parent clearfix">
      <div className="login_page_overlay">
        <div className="login">
          <div className="container">
            <h3 className="login-page_title">TravelPath</h3>
            <h4 className="login-page_title">Login</h4>
            <div className="login-form">
              <div className="login-button-div">
                <LoadingButton
                  size="big"
                  // color="primary"
                  className="login_submit_btn btn btn-primary login_button"
                  loading={isLoading}
                  loadingPosition="start"
                  variant="contained"
                  type="submit"
                  onClick={handleSubmit}
                >
                  <span>Auth</span>
                </LoadingButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
