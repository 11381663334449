import {
  LOGIN_USER_FAIL,
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  ADD_USER_FAIL,
  ADD_USER_REQUEST,
  ADD_USER_SUCCESS,
  LOG_OUT,
  ACTIVATE_USER_ACCOUNT_REQUEST,
  ACTIVATE_USER_ACCOUNT_SUCCESS,
  ACTIVATE_USER_ACCOUNT_FAIL,
  GET_USERS_LIST_REQUEST,
  GET_USERS_LIST_SUCCESS,
  GET_USERS_LIST_FAIL,
  GET_USER_ACCOUNT_REQUEST,
  GET_USER_ACCOUNT_FAIL,
  GET_USER_ACCOUNT_SUCCESS,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  UPDATE_USER_STATUS_REQUEST,
  UPDATE_USER_STATUS_SUCCESS,
  UPDATE_USER_STATUS_FAIL,
  GET_ACCESS_LIST_REQUEST,
  GET_ACCESS_LIST_SUCCESS,
  GET_ACCESS_LIST_FAIL,
  GET_SINGLE_ACCESS_LIST_REQUEST,
  GET_SINGLE_ACCESS_LIST_SUCCESS,
  GET_SINGLE_ACCESS_LIST_FAIL,
  IS_OPEN_NAV_BAR_REQUEST,
  IS_OPEN_NAV_BAR_SUCCESS,
  IS_OPEN_NAV_BAR_FAIL,
  IS_OPEN_NAV_LEADS_REQUEST,
  IS_OPEN_NAV_LEADS_SUCCESS,
  IS_OPEN_NAV_LEADS_FAIL,
  IS_OPEN_NAV_USER_REQUEST,
  IS_OPEN_NAV_USER_SUCCESS,
  IS_OPEN_NAV_USER_FAIL,
  IS_OPEN_NAV_APPLIANCE_REQUEST,
  IS_OPEN_NAV_APPLIANCE_SUCCESS,
  IS_OPEN_NAV_APPLIANCE_FAIL,
  IS_OPEN_NAV_ORDER,
  SEARCH_IN_USERS,
  GET_ALL_USERS_LIST_SUCCESS,
  UPDATE_ACCOUNT_ME_REQUEST,
  UPDATE_ACCOUNT_ME_SUCCESS,
  UPDATE_ACCOUNT_ME_FAIL,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  GET_ACTIVATION_MAIL_SUCCESS,
  GET_ACTIVATION_MAIL_REQUEST,
  GET_ACTIVATION_MAIL_FAIL,
  GET_TECHNICS_SUCCESS,
  MY_ACCOUNT_SUCCESS,
  MY_ACCOUNT_FAIL,
  MY_ACCOUNT_REQUEST,
  EXPORT_USERS_REQUEST,
  EXPORT_USERS_SUCCESS,
  EXPORT_USERS_FAIL,
  GET_ALL_SUPPLIERS_SUCCESS,
  GET_ALL_DRIVERS_SUCCESS,
  GET_SINGLE_USER_REQUEST,
  GET_SINGLE_USER_SUCCESS,
  GET_SINGLE_USER_FAIL,
  GET_MY_ACCOUNT_REQUEST,
  GET_MY_ACCOUNT_SUCCESS,
  GET_MY_ACCOUNT_FAIL,
  UPDATE_MY_ACCOUNT_REQUEST,
  UPDATE_MY_ACCOUNT_SUCCESS,
  UPDATE_MY_ACCOUNT_FAIL,
  GET_ALL_SALES_AGENT_SUCCESS,
} from '../actions/users';
import Account from '../../services/Account';
import { socket } from '../actions/socket';

const initialState = {
  token: Account.getToken(),
  account: Account.get(),
  myAccount: {},
  friendAccount: {},
  friendAccountRequestStatus: '',
  userAccount: {},
  userAccountRequestStatus: '',
  getMyAccountStatus: '',
  messages: [],
  usersList: [],
  allUsersList: [],
  allSupplierUsers: [],
  allDriverUsers: [],
  allSalesAgents: [],
  usersListRequestStatus: '',
  onlineUsers: [],
  typingUsers: {},
  activateAccount: {},
  activateAccountStatus: '',
  rolesData: [],
  accessData: [],
  accessList: [],
  defAccessList: [],
  accessListRequestStatus: '',
  isOpenNavAccount: false,
  isOpenNavLeads: false,
  isOpenNavAppliance: false,
  pageCount: 0,
  errorMessage: '',
  getActivationEmailStatus: '',
  activationEmail: '',
  technics: [],
  userLoginStatus: '',
  exportExcelStatus: '',
  actionStatus: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_USER_REQUEST: {
      return {
        ...state,
        actionStatus: 'pending',
        formData: action.payload,
      };
    }
    case ADD_USER_SUCCESS: {
      return {
        ...state,
        actionStatus: 'success',
        formData: action.payload.data,
      };
    }
    case ADD_USER_FAIL: {
      return {
        ...state,
        actionStatus: 'fail',
      };
    }
    case MY_ACCOUNT_SUCCESS: {
      return {
        ...state,
        account: action.payload.data,
        getMyAccountStatus: 'success',
      };
    }
    case MY_ACCOUNT_FAIL: {
      return {
        ...state,
        getMyAccountStatus: 'fail',
      };
    }
    case UPDATE_USER_REQUEST: {
      return {
        ...state,
        actionStatus: 'pending',
      };
    }
    case UPDATE_USER_SUCCESS: {
      return {
        ...state,
        actionStatus: 'success',
      };
    }
    case UPDATE_USER_FAIL: {
      return {
        ...state,
        actionStatus: 'fail',
      };
    }
    case UPDATE_USER_STATUS_REQUEST: {
      return {
        ...state,
        actionStatus: 'pending',
      };
    }
    case UPDATE_USER_STATUS_SUCCESS: {
      return {
        ...state,
        actionStatus: 'success',
      };
    }
    case UPDATE_USER_STATUS_FAIL: {
      return {
        ...state,
        actionStatus: 'fail',
      };
    }
    case GET_ALL_SUPPLIERS_SUCCESS: {
      return {
        ...state,
        allSupplierUsers: action.payload?.data || [],
      };
    }
    case GET_ALL_DRIVERS_SUCCESS: {
      return {
        ...state,
        allDriverUsers: action.payload?.data?.result || [],
      };
    }
    case GET_ALL_SALES_AGENT_SUCCESS: {
      return {
        ...state,
        allSalesAgents: action.payload?.data?.result || [],
      };
    }
    case DELETE_USER_REQUEST: {
      return {
        ...state,
        actionStatus: 'pending',
      };
    }
    case DELETE_USER_SUCCESS: {
      return {
        ...state,
        actionStatus: 'success',
      };
    }
    case DELETE_USER_FAIL: {
      return {
        ...state,
        actionStatus: 'fail',
      };
    }
    case LOGIN_USER_REQUEST: {
      return {
        ...state,
        userLoginStatus: 'pending',
      };
    }
    case LOGIN_USER_SUCCESS: {
      const { token, user } = action.payload;
      return {
        ...state,
        token: token,
        account: user,
        userLoginStatus: 'success',
      };
    }
    case LOGIN_USER_FAIL: {
      return {
        ...state,
      };
    }
    case LOG_OUT: {
      Account.delete();
      if (socket) {
        socket.disconnect();
      }
      return {
        ...state,
        token: '',
        account: {},
        friendAccount: {},
        friendAccountRequestStatus: '',
        userAccount: {},
        userAccountRequestStatus: '',
        messages: [],
        usersList: [],
        allUsersList: [],
        usersListRequestStatus: '',
        onlineUsers: [],
        typingUsers: {},
        activateAccount: {},
        activateAccountStatus: '',
        rolesData: [],
        accessData: [],
        accessList: [],
        accessListRequestStatus: '',
        isOpenNavAccount: false,
        isOpenNavLeads: false,
        isOpenNavAppliance: false,
        pageCount: 0,
      };
    }
    case ACTIVATE_USER_ACCOUNT_REQUEST: {
      return {
        ...state,
        activateAccount: {},
        activateAccountStatus: 'pending',
      };
    }
    case ACTIVATE_USER_ACCOUNT_SUCCESS: {
      return {
        ...state,
        activateAccount: action.payload.data,
        activateAccountStatus: 'success',
      };
    }
    case ACTIVATE_USER_ACCOUNT_FAIL: {
      return {
        ...state,
        activateAccountStatus: 'fail',
      };
    }
    case GET_USERS_LIST_REQUEST: {
      return {
        ...state,
        usersListRequestStatus: 'pending',
      };
    }
    case GET_USERS_LIST_SUCCESS: {
      return {
        ...state,
        usersList: action.payload.result,
        usersListRequestStatus: 'success',
        pageCount: action.payload.count,
      };
    }

    case GET_ALL_USERS_LIST_SUCCESS: {
      return {
        ...state,
        allUsersList: action.payload.data,
      };
    }
    case GET_USERS_LIST_FAIL: {
      return {
        ...state,
        usersListRequestStatus: 'fail',
      };
    }

    case SEARCH_IN_USERS: {
      const filteredData = state.usersList.filter(r =>
        Object.values(r)
          ?.toString()
          .toLowerCase()
          .includes(action.payload.search.toString().toLowerCase()),
      );
      return {
        ...state,
        usersList: filteredData,
      };
    }

    case GET_USER_ACCOUNT_REQUEST: {
      return {
        ...state,
        userAccount: {},
        userAccountRequestStatus: 'pending',
      };
    }
    case GET_USER_ACCOUNT_SUCCESS: {
      return {
        ...state,
        userAccount: action.payload.user,
        userAccountRequestStatus: 'success',
      };
    }
    case GET_USER_ACCOUNT_FAIL: {
      return {
        ...state,
        userAccount: {},
        userAccountRequestStatus: 'fail',
      };
    }
    case GET_ACCESS_LIST_REQUEST: {
      return {
        ...state,
      };
    }
    case GET_ACCESS_LIST_SUCCESS: {
      return {
        ...state,
        pages: action.payload.pages,
        accessData: action.payload.accessLists,
        defAccessList: action.payload.def,
      };
    }
    case GET_ACCESS_LIST_FAIL: {
      return {
        ...state,
      };
    }
    case GET_SINGLE_USER_REQUEST: {
      return {
        ...state,
        status: 'pending',
      };
    }
    case GET_SINGLE_USER_SUCCESS: {
      return {
        ...state,
        status: 'success',
      };
    }
    case GET_SINGLE_USER_FAIL: {
      return {
        ...state,
        status: 'fail',
      };
    }
    case GET_SINGLE_ACCESS_LIST_REQUEST: {
      return {
        ...state,
        accessListRequestStatus: 'pending',
      };
    }
    case GET_SINGLE_ACCESS_LIST_SUCCESS: {
      return {
        ...state,
        accessList: action.payload,
        accessListRequestStatus: 'success',
      };
    }
    case GET_SINGLE_ACCESS_LIST_FAIL: {
      return {
        ...state,
        accessListRequestStatus: 'fail',
      };
    }
    case IS_OPEN_NAV_USER_REQUEST: {
      return {
        ...state,
      };
    }
    case IS_OPEN_NAV_USER_SUCCESS: {
      return {
        ...state,
        isOpenNavUsersAccount: action.payload,
      };
    }
    case IS_OPEN_NAV_USER_FAIL: {
      return {
        ...state,
        massage: 'error',
      };
    }

    case IS_OPEN_NAV_BAR_REQUEST: {
      return {
        ...state,
      };
    }
    case IS_OPEN_NAV_BAR_SUCCESS: {
      return {
        ...state,
        isOpenNavAccount: action.payload,
      };
    }
    case IS_OPEN_NAV_BAR_FAIL: {
      return {
        ...state,
        massage: 'error',
      };
    }
    case IS_OPEN_NAV_LEADS_REQUEST: {
      return {
        ...state,
      };
    }
    case IS_OPEN_NAV_LEADS_SUCCESS: {
      return {
        ...state,
        isOpenNavLeads: action.payload,
      };
    }
    case IS_OPEN_NAV_LEADS_FAIL: {
      return {
        ...state,
        massage: 'error',
      };
    }

    case IS_OPEN_NAV_APPLIANCE_REQUEST: {
      return {
        ...state,
      };
    }
    case IS_OPEN_NAV_ORDER: {
      return {
        ...state,
        isOpenNavOrder: action.payload.bool,
      };
    }
    case IS_OPEN_NAV_APPLIANCE_SUCCESS: {
      return {
        ...state,
        isOpenNavAppliance: action.payload,
        status: 'success',
      };
    }
    case IS_OPEN_NAV_APPLIANCE_FAIL: {
      return {
        ...state,
        massage: 'error',
      };
    }

    case UPDATE_ACCOUNT_ME_REQUEST: {
      return {
        ...state,
      };
    }

    case UPDATE_ACCOUNT_ME_SUCCESS: {
      Account.updateAccount(action?.payload?.data?.updatedUser);
      return {
        ...state,
        account: action?.payload?.data?.updatedUser,
        payload: {
          status: 'success',
          formData: action?.payload?.data?.updatedAccount,
        },
      };
    }

    case UPDATE_ACCOUNT_ME_FAIL: {
      return {
        ...state,
        payload: {
          message: 'Fail',
        },
      };
    }
    case CHANGE_PASSWORD_REQUEST: {
      return {
        ...state,
        payload: {
          message: 'pending',
        },
      };
    }
    case CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        payload: {
          // formData: action.payload.formData,
          message: 'success',
        },
      };
    }
    case CHANGE_PASSWORD_FAIL: {
      return {
        ...state,
        payload: {
          message: 'Fail',
        },
      };
    }
    case GET_ACTIVATION_MAIL_REQUEST: {
      return {
        ...state,
        getActivationEmailStatus: 'pending',
      };
    }

    case GET_ACTIVATION_MAIL_SUCCESS: {
      return {
        ...state,
        getActivationEmailStatus: 'success',
        activationEmail: action.payload.data.findUSer?.email,
      };
    }

    case GET_ACTIVATION_MAIL_FAIL: {
      return {
        ...state,
        getActivationEmailStatus: 'Fail',
        errorMessage: action.payload?.message,
      };
    }

    case GET_TECHNICS_SUCCESS: {
      return {
        ...state,
        technics: action.payload.data,
      };
    }
    case MY_ACCOUNT_REQUEST: {
      return {
        ...state,
        getMyAccountStatus: 'pending',
      };
    }
    case EXPORT_USERS_REQUEST: {
      return {
        ...state,
        exportExcelStatus: 'pending',
      };
    }
    case EXPORT_USERS_SUCCESS: {
      return {
        ...state,
        exportExcelStatus: 'success',
      };
    }
    case EXPORT_USERS_FAIL: {
      return {
        ...state,
        exportExcelStatus: 'fail',
      };
    }
    case GET_MY_ACCOUNT_REQUEST: {
      return {
        ...state,
        actionStatus: 'pending',
      };
    }
    case GET_MY_ACCOUNT_SUCCESS: {
      return {
        ...state,
        actionStatus: 'success',
        myAccount: action.payload.data?.result,
      };
    }
    case GET_MY_ACCOUNT_FAIL: {
      return {
        ...state,
        actionStatus: 'fail',
      };
    }
    case UPDATE_MY_ACCOUNT_REQUEST: {
      return {
        ...state,
        actionStatus: 'pending',
      };
    }
    case UPDATE_MY_ACCOUNT_SUCCESS: {
      return {
        ...state,
        actionStatus: 'success',
      };
    }
    case UPDATE_MY_ACCOUNT_FAIL: {
      return {
        ...state,
        actionStatus: 'fail',
      };
    }
    default: {
      return state;
    }
  }
}
