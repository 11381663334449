import * as Yup from 'yup';

export const supplierValidation = Yup.object().shape({
  name: Yup.string().required('Required Field'),
  email: Yup.string()
    .required('Required Field')
    .matches(/[a-zA-Z0-9\._-]+@[a-zA-Z0-9.-_]+\.[a-z]{2,6}$/, {
      message: 'Invalid Email Address',
      excludeEmptyString: true,
    })
    .email('Invalid Email Address'),
  phone_number: Yup.string()
    .required('Required Field')
    .matches(/^\+?[()\d -]{6,25}/, {
      message: 'Invalid format',
      excludeEmptyString: true,
    }),
  add_user: Yup.boolean(),
  user_name: Yup.string()
    .matches(/^[\S\s]*$/, 'Invalid Format')
    .when('add_user', {
      is: true,
      then: schema => schema.required('Required Field'),
      otherwise: schema => schema.notRequired(),
    }),
  password: Yup.string().when(['add_user', 'new_password'], {
    is: (add_user, new_password) => {
      return add_user === true && !new_password;
    },
    then: schema => schema.required('Required Field'),
    otherwise: schema => schema.notRequired(),
  }),
});

export const driverValidation = Yup.object().shape({
  name: Yup.string().required('Required Field'),
  passport_details: Yup.string().required('Required Field'),
  driving_license_number: Yup.string().required('Required Field'),
  vehicle_type: Yup.string().required('Required Field'),
  registration_number: Yup.string().required('Required Field'),
  user_name: Yup.string().when('add_user', {
    is: true,
    then: schema =>
      schema.required('Required Field').matches(/^\S*$/, 'Invalid Format'),
    otherwise: schema => schema.notRequired(),
  }),
  password: Yup.string().when(['add_user', 'new_password'], {
    is: (add_user, new_password) => {
      return add_user === true && !new_password;
    },
    then: schema => schema.required('Required Field'),
    otherwise: schema => schema.notRequired(),
  }),
});

export const sales_agent_validation = Yup.object().shape({
  name: Yup.string().required('Required Field'),
  email: Yup.string()
    .matches(/[a-zA-Z0-9\._-]+@[a-zA-Z0-9.-_]+\.[a-z]{2,6}$/, {
      message: 'Invalid Email Address',
      excludeEmptyString: true,
    })
    .email('Invalid Email Address')
    .nullable(),
  phone_number: Yup.string()
    .matches(/^\+?[()\d -]{6,25}/, {
      message: 'Invalid format',
      excludeEmptyString: true,
    })
    .nullable(),
  add_user: Yup.boolean(),
  user_name: Yup.string()
    .matches(/^\S*$/, 'Invalid Format')
    .when('add_user', {
      is: true,
      then: schema => schema.required('Required Field'),
      otherwise: schema => schema.notRequired(),
    }),
  password: Yup.string().when(['add_user', 'new_password'], {
    is: (add_user, new_password) => {
      return add_user === true && !new_password;
    },
    then: schema => schema.required('Required Field'),
    otherwise: schema => schema.notRequired(),
  }),
});
