import React from 'react';
import { Skeleton } from '@mui/joy';
import Box from '@mui/material/Box';
import { range } from 'lodash';

function SkeletonLoading(props) {
  return (
    <div>
      <div style={{ opacity: '0.5' }}>
        <Skeleton
          variant="rectangular"
          animation="wave"
          style={{
            width: '100%',
            marginBottom: '30px',
          }}
          height={130}
        />
        <div className="d-flex justify-content-between align-items-center">
          <div style={{ width: '20%', paddingRight: '15px' }}>
            {range(0, 14).map(i => (
              <Skeleton
                variant="rectangular"
                animation="wave"
                style={{
                  width: '100%',
                  height: '4.9vh',
                  marginLeft: '30px',
                  marginBottom: '5px',
                }}
              />
            ))}
          </div>
          <div style={{ width: '78%', paddingRight: '2%' }}>
            <Skeleton
              variant="rectangular"
              animation="wave"
              style={{
                marginBottom: '10px',
                height: '5vh',
              }}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              style={{
                height: '69vh',
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SkeletonLoading;
