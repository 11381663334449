import React, { useEffect, useState, useRef } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { blockPageAction, getAllSuppliers } from '../../store/actions/users';
import { getAllRolesAction } from '../../store/actions/roles';
import Select from 'react-select';
import { customRequiredStyle, customStyleGray } from '../../services/utils';
import {
  addEmailSetupAction,
  editEmailSetupAction,
  getEmailSetupsListAction,
  getSingleEmailSetupAction,
} from '../../store/actions/emailSetups';
import { LoadingButton } from '@mui/lab';

function EmailSetupsForm({
  popupTitle,
  handleClose,
  singleUser,
  actionStatus,
}) {
  const setupTypes = [
    { id: 1, type: 'Tour' },
    { id: 2, type: 'Transfer' },
  ];

  const searchConfig = useSelector(store => store.search);
  const blockedPage = useSelector(store => store.search.blockedPage);
  const [submitted, setSubmitted] = useState(false);
  const [pass, setPass] = useState('password');
  const [formValidation, setFormValidation] = useState({});
  const [formData, setFormData] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [validated, setValidated] = useState(false);
  const [submit, setSubmit] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllSuppliers());
    dispatch(getAllRolesAction());
  }, []);

  useEffect(() => {
    if (!isEmpty(singleUser)) {
      setFormData(singleUser);
    }
  }, [singleUser]);

  useEffect(() => {
    if (id && typeof formData === typeof singleUser && !isEmpty(singleUser)) {
      if (
        JSON.stringify(formData) !== JSON.stringify(singleUser) &&
        !blockedPage
      ) {
        dispatch(blockPageAction('/user-management/users/41'));
      }
      if (
        JSON.stringify(formData) === JSON.stringify(singleUser) &&
        singleUser
      ) {
        dispatch(blockPageAction(''));
      }
    }
  }, [formData]);

  const handleChange = (key, val) => {
    setFormData({ ...formData, [key]: val });
    setFormValidation({ ...formValidation, [key]: val });
  };

  const handlePhotoChange = (key, val) => {};

  const handleEdit = params => {
    setFormValidation(!!formValidation);
    dispatch(
      getSingleEmailSetupAction(params, (err, data) => {
        if (data) {
          setFormData(data);
        }
      }),
    );
  };

  const onSubmit = event => {
    event.preventDefault();
    setSubmit(true);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      setDisabled(true);
      dispatch(
        addEmailSetupAction(formData, (err, data) => {
          if (data) {
            toast.success('Successfully Created', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });
          } else if (err) {
            toast.error(err.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });
          }
          dispatch(getEmailSetupsListAction(searchConfig));
          handleClose();
        }),
      );
      setTimeout(() => {
        setDisabled(false);
      }, 1500);
    }
  };

  const onEdit = ev => {
    ev.preventDefault();
    setSubmit(true);
    const form = ev.currentTarget;
    if (form.checkValidity() === false) {
      ev.preventDefault();
      ev.stopPropagation();
      setValidated(true);
    } else {
      setDisabled(true);
      setValidated(true);
      dispatch(
        editEmailSetupAction(formData, (err, data) => {
          if (data) {
            if (data) {
              toast.success('Successfully Updated', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 10000,
              });
              setDisabled(false);
              form.reset();
              navigate('/admin/setup/email_setup');
            }
          }
        }),
      );
      setTimeout(() => {
        setDisabled(false);
      }, 1500);
    }
  };

  const handlePassVis = () => {
    if (pass === 'password') {
      setPass('text');
    } else setPass('password');
  };

  return (
    <>
      <Form
        onSubmit={event => {
          if (id) {
            onEdit(event);
          } else {
            onSubmit(event);
          }
        }}
        noValidate
        validated={validated}
      >
        <Modal.Header closeButton={!id}>
          <Modal.Title>{!id && popupTitle + ' ' + 'Email Setup'}</Modal.Title>
        </Modal.Header>
        <div className="form-block">
          <Modal.Body>
            <Row>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Name
                    <span style={{ color: 'red' }}> *</span>
                  </Form.Label>
                  <Form.Control
                    id="email_setup_name"
                    name="email_setup_name"
                    value={formData?.email_setup_name || ''}
                    required={true}
                    onChange={e =>
                      handleChange('email_setup_name', e.target.value)
                    }
                    maxLength={254}
                  />
                  {!formData.email_setup_name ? (
                    <Form.Control.Feedback type="invalid">
                      Required Field
                    </Form.Control.Feedback>
                  ) : null}
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Type</Form.Label>
                  <Select
                    id="email_setup_type"
                    name="type"
                    value={
                      formData?.type && Array.isArray(setupTypes)
                        ? setupTypes.find(i => i.type === formData.type)
                        : setupTypes[0]
                    }
                    styles={customStyleGray(validated)}
                    options={setupTypes}
                    getOptionValue={i => i.type}
                    getOptionLabel={i => i.type}
                    onChange={ev => {
                      if (ev) {
                        setFormData({
                          ...formData,
                          type: ev.type,
                        });
                      } else {
                        handleChange('type', null);
                        setFormData({
                          ...formData,
                          type: null,
                        });
                      }
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    id="email_setup_to_send"
                    name="email_to_send"
                    value={formData?.email_to_send || ''}
                    required={false}
                    onChange={e =>
                      handleChange('email_to_send', e.target.value)
                    }
                    maxLength={254}
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Subject</Form.Label>
                  <Form.Control
                    id="email_setup_subject"
                    name="email_subject"
                    value={formData?.email_subject || ''}
                    required={false}
                    onChange={e =>
                      handleChange('email_subject', e.target.value)
                    }
                    maxLength={254}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label>CC</Form.Label>
                  <Form.Control
                    id="email_setup_cc"
                    name="email_cc"
                    value={formData?.email_cc || ''}
                    required={false}
                    onChange={e => handleChange('email_cc', e.target.value)}
                    maxLength={254}
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label>BCC</Form.Label>
                  <Form.Control
                    id="email_setup_bcc"
                    name="email_bcc"
                    value={formData?.email_bcc || ''}
                    required={false}
                    onChange={e => handleChange('email_bcc', e.target.value)}
                    maxLength={254}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <Form.Group className={'mb-3'}>
                  <Form.Label>
                    Body
                    <span style={{ color: 'red' }}> *</span>
                  </Form.Label>
                  <Form.Control
                    id="email_setup_body"
                    name="email_body"
                    as="textarea"
                    rows={4}
                    required={true}
                    className="text_area_input"
                    value={formData.email_body || ''}
                    onChange={event =>
                      handleChange('email_body', event.target.value)
                    }
                  />
                  {!formData.email_body ? (
                    <Form.Control.Feedback type="invalid">
                      Required Field
                    </Form.Control.Feedback>
                  ) : null}
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
        </div>
        <Modal.Footer>
          <div className={id ? 'previewButtonsID' : 'previewButtons'}>
            <LoadingButton
              size="small"
              className="login_submit_btn btn btn-primary "
              loadingPosition="center"
              variant="contained"
              loading={actionStatus === 'pending'}
              type={'submit'}
            >
              <span>{actionStatus === 'pending' ? '' : 'Save'}</span>
            </LoadingButton>
            {id ? (
              <Button variant="secondary" onClick={() => handleEdit(id)}>
                Reset
              </Button>
            ) : (
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Form>
    </>
  );
}

export default EmailSetupsForm;
