import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import PageTitle from '../../components/PageTitle.js';
import { useNavigate, useParams } from 'react-router-dom';
import { FadeLoader } from 'react-spinners';
import TourVouchersForm from './TourVouchersForm.js';
import { getSingleTourVoucher } from '../../store/actions/tourVouchers';
import { blockPageAction } from '../../store/actions/users';
import {findVouchersBokunDifference} from "../../services/utils";
import {isEmpty} from "lodash";

function PreviewTourVouchers() {
  const rowStatus = useSelector(store => store?.tourVouchers?.status);
  const [formData, setFormData] = useState({});
  const [formValidation, setFormValidation] = useState({});
  const [historyArr, setHistoryArr] = useState([]);
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    handleEdit(id);
    return () => {
      dispatch(blockPageAction(''));
    };
  }, []);

  // Get the row data to edit
  const handleEdit = id => {
    dispatch(
      getSingleTourVoucher(id, (err, data) => {
        if (data) {
          setFormData(data?.result);
          if(!isEmpty(data?.result?.voucherHistory)) {
            const newArr = findVouchersBokunDifference([...data?.result?.voucherHistory, data.result])
            setHistoryArr(newArr);
          }
          setFormData({
            ...data.result,
          });
        }
      }),
    );
    return () => {};
  };
  const handleChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  return (
    <>
      <PageTitle
        parentTitle={'Tour Vouchers'}
        childTitle={formData.reference_number || ''}
        parentLink={'/admin/vouchers'}
      />
      <div className="form-block">
        {rowStatus === 'success' ? (
          <TourVouchersForm
            title="edit"
            formValidation={formValidation}
            singleTourVoucher={formData}
            handleChange={handleChange}
            handleEdit={handleEdit}
            historyArr={historyArr}
          />
        ) : (
          <div className="form-loading">
            <FadeLoader
              color={`var(--primary_main)`}
              loading={rowStatus === 'pending'}
              size={10}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )}
      </div>
    </>
  );
}

export default PreviewTourVouchers;
