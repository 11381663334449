import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, range } from 'lodash';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import {
  addAdvertisementAction,
  getAdvertisementsAction,
  getAllAdvertisementsAction,
  getSingleAdvertisement,
  updateAdvertisement,
} from '../../store/actions/advertisements';
import { LoadingButton } from '@mui/lab';
import { blockPageAction } from '../../store/actions/users';

function AdvertisementsForm({ title, handleClose, single, actionStatus }) {
  const blockedPage = useSelector(store => store.search.blockedPage);
  const searchConfig = useSelector(store => store.search);
  let rows = useSelector(store => store?.advertisements?.allAds);
  const [formValidation, setFormValidation] = useState({});
  const [formData, setFormData] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [validated, setValidated] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isEmpty(single)) {
      setFormData(single);
    }
  }, [single]);

  useEffect(() => {
    if (id && typeof formData === typeof single && !isEmpty(single)) {
      if (JSON.stringify(formData) !== JSON.stringify(single) && !blockedPage) {
        dispatch(blockPageAction('/user-management/users/41'));
      }
      if (JSON.stringify(formData) === JSON.stringify(single) && blockedPage) {
        dispatch(blockPageAction(''));
      }
    }
  }, [formData]);
  useEffect(() => {
    !id &&
      setFormData({
        column: 'left',
        order:
          !formData.order && rows.filter(r => r.column === 'left').length + 1,
      });
    id &&
      formData.column &&
      setFormData({
        column: 'left',
        order:
          !formData.order && rows.filter(r => r.column === 'left').length + 2,
      });
  }, []);

  const handleChange = (key, val) => {
    setFormData({ ...formData, [key]: val });
  };
  const handleEdit = params => {
    setFormData(formData);
    setFormValidation(!!formValidation);
    dispatch(getSingleAdvertisement(params));
  };

  const onSubmit = event => {
    event.preventDefault();
    setSubmitted(true);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      setDisabled(true);
      dispatch(
        addAdvertisementAction(formData, (err, data) => {
          if (data) {
            toast.success('Successfully Created', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });
            setValidated(false);
            dispatch(getAdvertisementsAction(searchConfig));
            dispatch(getAllAdvertisementsAction());
            setDisabled(false);
            handleClose();
            form.reset();
            setFormData({});
          } else {
            // toast.error(err.response.data.errors[0].message, {
            //   position: toast.POSITION.TOP_RIGHT,
            //   autoClose: 5000,
            // });
          }
        }),
      );
      setTimeout(() => {
        setDisabled(false);
      }, 1500);
    }
  };

  const onEdit = ev => {
    ev.preventDefault();
    setSubmitted(true);
    const form = ev.currentTarget;
    if (form.checkValidity() === false) {
      ev.preventDefault();
      ev.stopPropagation();
      setValidated(true);
    } else {
      setDisabled(true);
      setValidated(true);
      dispatch(
        updateAdvertisement(formData, (err, data) => {
          if (err) {
            toast.error(err.errors.error ? err.errors.error : err.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 10000,
            });
            setValidated(false);
          } else {
            if (data) {
              toast.success('Successfully Updated', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 10000,
              });
              setDisabled(false);
              navigate('/admin/setup/advertisements');
              form.reset();
            }
          }
        }),
      );
      setTimeout(() => {
        setDisabled(false);
      }, 1500);
    }
  };

  return (
    <>
      <Form
        onSubmit={event => {
          if (id) {
            onEdit(event);
          } else {
            onSubmit(event);
          }
        }}
        noValidate
        validated={validated}
      >
        <Modal.Header closeButton={!id}>
          <Modal.Title>{title && title}</Modal.Title>
        </Modal.Header>
        <div className="form-block">
          <Modal.Body>
            <Row>
              <Col sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Html
                    <span style={{ color: 'red' }}> *</span>
                  </Form.Label>
                  <Form.Control
                    id="advertisement_html"
                    name="html"
                    rows={5}
                    as="textarea"
                    className="text_area_input"
                    required={true}
                    value={formData.html || ''}
                    onChange={ev => handleChange('html', ev.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Required Field
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Select Column</Form.Label>
                  <Form.Select
                    id="advertisement_select_column"
                    name="column"
                    aria-label="column"
                    value={formData.column}
                    onChange={ev => {
                      setFormData({
                        ...formData,
                        column: ev.target.value,
                        order:
                          rows.filter(r => r.column === ev.target.value)
                            .length + 1,
                      });
                    }}
                  >
                    <option value="left">Left</option>
                    <option value="right">Right</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Select Order</Form.Label>
                  <Form.Select
                    id="advertisement_select_order"
                    name="order"
                    aria-label="order"
                    disabled={!rows.length}
                    value={formData.order}
                    onChange={ev => handleChange('order', ev.target.value)}
                  >
                    {rows &&
                      range(
                        1,
                        rows.filter(r => r.column === formData.column).length +
                          (id ? 1 : 2),
                      ).map(i => (
                        <option key={i} value={i}>
                          {i}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
        </div>
        <Modal.Footer>
          <div className={id ? 'previewButtonsID' : 'previewButtons'}>
            <LoadingButton
              size="small"
              className="login_submit_btn btn btn-primary "
              loadingPosition="center"
              variant="contained"
              loading={actionStatus === 'pending'}
              type={'submit'}
            >
              <span>{actionStatus === 'pending' ? '' : 'Save'}</span>
            </LoadingButton>
            {id ? (
              <Button variant="secondary" onClick={() => handleEdit(id)}>
                Reset
              </Button>
            ) : (
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Form>
    </>
  );
}

export default AdvertisementsForm;
