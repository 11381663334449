import {takeLatest, call, put} from 'redux-saga/effects';
import {
    DELETE_SELECTED_TOUR_VOUCHERS_REQUEST,
    DELETE_SELECTED_TOUR_VOUCHERS_SUCCESS,
    DELETE_SELECTED_TOUR_VOUCHERS_FAIL,
    GET_ALL_TOUR_VOUCHERS_REQUEST,
    GET_ALL_TOUR_VOUCHERS_SUCCESS,
    GET_ALL_TOUR_VOUCHERS_FAIL,
    FETCH_TOUR_VOUCHER_SUCCESS,
    FETCH_TOUR_VOUCHER_FAIL,
    GET_VOUCHER_LOCATION_REQUEST,
    GET_VOUCHER_LOCATION_SUCCESS,
    GET_VOUCHER_LOCATION_FAIL,
} from '../actions/tourVouchers';
import TransferVouchers from '../../api/transferVouchers';
import {saveAs} from 'file-saver';
import {
    ADD_COMMENT_TRANSFER_VOUCHER_FAIL,
    ADD_COMMENT_TRANSFER_VOUCHER_REQUEST,
    ADD_COMMENT_TRANSFER_VOUCHER_SUCCESS,
    ADD_TRANSFER_VOUCHER_FAIL,
    ADD_TRANSFER_VOUCHER_REQUEST,
    ADD_TRANSFER_VOUCHER_SUCCESS,
    CHANGE_TRANSFER_VOUCHER_STATUS_FAIL,
    CHANGE_TRANSFER_VOUCHER_STATUS_REQUEST,
    CHANGE_TRANSFER_VOUCHER_STATUS_SUCCESS,
    CHECK_TRANSFER_VOUCHER_FAIL,
    CHECK_TRANSFER_VOUCHER_REQUEST,
    CHECK_TRANSFER_VOUCHER_SUCCESS,
    COPY_TRANSFER_VOUCHER_FAIL,
    COPY_TRANSFER_VOUCHER_REQUEST,
    COPY_TRANSFER_VOUCHER_SUCCESS,
    DELETE_TRANSFER_VOUCHER_FAIL,
    DELETE_TRANSFER_VOUCHER_REQUEST,
    DELETE_TRANSFER_VOUCHER_SUCCESS,
    DOWNLOAD_TRANSFER_VOUCHER_FAIL,
    DOWNLOAD_TRANSFER_VOUCHER_REQUEST,
    DOWNLOAD_TRANSFER_VOUCHER_SUCCESS,
    EXPORT_TRANSFER_VOUCHERS_FAIL,
    EXPORT_TRANSFER_VOUCHERS_REQUEST,
    EXPORT_TRANSFER_VOUCHERS_SUCCESS,
    GET_SINGLE_TRANSFER_VOUCHER_FAIL,
    GET_SINGLE_TRANSFER_VOUCHER_REQUEST,
    GET_SINGLE_TRANSFER_VOUCHER_SUCCESS,
    GET_TRANSFER_PREVIEW_VOUCHERS_FAIL,
    GET_TRANSFER_PREVIEW_VOUCHERS_REQUEST,
    GET_TRANSFER_PREVIEW_VOUCHERS_SUCCESS,
    GET_TRANSFER_VOUCHERS_FAIL,
    GET_TRANSFER_VOUCHERS_REQUEST,
    GET_TRANSFER_VOUCHERS_SUCCESS,
    SEND_EMAIL_TRANSFER_VOUCHER_FAIL,
    SEND_EMAIL_TRANSFER_VOUCHER_REQUEST,
    SEND_EMAIL_TRANSFER_VOUCHER_SUCCESS,
    SHARE_MY_LOCATION_TRANSFER_FAIL,
    SHARE_MY_LOCATION_TRANSFER_REQUEST,
    SHARE_MY_LOCATION_TRANSFER_SUCCESS,
    UPDATE_TRANSFER_VOUCHER_FAIL,
    UPDATE_TRANSFER_VOUCHER_REQUEST,
    UPDATE_TRANSFER_VOUCHER_STATE_REQUEST,
    UPDATE_TRANSFER_VOUCHER_SUCCESS,
    DOWNLOAD_TRANSFER_VOUCHERS_REPORT_REQUEST,
    DOWNLOAD_TRANSFER_VOUCHERS_REPORT_SUCCESS,
    DOWNLOAD_TRANSFER_VOUCHERS_REPORT_FAIL,
    DOWNLOAD_TRANSFER_VOUCHERS_KPI_REPORT_REQUEST,
    DOWNLOAD_TRANSFER_VOUCHERS_KPI_REPORT_SUCCESS,
    DOWNLOAD_TRANSFER_VOUCHERS_KPI_REPORT_FAIL,
    GET_TRANSFER_VOUCHERS_FOR_KPI_REPORT_REQUEST,
    GET_TRANSFER_VOUCHERS_FOR_KPI_REPORT_SUCCESS,
    GET_TRANSFER_VOUCHERS_FOR_KPI_REPORT_FAIL,
} from '../actions/transferVouchers';
import TourVouchers from '../../api/tourVouchers';
import dayjs from "dayjs";

export default function* watcher() {
    yield takeLatest(GET_TRANSFER_VOUCHERS_REQUEST, getTourVouchers);
    yield takeLatest(
        GET_TRANSFER_PREVIEW_VOUCHERS_REQUEST,
        getPreviewTransferVouchers,
    );
    // yield takeLatest(FETCH_TOUR_VOUCHER_REQUEST, fetchTourVouchers);
    yield takeLatest(CHANGE_TRANSFER_VOUCHER_STATUS_REQUEST, handleChangeStatus);
    yield takeLatest(DELETE_TRANSFER_VOUCHER_REQUEST, deleteTransferVoucher);
    yield takeLatest(ADD_TRANSFER_VOUCHER_REQUEST, addTransferVoucher);
    yield takeLatest(
        GET_SINGLE_TRANSFER_VOUCHER_REQUEST,
        getSingleTransferVoucher,
    );
    yield takeLatest(UPDATE_TRANSFER_VOUCHER_REQUEST, updateTransferVoucher);
    yield takeLatest(
        UPDATE_TRANSFER_VOUCHER_STATE_REQUEST,
        updateTransferVoucherState,
    );
    yield takeLatest(GET_VOUCHER_LOCATION_REQUEST, getTourVoucherLocation);
    yield takeLatest(
        ADD_COMMENT_TRANSFER_VOUCHER_REQUEST,
        addCommentTransferVoucher,
    );
    yield takeLatest(COPY_TRANSFER_VOUCHER_REQUEST, copyTourVoucher);
    yield takeLatest(SEND_EMAIL_TRANSFER_VOUCHER_REQUEST, handleSendEmail);
    yield takeLatest(
        DELETE_SELECTED_TOUR_VOUCHERS_REQUEST,
        deleteSelectedTourVouchers,
    );
    yield takeLatest(GET_ALL_TOUR_VOUCHERS_REQUEST, getAllTourVouchers);
    yield takeLatest(EXPORT_TRANSFER_VOUCHERS_REQUEST, handleExportExcel);
    yield takeLatest(CHECK_TRANSFER_VOUCHER_REQUEST, checkTransferVoucher);
    yield takeLatest(DOWNLOAD_TRANSFER_VOUCHER_REQUEST, downloadTransferVoucher);
    yield takeLatest(SHARE_MY_LOCATION_TRANSFER_REQUEST, shareLocation);
    yield takeLatest(DOWNLOAD_TRANSFER_VOUCHERS_REPORT_REQUEST, downloadTransferVouchersReport);
    yield takeLatest(DOWNLOAD_TRANSFER_VOUCHERS_KPI_REPORT_REQUEST, downloadTransferVouchersKPIReport);
    yield takeLatest(GET_TRANSFER_VOUCHERS_FOR_KPI_REPORT_REQUEST, getTransferVouchersForKPIReport);
}

function* addTransferVoucher(action) {
    try {
        const {formData} = action.payload;
        const {data} = yield call(TransferVouchers.addTransferVoucher, formData);
        yield put({
            type: ADD_TRANSFER_VOUCHER_SUCCESS,
            payload: {
                data: data.result,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(null, data);
        }
    } catch (e) {
        yield put({
            type: ADD_TRANSFER_VOUCHER_FAIL,
            payload: {
                massage: e.massage,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(e, null);
        }
    }
}

function* getTourVouchers(action) {
    try {
        const {page, limit, sort, search, filter} = action.payload?.searchConfig;
        const {data} = yield call(
            TransferVouchers.getTransferVouchers,
            page,
            limit,
            search,
            filter,
            sort,
        );

        yield put({
            type: GET_TRANSFER_VOUCHERS_SUCCESS,
            payload: {
                data: data,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(null, data);
        }
    } catch (e) {
        yield put({
            type: GET_TRANSFER_VOUCHERS_FAIL,
            payload: {
                massage: e.massage,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(e, null);
        }
    }
}

function* getPreviewTransferVouchers(action) {
    try {
        const {page, limit, sort, search, filter} = action.payload?.searchConfig;
        const {data} = yield call(
            TransferVouchers.getPreviewTransferVoucher,
            action.payload.id,
            page,
            limit,
            search,
            filter,
            sort,
        );

        yield put({
            type: GET_TRANSFER_PREVIEW_VOUCHERS_SUCCESS,
            payload: {
                data: data,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(null, data);
        }
    } catch (e) {
        yield put({
            type: GET_TRANSFER_PREVIEW_VOUCHERS_FAIL,
            payload: {
                massage: e.massage,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(e, null);
        }
    }
}

function* deleteTransferVoucher(action) {
    try {
        const {id} = action.payload;
        const {data} = yield call(TransferVouchers.deleteTransferVoucher, id);
        yield put({
            type: DELETE_TRANSFER_VOUCHER_SUCCESS,
            payload: {
                data: data.result,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(null, data);
        }
    } catch (e) {
        console.log(e);
        yield put({
            type: DELETE_TRANSFER_VOUCHER_FAIL,
            payload: {
                massage: e.massage,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(e, null);
        }
    }
}

function* deleteSelectedTourVouchers(action) {
    try {
        const {selectedRows} = action.payload;
        const {data} = yield call(
            TourVouchers.deleteSelectedTourVouchers,
            selectedRows,
        );
        yield put({
            type: DELETE_SELECTED_TOUR_VOUCHERS_SUCCESS,
            payload: {
                data: data.result,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(null, data);
        }
    } catch (e) {
        console.log(e);
        yield put({
            type: DELETE_SELECTED_TOUR_VOUCHERS_FAIL,
            payload: {
                massage: e.massage,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(e, null);
        }
    }
}

function* getSingleTransferVoucher(action) {
    try {
        const {id} = action.payload;
        const {data} = yield call(TransferVouchers.getSingleTransferVoucher, id);
        yield put({
            type: GET_SINGLE_TRANSFER_VOUCHER_SUCCESS,
            payload: {
                data: data?.result,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(null, data);
        }
    } catch (e) {
        console.log(e);
        yield put({
            type: GET_SINGLE_TRANSFER_VOUCHER_FAIL,
            payload: {
                massage: e.massage,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(e, null);
        }
    }
}

function* getTourVoucherLocation(action) {
    try {
        const {id} = action.payload;
        const {data} = yield call(TourVouchers.getTourVoucherLoc, id);
        yield put({
            type: GET_VOUCHER_LOCATION_SUCCESS,
            payload: {
                data: data.result,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(null, data?.result);
        }
    } catch (e) {
        console.log(e);
        yield put({
            type: GET_VOUCHER_LOCATION_FAIL,
            payload: {
                massage: e.massage,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(e, null);
        }
    }
}

function* updateTransferVoucher(action) {
    try {
        const {formData} = action.payload;
        const {data} = yield call(
            TransferVouchers.updateTransferVoucher,
            formData,
        );
        yield put({
            type: UPDATE_TRANSFER_VOUCHER_SUCCESS,
            payload: {
                data: data.result,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(null, data);
        }
    } catch (e) {
        yield put({
            type: UPDATE_TRANSFER_VOUCHER_FAIL,
            payload: {
                massage: e.massage,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(e, null);
        }
    }
}

function* updateTransferVoucherState(action) {
    try {
        const {formData} = action.payload;
        const {data} = yield call(
            TransferVouchers.updateTransferVoucherState,
            formData,
        );
        yield put({
            type: UPDATE_TRANSFER_VOUCHER_SUCCESS,
            payload: {
                data: data.result,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(null, data);
        }
    } catch (e) {
        yield put({
            type: UPDATE_TRANSFER_VOUCHER_FAIL,
            payload: {
                massage: e.massage,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(e, null);
        }
    }
}

function* getAllTourVouchers(action) {
    try {
        const {data} = yield call(TourVouchers.getAllTourVouchers);
        yield put({
            type: GET_ALL_TOUR_VOUCHERS_SUCCESS,
            payload: {
                data: data,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(null, data);
        }
    } catch (e) {
        yield put({
            type: GET_ALL_TOUR_VOUCHERS_FAIL,
            payload: {
                massage: e.massage,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(e, null);
        }
    }
}

function* handleExportExcel(action) {
    try {
        const {page, search, filter, sort} = action.payload?.searchConfig;
        const {data} = yield call(
            TransferVouchers.handleExportExcel,
            page,
            search,
            filter,
            sort,
        );
        yield put({
            type: EXPORT_TRANSFER_VOUCHERS_SUCCESS,
        });
        const blob = new Blob([data], {
            type: 'application/vnd.ms-excel',
        });
        saveAs(blob, `TransferVouchers.xlsx`);
    } catch (e) {
        yield put({
            type: EXPORT_TRANSFER_VOUCHERS_FAIL,
            message: e.message,
        });
        if (action.payload.cb) {
            action.payload.cb(e.message, null);
        }
    }
}

function* handleChangeStatus(action) {
    try {
        const {id} = action.payload;
        const {data} = yield call(
            TransferVouchers.changeTransferVoucherStatus,
            id,
        );
        yield put({
            type: CHANGE_TRANSFER_VOUCHER_STATUS_SUCCESS,
            payload: {
                data: data,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(null, data);
        }
    } catch (e) {
        console.log(e);
        yield put({
            type: CHANGE_TRANSFER_VOUCHER_STATUS_FAIL,
            payload: {
                massage: e.massage,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(e, null);
        }
    }
}

function* addCommentTransferVoucher(action) {
    try {
        const {comment, id} = action.payload;
        const {data} = yield call(TransferVouchers.addComment, id, comment);
        yield put({
            type: ADD_COMMENT_TRANSFER_VOUCHER_SUCCESS,
            payload: {
                data: data,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(null, data);
        }
    } catch (e) {
        console.log(e);
        yield put({
            type: ADD_COMMENT_TRANSFER_VOUCHER_FAIL,
            payload: {
                massage: e.massage,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(e, null);
        }
    }
}

function* copyTourVoucher(action) {
    try {
        const {id} = action.payload;
        const {data} = yield call(TransferVouchers.copy, id);
        yield put({
            type: COPY_TRANSFER_VOUCHER_SUCCESS,
            payload: {
                data: data,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(null, data);
        }
    } catch (e) {
        console.log(e);
        yield put({
            type: COPY_TRANSFER_VOUCHER_FAIL,
            payload: {
                massage: e.massage,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(e, null);
        }
    }
}

function* handleSendEmail(action) {
    try {
        const {id, formData} = action.payload;
        const {data} = yield call(
            TransferVouchers.sendTransferVoucherEmail,
            id,
            formData,
        );
        yield put({
            type: SEND_EMAIL_TRANSFER_VOUCHER_SUCCESS,
            payload: {
                data: data,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(null, data);
        }
    } catch (e) {
        yield put({
            type: SEND_EMAIL_TRANSFER_VOUCHER_FAIL,
            payload: {
                massage: e.massage,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(e, null);
        }
    }
}

function* checkTransferVoucher(action) {
    try {
        const {formData} = action.payload;
        const {data} = yield call(
            TransferVouchers.checkTransferVoucher,
            formData,
        );
        yield put({
            type: CHECK_TRANSFER_VOUCHER_SUCCESS,
            payload: {
                data: data,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(null, data);
        }
    } catch (e) {
        yield put({
            type: CHECK_TRANSFER_VOUCHER_FAIL,
            payload: {
                massage: e.massage,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(e.response?.data?.message, null);
        }
    }
}

function* downloadTransferVoucher(action) {
    try {
        const {id, ag_ref_number, page, role} = action.payload;
        const {data} = yield call(TransferVouchers.downloadTransferVoucher, {
            id,
            role,
        });
        const blob = new Blob([data], {type: 'application/pdf; charset=utf-8'});
        yield put({
            type: DOWNLOAD_TRANSFER_VOUCHER_SUCCESS,
            payload: {
                data: data,
            },
        });
        saveAs(blob, `${page}_${ag_ref_number}.pdf`);
        if (action.payload.cb) {
            action.payload.cb(null, data);
        }
    } catch (e) {
        yield put({
            type: DOWNLOAD_TRANSFER_VOUCHER_FAIL,
            payload: {
                massage: e.massage,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(e, null);
        }
    }
}

function* fetchTourVouchers(action) {
    try {
        const {data} = yield call(TourVouchers.fetchTourVouchers);

        yield put({
            type: FETCH_TOUR_VOUCHER_SUCCESS,
            payload: {
                data: data,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(null, data);
        }
    } catch (e) {
        yield put({
            type: FETCH_TOUR_VOUCHER_FAIL,
            payload: {
                massage: e.massage,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(e?.response?.data?.message, null);
        }
    }
}

function* shareLocation(action) {
    try {
        const location = action.payload;
        const {data} = yield call(TransferVouchers.shareMyLocation, location);

        yield put({
            type: SHARE_MY_LOCATION_TRANSFER_SUCCESS,
            payload: {
                data: data,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(null, data);
        }
    } catch (e) {
        yield put({
            type: SHARE_MY_LOCATION_TRANSFER_FAIL,
            payload: {
                massage: e.massage,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(e?.response?.data?.message, null);
        }
    }
}

function* downloadTransferVouchersReport(action) {
    try {
        const {
            user_id, group_field, service_date_from, service_date_to
        } =
            action.payload?.data;

        const {data} = yield call(
            TransferVouchers.handleDownloadTransferVouchersReport,
            user_id,
            group_field,
            service_date_from,
            service_date_to,
        );
        yield put({
            type: DOWNLOAD_TRANSFER_VOUCHERS_REPORT_SUCCESS,
        });
        const blob = new Blob([data], {
            type: 'application/vnd.ms-excel',
        });

        // if (action.payload.cb) {
        //     action.payload.cb(null, blob);
        // }

        if (user_id?.id === 'all') {
            saveAs(
                blob,
                service_date_from && service_date_to
                    ? `TransferVouchers_All_${group_field?.name}s_${service_date_from} - ${service_date_to}.xlsx`
                    : !service_date_from && service_date_to
                        ? `TransferVouchers_All_${group_field?.name}s_${service_date_to}.xlsx`
                        : service_date_from && !service_date_to
                            ? `TransferVouchers_All_${group_field?.name}s_${service_date_from}.xlsx`
                            : !service_date_from && !service_date_to && `TransferVouchers_All_${group_field?.name}s.xlsx`,
            );
        } else {
            saveAs(
                blob,
                service_date_from && service_date_to
                    ? `TransferVouchers_${group_field?.name}s_${user_id?.name}_${service_date_from} - ${service_date_to}.xlsx`
                    : !service_date_from && service_date_to
                        ? `TransferVouchers_${group_field?.name}s_${user_id?.name}_${service_date_to}.xlsx`
                        : service_date_from && !service_date_to
                            ? `TransferVouchers_${group_field?.name}s_${user_id?.name}_${service_date_from}.xlsx`
                            : !service_date_from && !service_date_to && `TransferVouchers_${group_field?.name}s_${user_id?.name}_.xlsx`,
            );
        }
    } catch (e) {
        yield put({
            type: DOWNLOAD_TRANSFER_VOUCHERS_REPORT_FAIL,
            message: e.message,
        });
        if (action.payload.cb) {
            action.payload.cb(e.message, null);
        }
    }
}

function* getTransferVouchersForKPIReport(action) {
    try {
        const {data} = yield call(
            TransferVouchers.handleGetTransferVouchersForKPIReport,
        );
        yield put({
            type: GET_TRANSFER_VOUCHERS_FOR_KPI_REPORT_SUCCESS,
        });

        if (action.payload.cb) {
            action.payload.cb(null, data);
        }
    } catch (e) {
        yield put({
            type: GET_TRANSFER_VOUCHERS_FOR_KPI_REPORT_FAIL,
            message: e.message,
        });
        if (action.payload.cb) {
            action.payload.cb(e.message, null);
        }
    }
}

function* downloadTransferVouchersKPIReport(action) {
    try {
        const {
            user_id, group_field, year
        } =
            action.payload?.data;

        const {data} = yield call(
            TransferVouchers.handleDownloadTransferVouchersKPIReport,
            user_id,
            group_field,
            year,
        );
        yield put({
            type: DOWNLOAD_TRANSFER_VOUCHERS_KPI_REPORT_SUCCESS,
        });
        const blob = new Blob([data], {
            type: 'application/vnd.ms-excel',
        });

        // if (action.payload.cb) {
        //     action.payload.cb(null, blob);
        // }

        // if (user_id?.id === 'all') {
        //     saveAs(
        //         blob,
        //         dayjs().format('YYYY') > year?.value
        //             ? `KPI_Report_${group_field?.name}s_All_01.01.${year?.value} - 31.12.${year?.value}.xlsx`
        //             : dayjs().format('YYYY') === year?.value
        //             && `KPI_Report_${group_field?.name}s_All_01.01.${year?.value} - ${dayjs().format('DD.MM.YYYY')}.xlsx`,
        //     );
        // } else {
        //     saveAs(
        //         blob,
        //         dayjs().format('YYYY') > year?.value
        //             ? `KPI_Report_${group_field?.name}_${user_id?.name}_01.01.${year?.value} - 31.12.${year?.value}.xlsx`
        //             : dayjs().format('YYYY') === year?.value
        //             && `KPI_Report_${group_field?.name}_${user_id?.name}_01.01.${year?.value} - ${dayjs().format('DD.MM.YYYY')}.xlsx`
        //     );
        // }
        if (user_id?.id === 'all') {
            saveAs(
                blob,
                dayjs().format('YYYY') > year?.value
                    ? `KPI_Report_${group_field?.name}_All_${year?.value}.xlsx`
                    : dayjs().format('YYYY') == year?.value
                    && `KPI_Report_${group_field?.name}_All_${year?.value}.xlsx`,
            );
        } else {
            saveAs(
                blob,
                dayjs().format('YYYY') > year?.value
                    ? `KPI_Report_${group_field?.name}_${user_id?.name}_${year?.value}.xlsx`
                    : dayjs().format('YYYY') == year?.value
                    && `KPI_Report_${group_field?.name}_${user_id?.name}_${year?.value}.xlsx`
            );
        }
    } catch (e) {
        yield put({
            type: DOWNLOAD_TRANSFER_VOUCHERS_KPI_REPORT_FAIL,
            message: e.message,
        });
        if (action.payload.cb) {
            action.payload.cb(e.message, null);
        }
    }
}