import React, { useState, useEffect } from 'react';
import './admin_side_bar.scss';
import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css';
import SideBar from './SideBar';
import {
  List,
  ListItemIcon,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { pages } from '../../pagesData/pages';
import { useSelector, useDispatch } from 'react-redux';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import Footer from '../footer/Footer';
import { useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import { logOut } from '../../store/actions/users';

function AdminSideBar({ status }) {
  const accessList = useSelector(store => store?.users?.accessList);
  const [active, setActive] = useState(null);
  const [active1, setActive1] = useState(null);
  const account = useSelector(store => store.users.account);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setActive();
  }, [location]);

  const userLogOut = () => {
    navigate('/login');
    dispatch(logOut());
  };

  return (
    <div className="admin_side_bar">
      <List
        className="list_block"
        sx={{ width: '100%', maxWidth: 360 }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        // subheader={
        //   <ListSubheader component="div" id="nested-list-subheader">
        //     Nested List Items
        //   </ListSubheader>
        // }
      >
        {/*<SideBar*/}
        {/*  page={{*/}
        {/*    title: 'Dashboard',*/}
        {/*  }}*/}
        {/*  setActive={setActive}*/}
        {/*  active={active}*/}
        {/*  active1={active1}*/}
        {/*  setActive1={setActive1}*/}
        {/*/>*/}
        {!isEmpty(account) &&
          pages
            .filter(i => i.access.includes(account.role))
            .map(page =>
              page?.side_bar ? (
                <SideBar
                  key={page.id}
                  page={page}
                  setActive={setActive}
                  active={active}
                  active1={active1}
                  setActive1={setActive1}
                />
              ) : null,
            )}
      </List>
      <List
        className="list_block menu_bottom"
        sx={{ width: '100%', maxWidth: 360 }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <>
          <ListItemButton className="side_bar_tab" selected={true}>
            <ListItemIcon className="icon_block">
              <PeopleAltIcon size={16} className="account_me_icon" />
            </ListItemIcon>
            <ListItemText
              className="side_bar_text"
              primary={account?.user_name || account?.name}
            />
          </ListItemButton>
          <ListItemButton className="side_bar_tab" onClick={() => navigate('/my-profile')} selected={location && location?.pathname === '/my-profile'}>
            <ListItemIcon className="icon_block">
            <ManageAccountsIcon size={16} className="account_me_icon" />
            </ListItemIcon>
            <ListItemText
              className="side_bar_text"
              primary={'My Profile'}
            />
          </ListItemButton>
          <ListItemButton className="side_bar_tab" onClick={userLogOut}>
            <ListItemIcon className="icon_block">
            <LogoutIcon size={16} className="account_me_icon" />
            </ListItemIcon>
            <ListItemText
              className="side_bar_text"
              primary={'Log Out'}
            />
          </ListItemButton>
          </>
        }
      >
      </List>
    </div>
  );
}

export default AdminSideBar;
