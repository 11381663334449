import { api } from './Api';

export default class EmailSetups {
  static addEmailSetup(formData) {
    return api.post(`/email_setups`, { ...formData });
  }
  static getEmailSetups(page, limit, search, filter, [{ sort, field }]) {
    return api.get(`/email_setups`, {
      params: {
        search,
        filter,
        page,
        limit,
        sort,
        field,
      },
    });
  }
  static deleteEmailSetup(id) {
    return api.delete(`/email_setups/${id}`);
  }
  static updateEmailSetup(formData) {
    return api.put(`/email_setups/${formData?.id}`, { ...formData });
  }
  static getSingleEmailSetup(id) {
    return api.get(`/email_setups/single/${id}`);
  }
  static getAllEmailSetups(type) {
    return api.get(`/email_setups/all`, {
      params: {
        type,
      },
    });
  }
  static updateStatus(id) {
    return api.put(`/email_setups/status/${id}`);
  }
}
