export const ADD_USER_REQUEST = 'ADD_USER_REQUEST';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAIL = 'ADD_USER_FAIL';

export function registerRequest(formData, cb) {
  return {
    type: ADD_USER_REQUEST,
    payload: { formData, cb },
  };
}

export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAIL = 'LOGIN_USER_FAIL';

export function loginRequest(formData, cb) {
  return {
    type: LOGIN_USER_REQUEST,
    payload: { formData, cb },
  };
}

export const LOG_OUT = 'LOG_OUT';

export function logOut(dispatch) {
  if (dispatch) {
    dispatch({
      type: LOG_OUT,
    });
  } else {
    return {
      type: LOG_OUT,
    };
  }
}

export const GET_ALL_SUPPLIERS_REQUEST = 'GET_ALL_SUPPLIERS_REQUEST';
export const GET_ALL_SUPPLIERS_SUCCESS = 'GET_ALL_SUPPLIERS_SUCCESS';
export const GET_ALL_SUPPLIERS_FAIL = 'GET_ALL_SUPPLIERS_FAIL';

export function getAllSuppliers(cb) {
  return {
    type: GET_ALL_SUPPLIERS_REQUEST,
    payload: {
      cb,
    },
  };
}

export const GET_ALL_DRIVERS_REQUEST = 'GET_ALL_DRIVERS_REQUEST';
export const GET_ALL_DRIVERS_SUCCESS = 'GET_ALL_DRIVERS_SUCCESS';
export const GET_ALL_DRIVERS_FAIL = 'GET_ALL_DRIVERS_FAIL';

export function getAllDriversAction(cb) {
  return {
    type: GET_ALL_DRIVERS_REQUEST,
    payload: {
      cb,
    },
  };
}

export const GET_ALL_SALES_AGENT_REQUEST = 'GET_ALL_SALES_AGENT_REQUEST';
export const GET_ALL_SALES_AGENT_SUCCESS = 'GET_ALL_SALES_AGENT_SUCCESS';
export const GET_ALL_SALES_AGENT_FAIL = 'GET_ALL_SALES_AGENT_FAIL';

export function getAllSalesAgentAction(cb) {
  return {
    type: GET_ALL_SALES_AGENT_REQUEST,
    payload: {
      cb,
    },
  };
}

export const MY_ACCOUNT_REQUEST = 'MY_ACCOUNT_REQUEST';
export const MY_ACCOUNT_SUCCESS = 'MY_ACCOUNT_SUCCESS';
export const MY_ACCOUNT_FAIL = 'MY_ACCOUNT_FAIL';

export function myAccountRequest() {
  return {
    type: MY_ACCOUNT_REQUEST,
    payload: {},
  };
}

export const ACTIVATE_USER_ACCOUNT_REQUEST = 'ACTIVATE_USER_ACCOUNT_REQUEST';
export const ACTIVATE_USER_ACCOUNT_SUCCESS = 'ACTIVATE_USER_ACCOUNT_SUCCESS';
export const ACTIVATE_USER_ACCOUNT_FAIL = 'ACTIVATE_USER_ACCOUNT_FAIL';

export function activateAccountRequest(formData, cb) {
  return {
    type: ACTIVATE_USER_ACCOUNT_REQUEST,
    payload: { formData, cb },
  };
}
export const GET_USERS_LIST_REQUEST = 'GET_USERS_LIST_REQUEST';
export const GET_USERS_LIST_SUCCESS = 'GET_USERS_LIST_SUCCESS';
export const GET_USERS_LIST_FAIL = 'GET_USERS_LIST_FAIL';

export function getUsersListAction(searchConfig, cb) {
  return {
    type: GET_USERS_LIST_REQUEST,
    payload: { searchConfig, cb },
  };
}

export const GET_ALL_USERS_LIST_REQUEST = 'GET_ALL_USERS_LIST_REQUEST';
export const GET_ALL_USERS_LIST_SUCCESS = 'GET_ALL_USERS_LIST_SUCCESS';
export const GET_ALL_USERS_LIST_FAIL = 'GET_ALL_USERS_LIST_FAIL';

export function getAllUsersList(cb) {
  return {
    type: GET_ALL_USERS_LIST_REQUEST,
    payload: { cb },
  };
}

export function clearUsersStore() {
  return {
    type: GET_USERS_LIST_SUCCESS,
    payload: {
      users: [],
    },
  };
}

export const SEARCH_IN_USERS = 'SEARCH_IN_USERS';

export function searchUserAction(search) {
  return {
    type: SEARCH_IN_USERS,
    payload: {
      search,
    },
  };
}

export const GET_ACCESS_LIST_REQUEST = 'GET_ACCESS_LIST_REQUEST';
export const GET_ACCESS_LIST_SUCCESS = 'GET_ACCESS_LIST_SUCCESS';
export const GET_ACCESS_LIST_FAIL = 'GET_ACCESS_LIST_FAIL';

export function getAccessList(cb) {
  return {
    type: GET_ACCESS_LIST_REQUEST,
    payload: {
      cb,
    },
  };
}
export const GET_SINGLE_ACCESS_LIST_REQUEST = 'GET_SINGLE_ACCESS_LIST_REQUEST';
export const GET_SINGLE_ACCESS_LIST_SUCCESS = 'GET_SINGLE_ACCESS_LIST_SUCCESS';
export const GET_SINGLE_ACCESS_LIST_FAIL = 'GET_SINGLE_ACCESS_LIST_FAIL';

export function getAccessListRequest(cb) {
  return {
    type: GET_SINGLE_ACCESS_LIST_REQUEST,
    payload: {
      cb,
    },
  };
}

export const IS_OPEN_NAV_USER_REQUEST = 'IS_OPEN_NAV_USER_REQUEST';
export const IS_OPEN_NAV_USER_SUCCESS = 'IS_OPEN_NAV_USER_SUCCESS';
export const IS_OPEN_NAV_USER_FAIL = 'IS_OPEN_NAV_USER_FAIL';

export function isOpenNavUsers(bool) {
  return {
    type: IS_OPEN_NAV_USER_REQUEST,
    payload: { bool },
  };
}
export const BLOCK_PAGE = 'BLOCK_PAGE';

export function blockPageAction(page) {
  return {
    type: BLOCK_PAGE,
    payload: {
      page,
    },
  };
}
export const IS_OPEN_NAV_BAR_REQUEST = 'IS_OPEN_NAV_BAR_REQUEST';
export const IS_OPEN_NAV_BAR_SUCCESS = 'IS_OPEN_NAV_BAR_SUCCESS';
export const IS_OPEN_NAV_BAR_FAIL = 'IS_OPEN_NAV_BAR_FAIL';

export function isOpenNavBar(bool) {
  return {
    type: IS_OPEN_NAV_BAR_REQUEST,
    payload: { bool },
  };
}
export const IS_OPEN_NAV_LEADS_REQUEST = 'IS_OPEN_NAV_LEADS_REQUEST';
export const IS_OPEN_NAV_LEADS_SUCCESS = 'IS_OPEN_NAV_LEADS_SUCCESS';
export const IS_OPEN_NAV_LEADS_FAIL = 'IS_OPEN_NAV_LEADS_FAIL';

export function isOpenNavLeads(bool) {
  return {
    type: IS_OPEN_NAV_LEADS_REQUEST,
    payload: { bool },
  };
}

export const IS_OPEN_NAV_ORDER = 'IS_OPEN_NAV_ORDER';

export function openNavOrder(bool) {
  return {
    type: IS_OPEN_NAV_ORDER,
    payload: { bool },
  };
}

export const IS_OPEN_NAV_APPLIANCE_REQUEST = 'IS_OPEN_NAV_APPLIANCE_REQUEST';
export const IS_OPEN_NAV_APPLIANCE_SUCCESS = 'IS_OPEN_NAV_APPLIANCE_SUCCESS';
export const IS_OPEN_NAV_APPLIANCE_FAIL = 'IS_OPEN_NAV_APPLIANCE_FAIL';

export function isOpenNavAppliance(bool) {
  return {
    type: IS_OPEN_NAV_APPLIANCE_REQUEST,
    payload: { bool },
  };
}
export const GET_USER_ACCOUNT_REQUEST = 'GET_USER_ACCOUNT_REQUEST';
export const GET_USER_ACCOUNT_SUCCESS = 'GET_USER_ACCOUNT_SUCCESS';
export const GET_USER_ACCOUNT_FAIL = 'GET_USER_ACCOUNT_FAIL';

export function getAccountRequest(id, cb) {
  return {
    type: GET_USER_ACCOUNT_REQUEST,
    payload: { id, cb },
  };
}

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';

export function updateUser(formData, cb) {
  return {
    type: UPDATE_USER_REQUEST,
    payload: {
      formData,
      cb,
    },
  };
}

export const UPDATE_USER_STATUS_REQUEST = 'UPDATE_USER_STATUS_REQUEST';
export const UPDATE_USER_STATUS_SUCCESS = 'UPDATE_USER_STATUS_SUCCESS';
export const UPDATE_USER_STATUS_FAIL = 'UPDATE_USER_STATUS_FAIL';

export function updateUserStatus(stat, userId, cb) {
  return {
    type: UPDATE_USER_STATUS_REQUEST,
    payload: {
      stat,
      userId,
      cb,
    },
  };
}

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';

export function deleteUser(id, cb) {
  return {
    type: DELETE_USER_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}
export const GET_ACTIVATION_MAIL_REQUEST = 'GET_ACTIVATION_MAIL_REQUEST';
export const GET_ACTIVATION_MAIL_SUCCESS = 'GET_ACTIVATION_MAIL_SUCCESS';
export const GET_ACTIVATION_MAIL_FAIL = 'GET_ACTIVATION_MAIL_FAIL';

export function getActivationMail(mailHash, cb) {
  return {
    type: GET_ACTIVATION_MAIL_REQUEST,
    payload: {
      mailHash,
      cb,
    },
  };
}

export const RESEND_ACTIVATION_REQUEST = 'RESEND_ACTIVATION_REQUEST';
export const RESEND_ACTIVATION_SUCCESS = 'RESEND_ACTIVATION_SUCCESS';
export const RESEND_ACTIVATION_FAIL = 'RESEND_ACTIVATION_FAIL';

export function resendActivation(id, cb) {
  return {
    type: RESEND_ACTIVATION_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}
export const UPDATE_ACCOUNT_ME_REQUEST = 'UPDATE_ACCOUNT_ME_REQUEST';
export const UPDATE_ACCOUNT_ME_SUCCESS = 'UPDATE_ACCOUNT_ME_SUCCESS';
export const UPDATE_ACCOUNT_ME_FAIL = 'UPDATE_ACCOUNT_ME_FAIL';

export function updateMyAccount(formData, cb) {
  return {
    type: UPDATE_ACCOUNT_ME_REQUEST,
    payload: {
      formData,
      cb,
    },
  };
}

export const CLEAR_ALL_TEST_DATA_REQUEST = 'CLEAR_ALL_TEST_DATA_REQUEST';
export const CLEAR_ALL_TEST_DATA_SUCCESS = 'CLEAR_ALL_TEST_DATA_SUCCESS';
export const CLEAR_ALL_TEST_DATA_FAIL = 'CLEAR_ALL_TEST_DATA_FAIL';

export function clearTestDataAction(cb) {
  return {
    type: CLEAR_ALL_TEST_DATA_REQUEST,
    payload: { cb },
  };
}

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL';

export function changePassword(formData, cb) {
  return {
    type: CHANGE_PASSWORD_REQUEST,
    payload: { formData, cb },
  };
}

export const EXPORT_USERS_REQUEST = 'EXPORT_USERS_REQUEST';
export const EXPORT_USERS_SUCCESS = 'EXPORT_USERS_SUCCESS';
export const EXPORT_USERS_FAIL = 'EXPORT_USERS_FAIL';

export function exportExcelUserAction(searchConfig, cb) {
  return {
    type: EXPORT_USERS_REQUEST,
    payload: { searchConfig, cb },
  };
}

export const GET_TECHNICS_REQUEST = 'GET_TECHNICS_REQUEST';
export const GET_TECHNICS_SUCCESS = 'GET_TECHNICS_SUCCESS';
export const GET_TECHNICS_FAIL = 'GET_TECHNICS_FAIL';

export function getAllTechnics(cb) {
  return {
    type: GET_TECHNICS_REQUEST,
    payload: { cb },
  };
}

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL';

export function forgotPassRequest(formData, cb) {
  return {
    type: FORGOT_PASSWORD_REQUEST,
    payload: { formData, cb },
  };
}

export const SWITCH_AUTO_SAVE_REQUEST = 'SWITCH_AUTO_SAVE_REQUEST';
export const SWITCH_AUTO_SAVE_SUCCESS = 'SWITCH_AUTO_SAVE_SUCCESS';
export const SWITCH_AUTO_SAVE_FAIL = 'SWITCH_AUTO_SAVE_FAIL';

export function switchAutoSaveAction(autoSave, cb) {
  return {
    type: SWITCH_AUTO_SAVE_REQUEST,
    payload: { autoSave, cb },
  };
}

export const DELETE_SELECTED_USERS_REQUEST = 'DELETE_SELECTED_USERS_REQUEST';
export const DELETE_SELECTED_USERS_SUCCESS = 'DELETE_SELECTED_USERS_SUCCESS';
export const DELETE_SELECTED_USERS_FAIL = 'DELETE_SELECTED_USERS_FAIL';

export function deleteSelectedUserAction(selectedRows, cb) {
  return {
    type: DELETE_SELECTED_USERS_REQUEST,
    payload: { selectedRows, cb },
  };
}

export const GET_SINGLE_USER_REQUEST = 'GET_SINGLE_USER_REQUEST';
export const GET_SINGLE_USER_SUCCESS = 'GET_SINGLE_USER_SUCCESS';
export const GET_SINGLE_USER_FAIL = 'GET_SINGLE_USER_FAIL';

export function getSingleUserAction(id, cb) {
  return {
    type: GET_SINGLE_USER_REQUEST,
    payload: { id, cb },
  };
}

export const GET_MY_ACCOUNT_REQUEST = 'GET_MY_ACCOUNT_REQUEST';
export const GET_MY_ACCOUNT_SUCCESS = 'GET_MY_ACCOUNT_SUCCESS';
export const GET_MY_ACCOUNT_FAIL = 'GET_MY_ACCOUNT_FAIL';

export function getMyAccountAction(cb) {
  return {
    type: GET_MY_ACCOUNT_REQUEST,
    payload: { cb },
  };
}

export const UPDATE_MY_ACCOUNT_REQUEST = 'UPDATE_MY_ACCOUNT_REQUEST';
export const UPDATE_MY_ACCOUNT_SUCCESS = 'UPDATE_MY_ACCOUNT_SUCCESS';
export const UPDATE_MY_ACCOUNT_FAIL = 'UPDATE_MY_ACCOUNT_FAIL';

export function handleUpdateMyProfile(formData, cb) {
  return {
    type: UPDATE_MY_ACCOUNT_REQUEST,
    payload: {
      formData,
      cb,
    },
  };
}

export const AUTH_USER_REQUEST = 'AUTH_USER_REQUEST';

export function authUserAction(query, cb) {
  return {
    type: AUTH_USER_REQUEST,
    payload: {
      query,
      cb,
    },
  };
}

export const EXCHANGE_CODE_REQUEST = 'EXCHANGE_CODE_REQUEST';

export function exchangeCodeAction(query, cb) {
  return {
    type: EXCHANGE_CODE_REQUEST,
    payload: {
      query,
      cb,
    },
  };
}
