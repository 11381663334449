import Login from '../pages/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import TransferTypes from '../pages/transferTypes/TransferTypes';
import PreviewTransferTypes from '../pages/transferTypes/PreviewTransferTypes';
import Tours from '../pages/tours/Tours';
import PreviewTours from '../pages/tours/PreviewTours';
import PickUpPoints from '../pages/pickUpPoints/PickUpPoints';
import PreviewPickUpPoints from '../pages/pickUpPoints/PreviewPickUpPoints';
import TourVouchers from '../pages/tourVouchers/TourVouchers';
import PreviewTourVouchers from '../pages/tourVouchers/PreviewTourVouchers';
import TourTransfers from '../pages/tourTransfers/TourTransfers';
import PreviewTourTransfers from '../pages/tourTransfers/PreviewTourTransfers';
import Advertisements from '../pages/advertisements/Advertisements';
import PreviewAdvertisement from '../pages/advertisements/PreviewAdvertisement';
import Directions from '../pages/directions/Directions';
import PreviewDirections from '../pages/directions/PreviewDirections';
import Users from '../pages/users/Users';
import PreviewUsers from '../pages/users/PreviewUsers';
import TransferVouchers from '../pages/transferVouchers/TransferVouchers';
import PreviewTransferVouchers from '../pages/transferVouchers/PreviewTransferVouchers';
import CustomSettings from '../pages/settings/CustomSettings';
import PreviewSettings from '../pages/settings/PreviewSettings';
import EmailSetups from '../pages/emailSetups/EmailSetups';
import PreviewEmailSetups from '../pages/emailSetups/PreviewEmailSetups';
import Dashboard from '../pages/dashboard/Dashboard';
import TermsConditions from '../pages/termsAndConditions/TermsConditions';
import PreviewTermsConditions from '../pages/termsAndConditions/PreviewTermsConditions';
import MyAccount from '../pages/my_account/MyAccount';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

export const menuSideBarBottom = [
  {
    id: 3,
    path: '/my-profile',
    title: 'My Profile',
    icon: <ManageAccountsIcon />,
    element: <MyAccount />,
    side_bar: true,
    access: ['Admin', 'Supplier', 'Driver', 'Sales Agent'],
  },

  {
    id: 1,
    path: '/login',
    title: 'Log Out',
    icon: <LogoutIcon />,
    element: <Login />,
    side_bar: true,
  },
];

export const mustPages = [
  {
    id: 1,
    path: '/login',
    title: 'Login',
    icon: '',
    element: <Login />,
    side_bar: true,
  },
];

export const pages = [
  //////////////////// SIDEBAR ////////////////////

  // {
  //   id: '22',
  //   path: 'admin/roles',
  //   title: 'Roles',
  //   element: <Roles />,
  //   side_bar: true,
  // },
  // {
  //   id: '23',
  //   path: 'admin/roles/:id',
  //   title: 'Roles',
  //   element: <PreviewRoles />,
  // },

  {
    id: '110',
    path: '/my-profile',
    title: 'My Profile',
    icon: <ManageAccountsIcon />,
    element: <MyAccount />,
    side_bar: false,
    access: ['Admin', 'Supplier', 'Driver', 'Sales Agent'],
  },
  {
    id: '100',
    path: 'admin/dashboard',
    title: 'Dashboard',
    element: <Dashboard />,
    side_bar: true,
    access: ['Admin', 'Supplier', 'Driver', 'Sales Agent'],
  },
  {
    id: '7',
    path: 'admin/tours',
    title: 'Tours',
    element: <Tours />,
    side_bar: true,
    access: ['Admin', 'Supplier'],
  },
  {
    id: '8',
    path: 'admin/tours/:id',
    title: 'Tours',
    element: <PreviewTours />,
    access: ['Admin', 'Supplier'],
  },
  {
    id: '1',
    path: 'admin/vouchers',
    title: 'Tour Vouchers',
    element: <TourVouchers />,
    side_bar: true,
    access: ['Admin', 'Sales Agent'],
  },
  {
    id: '2',
    path: 'admin/vouchers/:id',
    title: 'Tour Vouchers',
    element: <PreviewTourVouchers />,
    access: ['Admin', 'Sales Agent'],
  },
  {
    id: '3',
    path: 'admin/transfers',
    title: 'Transfers',
    element: <TourTransfers />,
    side_bar: true,
    access: ['Admin', 'Driver'],
  },

  {
    id: '23',
    path: 'admin/transfers_vouchers/:id',
    title: 'Transfer Vouchers',
    element: <TransferVouchers />,
    access: ['Admin', 'Driver', 'Sales Agent'],
  },
  {
    id: '24',
    path: 'admin/transfer_vouchers/:id',
    title: 'Transfer Vouchers',
    element: <PreviewTransferVouchers />,
    access: ['Admin', 'Sales Agent'],
  },
  {
    id: '22',
    path: 'admin/transfer_vouchers',
    title: 'Transfer Vouchers',
    element: <TransferVouchers />,
    side_bar: true,
    access: ['Admin', 'Sales Agent'],
  },
  {
    id: '4',
    path: 'admin/transfers/:id',
    title: 'Transfer Vouchers',
    element: <PreviewTourTransfers />,
    access: ['Admin', 'Sales Agent'],
  },
  {
    id: '5',
    path: 'admin/directions',
    title: 'Directions',
    element: <Directions />,
    side_bar: true,
    access: ['Admin'],
  },
  {
    id: '6',
    path: 'admin/directions/:id',
    title: 'Directions',
    element: <PreviewDirections />,
    access: ['Admin'],
  },

  {
    id: '8',
    path: 'admin/tours_vouchers/:id',
    title: 'Tours',
    element: <TourVouchers />,
    access: ['Admin', 'Supplier'],
  },
  {
    id: '9',
    path: 'admin/pick_up_points',
    title: 'Pick-Up Points',
    element: <PickUpPoints />,
    side_bar: true,
    access: ['Admin'],
  },
  {
    id: '10',
    path: 'admin/pick_up_points/:id',
    title: 'Pick-Up Points',
    element: <PreviewPickUpPoints />,
    access: ['Admin'],
  },
  // {
  //   id: '11',
  //   path: 'admin/suppliers',
  //   title: 'Suppliers',
  //   element: <Suppliers />,
  //   side_bar: true,
  //   access: ['Admin'],
  // },
  // {
  //   id: '12',
  //   path: 'admin/suppliers/:id',
  //   title: 'Suppliers',
  //   element: <PreviewSuppliers />,
  //   access: ['Admin'],
  // },
  {
    id: '20',
    path: 'admin/users',
    title: 'Users',
    element: <Users />,
    side_bar: true,
    access: ['Admin'],
  },
  {
    id: '21',
    path: 'admin/users/:id',
    title: 'Users',
    element: <PreviewUsers />,
    access: ['Admin'],
  },
  // {
  //   id: '13',
  //   path: 'admin/drivers',
  //   title: 'Drivers',
  //   element: <Drivers />,
  //   side_bar: true,
  //   access: ['Admin'],
  // },
  // {
  //   id: '14',
  //   path: 'admin/drivers/:id',
  //   title: 'Drivers',
  //   element: <PreviewDrivers />,
  //   access: ['Admin'],
  // },
  {
    id: '30',
    path: 'admin/setup',
    title: 'Setup',
    access: ['Admin'],
    side_bar: true,
    children: [
      {
        id: '15',
        path: 'admin/setup/transfer_types',
        title: 'Transfer Types',
        element: <TransferTypes />,
        side_bar: true,
        access: ['Admin'],
      },
      {
        id: '16',
        path: 'admin/setup/transfer_types/:id',
        title: 'Transfer Types',
        element: <PreviewTransferTypes />,
        access: ['Admin'],
      },
      {
        id: '17',
        path: 'admin/setup/advertisements',
        title: 'Advertisements',
        element: <Advertisements />,
        side_bar: true,
        access: ['Admin'],
      },
      {
        id: '18',
        path: 'admin/setup/advertisements/:id',
        title: 'Advertisements',
        element: <PreviewAdvertisement />,
        access: ['Admin'],
      },
      // {
      //   id: '25',
      //   path: 'admin/setup/settings',
      //   title: 'Settings',
      //   element: <CustomSettings />,
      //   side_bar: true,
      //   access: ['Admin'],
      // },
      // {
      //   id: '24',
      //   path: 'admin/setup/settings/:id',
      //   title: 'Settings',
      //   element: <PreviewSettings />,
      //   side_bar: false,
      //   access: ['Admin'],
      // },
      // {
      //   id: '19',
      //   path: 'admin/email_setup',
      //   title: 'Email Setup',
      //   element: <EmailSetup />,
      //   side_bar: true,
      //   access: ['Admin'],
      // },
      {
        id: '26',
        path: 'admin/setup/email_setup',
        title: 'Email Setup',
        element: <EmailSetups />,
        side_bar: true,
        access: ['Admin'],
      },
      {
        id: '27',
        path: 'admin/setup/email_setup/:id',
        title: 'Email Setup',
        element: <PreviewEmailSetups />,
        side_bar: false,
        access: ['Admin'],
      },
      {
        id: '28',
        path: 'admin/setup/terms_conditions',
        title: 'Terms and Conditions',
        element: <TermsConditions />,
        side_bar: true,
        access: ['Admin'],
      },
      {
        id: '29',
        path: 'admin/setup/terms_conditions/:id',
        title: 'Terms and Conditions',
        element: <PreviewTermsConditions />,
        side_bar: false,
        access: ['Admin'],
      },
    ],
  },
];
