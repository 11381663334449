import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Table from '../../components/table';
import Pagination from '../../components/pagination/Pagination';
import SearchFilterBar from '../../components/search_filter_bar/SearchFilterBar';
import TableTitle from '../../components/table_title/TableTitle';
import { setPage, sortAction } from '../../store/actions/search';
import DeleteModal2 from '../../components/modals/DeleteModal2';
import { getColumns } from './column';
import {
  changeTourStatusAction,
  deleteTour,
  deleteSelectedTours,
  exportExcelToursAction,
  getToursAction,
  updateTourPickUpId,
} from '../../store/actions/tours';
import ToursForm from './ToursForm';
import { isEmpty } from 'lodash';
import UpdateStatusModal from '../../components/modals/UpdateStatusModal';
import EditPickUpPointModal from '../../components/modals/EditPickUpPointModal';
import EditDriverLocationModal from '../../components/modals/EditDriverLocationModal';
import MobileLayout from '../../components/mobileLayout/MobileLayout';
import { tour_mobile_column } from '../../staticVariables/mobileColumns';

export const tourStatus = [
  {
    id: 'Active',
    color: 'green',
  },
  {
    id: 'Inactive',
    color: '#dc3545',
  },
];

function Tours() {
  let rows = useSelector(store => store?.tours?.tours);
  let rowStatus = useSelector(store => store?.tours?.status);
  let exportExcelStatus = useSelector(store => store?.tours?.exportExcelStatus);
  let actionStatus = useSelector(store => store?.tours?.actionStatus);
  let searchConfig = useSelector(store => store.search);
  const count = useSelector(store => store.tours.count);
  const account = useSelector(store => store.users.account);
  const [showDelete, setShowDelete] = useState(false);
  const [showEditPickUpPoint, setShowEditPickUpPoint] = useState(false);
  const [showDriverLocationModal, setShowDriverLocationModal] = useState(false);
  const [pickUpId, setPickUpId] = useState('');
  const [tourId, setTourId] = useState('');
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({});
  const [showStatus, setShowStatus] = useState(false);
  const [showSelectedDelete, setShowSelectedDelete] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [popupTitle, setPopupTitle] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [rowId, setRowId] = useState('');
  const dispatch = useDispatch();
  const [showFilter, setShowFilter] = useState(false);
  const [noteValidate, setNoteValidate] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    getToursList(searchConfig);
  }, []);

  // Redirect to preview page on click the data of the table
  const redirectToPages = event => {
    if (event.field == 'recovery_email' && event.row.recovery_email != null) {
      for (var value of rows) {
        if (value.email == event.formattedValue) {
          navigate(`/admin/dirvers/${value.id}`);
        }
      }
    }
  };

  const redirectToPreview = useCallback(params => {
    navigate(`/admin/tours/${params}`);
  }, []);

  const redirectToPreviewVoucher = useCallback(params => {
    navigate(`/admin/tours_vouchers/${params}`);
  }, []);
  const handleClose = () => {
    setShowDelete(false);
    setDisabled(false);
    setShow(false);
    setPopupTitle('');
    setFormData({});
    setShowStatus(false);
    setNoteValidate(true);
  };
  const handleCloseEditPickup = () => {
    setShowEditPickUpPoint(false);
    setPickUpId('');
    setTourId('');
    setFormData({});
  };

  const handleDelete = (ev, params) => {
    ev.stopPropagation();
    setShowDelete(true);
    setRowId(params);
  };

  const handleEditPickUpPoint = pickUp => {
    setShowEditPickUpPoint(true);
    setPickUpId(pickUp.pick_up_id);
    setFormData({ ...pickUp });
    setTourId(pickUp.id);
  };

  const handleConfirmDelete = (e, id) => {
    setDisabled(true);
    dispatch(
      deleteTour(rowId?.id, (err, data) => {
        if (data) {
          toast.success('Successfully Deleted', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 10000,
          });
          if (rows && rows.length === 1 && searchConfig.page !== 1) {
            searchConfig.page = searchConfig.page - 1;
            dispatch(setPage(searchConfig.page));
          }
          setRowId(null);
          setShowDelete(false);
          getToursList(searchConfig);
        }
        if (err) {
          toast.error(err.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 10000,
          });
          handleClose();
        }
      }),
    );
  };

  const handleShow = title => {
    setShow(true);
    setPopupTitle(title);
  };

  const handleSort = (model, page) => {
    if (model.length) {
      searchConfig.sort = model;
      dispatch(sortAction(model));
    } else {
      model = searchConfig.sort;
    }
    getToursList(searchConfig);
  };

  const getToursList = searchConfig => {
    dispatch(getToursAction(searchConfig));
  };

  const exportExcel = params => {
    dispatch(exportExcelToursAction(searchConfig));
  };

  const deleteSelectedRows = () => {
    setShowSelectedDelete(true);
  };
  const deleteSelectedRowsConfirm = reason => {
    dispatch(
      deleteSelectedTours(selectedRows, (err, data) => {
        if (data) {
          setSelectedRows([]);
          getToursAction(searchConfig);
          isEmpty(data)
            ? toast.success(`Successfully Deleted.`, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 10000,
              })
            : toast.success(
                `Successfully Deleted. Some of selected tours can't be deleted.`,
                {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 10000,
                },
              );

          setShowSelectedDelete(false);
        }
      }),
    );
  };

  const handleUpdateStatus = params => {
    setRowId(params);
    setShowStatus(true);
  };

  const handleConfirmUpdateStatus = id => {
    dispatch(
      changeTourStatusAction(rowId?.id, (err, data) => {
        if (data) {
          toast.success('Successfully Updated', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 10000,
          });
          getToursList(searchConfig);
        } else if (err) {
          toast.error(err.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 10000,
          });
        }
        setShowStatus(false);
      }),
    );
  };

  const handleOpenDriverModal = tour => {
    setFormData({ ...tour });
    setShowDriverLocationModal(true);
  };

  const handleCloseDriverModal = tour => {
    setFormData({});
    setShowDriverLocationModal(false);
  };

  const columns = getColumns({
    handleUpdateStatus,
    rows,
    handleEditPickUpPoint,
    handleOpenDriverModal,
    redirectToPreview,
    redirectToPreviewVoucher,
    handleDelete,
    navigate,
    role: !isEmpty(account) ? account.role : '',
  });

  const handleEditPickUpPoints = (pick_up_id, pick_up_time, id) => {
    dispatch(
      updateTourPickUpId({ pick_up_id, id, pick_up_time }, (err, data) => {
        if (err) {
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
        } else {
          if (data) {
            toast.success('Successfully Updated', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 10000,
            });

            handleCloseEditPickup();
            getToursList(searchConfig);
          }
        }
      }),
    );
  };

  const handleEditDriverLocation = data => {
    dispatch(
      updateTourPickUpId({ ...data }, (err, data) => {
        if (err) {
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
        } else {
          if (data) {
            toast.success('Successfully Updated', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 10000,
            });

            handleCloseEditPickup();
            getToursList(searchConfig);
          }
        }
      }),
    );
  };

  return (
    <div className="admin_tables_block">
      <TableTitle
        handleShow={handleShow}
        title={'Tours'}
        addNew={account && account.role === 'Admin'}
        exportExcel={
          !isEmpty(account) && account.role === 'Admin' ? exportExcel : null
        }
        batchAccess={'1103'}
        batchDelete={'1108'}
        selectedRows={selectedRows}
        deleteSelectedRows={deleteSelectedRows}
        exportExcelStatus={exportExcelStatus}
      />
      <SearchFilterBar
        getList={getToursList}
        columns={columns}
        setShowFilter={setShowFilter}
        showFilter={showFilter}
      />
      <Table
        status={rowStatus}
        rows={rows || []}
        columns={columns}
        setShow={setShow}
        setPopupTitle={setPopupTitle}
        setSort={handleSort}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        checkboxSelection={false}
      />
      <MobileLayout
        rows={rows || []}
        rowStatus={rowStatus}
        columns={columns}
        setShow={setShow}
        setPopupTitle={setPopupTitle}
        redirectToPages={redirectToPages}
        changeStatusHandler={handleConfirmUpdateStatus}
        parentTitle={'Tours'}
        link="/admin/tours"
        mobileCol={tour_mobile_column}
        status={tourStatus}
        search={getToursList}
      />
      <Modal
        size="xl"
        show={show}
        onHide={handleClose}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ToursForm
          popupTitle={popupTitle}
          setFormData={setFormData}
          title="add"
          handleClose={handleClose}
          actionStatus={actionStatus}
        />
      </Modal>
      {!isEmpty(formData) && (
        <EditPickUpPointModal
          className="react-select"
          classNamePrefix="react-select"
          Modal
          title={'Change Pick-Up Point'}
          tourId={tourId}
          value={pickUpId}
          setValue={setPickUpId}
          confirm={handleEditPickUpPoints}
          show={showEditPickUpPoint}
          handleClose={handleCloseEditPickup}
          setValidate={setNoteValidate}
          validate={noteValidate}
          formData={formData}
          setFormData={setFormData}
        />
      )}
      {!isEmpty(formData) && (
        <EditDriverLocationModal
          className="react-select"
          classNamePrefix="react-select"
          Modal
          title={'Change Driver Location Url'}
          tourId={tourId}
          value={pickUpId}
          setValue={setPickUpId}
          confirm={handleEditDriverLocation}
          show={showDriverLocationModal}
          handleClose={handleCloseDriverModal}
          setValidate={setNoteValidate}
          validate={noteValidate}
          formData={formData}
          setFormData={setFormData}
        />
      )}
      <DeleteModal2
        show={showSelectedDelete}
        handleClose={() => setShowSelectedDelete(false)}
        confirm={() => deleteSelectedRowsConfirm()}
        content={'You are about to delete selected items. Are you sure?'}
        title={'Delete'}
        actionStatus={actionStatus}
      />
      <DeleteModal2
        show={showDelete}
        handleClose={() => handleClose()}
        confirm={e => handleConfirmDelete(e, rowId)}
        content={`You are about to delete the #${rowId?.id} tour. Are you sure?`}
        title={'Delete'}
        actionStatus={actionStatus}
      />
      <UpdateStatusModal
        show={showStatus}
        handleConfirmUpdate={handleConfirmUpdateStatus}
        handleClose={handleClose}
        text={`You're about to ${
          rowId?.status === 'Active' ? 'de-activate' : 'activate'
        }  the ${rowId?.tour_name} tour. Are you sure?`}
        title={'Update Driver'}
        actionStatus={actionStatus}
      />
      <Pagination
        rowStatus={rowStatus}
        count={count}
        limit={10}
        getList={getToursList}
      />
    </div>
  );
}

export default Tours;
