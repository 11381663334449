export const GET_SETTINGS_REQUEST = 'GET_SETTINGS_REQUEST';
export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS';
export const GET_SETTINGS_FAIL = 'GET_SETTINGS_FAIL';

export function getSettingsAction(cb) {
  return {
    type: GET_SETTINGS_REQUEST,
    payload: {
      cb,
    },
  };
}

export const UPDATE_SETTINGS_REQUEST = 'UPDATE_SETTINGS_REQUEST';
export const UPDATE_SETTINGS_SUCCESS = 'UPDATE_SETTINGS_SUCCESS';
export const UPDATE_SETTINGS_FAIL = 'UPDATE_SETTINGS_FAIL';

export function updateSettingsAction(formData, cb) {
  return {
    type: UPDATE_SETTINGS_REQUEST,
    payload: {
      formData,
      cb,
    },
  };
}

export const GET_THEME_REQUEST = 'GET_THEME_REQUEST';
export const GET_THEME_SUCCESS = 'GET_THEME_SUCCESS';
export const GET_THEME_FAIL = 'GET_THEME_FAIL';

export function getThemeAction(mode, cb) {
  return {
    type: GET_THEME_REQUEST,
    payload: {
      mode,
      cb,
    },
  };
}

export const GET_THEME_LIST_REQUEST = 'GET_THEME_LIST_REQUEST';
export const GET_THEME_LIST_SUCCESS = 'GET_THEME_LIST_SUCCESS';
export const GET_THEME_LIST_FAIL = 'GET_THEME_LIST_FAIL';

export function getThemeListAction(cb) {
  return {
    type: GET_THEME_LIST_REQUEST,
    payload: {
      cb,
    },
  };
}

export const SET_THEME_REQUEST = 'SET_THEME_REQUEST';
export const SET_THEME_SUCCESS = 'SET_THEME_SUCCESS';
export const SET_THEME_FAIL = 'SET_THEME_FAIL';

export function setThemeAction(palette_id, mode, cb) {
  return {
    type: SET_THEME_REQUEST,
    payload: {
      palette_id,
      mode,
      cb,
    },
  };
}

export const SET_THEME_MODE_REQUEST = 'SET_THEME_MODE_REQUEST';
export const SET_THEME_MODE_SUCCESS = 'SET_THEME_MODE_SUCCESS';
export const SET_THEME_MODE_FAIL = 'SET_THEME_MODE_FAIL';

export function updateThemeModeAction(palette_id, mode, cb) {
  return {
    type: SET_THEME_MODE_REQUEST,
    payload: {
      palette_id,
      mode,
      cb,
    },
  };
}

export const TO_DEFAULT_THEME_REQUEST = 'TO_DEFAULT_THEME_REQUEST';
export const TO_DEFAULT_THEME_SUCCESS = 'TO_DEFAULT_THEME_SUCCESS';
export const TO_DEFAULT_THEME_FAIL = 'TO_DEFAULT_THEME_FAIL';

export function toDefaultThemeAction(cb) {
  return {
    type: TO_DEFAULT_THEME_REQUEST,
    payload: {
      cb,
    },
  };
}

export const CHANGE_CARD_VIEW = 'CHANGE_CARD_VIEW';
export const CHANGE_LIST_VIEW = 'CHANGE_LIST_VIEW';

export function changeCardView() {
  return {
    type: CHANGE_CARD_VIEW,
  };
}

export function changeListView() {
  return {
    type: CHANGE_LIST_VIEW,
  };
}
