import React from 'react';
import {GoogleMap, LoadScript, Marker} from '@react-google-maps/api';

function LocationMapTransfer({coordinates}) {

    // const apiKey = 'AIzaSyDDyaA6llK5Ghq1HeHz5o2ik_Mmfd1F_30';
    const apiKey = 'AIzaSyBHw0bPMraOWu_rJY3hwWTbIAZbh0AwJGg';

    const mapContainerStyle = {
        width: '100%',
        height: '500px',
    };
    return (
        <LoadScript googleMapsApiKey={apiKey}>
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={{
                    lat: +coordinates[0].lat,
                    lng: +coordinates[0].lng
                }}
                zoom={18}
            >
                {coordinates?.map((location, index) => (
                    <Marker
                        key={index}
                        position={{lat: +location?.lat, lng: +location?.lng}}
                        label={location.type === "pick_up_address" ? `P-${location.id.toString()}` : location.type === "location" ? `C-${location.id.toString()}` : "P"}
                    />
                ))}
            </GoogleMap>
        </LoadScript>
    );
}

export default LocationMapTransfer;
