export const SET_SEARCH = 'SET_SEARCH';

export function searchAction(search, cb) {
  return {
    type: SET_SEARCH,
    payload: {
      search,
      cb,
    },
  };
}

export const SET_SORT = 'SET_SORT';

export function sortAction(sort, cb) {
  return {
    type: SET_SORT,
    payload: {
      sort,
      cb,
    },
  };
}

export const SET_FILTER = 'SET_FILTER';

export function filterAction(filter, cb) {
  const combinedObject = _.merge(
    {},
    ...filter.map(obj => _.omit(obj, 'filterId')),
  );
  return {
    type: SET_FILTER,
    payload: {
      filter: combinedObject,
      cb,
    },
  };
}

export const DEL_FILTER = 'DEL_FILTER';

export function delFilterAction(key, cb) {
  return {
    type: DEL_FILTER,
    payload: {
      key,
      cb,
    },
  };
}

export const DEL_FILTER_ARR = 'DEL_FILTER_ARR';

export function delFilterArrayAction(id, cb) {
  return {
    type: DEL_FILTER_ARR,
    payload: {
      id,
      cb,
    },
  };
}

export const SET_PAGE = 'SET_PAGE';

export function setPage(page) {
  return {
    type: SET_PAGE,
    payload: {
      page,
    },
  };
}

export const SET_ROW_LIMIT = 'SET_ROW_LIMIT';

export function setRowLimit(limit) {
  return {
    type: SET_ROW_LIMIT,
    payload: {
      limit,
    },
  };
}

export const SET_DEFAULT_CONFIGS = 'SET_DEFAULT_CONFIGS';

export function setDefaultSearchConfigs(configs) {
  return {
    type: SET_DEFAULT_CONFIGS,
    payload: {
      configs,
    },
  };
}

export const CLEAR_SEARCH = 'CLEAR_SEARCH';

export function clearSearchAction() {
  return {
    type: CLEAR_SEARCH,
    payload: {},
  };
}

export const CLEAR_FILTER = 'CLEAR_FILTER';

export function clearFilter() {
  return {
    type: CLEAR_FILTER,
    payload: {},
  };
}

export const SET_DEFAULT_FILTER = 'SET_DEFAULT_FILTER';

export function setDefaultFilter(defFilter) {
  return {
    type: SET_DEFAULT_FILTER,
    payload: { defFilter },
  };
}
export const ADD_SERVICE_DATE_FILTER = 'ADD_SERVICE_DATE_FILTER';

export function addServiceFilter(filterList = []) {
  return {
    type: ADD_SERVICE_DATE_FILTER,
    payload: {
      filterList,
    },
  };
}

export const ADD_FILTER = 'ADD_FILTER';

export function addFilter(filterList = []) {
  return {
    type: ADD_FILTER,
    payload: {
      filterList,
    },
  };
}
