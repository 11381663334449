import {
  UPDATE_SETTINGS_REQUEST,
  UPDATE_SETTINGS_SUCCESS,
  UPDATE_SETTINGS_FAIL,
  GET_SETTINGS_REQUEST,
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS_FAIL,
  GET_THEME_REQUEST,
  GET_THEME_SUCCESS,
  GET_THEME_FAIL,
  GET_THEME_LIST_REQUEST,
  GET_THEME_LIST_SUCCESS,
  GET_THEME_LIST_FAIL,
} from '../actions/settings';

const initialState = {
  settings: {},
  theme: {},
  themeList: [],
  status: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_THEME_REQUEST: {
      return {
        ...state,
        // status: 'pending',
      };
    }
    case GET_THEME_SUCCESS: {
      return {
        ...state,
        theme: action.payload?.data?.result,
        status: 'success',
      };
    }
    case GET_THEME_LIST_REQUEST: {
      return {
        ...state,
        // status: 'pending',
      };
    }
    case GET_THEME_LIST_SUCCESS: {
      return {
        ...state,
        themeList: action.payload?.data?.theme,
        status: 'success',
      };
    }
    case GET_SETTINGS_REQUEST: {
      return {
        ...state,
        // status: 'pending',
      };
    }
    case GET_SETTINGS_SUCCESS: {
      return {
        ...state,
        settings: action.payload?.data?.settings,
        status: 'success',
      };
    }
    case UPDATE_SETTINGS_REQUEST: {
      return {
        ...state,
        status: 'pending',
      };
    }
    case UPDATE_SETTINGS_SUCCESS: {
      return {
        ...state,
        // settings: action.payload?.data?.settings,
        status: 'success',
      };
    }
    default: {
      return state;
    }
  }
}
