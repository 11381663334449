import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, Row } from 'react-bootstrap';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { getAllEmailSetup } from '../../store/actions/emailSetups';
import { LoadingButton } from '@mui/lab';

function DynamicEmailForm({
  handleChange,
  handleSubmit,
  handleClose,
  isValidEmail,
  isValidBCC,
  isValidCC,
  validated,
  formData,
  submit,
  page,
  forAdmin,
  setEmailSetup,
  actionStatus,
}) {
  const dispatch = useDispatch();
  const [emailSetUps, setEmailSetUps] = useState([]);
  useEffect(() => {
    if (forAdmin) {
      dispatch(
        getAllEmailSetup(page, (err, data) => {
          if (data?.status == 200) {
            setEmailSetUps(data?.result);
            fillEmailSetupForm(
              data?.result.find(i => i?.email_setup_name == 'Voucher Supplier'),
            );
          }
          if (err) {
            console.log(err);
          }
        }),
      );
    }
  }, []);

  const fillEmailSetupForm = ev => {
    if (ev) {
      setEmailSetup({
        id: ev.id,
        email_body: ev.email_body,
        bcc: ev.email_bcc || '',
        cc: ev.email_cc || '',
        email_setup_name: ev.email_setup_name,
        subject: ev.email_subject,
        email: ev.email_to_send || '',
      });
    } else {
      setEmailSetup({});
    }
  };

  const customStylesGreen = {
    control: styles => ({
      ...styles,
      borderColor: !!validated ? '#198754' : '#ced4da',
    }),
    option: styles => {
      return {
        ...styles,
        ':active': {
          ...styles[':active'],
          backgroundColor: `var(--primary_light)`,
        },
      };
    },
  };
  const customStyleGray = {
    control: styles => ({
      ...styles,
      borderColor: !!validated ? '' : '#ced4da',
    }),
    option: styles => {
      return {
        ...styles,
        ':active': {
          ...styles[':active'],
          backgroundColor: `var(--primary_light)`,
        },
      };
    },
  };
  const customRequiredStyle = {
    control: styles => ({
      ...styles,
      borderColor: !!validated ? '#dc3545' : '#ced4da',
    }),
    option: styles => {
      return {
        ...styles,
        ':active': {
          ...styles[':active'],
          backgroundColor: `var(--primary_light)`,
        },
      };
    },
  };
  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Modal.Header
          className="modal_header"
          closeButton
          style={
            forAdmin
              ? { padding: '15px' }
              : { background: '#f88f35', padding: '15px' }
          }
        >
          <Modal.Title className="modal_title">
            {' '}
            {forAdmin ? 'Send Email' : 'Send Via Email'}
          </Modal.Title>
        </Modal.Header>
        <div className="form-block">
          <Modal.Body>
            <div className="modal_content">
              <div className="email-modal-form">
                {forAdmin ? (
                  <Row className="mb-3">
                    <Form.Group>
                      <Form.Label>Email Template</Form.Label>
                      <Select
                        id={'email_body'}
                        value={
                          Array.isArray(emailSetUps)
                            ? emailSetUps.find(i => i?.id === formData?.id)
                            : {}
                        }
                        isClearable={true}
                        styles={
                          ({
                            option: (styles, { isSelected }) => {
                              return {
                                ...styles,
                                ':active': {
                                  ...styles[':active'],
                                  backgroundColor: null,
                                },
                              };
                            },
                          },
                          customStylesGreen)
                        }
                        name="email_body"
                        options={emailSetUps}
                        getOptionValue={i => i.email_body}
                        getOptionLabel={i => i.email_setup_name}
                        onChange={ev => fillEmailSetupForm(ev)}
                      />
                    </Form.Group>
                  </Row>
                ) : null}
                <Row>
                  <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <span style={{ color: 'red' }}> *</span>
                    <Form.Control
                      // type="email"
                      required
                      name="email"
                      value={formData.email || ''}
                      style={
                        formData.email && !isValidEmail && submit
                          ? { borderColor: '#dc3545' }
                          : { borderColor: '' }
                      }
                      onChange={ev => handleChange('email', ev.target.value)}
                      isInValid={!isValidEmail}
                    />
                    {formData.email && !isValidEmail && submit ? (
                      <span className="invalid_message">Invalid Format</span>
                    ) : null}

                    {!formData.email && submit ? (
                      <span className="invalid_message">Required Field</span>
                    ) : null}
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group>
                    <Form.Label>Subject</Form.Label>
                    <Form.Control
                      type="text"
                      value={formData.subject || ''}
                      onChange={event =>
                        handleChange('subject', event.target.value)
                      }
                      name="subject"
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group>
                    <Form.Label>CC</Form.Label>
                    <Form.Control
                      name="cc"
                      // type="email"
                      isInValid={!isValidCC}
                      value={formData.cc || ''}
                      onChange={event => handleChange('cc', event.target.value)}
                      style={
                        formData.cc && !isValidCC && submit
                          ? { borderColor: '#dc3545' }
                          : { borderColor: '' }
                      }
                    />
                    {formData.cc && !isValidCC && submit ? (
                      <span className="invalid_message">Invalid Format</span>
                    ) : null}
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group>
                    <Form.Label>BCC</Form.Label>
                    <Form.Control
                      name="bcc"
                      // type="email"
                      isInValid={!isValidBCC}
                      value={formData.bcc || ''}
                      onChange={event =>
                        handleChange('bcc', event.target.value)
                      }
                      style={
                        formData.bcc && !isValidBCC && submit
                          ? { borderColor: '#dc3545' }
                          : { borderColor: '' }
                      }
                    />
                    {formData.bcc && !isValidBCC && submit ? (
                      <span className="invalid_message">Invalid Format</span>
                    ) : null}
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Body <span style={{ color: 'red' }}> *</span>
                    </Form.Label>
                  </Form.Group>
                  <Form.Group>
                    <Form.Control
                      as="textarea"
                      rows={5}
                      className="text_area_input"
                      required
                      value={formData.email_body || ''}
                      onChange={event =>
                        handleChange('email_body', event.target.value)
                      }
                      name="email_body"
                    />
                    {!formData.email_body && submit ? (
                      <Form.Control.Feedback type="invalid">
                        Required Field
                      </Form.Control.Feedback>
                    ) : null}
                  </Form.Group>
                </Row>
              </div>
            </div>
          </Modal.Body>
        </div>
        <Modal.Footer>
          <div className={forAdmin ? 'previewButtons' : 'modalButtons'}>
            <LoadingButton
              size="small"
              className="login_submit_btn btn btn-primary "
              loadingPosition="center"
              variant="contained"
              loading={actionStatus === 'pending'}
              type={'submit'}
            >
              <span>
                {actionStatus === 'pending'
                  ? ''
                  : forAdmin
                  ? 'Confirm'
                  : 'Submit'}
              </span>
            </LoadingButton>
            <Button
              variant="secondary"
              style={{ marginLeft: '15px' }}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </div>
        </Modal.Footer>
      </Form>
    </>
  );
}

export default DynamicEmailForm;
