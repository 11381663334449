import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Form, InputGroup } from 'react-bootstrap';
import { FaLock, FaUnlock, FaUnlockAlt, FaUserAlt } from 'react-icons/fa';

import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { loginRequest } from '../store/actions/users';

function Login() {
  const dispatch = useDispatch();
  const [lock, setLock] = useState(true);
  const [formData, setFormData] = useState({});
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [submit, setSubmit] = useState(false);
  const navigate = useNavigate();
  const handleChange = (key, val) => {
    setFormData({ ...formData, [key]: val });
  };

  window?.history.pushState(null, '', window?.location?.href);
  window?.addEventListener('popstate', function (event) {
    window?.history?.pushState(null, document?.title, window?.location.href);
  });

  useEffect(() => {
    window.onpopstate = () => {
      window.history.pushState(null, '', window.location.href);
    };
  }, []);

  const handleLock = () => {
    setLock(!lock);
  };

  const handleSubmit = event => {
    event?.preventDefault();
    const form = event?.currentTarget;
    setSubmit(true);
    if (form?.checkValidity() === false) {
      event?.preventDefault();
      event?.stopPropagation();
      setValidated(true);
    } else {
      setIsLoading(true);
      dispatch(
        loginRequest(formData, (err, data) => {
          if (err) {
            toast.error(err, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 10000,
            });
          }
          setIsLoading(false);
        }),
      );
    }
  };

  return (
    <div className="login-page parent clearfix">
      <div className="login_page_overlay">
        <div className="login">
          <div className="container">
            <h3 className="login-page_title">TravelPath</h3>
            <h4 className="login-page_title">Login</h4>
            <div className="login-form">
              <Form
                onSubmit={event => handleSubmit(event)}
                noValidate
                validated={validated}
              >
                <InputGroup className="mb-3">
                  <InputGroup.Text
                    className="login_page_input_group"
                    id="basic-addon1"
                  >
                    <FaUserAlt />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    value={formData?.user_name || ''}
                    placeholder="Username *"
                    onChange={e => handleChange('user_name', e.target?.value)}
                    required={true}
                    id="user_name"
                    name="user_name"
                  />
                  <Form.Control.Feedback type="invalid">
                    Required Field
                  </Form.Control.Feedback>
                </InputGroup>
                <InputGroup className="mb-3 input_block login_page_input_group">
                  <InputGroup.Text id="basic-addon1" onClick={handleLock}>
                    {lock ? <FaLock /> : <FaUnlock />}
                  </InputGroup.Text>
                  <Form.Control
                    type={lock ? 'password' : 'text'}
                    className="input"
                    id="password"
                    placeholder="Password *"
                    autoComplete="new-password"
                    value={formData?.password || ''}
                    required={true}
                    onChange={ev => handleChange('password', ev.target?.value)}
                  />
                </InputGroup>

                <div className="login-button-div">
                  <LoadingButton
                    size="big"
                    // color="primary"
                    className="login_submit_btn btn btn-primary login_button"
                    loading={isLoading}
                    loadingPosition="start"
                    variant="contained"
                    type="submit"
                  >
                    <span>Login</span>
                  </LoadingButton>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
