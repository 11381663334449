import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { emailValidateForSend } from '../../services/emailValidate';
import DynamicEmailForm from '../../pages/generatePdf/DynamicEmailForm';

function EmailModal({ show, handleClose, title, confirm, page, actionStatus }) {
  const [formData, setFormData] = useState({});
  const [submit, setSubmit] = useState(false);
  const [validated, setValidated] = useState(false);
  const [emailSetUps, setEmailSetUps] = useState([]);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isValidCC, setIsValidCC] = useState(false);
  const [isValidBCC, setIsValidBCC] = useState(false);

  const close = () => {
    handleClose();
    setFormData({});
    setEmailSetUps([]);
    setSubmit(false);
    setValidated(false);
  };

  const setEmailSetup = setup => {
    for (const key in setup) {
      if (['email', 'cc', 'bcc'].includes(key)) {
        emailValidateForSend(
          key,
          setup[key],
          setIsValidEmail,
          setIsValidBCC,
          setIsValidCC,
        );
      }
    }
    setFormData(setup);
  };
  const handleChange = (key, value) => {
    if (['email', 'cc', 'bcc'].includes(key)) {
      emailValidateForSend(
        key,
        value,
        setIsValidEmail,
        setIsValidBCC,
        setIsValidCC,
      );
      if (isValidEmail) {
        setFormData({ ...formData, email: value });
      }
      if (isValidBCC) {
        setFormData({ ...formData, bcc: value });
      }
      if (isValidCC) {
        setFormData({ ...formData, cc: value });
      }
    }
    setFormData({ ...formData, [key]: value });
  };

  const handleSubmit = event => {
    event.preventDefault();
    event.stopPropagation();
    setSubmit(true);
    const form = event.currentTarget;
    if (!isFormValid()) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      confirm(formData);
      setFormData({});
      setSubmit(false);
      setValidated(false);
    }
  };
  const isFormValid = () => {
    if (
      !formData.email ||
      (formData.email && !isValidEmail) ||
      !formData.email_body ||
      (formData.cc && !isValidCC) ||
      (formData.bcc && !isValidBCC)
    ) {
      return false;
    }
    return true;
  };
  return (
    <Modal
      size="lg"
      show={show}
      onHide={close}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <DynamicEmailForm
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        handleClose={close}
        submit={submit}
        validated={validated}
        formData={formData}
        isValidEmail={isValidEmail}
        isValidCC={isValidCC}
        isValidBCC={isValidBCC}
        page={page === 'Voucher' ? 'Tour' : page}
        forAdmin={true}
        setEmailSetup={setEmailSetup}
        actionStatus={actionStatus}
      />
    </Modal>
  );
}

export default EmailModal;
