import React, { useEffect, useState, useRef } from 'react';
import './pickUpPoints.scss';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { MdOutlineClear } from 'react-icons/md';
import {
  addPickUpPointAction,
  getPickUpPointsAction,
  updatePickUpPoint,
} from '../../store/actions/pickUpPoints';
import { LoadingButton } from '@mui/lab';
import { blockPageAction } from '../../store/actions/users';

const { REACT_APP_API_URL } = process.env;

function PickUpPointsForm({ popupTitle, handleClose, singlePickUpPoint }) {
  const searchConfig = useSelector(store => store.search);
  const blockedPage = useSelector(store => store.search.blockedPage);
  const actionStatus = useSelector(store => store.pickUpPoints.actionStatus);
  const [formValidation, setFormValidation] = useState({});
  const [selectedPhotos, setSelectedPhotos] = useState({});
  const [formData, setFormData] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [validated, setValidated] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (!isEmpty(singlePickUpPoint)) {
      setFormData(singlePickUpPoint);
    }
  }, [singlePickUpPoint]);

  useEffect(() => {
    if (
      id &&
      typeof formData === typeof singlePickUpPoint &&
      !isEmpty(singlePickUpPoint)
    ) {
      if (
        JSON.stringify(formData) !== JSON.stringify(singlePickUpPoint) &&
        !blockedPage
      ) {
        dispatch(blockPageAction('/user-management/users/41'));
      }
      if (
        JSON.stringify(formData) === JSON.stringify(singlePickUpPoint) &&
        blockedPage
      ) {
        dispatch(blockPageAction(''));
      }
    }
  }, [formData]);

  const handleChange = (key, val) => {
    setFormData({ ...formData, [key]: val });
    setFormValidation({ ...formValidation, [key]: val });
  };
  const handleEdit = params => {
    setFormData(singlePickUpPoint);
    setFormValidation(!!formValidation);
    setSelectedPhotos({});
    // dispatch(getsinglePickUpPoint(params));
  };
  const handleClick = () => {
    fileInputRef.current.click();
  };
  const handleRemoveImg = key => {
    setFormData({ ...formData, [key]: null, [`${key}_file`]: null });
    setSelectedPhotos({ ...selectedPhotos, [key]: null });
  };
  const handlePhotoChange = (key, val) => {
    if (val) {
      setFormData({ ...formData, [key]: val.name, [`${key}_file`]: val });
      setSelectedPhotos({ ...selectedPhotos, [key]: URL.createObjectURL(val) });
    }
  };

  const onSubmit = event => {
    event.preventDefault();
    setSubmitted(true);
    const form = event.currentTarget;
    if (form.checkValidity() === false || !formData.pick_up_photo) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      setDisabled(true);
      dispatch(
        addPickUpPointAction(formData, (err, data) => {
          if (data) {
            toast.success('Successfully Created', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });
            setValidated(false);
            dispatch(getPickUpPointsAction(searchConfig));
            setDisabled(false);
            handleClose();
            form.reset();
          } else {
            toast.error(err.response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });
          }
          setDisabled(false);
        }),
      );
    }
  };

  const onEdit = ev => {
    ev.preventDefault();
    setSubmitted(true);
    const form = ev.currentTarget;
    if (form.checkValidity() === false) {
      ev.preventDefault();
      ev.stopPropagation();
      setValidated(true);
    } else {
      setDisabled(true);
      dispatch(
        updatePickUpPoint(formData, (err, data) => {
          if (err) {
            toast.error(err.response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });
            setValidated(false);
          }
          if (data) {
            toast.success('Successfully Updated', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 10000,
            });
            setDisabled(false);
            form.reset();
            navigate('/admin/pick_up_points');
          }
        }),
      );
      setTimeout(() => {
        setDisabled(false);
      }, 1500);
    }
  };
  return (
    <>
      <Form
        onSubmit={event => {
          if (id) {
            onEdit(event);
          } else {
            onSubmit(event);
          }
        }}
        noValidate
        validated={validated}
      >
        <Modal.Header closeButton={!id}>
          <Modal.Title>{!id && popupTitle + ' ' + 'Pick-Up Point'}</Modal.Title>
        </Modal.Header>
        <div className="form-block">
          <Modal.Body>
            <Row>
              <Col sm={4}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Pick-Up Name
                    <span style={{ color: 'red' }}> *</span>
                  </Form.Label>
                  <Form.Control
                    id="pick_up_point_name"
                    name="pick_up_name"
                    type="text"
                    value={formData.pick_up_name || ''}
                    onChange={ev =>
                      handleChange('pick_up_name', ev.target.value)
                    }
                    required={true}
                    maxLength={254}
                  />
                  {!formData.pick_up_name ? (
                    <Form.Control.Feedback type="invalid">
                      Required Field
                    </Form.Control.Feedback>
                  ) : null}
                </Form.Group>
              </Col>
              <Col sm={4}>
                <Form.Label>
                  Address <span style={{ color: 'red' }}>*</span>
                </Form.Label>
                <Form.Control
                  id="pick_up_point_address"
                  name="pick_up_address"
                  type="text"
                  required={true}
                  value={formData?.pick_up_address || ''}
                  onChange={ev =>
                    handleChange('pick_up_address', ev.target.value)
                  }
                  maxLength={254}
                />
                {!formData?.pick_up_address ? (
                  <Form.Control.Feedback type="invalid">
                    Required Field
                  </Form.Control.Feedback>
                ) : null}
                {validated && formData.pick_up_address ? (
                  <Form.Control.Feedback type="invalid">
                    Invalid Format
                  </Form.Control.Feedback>
                ) : null}
              </Col>
              <Col sm={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Embed a Map</Form.Label>
                  <Form.Control
                    id="pick_up_point_map_embed"
                    name="map_embed"
                    type="text"
                    value={formData.map_embed || ''}
                    onChange={ev => handleChange('map_embed', ev.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label style={{ marginBottom: 0 }}>
                    Pick-Up Photo
                    <span style={{ color: 'red' }}> *</span>
                  </Form.Label>
                  <div className="file-input file-upload">
                    <label htmlFor="pick_up_photo">
                      <input
                        className="choose-btn"
                        type="button"
                        value="Choose"
                        onClick={handleClick}
                      />
                    </label>
                    <input
                      className="border-none"
                      type="text"
                      value={formData?.pick_up_photo || ''}
                      readOnly
                      style={
                        submitted && !formData.pick_up_photo
                          ? {
                              border: '1px',
                              borderStyle: 'solid',
                              borderColor: '#dc3545',
                            }
                          : {
                              border: '1px',
                              borderStyle: 'solid',
                              borderColor: '#ccc',
                            }
                      }
                    />
                    <input
                      type="file"
                      id="pick_up_photo"
                      name="logo"
                      accept="image/*"
                      onChange={e =>
                        handlePhotoChange('pick_up_photo', e.target.files[0])
                      }
                      ref={fileInputRef}
                    />
                    {formData?.pick_up_photo ? (
                      <MdOutlineClear
                        id="remove_pick_up_photo"
                        className="remove_img"
                        onClick={() => handleRemoveImg('pick_up_photo')}
                      />
                    ) : null}
                  </div>
                  {submitted && !formData.pick_up_photo && (
                    <span
                      style={{
                        color: '#dc3545',
                        fontSize: '0.875em',
                        marginTop: '0.25rem',
                      }}
                    >
                      Required Field
                    </span>
                  )}
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label style={{ marginBottom: 0 }}>Map Photo</Form.Label>
                  <div className="file-input file-upload">
                    <label htmlFor="map_photo">
                      <input
                        className="choose-btn"
                        type="button"
                        value="Choose"
                        onClick={handleClick}
                      />
                    </label>
                    <input
                      className="border-none"
                      type="text"
                      value={formData?.map_photo || ''}
                      readOnly
                      style={
                        submitted && !formData.ad_photo
                          ? {
                              border: '1px',
                              borderStyle: 'solid',
                              borderColor: '#198754',
                            }
                          : {
                              border: '1px',
                              borderStyle: 'solid',
                              borderColor: '#ccc',
                            }
                      }
                    />
                    <input
                      type="file"
                      id="map_photo"
                      name="logo"
                      accept="image/*"
                      onChange={e =>
                        handlePhotoChange('map_photo', e.target.files[0])
                      }
                      ref={fileInputRef}
                    />
                    {formData?.map_photo ? (
                      <MdOutlineClear
                        id="remove_map_photo"
                        className="remove_img"
                        onClick={() => handleRemoveImg('map_photo')}
                      />
                    ) : null}
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                {selectedPhotos.pick_up_photo && (
                  <div className="pick-up-img-container">
                    <img src={selectedPhotos.pick_up_photo} alt="" />
                  </div>
                )}
                {formData.id &&
                  formData.pick_up_photo &&
                  !selectedPhotos.pick_up_photo && (
                    <div className="pick-up-img-container">
                      <img
                        src={`${REACT_APP_API_URL}/pick_up_points/${formData.pick_up_photo}`}
                        alt="pick_up_photo"
                      />
                    </div>
                  )}
              </Col>
              <Col sm={6}>
                {selectedPhotos.map_photo && (
                  <div className="pick-up-img-container">
                    <img src={selectedPhotos.map_photo} alt="map_photo" />
                  </div>
                )}
                {formData.id &&
                  formData.map_photo &&
                  !selectedPhotos.map_photo && (
                    <div className="pick-up-img-container">
                      <img
                        src={`${REACT_APP_API_URL}/pick_up_points/${formData.map_photo}`}
                        alt="map_photo"
                      />
                    </div>
                  )}
              </Col>
            </Row>
          </Modal.Body>
        </div>
        <Modal.Footer>
          <div className={id ? 'previewButtonsID' : 'previewButtons'}>
            <LoadingButton
              size="small"
              className="login_submit_btn btn btn-primary "
              loadingPosition="center"
              variant="contained"
              loading={actionStatus === 'pending'}
              disabled={disabled}
              type="submit"
            >
              <span>{actionStatus === 'pending' ? '' : 'Save'}</span>
            </LoadingButton>
            {id ? (
              <Button variant="secondary" onClick={() => handleEdit(id)}>
                Reset
              </Button>
            ) : (
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Form>
    </>
  );
}

export default PickUpPointsForm;
