import React, { useEffect, useState, useRef } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import {
  blockPageAction,
  getAllDriversAction,
} from '../../store/actions/users';
import Select from 'react-select';
import {
  addTourTransferAction,
  getSingleTourTransfer,
  getToursTransfersAction,
  updateTourTransfer,
} from '../../store/actions/toursTransfers';
import { getAllPickUpPointsAction } from '../../store/actions/pickUpPoints';
import { getAllTransferTypesAction } from '../../store/actions/transferTypes';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Stack from '@mui/material/Stack';
import { createTheme, TextField, ThemeProvider } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import { getAllTermsConditionsAction } from '../../store/actions/termsConditions';
import { LoadingButton } from '@mui/lab';

function TourTransfersForms({
  popupTitle,
  handleClose,
  singleTourTransfer,
  actionStatus,
}) {
  const searchConfig = useSelector(store => store.search);
  const allPickUpPoints = useSelector(
    store => store.pickUpPoints.allPickUpPoints,
  );
  const drivers = useSelector(store => store.users?.allDriverUsers);
  const allTransferTypes = useSelector(
    store => store.transferTypes.allTransferTypes,
  );
  const allTermsConditions = useSelector(
    store => store.termsConditions.allTermsConditions,
  );
  const blockedPage = useSelector(store => store.search.blockedPage);
  const [formValidation, setFormValidation] = useState({});
  const [formData, setFormData] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [validated, setValidated] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllDriversAction());
    dispatch(getAllPickUpPointsAction());
    dispatch(getAllTransferTypesAction());
    dispatch(getAllTermsConditionsAction());
  }, []);

  useEffect(() => {
    if (!isEmpty(singleTourTransfer)) {
      setFormData({
        ...singleTourTransfer,
        driver_user_id: singleTourTransfer?.driverUserId
          ? singleTourTransfer?.driverUserId?.id
          : null,
      });
    }
  }, [singleTourTransfer]);

  useEffect(() => {
    if (
      id &&
      typeof formData === typeof singleTourTransfer &&
      !isEmpty(singleTourTransfer)
    ) {
      if (
        JSON.stringify(formData) !== JSON.stringify(singleTourTransfer) &&
        !blockedPage
      ) {
        dispatch(blockPageAction('/user-management/users/41'));
      }
      if (
        JSON.stringify(formData) === JSON.stringify(singleTourTransfer) &&
        singleTourTransfer
      ) {
        dispatch(blockPageAction(''));
      }
    }
  }, [formData]);

  const handleChange = (key, val) => {
    setFormData(prevFormData => ({ ...prevFormData, [key]: val }));
    setFormValidation({ ...formValidation, [key]: val });
  };

  const handleEdit = params => {
    setFormValidation(!!formValidation);
    dispatch(
      getSingleTourTransfer(params, (err, data) => {
        if (data && data.result) {
          setFormData({ ...data.result });
        }
      }),
    );
  };
  const handleTransferTypeChange = ev => {
    const Transferupdates = ev
      ? { transfer_type_id: ev.id, transfer_type: ev.type }
      : { transfer_type_id: null, transfer_type: null };
    Object.keys(Transferupdates).forEach(key => handleChange(key, Transferupdates[key]));
  };

  const handleTermsChange = ev => {
    const Termsupdates = ev
      ? { term_condition_id: ev.id, including_service_info: ev.description }
      : { term_condition_id: null, including_service_info: null };

    Object.keys(Termsupdates).forEach(key => handleChange(key, Termsupdates[key]));
  };
  const onSubmit = event => {
    event.preventDefault();
    setSubmitted(true);
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      setDisabled(true);
      dispatch(
        addTourTransferAction(formData, (err, data) => {
          if (data && data.status === 200) {
            toast.success('Successfully Created', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });
            setValidated(false);
            setFormData({});
            dispatch(getToursTransfersAction(searchConfig));
            setDisabled(false);
            handleClose();
            form.reset();
          } else if (data && data.status === 400) {
            toast.error(data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });
          }
        }),
      );
      setTimeout(() => {
        setDisabled(false);
      }, 1500);
    }
  };

  const onEdit = ev => {
    ev.preventDefault();
    setSubmitted(true);
    const form = ev.currentTarget;
    if (form.checkValidity() === false) {
      ev.preventDefault();
      ev.stopPropagation();
      setValidated(true);
    } else {
      setDisabled(true);
      setValidated(true);
      dispatch(
        updateTourTransfer(formData, (err, data) => {
          if (err) {
            toast.error(err, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });
            setValidated(false);
          } else {
            if (data && data.status === 200) {
              toast.success('Successfully Updated', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 10000,
              });
              setDisabled(false);
              form.reset();
              navigate('/admin/transfers');
            }
          }
        }),
      );
      setTimeout(() => {
        setDisabled(false);
      }, 1500);
    }
  };

  const customStylesGreen = {
    control: styles => ({
      ...styles,
      borderColor: !!validated ? '#198754' : '#ced4da',
    }),
    option: styles => {
      return {
        ...styles,
        ':active': {
          ...styles[':active'],
          backgroundColor: `var(--primary_light)`,
        },
      };
    },
  };
  const customStyleGray = {
    control: styles => ({
      ...styles,
      borderColor: !!validated ? '' : '#ced4da',
    }),
    option: styles => {
      return {
        ...styles,
        ':active': {
          ...styles[':active'],
          backgroundColor: `var(--primary_light)`,
        },
      };
    },
  };

  const requiredTheme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: !!validated ? '#198754' : '#ced4da',
          },
        },
      },
    },
  });

  const theme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: !!validated ? '#198754' : '#ced4da',
          },
        },
      },
    },
  });

  const handleRadioChange = ev => {
    setFormData({
      ...formData,
      including_service_type: ev.target.value,
    });
  };

  return (
    <>
      <Form
        onSubmit={event => {
          if (id) {
            onEdit(event);
          } else {
            onSubmit(event);
          }
        }}
        noValidate
        validated={validated}
      >
        <Modal.Header closeButton={!id}>
          <Modal.Title>{!id && popupTitle + ' ' + 'Transfer'}</Modal.Title>
        </Modal.Header>
        <div className="form-block">
          <Modal.Body>
            <Row>
              <Col sm={6} md={4}>
                <Form.Group>
                  <Form.Label>
                    Transfer Name
                    <span style={{ color: 'red' }}> *</span>
                  </Form.Label>
                  <Form.Control
                    id="transfer_name"
                    name="transfer_name"
                    maxLength={254}
                    value={formData?.transfer_name || ''}
                    required={true}
                    onChange={e =>
                      handleChange('transfer_name', e.target.value)
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    Required Field
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={6} md={4}>
                <Form.Group className={!submitted ? 'mb-3' : null}>
                  <Form.Label>Duration (h)</Form.Label>
                  <Form.Control
                    id="transfer_duration"
                    name="duration"
                    type="number"
                    value={formData.duration || ''}
                    onChange={event =>
                      handleChange('duration', event.target.value)
                    }
                    maxLength={254}
                  />
                </Form.Group>
              </Col>
              <Col sm={12} md={4}>
                <Form.Group>
                  <Form.Label>Emergency Number</Form.Label>
                  <PhoneInput
                    value={formData.emergency_number || ''}
                    onChange={val => handleChange('emergency_number', val)}
                    country={'gr'}
                    buttonStyle={submitted ? { borderColor: '#198754' } : null}
                    inputProps={{
                      name: 'emergency_number',
                      id: 'transfer_emergency_number',
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6} md={4}>
                <Form.Label>Service Date</Form.Label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={3} id={'transfer_service_date'}>
                    <ThemeProvider
                      theme={
                        !formData['service_date'] && submitted
                          ? requiredTheme
                          : theme
                      }
                    >
                      <DesktopDatePicker
                        name={'service_date'}
                        className="month_year_picker"
                        views={['year', 'month', 'day']}
                        value={
                          formData?.service_date &&
                          dayjs(formData?.service_date)
                        }
                        onChange={newValue => {
                          if (newValue) {
                            handleChange(
                              'service_date',
                              dayjs(newValue).format('YYYY-MM-DD'),
                            );
                          } else {
                            handleChange('service_date', null);
                          }
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            disabled={formData.send === 'true'}
                          />
                        )}
                      />
                    </ThemeProvider>
                  </Stack>
                </LocalizationProvider>
              </Col>
              <Col sm={6} md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Time Start</Form.Label>
                  <Form.Control
                    id={'transfer_start_time'}
                    name="start_time"
                    type="time"
                    value={formData.start_time || ''}
                    onChange={event =>
                      handleChange('start_time', event.target.value)
                    }
                  />
                </Form.Group>
              </Col>
              <Col sm={12} md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Time Finish</Form.Label>
                  <Form.Control
                    id={'transfer_end_time'}
                    name="end_time"
                    type="time"
                    value={formData.end_time || ''}
                    onChange={event =>
                      handleChange('end_time', event.target.value)
                    }
                    min={formData.start_time}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formData.end_time && 'Invalid time'}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>From</Form.Label>
                  <Form.Control
                    id="transer_from_location"
                    name="from_location"
                    value={formData.from_location || ''}
                    onChange={event =>
                      handleChange('from_location', event.target.value)
                    }
                    maxLength={254}
                  />
                </Form.Group>
              </Col>
              <Col sm={12} md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>To</Form.Label>
                  <Form.Control
                    id="transfer_to_location"
                    name="to_location"
                    type="text"
                    value={formData.to_location || ''}
                    onChange={event =>
                      handleChange('to_location', event.target.value)
                    }
                    maxLength={254}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={6} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Transfer Type</Form.Label>
                  <Select
                    id={'transfer_type_id'}
                    name={'transfer_type'}
                    value={
                      formData?.transfer_type_id &&
                      Array.isArray(allTransferTypes)
                        ? allTransferTypes.find(
                            i => i.id === formData.transfer_type_id,
                          )
                        : {}
                    }
                    styles={
                      ({
                        option: (styles, { isSelected }) => {
                          return {
                            ...styles,
                            ':active': {
                              ...styles[':active'],
                              backgroundColor: null,
                            },
                          };
                        },
                      },
                      !submitted && !formData.transfer_type_id
                        ? customStyleGray
                        : customStylesGreen)
                    }
                    options={
                      Array.isArray(allTransferTypes) ? allTransferTypes : []
                    }
                    isClearable={true}
                    getOptionLabel={i => i.type}
                    getOptionValue={i => i.id}
                    onChange={handleTransferTypeChange}
                  />
                </Form.Group>
              </Col>
              <Col sm={12} md={6} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Terms and Conditions</Form.Label>
                  <Select
                    id={'transfer_term_condition_id'}
                    name={'term_condition'}
                    value={
                      formData?.term_condition_id &&
                      Array.isArray(allTermsConditions)
                        ? allTermsConditions.find(
                            i => i.id === formData.term_condition_id,
                          )
                        : {}
                    }
                    styles={
                      ({
                        option: (styles, { isSelected }) => {
                          return {
                            ...styles,
                            ':active': {
                              ...styles[':active'],
                              backgroundColor: null,
                            },
                          };
                        },
                      },
                      !submitted && !formData.term_condition_id
                        ? customStyleGray
                        : customStylesGreen)
                    }
                    options={
                      Array.isArray(allTermsConditions)
                        ? allTermsConditions
                        : []
                    }
                    isClearable={true}
                    getOptionLabel={i => i.name}
                    getOptionValue={i => i.id}
                    onChange={handleTermsChange}
                  />
                </Form.Group>
              </Col>
              <Col sm={12} md={6} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Driver</Form.Label>
                  <Select
                    id={'transfer_driver_user_id'}
                    name={'driver_user'}
                    value={
                      formData?.driver_user_id && Array.isArray(drivers)
                        ? drivers.find(i => i.id === formData.driver_user_id)
                        : {}
                    }
                    styles={
                      ({
                        option: (styles, { isSelected }) => {
                          return {
                            ...styles,
                            ':active': {
                              ...styles[':active'],
                              backgroundColor: null,
                            },
                          };
                        },
                      },
                      !submitted && !formData.driver_user_id
                        ? customStyleGray
                        : customStylesGreen)
                    }
                    options={Array.isArray(drivers) ? drivers : []}
                    isClearable={true}
                    getOptionLabel={i => i.name}
                    getOptionValue={i => i.id}
                    onChange={ev => {
                      if (ev) {
                        handleChange('driver_user_id', ev.id);
                      } else {
                        handleChange('driver_user_id', null);
                      }
                    }}
                  />
                </Form.Group>
              </Col>
              <Col sm={12} md={6} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Starting point</Form.Label>
                  <Select
                    id={'transfer_pick_up_point_id'}
                    name={'pick_up_point'}
                    value={
                      formData?.pick_up_point_id &&
                      Array.isArray(allPickUpPoints)
                        ? allPickUpPoints.find(
                            i => i.id === formData.pick_up_point_id,
                          )
                        : {}
                    }
                    options={
                      Array.isArray(allPickUpPoints) ? allPickUpPoints : []
                    }
                    styles={
                      ({
                        option: (styles, { isSelected }) => {
                          return {
                            ...styles,
                            ':active': {
                              ...styles[':active'],
                              backgroundColor: null,
                            },
                          };
                        },
                      },
                      !submitted && !formData.pick_up_point_id
                        ? customStyleGray
                        : customStylesGreen)
                    }
                    isClearable={true}
                    getOptionLabel={i => i.pick_up_name}
                    getOptionValue={i => i.id}
                    onChange={ev => {
                      if (ev) {
                        handleChange('pick_up_point_id', ev.id);
                      } else {
                        handleChange('pick_up_point_id', null);
                      }
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Included services</Form.Label>
                  <Form.Control
                    id={'transfer_including_service_info'}
                    name={'including_service_info'}
                    as="textarea"
                    rows={3}
                    value={formData?.including_service_info || ''}
                    onChange={ev =>
                      handleChange('including_service_info', ev.target.value)
                    }
                    maxLength={4000}
                  />
                  <div style={{ marginTop: '5px' }}>
                    <Form.Group className="d-flex align-items-center">
                      <Form.Check
                        type="radio"
                        label="Arrival"
                        value="Arrival"
                        name="formHorizontalRadios"
                        id="formHorizontalRadios1"
                        checked={formData?.including_service_type === 'Arrival'}
                        // checked={!formData.including_service_type}
                        style={{ marginRight: '20px' }}
                        onChange={handleRadioChange}
                      />
                      <Form.Check
                        type="radio"
                        label="Departure"
                        value="Departure"
                        name="formHorizontalRadios"
                        id="formHorizontalRadios2"
                        checked={
                          formData?.including_service_type === 'Departure'
                        }
                        onChange={handleRadioChange}
                      />
                      <Form.Check.Label
                        htmlFor="show_on_pdf"
                        className="p-0 px-1"
                        style={{ cursor: 'pointer' }}
                      >
                        {/*Type*/}
                      </Form.Check.Label>
                    </Form.Group>
                  </div>
                </Form.Group>
              </Col>
              <Col sm={12} md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Notes</Form.Label>
                  <Form.Control
                    id="transfer_notes"
                    name="notes"
                    as="textarea"
                    rows={3}
                    value={formData?.notes || ''}
                    onChange={ev => handleChange('notes', ev.target.value)}
                    maxLength={4000}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Driver Location Url</Form.Label>
                  <Form.Control
                    id="transfer_driver_location_url"
                    name="driver_location_url"
                    value={formData?.driver_location_url || ''}
                    pattern="https?://.+"
                    onChange={e =>
                      handleChange('driver_location_url', e.target.value)
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formData.driver_location_url && 'Invalid format'}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
        </div>
        <Modal.Footer>
          <div className={id ? 'previewButtonsID' : 'previewButtons'}>
            <LoadingButton
              size="small"
              className="login_submit_btn btn btn-primary "
              loadingPosition="center"
              variant="contained"
              loading={actionStatus === 'pending'}
              type={'submit'}
            >
              <span>{actionStatus === 'pending' ? '' : 'Save'}</span>
            </LoadingButton>
            {id ? (
              <Button variant="secondary" onClick={() => handleEdit(id)}>
                Reset
              </Button>
            ) : (
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Form>
    </>
  );
}

export default TourTransfersForms;
