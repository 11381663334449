export const GET_ADVERTISEMENTS_REQUEST = 'GET_ADVERTISEMENTS_REQUEST';
export const GET_ADVERTISEMENTS_SUCCESS = 'GET_ADVERTISEMENTS_SUCCESS';
export const GET_ADVERTISEMENTS_FAIL = 'GET_ADVERTISEMENTS_FAIL';

export function getAdvertisementsAction(searchConfig, cb) {
  return {
    type: GET_ADVERTISEMENTS_REQUEST,
    payload: {
      searchConfig,
      cb,
    },
  };
}

export const GET_ALL_ADVERTISEMENTS_REQUEST = 'GET_ALL_ADVERTISEMENTS_REQUEST';
export const GET_ALL_ADVERTISEMENTS_SUCCESS = 'GET_ALL_ADVERTISEMENTS_SUCCESS';
export const GET_ALL_ADVERTISEMENTS_FAIL = 'GET_ALL_ADVERTISEMENTS_FAIL';

export function getAllAdvertisementsAction(cb) {
  return {
    type: GET_ALL_ADVERTISEMENTS_REQUEST,
    payload: {
      cb,
    },
  };
}

export const ADD_ADVERTISEMENT_REQUEST = 'ADD_ADVERTISEMENT_REQUEST';
export const ADD_ADVERTISEMENT_SUCCESS = 'ADD_ADVERTISEMENT_SUCCESS';
export const ADD_ADVERTISEMENT_FAIL = 'ADD_ADVERTISEMENT_FAIL';

export function addAdvertisementAction(formData, cb) {
  return {
    type: ADD_ADVERTISEMENT_REQUEST,
    payload: {
      formData,
      cb,
    },
  };
}

export const DELETE_ADVERTISEMENT_REQUEST = 'DELETE_ADVERTISEMENT_REQUEST';
export const DELETE_ADVERTISEMENT_SUCCESS = 'DELETE_ADVERTISEMENT_SUCCESS';
export const DELETE_ADVERTISEMENT_FAIL = 'DELETE_ADVERTISEMENT_FAIL';

export function deleteAdvertisement(id, cb) {
  return {
    type: DELETE_ADVERTISEMENT_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const DELETE_SELECTED_ADVERTISEMENTS_REQUEST =
  'DELETE_SELECTED_ADVERTISEMENTS_REQUEST';
export const DELETE_SELECTED_ADVERTISEMENTS_SUCCESS =
  'DELETE_SELECTED_ADVERTISEMENTS_SUCCESS';
export const DELETE_SELECTED_ADVERTISEMENTS_FAIL =
  'DELETE_SELECTED_ADVERTISEMENTS_FAIL';

export function deleteSelectedAdvertisements(selectedRows, cb) {
  return {
    type: DELETE_SELECTED_ADVERTISEMENTS_REQUEST,
    payload: {
      selectedRows,
      cb,
    },
  };
}

export const UPDATE_ADVERTISEMENT_REQUEST = 'UPDATE_ADVERTISEMENT_REQUEST';
export const UPDATE_ADVERTISEMENT_SUCCESS = 'UPDATE_ADVERTISEMENT_SUCCESS';
export const UPDATE_ADVERTISEMENT_FAIL = 'UPDATE_ADVERTISEMENT_FAIL';

export function updateAdvertisement(formData, cb) {
  return {
    type: UPDATE_ADVERTISEMENT_REQUEST,
    payload: {
      formData,
      cb,
    },
  };
}

export const GET_SINGLE_ADVERTISEMENT_REQUEST =
  'GET_SINGLE_ADVERTISEMENT_REQUEST';
export const GET_SINGLE_ADVERTISEMENT_SUCCESS =
  'GET_SINGLE_ADVERTISEMENT_SUCCESS';
export const GET_SINGLE_ADVERTISEMENT_FAIL = 'GET_SINGLE_ADVERTISEMENT_FAIL';

export function getSingleAdvertisement(id, cb) {
  return {
    type: GET_SINGLE_ADVERTISEMENT_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const EXPORT_ADVERTISEMENTS_REQUEST = 'EXPORT_ADVERTISEMENTS_REQUEST';
export const EXPORT_ADVERTISEMENTS_SUCCESS = 'EXPORT_ADVERTISEMENTS_SUCCESS';
export const EXPORT_ADVERTISEMENTS_FAIL = 'EXPORT_ADVERTISEMENTS_FAIL';

export function exportExcelAdvertisementsAction(searchConfig, cb) {
  return {
    type: EXPORT_ADVERTISEMENTS_REQUEST,
    payload: { searchConfig, cb },
  };
}

export const UPDATE_STATUS_ADVERTISEMENT_REQUEST =
  'UPDATE_STATUS_ADVERTISEMENT_REQUEST';
export const UPDATE_STATUS_ADVERTISEMENT_SUCCESS =
  'UPDATE_STATUS_ADVERTISEMENT_SUCCESS';
export const UPDATE_STATUS_ADVERTISEMENT_FAIL =
  'UPDATE_STATUS_ADVERTISEMENT_FAIL';

export function updateStatusAdvertisementAction(id, cb) {
  return {
    type: UPDATE_STATUS_ADVERTISEMENT_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}
