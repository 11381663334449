import React, { useState } from 'react';
import './table_title.scss';
import { IoMdAdd } from 'react-icons/io';
import { BiDownload } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { TbRefresh } from 'react-icons/tb';
import CustomizedMenus from './AddNewDropDown';
import AddNewDropDown from './AddNewDropDown';
import Box from '@mui/material/Box';
import { Typography } from '@material-ui/core';
import { RiFileExcel2Fill } from "react-icons/ri";

export default function TableTitle({
  handleShow,
  title,
  exportExcel,
  addNew,
  fetch,
  fetchStatus,
  exportExcelStatus,
  dropDown,
  downloadReport,
  downloadReportStatus,
}) {
  let fetchingPercent = useSelector(
    store => store?.tourVouchers?.fetchingPercent,
  );

  return (
    <div className="admin_tables_title">
      <div>
        <span className="pageTitle">{title}</span>
      </div>
      <div className="action_block">
      {downloadReport ? (
          <div
            className={`toolBar mobile_add action_block_item ${
              downloadReportStatus === 'pending' ? 'disabled' : ''
            }`}
            onClick={() =>
              downloadReportStatus === 'pending' ? null : downloadReport()
            }
          >
            {downloadReportStatus === 'pending' ? (
              <CircularProgress style={{ marginLeft: '5px' }} size={15} />
            ) : (
              <RiFileExcel2Fill />
            )}
            <span className="action_title">Report</span>
          </div>
        ) : null}
        {exportExcel ? (
          <div
            className={`toolBar mobile_add action_block_item ${
              exportExcelStatus === 'pending' ? 'disabled' : ''
            }`}
            onClick={() =>
              exportExcelStatus === 'pending' ? null : exportExcel()
            }
          >
            {exportExcelStatus === 'pending' ? (
              <CircularProgress style={{ marginLeft: '5px' }} size={15} />
            ) : (
              <BiDownload />
            )}
            <span className="action_title">Export</span>
          </div>
        ) : null}
        {fetch ? (
          <div
            className={`toolBar mobile_add action_block_item`}
            onClick={() => (fetchStatus === 'pending' ? null : fetch())}
          >
            {fetchStatus === 'pending' && fetchingPercent ? (
              <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress
                  // size={'sm'}
                  variant="determinate"
                  value={fetchingPercent}
                />
                <Box
                  sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    variant="caption"
                    component="div"
                    color="text.secondary"
                  >
                    {`${
                      fetchingPercent ? `${Math.round(fetchingPercent)}%` : ''
                    }`}
                  </Typography>
                </Box>
              </Box>
            ) : (
              <TbRefresh />
            )}
            <span className="action_title">
              {fetchStatus === 'pending' ? 'Fetching..' : 'Fetch'}
            </span>
          </div>
        ) : null}
        {addNew && !dropDown ? (
          <div
            className="toolBar action_block_item"
            onClick={() => handleShow('Add New')}
          >
            <IoMdAdd /> <span className="action_title">Add New</span>
          </div>
        ) : dropDown ? (
          <div className="toolBar action_block_item">
            <AddNewDropDown handleShow={handleShow} />
          </div>
        ) : null}
        {/* <BatchMenu
          batchDelete={batchDelete}
          batchStatusUpdate={batchStatusUpdate}
          selectedRows={selectedRows}
          deleteSelectedRows={deleteSelectedRows}
          updateStatusSelectedRows={updateStatusSelectedRows}
        /> */}
      </div>
    </div>
  );
}
