import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import PageTitle from '../../components/PageTitle.js';
import { useNavigate, useParams } from 'react-router-dom';
import { FadeLoader } from 'react-spinners';
import PickUpPointsForm from './PickUpPointsForm.js';
import { getSinglePickUpPoint } from '../../store/actions/pickUpPoints';
import { blockPageAction } from '../../store/actions/users';

function PreviewPickUpPoints() {
  const rowStatus = useSelector(store => store?.pickUpPoints?.status);
  const [formData, setFormData] = useState({});
  const [siteName, setSiteName] = useState('');
  const [formValidation, setFormValidation] = useState({});
  const searchConfig = useSelector(store => store.search);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    handleEdit(id);
    return () => {
      dispatch(blockPageAction(''));
    };
  }, []);

  // Get the row data to edit
  const handleEdit = id => {
    dispatch(
      getSinglePickUpPoint(id, (err, data) => {
        if (data) {
          setFormData({ ...data.result });
        }
      }),
    );
  };

  // Close Add/Edit modal
  const handleClose = () => {};

  const handleChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  return (
    <>
      <PageTitle
        parentTitle={'Pick-Up Points'}
        childTitle={formData.pick_up_name || ''}
        parentLink={'/admin/pick_up_points'}
      />
      <div className="form-block">
        {rowStatus === 'success' ? (
          <PickUpPointsForm
            title="edit"
            formValidation={formValidation}
            handleClose={handleClose}
            singlePickUpPoint={formData}
            handleChange={handleChange}
            handleEdit={handleEdit}
          />
        ) : (
          <div className="form-loading">
            <FadeLoader
              color={`var(--primary_main)`}
              loading={rowStatus === 'pending'}
              size={10}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )}
      </div>
    </>
  );
}

export default PreviewPickUpPoints;
