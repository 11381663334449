import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import PageTitle from '../../components/PageTitle.js';
import { useNavigate, useParams } from 'react-router-dom';
import { FadeLoader } from 'react-spinners';
import AdvertisementsForm from './AdvertisementsForm.js';
import {
  getAllAdvertisementsAction,
  getSingleAdvertisement,
} from '../../store/actions/advertisements.js';
import { blockPageAction } from '../../store/actions/users';

function PreviewAdvertisement() {
  let rows = useSelector(store => store?.advertisements?.ads);
  let allAds = useSelector(store => store?.advertisements?.allAds);
  const rowStatus = useSelector(store => store?.advertisements?.status);
  const actionStatus = useSelector(
    store => store?.advertisements?.actionStatus,
  );
  const [formData, setFormData] = useState({});
  const [siteName, setSiteName] = useState('');
  const [formValidation, setFormValidation] = useState({});
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getAllAdvertisementsAction());
    handleEdit(id);
    return () => {
      dispatch(blockPageAction(''));
    };
  }, []);

  // Get the row data to edit
  const handleEdit = id => {
    dispatch(
      getSingleAdvertisement(id, (err, data) => {
        if (data) {
          setFormData(data?.result);
          setSiteName(
            data.result?.ad_title_1
              ? 'ID: ' + data?.result?.id + ' - ' + data?.result?.ad_title_1
              : 'ID: ' + data?.result?.id,
          );
        }

        // setFormValidation(!!formValidation);
      }),
    );
  };

  // Close Add/Edit modal
  const handleClose = () => {
    setFormData({});
  };

  const handleChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  return (
    <>
      <PageTitle
        parentTitle={'Advertisements'}
        childTitle={siteName}
        parentLink={'/admin/setup/advertisements'}
      />
      <div className="form-block">
        {rowStatus === 'success' ? (
          <AdvertisementsForm
            formValidation={formValidation}
            handleClose={handleClose}
            single={formData}
            handleChange={handleChange}
            handleEdit={handleEdit}
            rows={allAds}
            actionStatus={actionStatus}
          />
        ) : (
          <div className="form-loading">
            <FadeLoader
              color={`var(--primary_main)`}
              loading={rowStatus === 'pending'}
              size={10}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )}
      </div>
    </>
  );
}

export default PreviewAdvertisement;
