export const ADD_EMAIL_SETUP_REQUEST = 'ADD_EMAIL_SETUP_REQUEST';
export const ADD_EMAIL_SETUP_SUCCESS = 'ADD_EMAIL_SETUP_SUCCESS';
export const ADD_EMAIL_SETUP_FAIL = 'ADD_EMAIL_SETUP_FAIL';

export function addEmailSetupAction(formData, cb) {
  return {
    type: ADD_EMAIL_SETUP_REQUEST,
    payload: {formData, cb},
  };
}

export const EDIT_EMAIL_SETUP_REQUEST = 'EDIT_EMAIL_SETUP_REQUEST';
export const EDIT_EMAIL_SETUP_SUCCESS = 'EDIT_EMAIL_SETUP_SUCCESS';
export const EDIT_EMAIL_SETUP_FAIL = 'EDIT_EMAIL_SETUP_FAIL';

export function editEmailSetupAction(formData, cb) {
  return {
    type: EDIT_EMAIL_SETUP_REQUEST,
    payload: {formData, cb},
  };
}

export const GET_SINGLE_EMAIL_SETUP_REQUEST = 'GET_SINGLE_EMAIL_SETUP_REQUEST';
export const GET_SINGLE_EMAIL_SETUP_SUCCESS = 'GET_SINGLE_EMAIL_SETUP_SUCCESS';
export const GET_SINGLE_EMAIL_SETUP_FAIL = 'GET_SINGLE_EMAIL_SETUP_FAIL';

export function getSingleEmailSetupAction(formData, cb) {
  return {
    type: GET_SINGLE_EMAIL_SETUP_REQUEST,
    payload: {formData, cb},
  };
}

export const GET_EMAIL_SETUPS_LIST_REQUEST = 'GET_EMAIL_SETUPS_LIST_REQUEST';
export const GET_EMAIL_SETUPS_LIST_SUCCESS = 'GET_EMAIL_SETUPS_LIST_SUCCESS';
export const GET_EMAIL_SETUPS_LIST_FAIL = 'GET_EMAIL_SETUPS_LIST_FAIL';

export function getEmailSetupsListAction(formData, cb) {
  return {
    type: GET_EMAIL_SETUPS_LIST_REQUEST,
    payload: {formData, cb},
  };
}

export const DELETE_EMAIL_SETUP_REQUEST = 'DELETE_EMAIL_SETUP_REQUEST';
export const DELETE_EMAIL_SETUP_SUCCESS = 'DELETE_EMAIL_SETUP_SUCCESS';
export const DELETE_EMAIL_SETUP_FAIL = 'DELETE_EMAIL_SETUP_FAIL';

export function deleteEmailSetupAction(formData, cb) {
  return {
    type: DELETE_EMAIL_SETUP_REQUEST,
    payload: {formData, cb},
  };
}

export const GET_ALL_EMAIL_SETUP_REQUEST = 'GET_ALL_EMAIL_SETUP_REQUEST';
export const GET_ALL_EMAIL_SETUP_SUCCESS = 'GET_ALL_EMAIL_SETUP_SUCCESS';
export const GET_ALL_EMAIL_SETUP_FAIL = 'GET_ALL_EMAIL_SETUP_FAIL';

export function getAllEmailSetup(type, cb) {
  return {
    type: GET_ALL_EMAIL_SETUP_REQUEST,
    payload: {
      type,
      cb,
    },
  };
}



