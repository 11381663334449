import {
  GET_TERMS_AND_CONDITIONS_REQUEST,
  GET_TERMS_AND_CONDITIONS_SUCCESS,
  GET_TERMS_AND_CONDITIONS_FAIL,
  ADD_TERMS_AND_CONDITION_REQUEST,
  ADD_TERMS_AND_CONDITION_SUCCESS,
  ADD_TERMS_AND_CONDITION_FAIL,
  DELETE_TERMS_AND_CONDITION_REQUEST,
  DELETE_TERMS_AND_CONDITION_SUCCESS,
  DELETE_TERMS_AND_CONDITION_FAIL,
  UPDATE_TERMS_AND_CONDITION_REQUEST,
  UPDATE_TERMS_AND_CONDITION_SUCCESS,
  UPDATE_TERMS_AND_CONDITION_FAIL,
  GET_SINGLE_TERMS_AND_CONDITION_REQUEST,
  GET_SINGLE_TERMS_AND_CONDITION_SUCCESS,
  GET_SINGLE_TERMS_AND_CONDITION_FAIL,
  GET_ALL_TERMS_AND_CONDITIONS_REQUEST,
  GET_ALL_TERMS_AND_CONDITIONS_SUCCESS,
  GET_ALL_TERMS_AND_CONDITIONS_FAIL,
} from '../actions/termsConditions';

const initialState = {
  status: '',
  actionStatus: '',
  termsConditions: [],
  allTermsConditions: [],
  singleTermsCondition: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_TERMS_AND_CONDITIONS_REQUEST: {
      return {
        ...state,
        status: 'pending',
      };
    }
    case GET_TERMS_AND_CONDITIONS_SUCCESS: {
      return {
        ...state,
        status: 'success',
        count: action.payload.data.count,
        termsConditions: action.payload.data.result,
      };
    }
    case GET_TERMS_AND_CONDITIONS_FAIL: {
      return {
        ...state,
        status: 'fail',
      };
    }
    case GET_SINGLE_TERMS_AND_CONDITION_REQUEST: {
      return {
        ...state,
        status: 'pending',
      };
    }
    case GET_SINGLE_TERMS_AND_CONDITION_SUCCESS: {
      return {
        ...state,
        status: 'success',
        singleTermsCondition: action.payload.data,
      };
    }
    case GET_SINGLE_TERMS_AND_CONDITION_FAIL: {
      return {
        ...state,
        status: 'fail',
      };
    }
    case ADD_TERMS_AND_CONDITION_REQUEST: {
      return {
        ...state,
        actionStatus: 'pending',
      };
    }
    case ADD_TERMS_AND_CONDITION_SUCCESS: {
      return {
        ...state,
        actionStatus: 'success',
      };
    }
    case ADD_TERMS_AND_CONDITION_FAIL: {
      return {
        ...state,
        actionStatus: 'fail',
      };
    }
    case UPDATE_TERMS_AND_CONDITION_REQUEST: {
      return {
        ...state,
        actionStatus: 'pending',
      };
    }
    case UPDATE_TERMS_AND_CONDITION_SUCCESS: {
      return {
        ...state,
        actionStatus: 'success',
      };
    }
    case UPDATE_TERMS_AND_CONDITION_FAIL: {
      return {
        ...state,
        actionStatus: 'fail',
      };
    }
    case DELETE_TERMS_AND_CONDITION_REQUEST: {
      return {
        ...state,
        actionStatus: 'pending',
      };
    }
    case DELETE_TERMS_AND_CONDITION_SUCCESS: {
      return {
        ...state,
        actionStatus: 'success',
      };
    }
    case DELETE_TERMS_AND_CONDITION_FAIL: {
      return {
        ...state,
        actionStatus: 'fail',
      };
    }
    case GET_ALL_TERMS_AND_CONDITIONS_REQUEST: {
      return {
        ...state,
        status: 'pending',
      };
    }
    case GET_ALL_TERMS_AND_CONDITIONS_SUCCESS: {
      return {
        ...state,
        status: 'success',
        allTermsConditions: action.payload.data,
      };
    }
    case GET_ALL_TERMS_AND_CONDITIONS_FAIL: {
      return {
        ...state,
        status: 'fail',
      };
    }

    default: {
      return state;
    }
  }
}
