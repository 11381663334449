import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { FormControlLabel } from '@material-ui/core';
import { Checkbox } from '@mui/material';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import UsersForm from './UsersForm';
import { sales_agent_validation } from './validationSchema';
import { registerRequest, updateUser } from '../../../store/actions/users';
import { LoadingButton } from '@mui/lab';
import PhoneInput from 'react-phone-input-2';

function SalesAgentForm({
  popupTitle,
  handleClose,
  singleSalesAgent,
  getUsersList,
  add_new_modal,
  actionStatus,
}) {
  const { id } = useParams();
  const searchConfig = useSelector(store => store.search);
  const blockedPage = useSelector(store => store.search.blockedPage);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(sales_agent_validation),
    defaultValues: singleSalesAgent || { role: 'Sales Agent' },
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors, isValid, isDirty },
    reset,
    setError,
    clearErrors,
  } = methods;

  useEffect(() => {
    if (id && singleSalesAgent) {
      reset({
        ...singleSalesAgent,
        add_user: !!singleSalesAgent.user_name,
        new_password: !!singleSalesAgent.user_name,
      });
    }
  }, [id, singleSalesAgent, reset]);

  const onSubmit = data => {
    setDisabled(true);
    dispatch(
      registerRequest(data, (err, data) => {
        if (err) {
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 10000,
          });
        } else {
          toast.success('Successfully Created', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 10000,
          });
          getUsersList(searchConfig);
          handleClose();
        }
      }),
    );

    setTimeout(() => setDisabled(false), 1500);
  };

  const onEdit = data => {
    dispatch(
      updateUser(data, (err, data) => {
        if (err) {
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 10000,
          });
        } else {
          if (data && data?.status === 200) {
            toast.success('Successfully Updated', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 10000,
            });
            navigate('/admin/users');
          }
        }
      }),
    );
  };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(id ? onEdit : onSubmit)} noValidate>
        {!id && (
          <Modal.Header closeButton={!id}>
            <Modal.Title>Add New Sales Agent</Modal.Title>
          </Modal.Header>
        )}
        <div className="form-block">
          <Modal.Body>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Full Name<span style={{ color: 'red' }}> *</span>
                  </Form.Label>
                  <Form.Control
                    id="sasles_agent_name"
                    name="name"
                    type="text"
                    {...register('name')}
                    isInvalid={errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Label>Email</Form.Label>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      id="supplier_email"
                      type="email"
                      {...field}
                      isInvalid={errors.email}
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email?.message}
                </Form.Control.Feedback>
              </Col>
              <Col>
                <Form.Label>Phone Number</Form.Label>
                <PhoneInput
                  inputProps={{
                    id: 'supplier_phone_number',
                    name: 'phone_number',
                  }}
                  value={watch('phone_number') || ''}
                  onChange={value => {
                    setValue('phone_number', value);
                    clearErrors('phone_number');
                  }}
                />
                {errors.phone_number?.message && (
                  <span className="invalid_message">
                    {errors.phone_number?.message}
                  </span>
                )}
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <Controller
                  name="add_user"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="sales_agent_create_account"
                          {...field}
                          disabled={id && singleSalesAgent?.user_name}
                          defaultChecked={id && singleSalesAgent?.user_name}
                          checked={field.value}
                          onChange={ev => {
                            setValue('add_user', ev.target.checked);
                            setValue('password', '');
                            setValue('user_name', '');
                          }}
                        />
                      }
                      label="Create Account ?"
                    />
                  )}
                />
              </Col>
            </Row>
            {watch('add_user') ||
            (!add_new_modal && singleSalesAgent?.user_name) ? (
              <UsersForm
                setValue={setValue}
                control={control}
                singleUser={singleSalesAgent}
              />
            ) : null}
          </Modal.Body>
        </div>
        <Modal.Footer>
          {id ? (
            <div className="previewButtonsID">
              <LoadingButton
                size="small"
                className="login_submit_btn btn btn-primary "
                loadingPosition="center"
                variant="contained"
                loading={actionStatus === 'pending'}
                type={'submit'}
              >
                <span>{actionStatus === 'pending' ? '' : 'Save'}</span>
              </LoadingButton>
              <Button
                variant="secondary"
                onClick={() => reset({ ...singleSalesAgent })}
              >
                Reset
              </Button>
            </div>
          ) : (
            <>
              <div className="previewButtons">
                <LoadingButton
                  size="small"
                  className="login_submit_btn btn btn-primary "
                  loadingPosition="center"
                  variant="contained"
                  loading={actionStatus === 'pending'}
                  type={'submit'}
                >
                  <span>{actionStatus === 'pending' ? '' : 'Save'}</span>
                </LoadingButton>
                <Button variant="secondary" onClick={handleClose}>
                  Cancel
                </Button>
              </div>
            </>
          )}
        </Modal.Footer>
      </Form>
    </FormProvider>
  );
}

export default SalesAgentForm;
