import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { getAllPickUpPointsAction } from '../../store/actions/pickUpPoints';
import Select from 'react-select';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Stack from '@mui/material/Stack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { createTheme, TextField, ThemeProvider } from '@mui/material';
import dayjs from 'dayjs';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { getAllDirectionsAction } from '../../store/actions/directions';
import { FiFilter } from 'react-icons/fi';
import {
  addTransferVoucherAction,
  getSingleTransferVoucher,
  getTransferVouchersAction,
  updateTransferVoucher,
} from '../../store/actions/transferVouchers';
import { getSelectTransfers } from '../../store/actions/toursTransfers';
import { getAllCurrency } from '../../store/actions/currency';
import { LoadingButton } from '@mui/lab';
import {
  blockPageAction,
  getAllSalesAgentAction,
} from '../../store/actions/users';
import roles from '../roles/Roles';
import Account from '../../services/Account';

function TransferVouchersForm({
  popupTitle,
  handleClose,
  singleTourVoucher: singleTransferVoucher,
  actionStatus,
}) {
  const searchConfig = useSelector(store => store.search);
  const blockedPage = useSelector(store => store.search.blockedPage);
  const allCurrency = useSelector(store => store.currency.allCurrency);
  const allSalesAgents = useSelector(store => store.users?.allSalesAgents);
  const allTransfers = useSelector(
    store => store.toursTransfers.selectTransfers,
  );
  const [formValidation, setFormValidation] = useState({});
  const [formData, setFormData] = useState({ pre_paid_package: true });
  const [disabled, setDisabled] = useState(false);
  const [validated, setValidated] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [inputDisabled, setInputDisabled] = useState(false);
  const [bookingInfo, setBookingInfo] = useState('');
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const account = useSelector(store => store.users.account);
  useEffect(() => {
    dispatch(getAllPickUpPointsAction());
    dispatch(getAllDirectionsAction());
    dispatch(getAllCurrency());
    dispatch(getAllSalesAgentAction());
  }, []);

  useEffect(() => {
    if (account?.role === 'Sales Agent') {
      setInputDisabled(true);
    }
  }, [account]);

  useEffect(() => {
    if (!isEmpty(singleTransferVoucher)) {
      if (
        singleTransferVoucher?.transfer?.service_date
        // &&
        // singleTransferVoucher?.transfer?.direction_id
      ) {
        handleGetTransfers(
          singleTransferVoucher?.transfer?.service_date,
          // singleTransferVoucher?.transfer?.direction_id,
        );
      }
      setFormData({
        ...singleTransferVoucher,
      });
    }
  }, [singleTransferVoucher]);

  useEffect(() => {
    if (
      id &&
      typeof formData === typeof singleTransferVoucher &&
      !isEmpty(singleTransferVoucher)
    ) {
      if (
        JSON.stringify(formData) !== JSON.stringify(singleTransferVoucher) &&
        !blockedPage
      ) {
        dispatch(blockPageAction('/user-management/users/41'));
      }
      if (
        JSON.stringify(formData) === JSON.stringify(singleTransferVoucher) &&
        blockedPage
      ) {
        dispatch(blockPageAction(''));
      }
    }
    if (formData && formData.bokun_info) {
      let bookingInfoStr = formData?.bokun_info;
      let parts = bookingInfoStr && bookingInfoStr.split(';');
      let joinedString = parts && parts.join('\n');
      let formattedString = joinedString.includes('undefined' || undefined)
        ? joinedString.replaceAll(/undefined/g, 'No data')
        : joinedString;
      setBookingInfo(formattedString);
    }
  }, [formData]);

  const handleChange = (key, val) => {
    const regex = /^[a-zA-Z0-9-_]*$/;
    if (key === 'reference_number' && !regex.test(val)) {
      return;
    }

    setFormData({ ...formData, [key]: val });
    setFormValidation({ ...formValidation, [key]: val });
  };
  const handleEdit = params => {
    setFormData(formData);
    setFormValidation(!!formValidation);
    dispatch(getSingleTransferVoucher(params));
  };

  const onSubmit = event => {
    event.preventDefault();
    setSubmitted(true);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      setDisabled(true);
      dispatch(
        addTransferVoucherAction(formData, (err, data) => {
          if (data && data.status === 200) {
            toast.success('Successfully Created', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });
            setValidated(false);
            setFormData({ brand_name: '' });
            dispatch(getTransferVouchersAction(searchConfig));
            setDisabled(false);
            handleClose();
            form.reset();
          } else if (data && data.status === 400) {
            toast.error(data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });
          }
        }),
      );
      setTimeout(() => {
        setDisabled(false);
      }, 1500);
    }
  };

  const onEdit = ev => {
    ev.preventDefault();
    setSubmitted(true);
    const form = ev.currentTarget;
    if (form.checkValidity() === false) {
      ev.preventDefault();
      ev.stopPropagation();
      setValidated(true);
    } else {
      setDisabled(true);
      setValidated(true);
      dispatch(
        updateTransferVoucher(formData, (err, data) => {
          if (data && data.status === 400) {
            toast.error(data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });
            // setValidated(false);
          } else {
            if (data && data.status === 200) {
              toast.success('Successfully Updated', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 10000,
              });
              setDisabled(false);
              form.reset();
              navigate('/admin/transfer_vouchers');
            }
          }
        }),
      );
      setTimeout(() => {
        setDisabled(false);
      }, 1500);
    }
  };

  const handleGetTransfers = service_date => {
    dispatch(getSelectTransfers(service_date));
  };

  const customStylesGreen = {
    control: styles => ({
      ...styles,
      borderColor: !!validated ? '#198754' : '#ced4da',
    }),
    option: styles => {
      return {
        ...styles,
        ':active': {
          ...styles[':active'],
          backgroundColor: `var(--primary_light)`,
        },
      };
    },
  };
  const customStyleGray = {
    control: styles => ({
      ...styles,
      borderColor: !!validated ? '' : '#ced4da',
    }),
    option: styles => {
      return {
        ...styles,
        ':active': {
          ...styles[':active'],
          backgroundColor: `var(--primary_light)`,
        },
      };
    },
  };
  const customRequiredStyle = {
    control: styles => ({
      ...styles,
      borderColor: !!validated ? '#dc3545' : '#ced4da',
    }),
    option: styles => {
      return {
        ...styles,
        ':active': {
          ...styles[':active'],
          backgroundColor: `var(--primary_light)`,
        },
      };
    },
  };

  const requiredTheme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: !!validated ? '#198754' : '#ced4da',
          },
        },
      },
    },
  });

  const theme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: !!validated ? '#ced4da' : '#ced4da',
          },
        },
      },
    },
  });
  return (
    <>
      <Form
        onSubmit={event => {
          if (id) {
            onEdit(event);
          } else {
            onSubmit(event);
          }
        }}
        noValidate
        validated={validated}
      >
        <Modal.Header closeButton={!id}>
          <Modal.Title>
            {!id && popupTitle + ' ' + 'Transfer Voucher'}
          </Modal.Title>
        </Modal.Header>
        <div className="form-block">
          <Modal.Body>
            <Row>
              <Col sm={6} md={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Agency Reference Number</Form.Label>
                  <Form.Control
                    id="transfer_voucher_ag_ref_number"
                    name="ag_ref_number"
                    value={formData?.ag_ref_number || ''}
                    disabled={true}
                    onChange={e =>
                      handleChange('ag_ref_number', e.target.value)
                    }
                  />
                </Form.Group>
              </Col>
              <Col sm={6} md={3}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Reference Number
                    <span style={{ color: 'red' }}> *</span>
                  </Form.Label>
                  <Form.Control
                    id="transfer_voucher_reference_number"
                    name="reference_number"
                    placeholder={'Allowed: a-zA-Z0-9-_'}
                    maxLength={254}
                    disabled={inputDisabled}
                    value={formData?.reference_number || ''}
                    required={true}
                    onChange={e =>
                      handleChange('reference_number', e.target.value)
                    }
                  />
                  <div>
                    <Form.Group className="d-flex align-items-center">
                      <Form.Check
                        type="checkbox"
                        id={'transfer_voucher_show_on_pdf'}
                        name="show_reference_number"
                        checked={formData?.show_reference_number}
                        disabled={inputDisabled}
                        onChange={ev =>
                          handleChange(
                            'show_reference_number',
                            ev.target.checked,
                          )
                        }
                      />
                      <Form.Check.Label
                        htmlFor="show_on_pdf"
                        className="p-0 px-1"
                        style={{ cursor: 'pointer' }}
                      >
                        Show on PDF
                      </Form.Check.Label>
                    </Form.Group>
                  </div>
                  <Form.Control.Feedback type="invalid">
                    Required Field
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={6} md={3}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Passenger Name
                    <span style={{ color: 'red' }}> *</span>
                  </Form.Label>
                  <Form.Control
                    id="transfer_voucher_passenger_name"
                    name="passenger_name"
                    maxLength={254}
                    disabled={inputDisabled}
                    value={formData?.passenger_name || ''}
                    required={true}
                    onChange={e =>
                      handleChange('passenger_name', e.target.value)
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    Required Field
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={6} md={3}>
                <Form.Label>Reservation Date</Form.Label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={3} id={'transfer_voucher_reservation_date'}>
                    <ThemeProvider
                      theme={
                        !formData['reservation_date'] && submitted
                          ? requiredTheme
                          : theme
                      }
                    >
                      <DesktopDatePicker
                        name={'reservation_date'}
                        className="month_year_picker"
                        views={['year', 'month', 'day']}
                        value={
                          formData?.reservation_date &&
                          dayjs(formData?.reservation_date)
                        }
                        disabled={inputDisabled}
                        minDate={dayjs()}
                        onChange={newValue => {
                          if (newValue) {
                            handleChange(
                              'reservation_date',
                              dayjs(newValue).format('YYYY-MM-DD'),
                            );
                          } else {
                            handleChange('reservation_date', null);
                          }
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            disabled={formData.send === 'true'}
                          />
                        )}
                      />
                    </ThemeProvider>
                  </Stack>
                </LocalizationProvider>
              </Col>
            </Row>
            <Row>
              <Col sm={6} md={6} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Sales Agent</Form.Label>
                  <Select
                    id={'tour_voucher_sales_agent_id'}
                    name={'sales_agent_id'}
                    isDisabled={inputDisabled}
                    styles={
                      ({
                        option: (styles, { isSelected }) => {
                          return {
                            ...styles,
                            ':active': {
                              ...styles[':active'],
                              backgroundColor: null,
                            },
                          };
                        },
                      },
                      !submitted && !formData.sales_agent_id
                        ? customStyleGray
                        : customStylesGreen)
                    }
                    value={
                      formData?.sales_agent_id && Array.isArray(allSalesAgents)
                        ? allSalesAgents.find(
                            i => i.id === formData.sales_agent_id,
                          )
                        : {}
                    }
                    options={allSalesAgents}
                    isClearable={true}
                    getOptionLabel={i => i.name}
                    getOptionValue={i => i.id}
                    onChange={ev => {
                      if (ev) {
                        handleChange('sales_agent_id', ev.id);
                      } else {
                        handleChange('sales_agent_id', null);
                      }
                    }}
                  />
                </Form.Group>
              </Col>
              <Col sm={6} md={6} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Passport Details</Form.Label>
                  <Form.Control
                    id="transfer_voucher_passenger_passport_details"
                    name="passenger_passport_details"
                    maxLength={254}
                    disabled={inputDisabled}
                    type="text"
                    value={formData.passenger_passport_details || ''}
                    onChange={event =>
                      handleChange(
                        'passenger_passport_details',
                        event.target.value,
                      )
                    }
                  />
                </Form.Group>
              </Col>
              <Col xs={4} sm={4} md={4} lg={2}>
                <Form.Group className="mb-3">
                  <Form.Label>Children</Form.Label>
                  <Form.Control
                    id="transfer_voucher_passenger_child"
                    name="child"
                    maxLength={254}
                    type="number"
                    min="0"
                    disabled={inputDisabled}
                    value={formData.child || ''}
                    onChange={event =>
                      handleChange('child', event.target.value)
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formData.child && 'Invalid format'}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={4} sm={4} md={4} lg={2}>
                <Form.Group className="mb-3">
                  <Form.Label>Adults</Form.Label>
                  <Form.Control
                    id="transfer_voucher_adult_members"
                    name="adult_members"
                    maxLength={254}
                    type="number"
                    min="0"
                    disabled={inputDisabled}
                    value={formData.adult_members || ''}
                    onChange={event =>
                      handleChange('adult_members', event.target.value)
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formData.adult_members && 'Invalid format'}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={4} sm={4} md={4} lg={2}>
                <Form.Group className="mb-3">
                  <Form.Label>Infants</Form.Label>
                  <Form.Control
                    id="transfer_voucher_infant"
                    name="infant"
                    disabled={inputDisabled}
                    maxLength={254}
                    type="number"
                    min="0"
                    value={formData.infant || ''}
                    onChange={event =>
                      handleChange('infant', event.target.value)
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formData.infant && 'Invalid format'}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6} md={6} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Passenger Phone</Form.Label>
                  <PhoneInput
                    value={formData.phone || ''}
                    onChange={val => handleChange('phone', val)}
                    country={'gr'}
                    disabled={inputDisabled}
                    buttonStyle={submitted ? { borderColor: '#198754' } : null}
                    inputProps={{
                      name: 'phone',
                      id: 'transfer_voucher_phone',
                    }}
                  />
                </Form.Group>
              </Col>
              <Col sm={6} md={6} lg={2}>
                <Form.Group className={!submitted ? 'mb-3' : null}>
                  <Form.Label>Pick Up Time</Form.Label>
                  <Form.Control
                    id="transfer_voucher_pick_up_time"
                    name="pick_up_time"
                    type="time"
                    disabled={inputDisabled}
                    value={formData.pick_up_time || ''}
                    onChange={event =>
                      handleChange('pick_up_time', event.target.value)
                    }
                    max={formData.end_time}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formData.pick_up_time && 'Invalid time'}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={4} md={4} lg={2}>
                <Form.Group className="mb-3">
                  <Form.Label>Flight Time</Form.Label>
                  <Form.Control
                    id="transfer_voucher_flight_time"
                    name="flight_time"
                    type="time"
                    disabled={inputDisabled}
                    value={formData.flight_time || ''}
                    onChange={event =>
                      handleChange('flight_time', event.target.value)
                    }
                  />
                </Form.Group>
              </Col>
              <Col sm={4} md={4} lg={2}>
                <Form.Group className="mb-3">
                  <Form.Label>Flight No</Form.Label>
                  <Form.Control
                    id="transfer_voucher_flight_number"
                    name="flight_number"
                    type="text"
                    disabled={inputDisabled}
                    value={formData.flight_number || ''}
                    onChange={event =>
                      handleChange('flight_number', event.target.value)
                    }
                    maxLength={254}
                  />
                </Form.Group>
              </Col>
              <Col sm={4} md={4} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Original Point Of Rental</Form.Label>
                  <Form.Control
                    id="transfer_voucher_original_point_of_rental"
                    name="original_point_of_rental"
                    type="text"
                    disabled={inputDisabled}
                    value={formData.original_point_of_rental || ''}
                    onChange={event =>
                      handleChange(
                        'original_point_of_rental',
                        event.target.value,
                      )
                    }
                    maxLength={254}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6} md={4} lg={4}>
                <Form.Group className="mb-2">
                  <Form.Label>Payment Amount</Form.Label>
                  <Form.Control
                    id="transfer_voucher_payment_amount"
                    name="payment_amount"
                    type="number"
                    min="0"
                    disabled={inputDisabled}
                    value={formData.payment_amount || ''}
                    onChange={event =>
                      handleChange('payment_amount', event.target.value)
                    }
                    maxLength={50}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formData.payment_amount && 'Invalid format'}
                  </Form.Control.Feedback>
                </Form.Group>
                <div>
                  <Form.Group className="d-flex align-items-center">
                    <Form.Check
                      id={'transfer_voucher_pre_paid_package'}
                      name={'pre_paid_package'}
                      type="checkbox"
                      disabled={inputDisabled}
                      checked={formData?.pre_paid_package}
                      onChange={ev =>
                        handleChange('pre_paid_package', ev.target.checked)
                      }
                    />
                    <Form.Check.Label
                      htmlFor="pre_paid_package"
                      className="p-0 px-1"
                      style={{ cursor: 'pointer' }}
                    >
                      Pre Paid Package
                    </Form.Check.Label>
                  </Form.Group>
                </div>
              </Col>
              <Col sm={6} md={4} lg={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Currency</Form.Label>
                  <Select
                    id={'transfer_voucher_currency'}
                    name={'currency'}
                    value={
                      formData?.currency_id && Array.isArray(allCurrency)
                        ? allCurrency.find(i => i.id === formData.currency_id)
                        : []
                    }
                    styles={
                      ({
                        option: (styles, { isSelected }) => {
                          return {
                            ...styles,
                            ':active': {
                              ...styles[':active'],
                              backgroundColor: null,
                            },
                          };
                        },
                      },
                      !submitted && !formData.currency_id
                        ? customStyleGray
                        : customStylesGreen)
                    }
                    options={Array.isArray(allCurrency) ? allCurrency : []}
                    getOptionLabel={i =>
                      i.currency_name + `(${i.currencySymbol})`
                    }
                    getOptionValue={i => i.id}
                    isDisabled={inputDisabled}
                    onChange={ev => {
                      if (ev) {
                        handleChange('currency_id', ev.id);
                      } else {
                        handleChange('currency_id', null);
                      }
                    }}
                  />
                </Form.Group>
              </Col>
              <Col sm={12} md={4} lg={4}>
                <Form.Group className="mb-2">
                  <Form.Label>Pick Up Point</Form.Label>
                  <Form.Control
                    id={'transfer_voucher_pick_up_address'}
                    name="pick_up_address"
                    type="text"
                    value={formData.pick_up_address || ''}
                    disabled={inputDisabled}
                    onChange={event =>
                      handleChange('pick_up_address', event.target.value)
                    }
                    maxLength={254}
                  />
                </Form.Group>
                {/*<div>*/}
                {/*    <Form.Group className="d-flex align-items-center">*/}
                {/*        <Form.Check*/}
                {/*            type="checkbox"*/}
                {/*            id={'show_pick_up_point'}*/}
                {/*            checked={formData?.show_pick_up_point}*/}
                {/*            onChange={ev =>*/}
                {/*                handleChange(*/}
                {/*                    'show_pick_up_point',*/}
                {/*                    ev.target.checked,*/}
                {/*                )*/}
                {/*            }*/}
                {/*        />*/}
                {/*        <Form.Check.Label*/}
                {/*            htmlFor="show_pick_up_point"*/}
                {/*            className="p-0 px-1"*/}
                {/*            style={{cursor: 'pointer'}}*/}
                {/*        >*/}
                {/*            Show Pickup Point*/}
                {/*        </Form.Check.Label>*/}
                {/*    </Form.Group>*/}
                {/*</div>*/}
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="d-flex align-items-center justify-content-between">
                  <p className={id ? 'select_preview_tour' : 'select__tour'}>
                    Select Transfer
                  </p>
                  <div className="select__tour__line" />
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Label className="label-m-1r">
                  {' '}
                  Service Date <FiFilter size={16} />{' '}
                </Form.Label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={3} id={'transfer_voucher_service_date'}>
                    <ThemeProvider theme={theme}>
                      <DesktopDatePicker
                        className="month_year_picker"
                        name="service_date"
                        views={['year', 'month', 'day']}
                        value={
                          formData?.service_date &&
                          dayjs(formData?.service_date)
                        }
                        // minDate={!id ? dayjs() : null}
                        onChange={newValue => {
                          if (newValue) {
                            // if (formData.direction_id) {
                            handleGetTransfers(
                              dayjs(newValue).format('YYYY-MM-DD'),
                              formData.direction_id,
                            );
                            // }
                            setFormData({
                              ...formData,
                              service_date:
                                dayjs(newValue).format('YYYY-MM-DD'),
                              transfer_id: null,
                            });
                          } else {
                            setFormData({
                              ...formData,
                              service_date: null,
                              transfer_id: null,
                            });
                          }
                        }}
                        disabled={inputDisabled}
                        renderInput={params => (
                          <TextField
                            {...params}
                            disabled={formData.send === 'true'}
                          />
                        )}
                      />
                    </ThemeProvider>
                  </Stack>
                </LocalizationProvider>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Transfer Name</Form.Label>
                  <Select
                    id={'transfer_voucher_transfer_name'}
                    name={'transfer_name'}
                    value={
                      formData?.transfer_id && Array.isArray(allTransfers)
                        ? allTransfers.find(i => i.id === formData.transfer_id)
                        : {}
                    }
                    isDisabled={
                      !formData.service_date ||
                      // !formData.direction_id ||
                      formData.service_date === 'Invalid Date' ||
                      inputDisabled
                    }
                    isClearable={true}
                    options={allTransfers}
                    getOptionValue={i => i.id}
                    getOptionLabel={i => i.transfer_name}
                    onChange={ev => {
                      if (ev) {
                        handleChange('transfer_id', ev.id);
                      } else {
                        handleChange('transfer_id', null);
                      }
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            {id && bookingInfo ? (
              <>
                <h5>
                  <strong>Original Booking Info</strong>
                </h5>
                <div className="info_div">
                  <pre>{bookingInfo}</pre>
                </div>
              </>
            ) : null}
          </Modal.Body>
        </div>
        <Modal.Footer>
          {!isEmpty(account) && account.role !== 'Sales Agent' && (
            <div className={id ? 'previewButtonsID' : 'previewButtons'}>
              <LoadingButton
                size="small"
                className="login_submit_btn btn btn-primary "
                loadingPosition="center"
                variant="contained"
                loading={actionStatus === 'pending'}
                type={'submit'}
              >
                <span>{actionStatus === 'pending' ? '' : 'Save'}</span>
              </LoadingButton>
              {id ? (
                <Button variant="secondary" onClick={() => handleEdit(id)}>
                  Reset
                </Button>
              ) : (
                <Button variant="secondary" onClick={handleClose}>
                  Cancel
                </Button>
              )}
            </div>
          )}
        </Modal.Footer>
      </Form>
    </>
  );
}

export default TransferVouchersForm;
