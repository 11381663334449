import { takeLatest, call, put } from 'redux-saga/effects';
import {
  ADD_USER_FAIL,
  ADD_USER_REQUEST,
  ADD_USER_SUCCESS,
  LOGIN_USER_FAIL,
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  UPDATE_USER_FAIL,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  DELETE_USER_FAIL,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  ACTIVATE_USER_ACCOUNT_REQUEST,
  ACTIVATE_USER_ACCOUNT_SUCCESS,
  ACTIVATE_USER_ACCOUNT_FAIL,
  GET_USERS_LIST_FAIL,
  GET_USERS_LIST_REQUEST,
  GET_USERS_LIST_SUCCESS,
  MY_ACCOUNT_FAIL,
  MY_ACCOUNT_REQUEST,
  MY_ACCOUNT_SUCCESS,
  GET_USER_ACCOUNT_REQUEST,
  GET_USER_ACCOUNT_SUCCESS,
  GET_USER_ACCOUNT_FAIL,
  GET_ACCESS_LIST_REQUEST,
  GET_ACCESS_LIST_SUCCESS,
  GET_ACCESS_LIST_FAIL,
  GET_SINGLE_ACCESS_LIST_SUCCESS,
  GET_SINGLE_ACCESS_LIST_FAIL,
  GET_SINGLE_ACCESS_LIST_REQUEST,
  UPDATE_USER_STATUS_REQUEST,
  UPDATE_USER_STATUS_SUCCESS,
  UPDATE_USER_STATUS_FAIL,
  IS_OPEN_NAV_BAR_REQUEST,
  IS_OPEN_NAV_BAR_SUCCESS,
  IS_OPEN_NAV_BAR_FAIL,
  IS_OPEN_NAV_LEADS_SUCCESS,
  IS_OPEN_NAV_LEADS_FAIL,
  IS_OPEN_NAV_LEADS_REQUEST,
  IS_OPEN_NAV_USER_SUCCESS,
  IS_OPEN_NAV_USER_FAIL,
  IS_OPEN_NAV_USER_REQUEST,
  IS_OPEN_NAV_APPLIANCE_REQUEST,
  IS_OPEN_NAV_APPLIANCE_SUCCESS,
  IS_OPEN_NAV_APPLIANCE_FAIL,
  RESEND_ACTIVATION_REQUEST,
  RESEND_ACTIVATION_SUCCESS,
  RESEND_ACTIVATION_FAIL,
  GET_ALL_USERS_LIST_REQUEST,
  GET_ALL_USERS_LIST_SUCCESS,
  UPDATE_ACCOUNT_ME_SUCCESS,
  UPDATE_ACCOUNT_ME_REQUEST,
  UPDATE_ACCOUNT_ME_FAIL,
  CLEAR_ALL_TEST_DATA_REQUEST,
  CLEAR_ALL_TEST_DATA_SUCCESS,
  CLEAR_ALL_TEST_DATA_FAIL,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  GET_ACTIVATION_MAIL_REQUEST,
  GET_ACTIVATION_MAIL_SUCCESS,
  GET_ACTIVATION_MAIL_FAIL,
  EXPORT_USERS_REQUEST,
  EXPORT_USERS_FAIL,
  GET_TECHNICS_REQUEST,
  GET_TECHNICS_SUCCESS,
  GET_TECHNICS_FAIL,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_FAIL,
  FORGOT_PASSWORD_SUCCESS,
  SWITCH_AUTO_SAVE_REQUEST,
  SWITCH_AUTO_SAVE_SUCCESS,
  SWITCH_AUTO_SAVE_FAIL,
  DELETE_SELECTED_USERS_REQUEST,
  DELETE_SELECTED_USERS_SUCCESS,
  DELETE_SELECTED_USERS_FAIL,
  GET_SINGLE_USER_REQUEST,
  GET_SINGLE_USER_SUCCESS,
  GET_SINGLE_USER_FAIL,
  EXPORT_USERS_SUCCESS,
  GET_ALL_SUPPLIERS_FAIL,
  GET_ALL_SUPPLIERS_SUCCESS,
  GET_ALL_SUPPLIERS_REQUEST,
  GET_ALL_DRIVERS_FAIL,
  GET_ALL_DRIVERS_REQUEST,
  GET_ALL_DRIVERS_SUCCESS,
  GET_MY_ACCOUNT_REQUEST,
  GET_MY_ACCOUNT_SUCCESS,
  GET_MY_ACCOUNT_FAIL,
  UPDATE_MY_ACCOUNT_REQUEST,
  UPDATE_MY_ACCOUNT_SUCCESS,
  UPDATE_MY_ACCOUNT_FAIL,
  GET_ALL_SALES_AGENT_REQUEST,
  GET_ALL_SALES_AGENT_SUCCESS,
  GET_ALL_SALES_AGENT_FAIL,
  AUTH_USER_REQUEST,
  EXCHANGE_CODE_REQUEST,
} from '../actions/users';
import Account from '../../services/Account';
import 'react-toastify/dist/ReactToastify.css';
import { saveAs } from 'file-saver';
import Users from '../../api/users';
import Drivers from '../../api/drivers';

export default function* watcher() {
  yield takeLatest(ADD_USER_REQUEST, handleAddUser);
  yield takeLatest(LOGIN_USER_REQUEST, handleLoginUser);
  yield takeLatest(UPDATE_USER_REQUEST, handleUpdateUser);
  yield takeLatest(UPDATE_USER_STATUS_REQUEST, handleUpdateUserStatus);
  yield takeLatest(DELETE_USER_REQUEST, handleDeleteUser);
  yield takeLatest(ACTIVATE_USER_ACCOUNT_REQUEST, handleActivateAccountUser);
  yield takeLatest(GET_ACCESS_LIST_REQUEST, handleAccessLists);
  yield takeLatest(GET_USERS_LIST_REQUEST, handleUsersRequest);
  yield takeLatest(GET_ALL_USERS_LIST_REQUEST, handleAllUserList);
  yield takeLatest(MY_ACCOUNT_REQUEST, handleMyAccount);
  yield takeLatest(GET_USER_ACCOUNT_REQUEST, handleUserAccountRequest);
  yield takeLatest(GET_SINGLE_ACCESS_LIST_REQUEST, handleSingleAccessList);
  yield takeLatest(IS_OPEN_NAV_USER_REQUEST, handleIsOpenNavUsers);
  yield takeLatest(IS_OPEN_NAV_BAR_REQUEST, handleIsOpenNavAccount);
  yield takeLatest(IS_OPEN_NAV_LEADS_REQUEST, handleIsOpenNavLeads);
  yield takeLatest(IS_OPEN_NAV_APPLIANCE_REQUEST, handleIsOpenNavAppliance);
  yield takeLatest(RESEND_ACTIVATION_REQUEST, handleResendActivation);
  yield takeLatest(UPDATE_ACCOUNT_ME_REQUEST, handleUpdateMyAccount);
  yield takeLatest(CLEAR_ALL_TEST_DATA_REQUEST, handleClearTestData);
  yield takeLatest(CHANGE_PASSWORD_REQUEST, handleChangePassword);
  yield takeLatest(GET_ACTIVATION_MAIL_REQUEST, handleGetActiveEmail);
  yield takeLatest(EXPORT_USERS_REQUEST, handleExportExcel);
  yield takeLatest(GET_TECHNICS_REQUEST, handleGetTechnics);
  yield takeLatest(FORGOT_PASSWORD_REQUEST, handleForgotPass);
  yield takeLatest(SWITCH_AUTO_SAVE_REQUEST, handleSwitchAutoSave);
  yield takeLatest(DELETE_SELECTED_USERS_REQUEST, deleteSelectedUsers);
  yield takeLatest(GET_SINGLE_USER_REQUEST, getSingleUser);
  yield takeLatest(GET_ALL_SUPPLIERS_REQUEST, getAllSuppliers);
  yield takeLatest(GET_ALL_DRIVERS_REQUEST, handleGetAllDrivers);
  yield takeLatest(GET_ALL_SALES_AGENT_REQUEST, handleGetSalesAgent);
  yield takeLatest(GET_MY_ACCOUNT_REQUEST, handleGetMyAccount);
  yield takeLatest(UPDATE_MY_ACCOUNT_REQUEST, handleUpdateMyProfile);
  yield takeLatest(AUTH_USER_REQUEST, handleAuthUser);
  yield takeLatest(EXCHANGE_CODE_REQUEST, handleExchange);
}

function* handleAddUser(action) {
  try {
    const { data } = yield call(Users.add, action.payload.formData);

    yield put({
      type: ADD_USER_SUCCESS,
      payload: data,
    });

    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: ADD_USER_FAIL,
    });
    if (action.payload.cb) {
      action.payload.cb(e?.response?.data?.message, null);
    }
  }
}

function* handleLoginUser(action) {
  try {
    const { remember } = action.payload.formData;

    const { data = {} } = yield call(Users.login, action.payload.formData);
    // localStorage.setItem(data.user.access)
    yield put({
      type: LOGIN_USER_SUCCESS,
      payload: data,
    });

    Account.setToken(data?.token, remember);
    Account.set(data?.user);

    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    if (action.payload.cb) {
      action.payload.cb(e.response?.data?.message, null);
    }
    // console.warn(e)
    yield put({
      type: LOGIN_USER_FAIL,
    });
  }
}

function* handleUpdateUser(action) {
  try {
    const { data } = yield call(Users.update, action.payload.formData);

    yield put({
      type: UPDATE_USER_SUCCESS,
      payload: data,
    });

    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    console.warn(e.response);
    yield put({
      type: UPDATE_USER_FAIL,
      message: e.message,
    });
    if (action.payload.cb) {
      action.payload.cb(e.response?.data?.message, null);
    }
  }
}

function* handleUpdateUserStatus(action) {
  try {
    const { stat, userId } = action.payload;

    const { data } = yield call(Users.updateStatus, stat, userId);
    yield put({
      type: UPDATE_USER_STATUS_SUCCESS,
      payload: data,
    });

    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    console.warn(e.response);
    yield put({
      type: UPDATE_USER_STATUS_FAIL,
      message: e.message,
      payload: e.response.data,
    });
    if (action.payload.cb) {
      action.payload.cb(e.response.data, null);
    }
  }
}

function* handleDeleteUser(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(Users.delete, id);

    yield put({
      type: DELETE_USER_SUCCESS,
      payload: data,
    });

    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    console.warn(e.response);
    yield put({
      type: DELETE_USER_FAIL,
      message: e.message,
      payload: e.response.data,
    });
    if (action.payload.cb) {
      action.payload.cb(e?.response?.data?.message, null);
    }
  }
}

function* handleActivateAccountUser(action) {
  try {
    const { data } = yield call(Users.activateAccount, {
      ...action.payload.formData,
    });

    yield put({
      type: ACTIVATE_USER_ACCOUNT_SUCCESS,
      payload: data,
    });

    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    console.warn(e.response);
    yield put({
      type: ACTIVATE_USER_ACCOUNT_FAIL,
      message: e.message,
      payload: e.response.data,
    });
    if (action.payload.cb) {
      action.payload.cb(e.response.data, null);
    }
  }
}

function* handleAccessLists(action) {
  try {
    const { data } = yield call(Users.getAccessLists);

    yield put({
      type: GET_ACCESS_LIST_SUCCESS,
      payload: data,
    });

    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    console.warn(e.response);
    yield put({
      type: GET_ACCESS_LIST_FAIL,
      message: e.message,
      payload: e.response.data,
    });
    if (action.payload.cb) {
      action.payload.cb(e.response.data, null);
    }
  }
}

function* handleSingleAccessList(action) {
  try {
    const { data } = yield call(Users.getSingleAccessList);

    yield put({
      type: GET_SINGLE_ACCESS_LIST_SUCCESS,
      payload: data.accessList.roles_id.accessList,
    });

    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    console.warn(e.response);
    yield put({
      type: GET_SINGLE_ACCESS_LIST_FAIL,
      message: e.message,
      payload: e.response?.data,
    });
    if (action.payload.cb) {
      action.payload.cb(e.response?.data, null);
    }
  }
}

function* handleUsersRequest(action) {
  try {
    const { page, limit, sort, search, filter } = action.payload?.searchConfig;
    const { data } = yield call(
      Users.getUsersList,
      page,
      limit,
      search,
      filter,
      sort,
    );
    yield put({
      type: GET_USERS_LIST_SUCCESS,
      payload: data,
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    console.warn(e.response);
    yield put({
      type: GET_USERS_LIST_FAIL,
      message: e.message,
    });
  }
}

function* handleAllUserList(action) {
  try {
    const { data } = yield call(Users.getAllUsersList);
    yield put({
      type: GET_ALL_USERS_LIST_SUCCESS,
      payload: {
        data: data.users,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: GET_USERS_LIST_FAIL,
      message: e.message,
    });
  }
}

function* handleUserAccountRequest(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(Users.getUserRequest, id);

    yield put({
      type: GET_USER_ACCOUNT_SUCCESS,
      payload: data,
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    console.warn(e);
    yield put({
      type: GET_USER_ACCOUNT_FAIL,
      message: e.message,
      payload: e.response.data,
    });
    if (action.payload.cb) {
      action.payload.cb(e.response.data, null);
    }
  }
}

function* handleMyAccount(action) {
  try {
    const { data } = yield call(Users.myAccount);
    yield put({
      type: MY_ACCOUNT_SUCCESS,
      payload: {
        data: data.result,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    console.warn(e.response);
    yield put({
      type: MY_ACCOUNT_FAIL,
      message: e.message,
      payload: e.response.data,
    });
    if (action.payload.cb) {
      action.payload.cb(e.response.data, null);
    }
  }
}

function* handleIsOpenNavUsers(action) {
  try {
    const { bool } = action.payload;
    yield put({
      type: IS_OPEN_NAV_USER_SUCCESS,
      payload: bool,
    });
  } catch (e) {
    console.warn(e.response);
    yield put({
      type: IS_OPEN_NAV_USER_FAIL,
      message: e.message,
      payload: e.response.data,
    });
  }
}

function* handleIsOpenNavAccount(action) {
  try {
    const { bool } = action.payload;
    yield put({
      type: IS_OPEN_NAV_BAR_SUCCESS,
      payload: bool,
    });
  } catch (e) {
    console.warn(e.response);
    yield put({
      type: IS_OPEN_NAV_BAR_FAIL,
      message: e.message,
      payload: e.response.data,
    });
  }
}

function* handleIsOpenNavLeads(action) {
  try {
    const { bool } = action.payload;
    yield put({
      type: IS_OPEN_NAV_LEADS_SUCCESS,
      payload: bool,
    });
  } catch (e) {
    console.warn(e.response);
    yield put({
      type: IS_OPEN_NAV_LEADS_FAIL,
      message: e.message,
      payload: e.response.data,
    });
  }
}

function* handleIsOpenNavAppliance(action) {
  try {
    const { bool } = action.payload;
    yield put({
      type: IS_OPEN_NAV_APPLIANCE_SUCCESS,
      payload: bool,
    });
  } catch (e) {
    console.warn(e.response);
    yield put({
      type: IS_OPEN_NAV_APPLIANCE_FAIL,
      message: e.message,
      payload: e.response.data,
    });
  }
}

function* handleResendActivation(action) {
  const { id } = action.payload;
  try {
    const { data } = yield call(Users.resendActivation, id);

    yield put({
      type: RESEND_ACTIVATION_SUCCESS,
      payload: data,
    });

    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    console.warn(e.response);
    yield put({
      type: RESEND_ACTIVATION_FAIL,
      message: e.message,
      payload: e.response.data,
    });
    if (action.payload.cb) {
      action.payload.cb(e.response.data, null);
    }
  }
}

function* handleUpdateMyAccount(action) {
  try {
    const { formData } = action.payload;
    const { data } = yield call(Users.getUpdateAccountMe, formData);

    yield put({
      type: UPDATE_ACCOUNT_ME_SUCCESS,
      payload: {
        data,
      },
    });

    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    // console.warn(e.response);
    yield put({
      type: UPDATE_ACCOUNT_ME_FAIL,
      message: e.message,
      payload: e.response,
    });
    if (action.payload.cb) {
      action.payload.cb(e.response, null);
    }
  }
}

function* handleClearTestData(action) {
  try {
    const { data } = yield call(Users.clearTestData);

    yield put({
      type: CLEAR_ALL_TEST_DATA_SUCCESS,
      payload: {
        data,
      },
    });

    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    // console.warn(e.response);
    yield put({
      type: CLEAR_ALL_TEST_DATA_FAIL,
      message: e.message,
      payload: e.response,
    });
    if (action.payload.cb) {
      action.payload.cb(e.message, null);
    }
  }
}

function* handleChangePassword(action) {
  try {
    const { formData } = action.payload;
    const { data } = yield call(Users.changePassword, formData);

    yield put({
      type: CHANGE_PASSWORD_SUCCESS,
      payload: {
        data,
      },
    });

    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    // console.warn(e.response);
    yield put({
      type: CHANGE_PASSWORD_FAIL,
      message: e.message,
      payload: e.response,
    });
    if (action.payload.cb) {
      action.payload.cb(e.response.data, null);
    }
  }
}

function* handleGetActiveEmail(action) {
  try {
    const { mailHash } = action.payload;
    const { data } = yield call(Users.getActivationEmail, mailHash);
    yield put({
      type: GET_ACTIVATION_MAIL_SUCCESS,
      payload: {
        data,
      },
    });

    // if (action.payload.cb) {
    //   action.payload.cb(null, data);
    // }
  } catch (e) {
    yield put({
      type: GET_ACTIVATION_MAIL_FAIL,
      message: e.message,
      payload: e.response.data,
    });
    if (action.payload.cb) {
      action.payload.cb(e.message, null);
    }
  }
}

function* handleExportExcel(action) {
  try {
    const { page, sort, search, filter } = action.payload?.searchConfig;
    const { data } = yield call(
      Users.handleExportUserExcel,
      page,
      search,
      filter,
      sort,
    );
    yield put({
      type: EXPORT_USERS_SUCCESS,
    });
    const blob = new Blob([data], {
      type: 'application/vnd.ms-excel',
    });
    saveAs(blob, `Users.xlsx`);
  } catch (e) {
    console.log(e);
    yield put({
      type: EXPORT_USERS_FAIL,
      message: e.message,
    });
    if (action.payload.cb) {
      action.payload.cb(e.message, null);
    }
  }
}

function* handleGetTechnics(action) {
  try {
    const { data } = yield call(Users.getAllTechnics);
    yield put({
      type: GET_TECHNICS_SUCCESS,
      payload: {
        data: data.technics,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: GET_TECHNICS_FAIL,
      message: e.message,
    });
    if (action.payload.cb) {
      action.payload.cb(e.message, null);
    }
  }
}

function* handleForgotPass(action) {
  try {
    const { formData } = action.payload;
    const { data } = yield call(Users.forgotPass, formData);
    yield put({
      type: FORGOT_PASSWORD_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: FORGOT_PASSWORD_FAIL,
      message: e.message,
    });
    if (action.payload.cb) {
      action.payload.cb(e.message, null);
    }
  }
}

function* handleSwitchAutoSave(action) {
  try {
    const { data } = yield call(Users.switchAutoSave, action.payload.autoSave);

    yield put({
      type: SWITCH_AUTO_SAVE_SUCCESS,
      payload: data,
    });

    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    console.warn(e.response);
    yield put({
      type: SWITCH_AUTO_SAVE_FAIL,
      message: e.message,
      payload: e.response.data,
    });
    if (action.payload.cb) {
      action.payload.cb(e.response.data, null);
    }
  }
}
function* deleteSelectedUsers(action) {
  try {
    const { selectedRows } = action.payload;
    const { data } = yield call(Users.deleteSelectedUsers, selectedRows);
    yield put({
      type: DELETE_SELECTED_USERS_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: DELETE_SELECTED_USERS_FAIL,
      payload: {
        massage: e.massage,
      },
    });
  }
}

function* getSingleUser(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(Users.singleUser, id);
    yield put({
      type: GET_SINGLE_USER_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: GET_SINGLE_USER_FAIL,
      payload: {
        massage: e.massage,
      },
    });
  }
}

function* getAllSuppliers(action) {
  try {
    const { data } = yield call(Users.getAllSuppliersList);
    yield put({
      type: GET_ALL_SUPPLIERS_SUCCESS,
      payload: {
        data: data.result,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: GET_ALL_SUPPLIERS_FAIL,
      payload: {
        massage: e.massage,
      },
    });
  }
}

function* handleGetAllDrivers(action) {
  try {
    const { data } = yield call(Users.getAllDrivers);
    yield put({
      type: GET_ALL_DRIVERS_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: GET_ALL_DRIVERS_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}

function* handleGetSalesAgent(action) {
  try {
    const { data } = yield call(Users.getAllSalesAgents);
    yield put({
      type: GET_ALL_SALES_AGENT_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: GET_ALL_SALES_AGENT_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}

function* handleGetMyAccount(action) {
  try {
    const { data } = yield call(Users.getMyProfileData);
    yield put({
      type: GET_MY_ACCOUNT_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: GET_MY_ACCOUNT_FAIL,
      payload: {
        message: e.message,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}

function* handleUpdateMyProfile(action) {
  try {
    const { formData } = action.payload;
    const { data } = yield call(Users.updateMyAccount, formData);
    yield put({
      type: GET_MY_ACCOUNT_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: GET_MY_ACCOUNT_FAIL,
      payload: {
        message: e.message,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e?.response?.data, null);
    }
  }
}

function* handleAuthUser(action) {
  try {
    const { query } = action.payload;
    const { data } = yield call(Users.authUserBokun, query);

    // Perform the redirection on the client side
    if (data.authUrl) {
      window.location.href = data.authUrl;
    }

    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    if (action.payload.cb) {
      action.payload.cb(e?.response?.data, null);
    }
  }
}

function* handleExchange(action) {
  try {
    const { query } = action.payload;
    const { data } = yield call(Users.authUserExchange, query);

    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    if (action.payload.cb) {
      action.payload.cb(e?.response?.data, null);
    }
  }
}
