import md5 from 'md5';
import { api } from './Api';
import axios from 'axios';
import Account from '../services/Account';
const { REACT_APP_API_URL } = process.env;
export default class Users {
  //User
  static login(data = {}) {
    const clonedData = { ...data };
    clonedData.password = md5(clonedData.password);
    return api.post('/users/login', { ...clonedData });
  }

  static add(data = {}) {
    const clonedData = { ...data };
    if (clonedData.password) {
      clonedData.password = md5(clonedData.password);
    }
    const fd = new FormData();
    fd.append('supplier_logo_file', clonedData.supplier_logo_file);
    fd.append('formData', JSON.stringify(clonedData));
    return axios.post(`${REACT_APP_API_URL}/users`, fd, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: '*/*',
        Authorization: Account.getToken() && `Bearer ${Account.getToken()}`,
      },
    });
  }

  static update(data = {}) {
    const clonedData = { ...data };
    if (clonedData.password) {
      clonedData.password = md5(clonedData.password);
    }
    const fd = new FormData();
    fd.append('supplier_logo_file', clonedData.supplier_logo_file);
    fd.append('formData', JSON.stringify(clonedData));
    return axios.put(`${REACT_APP_API_URL}/users/${data.id}`, fd, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: '*/*',
        Authorization: Account.getToken() && `Bearer ${Account.getToken()}`,
      },
    });
  }

  static updateStatus(stat, id) {
    return api.put(`users/status/${id}`, { status: stat, id });
  }

  static delete(id) {
    return api.delete(`/users/${id}`);
  }

  static activateAccount(data = {}) {
    return api.patch('users/activate', { ...data });
  }

  static myAccount() {
    return api.get('/users/account/me');
  }

  static getAllSuppliersList() {
    return api.get(`/users/suppliers/all`);
  }
  static getAllDrivers() {
    return api.get(`/users/drivers/all`);
  }
  static getAllSalesAgents() {
    return api.get(`/users/sales_agents/all`);
  }

  static getUsersList(
    page = 1,
    limit,
    search,
    filter,
    [{ sort = 'desc', field = 'updated_at' }],
  ) {
    return api.get(`/users/`, {
      params: {
        search,
        filter,
        page,
        limit,
        sort,
        field,
      },
    });
  }

  static getAllUsersList() {
    return api.get(`users/list/all`);
  }

  static getUserRequest(userId) {
    return api.get(`users/account/${userId}`);
  }

  static getU(userId) {
    return api.get(`users/account/${userId}`);
  }

  static handleExportUserExcel(
    page,
    search,
    filter,
    [{ sort, field}],
  ) {
    return api({
      url: `/users/excel`,
      params: {
        search,
        filter,
        page,
        sort,
        field,
      },
      method: 'GET',
      responseType: 'blob',
    });
  }

  static getAllTechnics() {
    return api.get('/users/technicians');
  }

  static forgotPass(formData = {}) {
    return api.post('/users/forgotPassword', { ...formData });
  }
  //Access Lists
  static getAccessLists() {
    return api.get('/users/access/list');
  }

  static getSingleAccessList() {
    return api.get('/users/single/access');
  }

  static singleUser(id) {
    return api.get(`/users/single/${id}`);
  }

  static getUpdateAccountMe(formData) {
    return api.put('/users/update-me', { ...formData });
  }

  //Resend Activation
  static resendActivation(id) {
    return api.post('/users/activate/resend', {
      id,
    });
  }

  static changePassword(data) {
    return api.post('/users/changePassword', { ...data });
  }

  static getActivationEmail(mailHash) {
    return api.post(`users/activation/email`, {
      mailHash,
    });
  }
  // Clear test data
  static clearTestData() {
    return api.delete(`/clear/all`);
  }

  static switchAutoSave(autoSave) {
    return api.put('/users/auto_save', { ...autoSave });
  }

  static deleteSelectedUsers(selectedRows) {
    return api.post(`/users/delete_selected_users`, {
      selectedRows,
    });
  }
  static getMyProfileData() {
    return api.get('/users/my-profile');
  }
  static updateMyAccount(data = {}) {
    const clonedData = { ...data };
    if (clonedData.old_password) {
      clonedData.old_password = md5(clonedData.old_password);
    }
    if (clonedData.password) {
      clonedData.password = md5(clonedData.password);
    }
    const fd = new FormData();
    fd.append('supplier_logo_file', clonedData.supplier_logo_file);
    fd.append('formData', JSON.stringify(clonedData));
    return axios.put(`${REACT_APP_API_URL}/users/profile/${data.id}`, fd, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: '*/*',
        Authorization: Account.getToken() && `Bearer ${Account.getToken()}`,
      },
    });
  }

  static authUserBokun(query) {
    return api.post('/users/auth', query);
  }

  static authUserExchange(query) {
    return api.post('/users/exchange', query);
  }
}
