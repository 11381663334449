import React, { useEffect } from 'react';
import { Button, Col, Form, Modal, Row, InputGroup } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { userValidation } from './validationSchema';
import { handleUpdateMyProfile } from '../../../store/actions/users';
import { LoadingButton } from '@mui/lab';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import PhoneInput from 'react-phone-input-2';
import { Riple } from 'react-loading-indicators';

function UsersForm({ user, actionStatus , getMyAccountData}) {
  const dispatch = useDispatch();
  const [pass, setPass] = React.useState({
    old: true,
    new: true,
    new_confirm: true,
  });
  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(userValidation),
    defaultValues: { ...user } || {},
  });
  const handlePassVis = key => {
    if (pass[key] === true) {
      setPass({ ...pass, [key]: false });
    } else {
      setPass({ ...pass, [key]: true });
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setError,
    setValue,
  } = methods;

  useEffect(() => {
    if (user) {
      reset(user);
    }
  }, [user, reset]);

  const onEdit = data => {
    if (data.password && data.confirm_password && !data.old_password) {
      setError('old_password', { type: 'custom', message: 'Required Field' });
      return;
    }
    dispatch(
      handleUpdateMyProfile(data, (err, data) => {
        if (err) {
          toast.error(err.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 10000,
          });
        } else {
          if (data && data?.status === 200) {
            toast.success('Successfully Updated', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 10000,
            });
            setValue('old_password', '');
            setValue('password', '');
            setValue('confirm_password', '');
          }
        }
      }),
    );
  };

  return (
    <>
    {actionStatus === 'pending' ? (
        <div className="dashboard-empty-div">
          <Riple color="#134074" size="large" text="" textColor="" />
        </div>
      ) : (
        <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onEdit)} noValidate>
          <Modal.Body>
            <div>
              <h5 style={{ fontSize: 17 }}>Personal Information</h5>
              <Row>
                <Col sm={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Full Name <span style={{ color: 'red' }}> *</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      {...register('name')}
                      isInvalid={errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="text"
                      {...register('email')}
                      isInvalid={errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>User Name</Form.Label>
                    <Form.Control
                      type="text"
                      {...register('user_name')}
                      isInvalid={errors.user_name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.user_name?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Phone Number</Form.Label>
                    <PhoneInput
                      inputProps={{
                        name: 'phone_number',
                      }}
                      value={watch('phone_number') || ''}
                      onChange={value => {
                        setValue('phone_number', value);
                      }}
                    />
                    {errors.phone_number?.message && (
                      <span className="invalid_message">
                        {errors.phone_number?.message}
                      </span>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            </div>
            <div>
              <h5 style={{ fontSize: 17 }}>Change Password</h5>
              <Row>
                <Col sm={4}>
                  <Form.Group className="mb-3 pass_input">
                    <Form.Group className="mb-3">
                      <Form.Label>Current Password</Form.Label>
                      <InputGroup className="input_block">
                        <Form.Control
                          type={pass.old ? 'password' : 'text'}
                          autoComplete="off"
                          name="old_password"
                          value={watch('old_password') || ''}
                          {...register('old_password')}
                          className={errors?.old_password && 'is-invalid'}
                        />
                        <InputGroup.Text
                          id="basic-addon1"
                          onClick={() => handlePassVis('old')}
                        >
                          {pass.old ? (
                            <FiEyeOff cursor="pointer" />
                          ) : (
                            <FiEye cursor="pointer" />
                          )}
                        </InputGroup.Text>
                      </InputGroup>
                      <span className="custom-invalid-feedback">
                        {errors.old_password?.message}
                      </span>
                    </Form.Group>
                  </Form.Group>
                </Col>
                <Col sm={4}>
                  <Form.Group className="mb-3 pass_input">
                    <Form.Group className="mb-3">
                      <Form.Label>New Password</Form.Label>
                      <InputGroup className="input_block">
                        <Form.Control
                          type={pass.new ? 'password' : 'text'}
                          name="password"
                          className={errors?.password && 'is-invalid'}
                          autoComplete="off"
                          value={watch('password') || ''}
                          {...register('password')}
                        />
                        <InputGroup.Text
                          id="basic-addon1"
                          onClick={() => handlePassVis('new')}
                        >
                          {pass.new ? (
                            <FiEyeOff cursor="pointer" />
                          ) : (
                            <FiEye cursor="pointer" />
                          )}
                        </InputGroup.Text>
                      </InputGroup>
                      <span className="custom-invalid-feedback">
                        {errors.password?.message}
                      </span>
                    </Form.Group>
                  </Form.Group>
                </Col>
                <Col sm={4}>
                  <Form.Group className="mb-3 pass_input">
                    <Form.Group className="mb-3">
                      <Form.Label>Confirm Password</Form.Label>
                      <InputGroup className="input_block">
                        <Form.Control
                          type={pass.new_confirm ? 'password' : 'text'}
                          name="confirm_password"
                          className={errors?.confirm_password && 'is-invalid'}
                          autoComplete="off"
                          value={watch('confirm_password') || ''}
                          {...register('confirm_password')}
                        />
                        <InputGroup.Text
                          id="basic-addon1"
                          onClick={() => handlePassVis('new_confirm')}
                        >
                          {pass.new_confirm ? (
                            <FiEyeOff cursor="pointer" />
                          ) : (
                            <FiEye cursor="pointer" />
                          )}
                        </InputGroup.Text>
                      </InputGroup>
                      <span className="custom-invalid-feedback">
                        {errors.confirm_password?.message}
                      </span>
                    </Form.Group>
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="previewButtonsID">
              <LoadingButton
                size="small"
                className="login_submit_btn btn btn-primary "
                loadingPosition="center"
                variant="contained"
                loading={actionStatus === 'pending'}
                type={'submit'}
              >
                <span>{actionStatus === 'pending' ? '' : 'Save'}</span>
              </LoadingButton>
              <Button variant="secondary" onClick={() => getMyAccountData()}>
                Reset
              </Button>
            </div>
          </Modal.Footer>
        </Form>
      </FormProvider>
      )}
    </>
   
  );
}

export default UsersForm;
