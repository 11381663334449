import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { LoadingButton } from '@mui/lab';

function CommentModal({
  show,
  handleClose,
  confirm,
  title,
  note,
  actionStatus,
}) {
  const [hidden_note, setHidden_note] = useState(note);
  const [submitted, setSubmit] = useState('');
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    setHidden_note(note.trim());
  }, [note]);

  const handleSubmit = event => {
    event.preventDefault();
    confirm(event, hidden_note?.trim());
  };

  const close = () => {
    setHidden_note('');
    setSubmit('');
    setValidated(false);
    handleClose();
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={close}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="modal_header" closeButton>
        <Modal.Title className="modal_title">{title}</Modal.Title>
      </Modal.Header>
      <div className="form-block">
        <Modal.Body>
          <Form
            noValidate
            validated={validated}
            onSubmit={ev => handleSubmit(ev)}
          >
            <Form.Group>
              <Form.Label>Comment</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                maxLength={1500}
                // required={true}
                className="text_area_input"
                value={hidden_note}
                onChange={event => setHidden_note(event.target.value)}
                name="comment"
              />
              <Form.Control.Feedback type="invalid">
                Required Field
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
      </div>
      <Modal.Footer>
        <div className="previewButtons">
          <LoadingButton
            size="small"
            className="login_submit_btn btn btn-primary "
            loadingPosition="center"
            variant="contained"
            loading={actionStatus === 'pending'}
            disabled={actionStatus === 'pending'}
            onClick={handleSubmit}
          >
            <span>Confirm</span>
          </LoadingButton>
          <Button variant="secondary" onClick={close}>
            Cancel
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default CommentModal;
