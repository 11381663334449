import React, { useEffect, useState, useRef } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import {
  addTourAction,
  getAllToursAction,
  getSingleTour,
  getToursAction,
  updateTour,
} from '../../store/actions/tours';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Stack from '@mui/material/Stack';
import { createTheme, TextField, ThemeProvider } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs from 'dayjs';
import Select from 'react-select';
import { getAllPickUpPointsAction } from '../../store/actions/pickUpPoints';
import { blockPageAction, getAllSuppliers } from '../../store/actions/users';
import { getAllDirectionsAction } from '../../store/actions/directions';
import { LoadingButton } from '@mui/lab';

function ToursForm({ popupTitle, handleClose, singleTour, actionStatus }) {
  const searchConfig = useSelector(store => store.search);
  const blockedPage = useSelector(store => store.search.blockedPage);
  const allSuppliers = useSelector(store => store.users.allSupplierUsers);
  const allDirections = useSelector(store => store.directions.allDirections);
  const allPickUpPoints = useSelector(
    store => store.pickUpPoints.allPickUpPoints,
  );
  const [formValidation, setFormValidation] = useState({});
  const [formData, setFormData] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [validated, setValidated] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllPickUpPointsAction());
    dispatch(getAllSuppliers());
    dispatch(getAllDirectionsAction());
  }, []);

  useEffect(() => {
    if (!isEmpty(singleTour)) {
      setFormData(singleTour);
    }
  }, [singleTour]);

  useEffect(() => {
    if (id && typeof formData === typeof singleTour && !isEmpty(singleTour)) {
      if (
        JSON.stringify(formData) !== JSON.stringify(singleTour) &&
        !blockedPage
      ) {
        dispatch(blockPageAction('/user-management/users/41'));
      }
      if (
        JSON.stringify(formData) === JSON.stringify(singleTour) &&
        singleTour
      ) {
        dispatch(blockPageAction(''));
      }
    }
  }, [formData]);

  const handleChange = (key, val) => {
    setFormData({ ...formData, [key]: val });
    setFormValidation({ ...formValidation, [key]: val });
  };

  const handleEdit = params => {
    setFormValidation(!!formValidation);
    dispatch(
      getSingleTour(params, (err, data) => {
        if (data && data.result) {
          setFormData({ ...data.result });
        }
      }),
    );
  };

  const onSubmit = event => {
    event.preventDefault();
    setSubmitted(true);
    const form = event.currentTarget;

    if (form.checkValidity() === false || !formData.direction_id) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      setDisabled(true);
      dispatch(
        addTourAction(formData, (err, data) => {
          if (data) {
            toast.success('Successfully Created', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });
            setValidated(false);
            setFormData({});
            dispatch(getToursAction(searchConfig));
            setDisabled(false);
            handleClose();
            form.reset();
          } else if (err) {
            toast.error(err, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });
          }
          setDisabled(false);
        }),
      );
    }
  };

  const onEdit = ev => {
    ev.preventDefault();
    setSubmitted(true);
    const form = ev.currentTarget;
    if (form.checkValidity() === false || !formData.direction_id) {
      ev.preventDefault();
      ev.stopPropagation();
      setValidated(true);
    } else {
      setDisabled(true);
      setValidated(true);
      dispatch(
        updateTour(formData, (err, data) => {
          if (err) {
            toast.error(err, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });
            setValidated(false);
          } else {
            if (data) {
              toast.success('Successfully Updated', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 10000,
              });
              setDisabled(false);
              form.reset();
              navigate('/admin/tours');
            }
          }
          setDisabled(false);
        }),
      );
    }
  };

  const customStylesGreen = {
    control: styles => ({
      ...styles,
      borderColor: !!validated ? '#198754' : '#ced4da',
    }),
    option: styles => {
      return {
        ...styles,
        ':active': {
          ...styles[':active'],
          backgroundColor: `var(--primary_light)`,
        },
      };
    },
  };
  const customStyleGray = {
    control: styles => ({
      ...styles,
      borderColor: !!validated ? '' : '#ced4da',
    }),
    option: styles => {
      return {
        ...styles,
        ':active': {
          ...styles[':active'],
          backgroundColor: `var(--primary_light)`,
        },
      };
    },
  };
  const customRequiredStyle = {
    control: styles => ({
      ...styles,
      borderColor: !!validated ? '#dc3545' : '#ced4da',
    }),
    option: styles => {
      return {
        ...styles,
        ':active': {
          ...styles[':active'],
          backgroundColor: `var(--primary_light)`,
        },
      };
    },
  };

  const requiredTheme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: !!validated ? '#198754' : '#ced4da',
          },
        },
      },
    },
  });

  const theme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: !!validated ? '#ced4da' : '#ced4da',
          },
        },
      },
    },
  });

  return (
    <>
      <Form
        onSubmit={event => {
          if (id) {
            onEdit(event);
          } else {
            onSubmit(event);
          }
        }}
        noValidate
        validated={validated}
      >
        <Modal.Header closeButton={!id}>
          <Modal.Title>{!id && popupTitle + ' ' + 'Tour'}</Modal.Title>
        </Modal.Header>
        <div className="form-block">
          <Modal.Body>
            <Row>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Tour Name
                    <span style={{ color: 'red' }}> *</span>
                  </Form.Label>
                  <Form.Control
                    id="tour_name"
                    name="tour_name"
                    maxLength={254}
                    value={formData?.tour_name || ''}
                    required={true}
                    onChange={e => handleChange('tour_name', e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Required Field
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Driver Location Url</Form.Label>
                  <Form.Control
                    id="tour_driver_location_url"
                    name="driver_location_url"
                    value={formData?.driver_location_url || ''}
                    pattern="https?://.+"
                    onChange={e =>
                      handleChange('driver_location_url', e.target.value)
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formData.driver_location_url && 'Invalid format'}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6} lg={3}>
                <Form.Label className="label-m-1r"> Service Date </Form.Label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={3} id={'tour_service_date'}>
                    <ThemeProvider
                      theme={
                        !formData['service_date'] && submitted
                          ? requiredTheme
                          : theme
                      }
                    >
                      <DesktopDatePicker
                        name={'service_date'}
                        className="month_year_picker"
                        views={['year', 'month', 'day']}
                        value={
                          formData?.service_date &&
                          dayjs(formData?.service_date)
                        }
                        onChange={newValue => {
                          if (newValue) {
                            handleChange(
                              'service_date',
                              dayjs(newValue).format('YYYY-MM-DD'),
                            );
                          } else {
                            handleChange('service_date', null);
                          }
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            disabled={formData.send === 'true'}
                          />
                        )}
                      />
                    </ThemeProvider>
                  </Stack>
                </LocalizationProvider>
              </Col>
              <Col sm={6} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Time Start</Form.Label>
                  <Form.Control
                    id="tour_start_time"
                    name="start_time"
                    type="time"
                    value={formData.start_time || ''}
                    onChange={event =>
                      handleChange('start_time', event.target.value)
                    }
                  />
                </Form.Group>
              </Col>
              <Col sm={6} lg={3}>
                <Form.Label className="label-m-1r"> End Date </Form.Label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={3} id={'tour_end_date'}>
                    <ThemeProvider
                      theme={
                        !formData['end_date'] && submitted
                          ? requiredTheme
                          : theme
                      }
                    >
                      <DesktopDatePicker
                        name={'end_date'}
                        className="month_year_picker"
                        views={['year', 'month', 'day']}
                        minDate={
                          formData.service_date && dayjs(formData.service_date)
                        }
                        value={
                          (formData?.service_date &&
                            !formData?.end_date &&
                            dayjs(formData?.service_date)) ||
                          (formData?.end_date && dayjs(formData?.end_date))
                        }
                        onChange={newValue => {
                          if (newValue) {
                            handleChange(
                              'end_date',
                              dayjs(newValue).format('YYYY-MM-DD'),
                            );
                          } else {
                            handleChange('end_date', null);
                          }
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            disabled={formData.send === 'true'}
                          />
                        )}
                      />
                    </ThemeProvider>
                  </Stack>
                </LocalizationProvider>
              </Col>
              <Col sm={6} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Time Finish</Form.Label>
                  <Form.Control
                    id={'tour_end_time'}
                    name={'end_time'}
                    type="time"
                    value={formData.end_time || ''}
                    onChange={event =>
                      handleChange('end_time', event.target.value)
                    }
                    min={formData.start_time}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formData.end_time && 'Invalid time'}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group className={!submitted ? 'mb-3' : null}>
                  <Form.Label>Pick Up Time</Form.Label>
                  <Form.Control
                    id={'tour_pick_up_time'}
                    name={'pick_up_time'}
                    type="time"
                    value={formData.pick_up_time || ''}
                    onChange={event =>
                      handleChange('pick_up_time', event.target.value)
                    }
                    max={formData.end_time}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formData.end_time && 'Invalid time'}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Contact Info</Form.Label>
                  <Form.Control
                    id={'tour_contact_info'}
                    name={'contact_info'}
                    maxLength={254}
                    type="text"
                    value={formData.contact_info || ''}
                    onChange={event =>
                      handleChange('contact_info', event.target.value)
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6} lg={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Pick Up Name</Form.Label>
                  <Select
                    id={'tour_pick_up_id'}
                    name={'pick_up_id'}
                    value={
                      formData?.pick_up_id && Array.isArray(allPickUpPoints)
                        ? allPickUpPoints.find(
                            i => i.id === formData.pick_up_id,
                          )
                        : {}
                    }
                    styles={
                      ({
                        option: (styles, { isSelected }) => {
                          return {
                            ...styles,
                            ':active': {
                              ...styles[':active'],
                              backgroundColor: null,
                            },
                          };
                        },
                      },
                      !submitted && !formData.pick_up_id
                        ? customStyleGray
                        : customStylesGreen)
                    }
                    options={
                      Array.isArray(allPickUpPoints) ? allPickUpPoints : []
                    }
                    isClearable={true}
                    getOptionLabel={i => i.pick_up_name}
                    getOptionValue={i => i.id}
                    onChange={ev => {
                      if (ev) {
                        handleChange('pick_up_id', ev.id);
                      } else {
                        handleChange('pick_up_id', null);
                      }
                    }}
                  />
                </Form.Group>
              </Col>
              <Col sm={6} lg={4}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Directions
                    <span style={{ color: 'red' }}> *</span>
                  </Form.Label>
                  <Select
                    id={'tour_direction_name'}
                    name={'direction_id'}
                    value={
                      formData?.direction_id && Array.isArray(allDirections)
                        ? allDirections.find(
                            i => i.id === formData.direction_id,
                          )
                        : {}
                    }
                    styles={
                      ({
                        option: (styles, { isSelected }) => {
                          return {
                            ...styles,
                            ':active': {
                              ...styles[':active'],
                              backgroundColor: null,
                            },
                          };
                        },
                      },
                      !formData.direction_id
                        ? customRequiredStyle
                        : customStylesGreen)
                    }
                    required={true}
                    isClearable={true}
                    options={allDirections}
                    getOptionValue={i => i.id}
                    getOptionLabel={i => i.direction_name}
                    onChange={ev => {
                      if (ev) {
                        handleChange('direction_id', ev.id);
                      } else {
                        handleChange('direction_id', null);
                      }
                    }}
                  />
                  {(submitted || !!validated) && !formData.direction_id ? (
                    <small style={{ color: '#dc3545' }}>Required Field</small>
                  ) : null}
                </Form.Group>
              </Col>
              <Col sm={12} lg={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Suppliers</Form.Label>
                  <Select
                    id={'supplier_user_id'}
                    name={'supplier_user_id'}
                    value={
                      formData?.supplier_user_id && Array.isArray(allSuppliers)
                        ? allSuppliers.find(
                            i => i.id === formData.supplier_user_id,
                          )
                        : {}
                    }
                    styles={
                      ({
                        option: (styles, { isSelected }) => {
                          return {
                            ...styles,
                            ':active': {
                              ...styles[':active'],
                              backgroundColor: null,
                            },
                          };
                        },
                      },
                      !submitted && !formData.supplier_user_id
                        ? customStyleGray
                        : customStylesGreen)
                    }
                    isClearable={true}
                    options={allSuppliers}
                    getOptionValue={i => i.id}
                    getOptionLabel={i => i.name}
                    onChange={ev => {
                      if (ev) {
                        handleChange('supplier_user_id', ev.id);
                      } else {
                        handleChange('supplier_user_id', null);
                      }
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
        </div>
        <Modal.Footer>
          <div className={id ? 'previewButtonsID' : 'previewButtons'}>
            <LoadingButton
              size="small"
              className="login_submit_btn btn btn-primary "
              loadingPosition="center"
              variant="contained"
              loading={actionStatus === 'pending'}
              disabled={disabled}
              type="submit"
            >
              <span>{actionStatus === 'pending' ? '' : 'Save'}</span>
            </LoadingButton>
            {id ? (
              <Button variant="secondary" onClick={() => handleEdit(id)}>
                Reset
              </Button>
            ) : (
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Form>
    </>
  );
}

export default ToursForm;
