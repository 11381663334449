import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import React, { useEffect, useRef, useState } from 'react';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import 'react-datepicker/dist/react-datepicker.css';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { MdOutlineClear } from 'react-icons/md';
import PhoneInput from 'react-phone-input-2';
import { Checkbox } from '@mui/material';
import { FormControlLabel } from '@material-ui/core';
import UsersForm from './UsersForm';
import { yupResolver } from '@hookform/resolvers/yup';
import { supplierValidation } from './validationSchema';
import { registerRequest, updateUser } from '../../../store/actions/users';
import { isEqual } from 'lodash';
import { LoadingButton } from '@mui/lab';

function SuppliersForm({
  popupTitle,
  handleClose,
  single,
  getUsersList,
  add_new_modal,
  actionStatus,
}) {
  let searchConfig = useSelector(store => store.search);
  const blockedPage = useSelector(store => store.search.blockedPage);
  const [disabled, setDisabled] = useState(false);
  const { id } = useParams();
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors, isValid, isDirty },
    setError,
    clearErrors,
    reset,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(supplierValidation),
    defaultValues: id
      ? { ...single, add_user: !!single?.user_name }
      : { add_user: false, role: 'Supplier' },
  });

  useEffect(() => {
    if (single) {
      for (const [key, value] of Object.entries(single)) {
        setValue(key, value);
      }
      if (single.user_name) {
        setValue('add_user', true);
        setValue('new_password', true);
      }
    }
  }, [single, setValue]);

  const handlePhotoChange = (key, val) => {
    if (val) {
      setValue(key, val.name);
      setValue(`${key}_file`, val);
    }
  };

  const handleRemoveImg = () => {
    setValue('supplier_logo', null);
    setValue('supplier_logo_file', null);
  };

  const onSubmit = data => {
    dispatch(
      registerRequest(data, (err, data) => {
        if (err) {
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 10000,
          });
        } else {
          toast.success('Successfully Created', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 10000,
          });
          getUsersList(searchConfig);
          handleClose();
        }
      }),
    );

    setTimeout(() => setDisabled(false), 1500);
  };

  const onEdit = data => {
    dispatch(
      updateUser(data, (err, data) => {
        if (err) {
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 10000,
          });
        } else {
          if (data && data?.status === 200) {
            toast.success('Successfully Updated', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 10000,
            });
            navigate('/admin/users');
          }
        }
      }),
    );
  };
  const formData = watch();
  return (
    <>
      <FormProvider
        {...{
          control,
          register,
          handleSubmit,
          setValue,
          watch,
          formState: { errors, isValid },
        }}
      >
        <Form onSubmit={handleSubmit(id ? onEdit : onSubmit)} noValidate>
          {!id && (
            <Modal.Header closeButton={!id}>
              <Modal.Title>Add New Supplier</Modal.Title>
            </Modal.Header>
          )}
          <div className="form-block">
            <Modal.Body>
              <Row>
                <Col sm={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Full Name<span style={{ color: 'red' }}> *</span>
                    </Form.Label>
                    <Form.Control
                      id="supplier_name"
                      name="name"
                      type="text"
                      minLength={2}
                      maxLength={254}
                      {...register('name', { required: true })}
                      isInvalid={errors?.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      Required Field
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Supplier Logo</Form.Label>
                    <div className="file-input file-upload">
                      <label htmlFor="supplier_logo">
                        <input
                          className="choose-btn"
                          type="button"
                          value="Choose"
                          onClick={() => fileInputRef.current.click()}
                        />
                      </label>
                      <input
                        className="border-none"
                        type="text"
                        value={formData.supplier_logo || ''}
                        readOnly
                      />
                      <input
                        type="file"
                        id="supplier_logo"
                        accept="image/*"
                        ref={fileInputRef}
                        onChange={e =>
                          handlePhotoChange('supplier_logo', e.target.files[0])
                        }
                      />
                      {formData.supplier_logo && (
                        <MdOutlineClear
                          className="remove_img"
                          onClick={handleRemoveImg}
                        />
                      )}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <Form.Label>
                    Email<span style={{ color: 'red' }}>*</span>
                  </Form.Label>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        id="supplier_email"
                        type="email"
                        {...field}
                        isInvalid={errors.email}
                      />
                    )}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email?.message}
                  </Form.Control.Feedback>
                </Col>
                <Col sm={6}>
                  <Form.Label>
                    Phone Number<span style={{ color: 'red' }}>*</span>
                  </Form.Label>
                  <PhoneInput
                    inputProps={{
                      id: 'supplier_phone_number',
                      name: 'phone_number',
                    }}
                    value={watch('phone_number') || ''}
                    onChange={value => {
                      setValue('phone_number', value);
                      clearErrors('phone_number');
                    }}
                  />
                  {errors.phone_number?.message && (
                    <span className="invalid_message">
                      {errors.phone_number?.message}
                    </span>
                  )}
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    id="supplier_description"
                    name="description"
                    as="textarea"
                    rows={5}
                    {...register('description')}
                    maxLength={4000}
                    value={watch('description') || ''}
                  />
                  <div className="max_style">
                    Max length of description is 4000 characters
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={3}>
                  {id && !!single?.user_name ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={true}
                          defaultChecked={true}
                          checked={true}
                        />
                      }
                      label="Create Account ?"
                    />
                  ) : (
                    <Controller
                      name="add_user"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              id="supplier_create_account"
                              {...field}
                              disabled={id && single?.user_name}
                              defaultChecked={id && single?.user_name}
                              checked={
                                id && !!single?.user_name
                                  ? true
                                  : watch('add_user')
                              }
                              onChange={ev => {
                                setValue('add_user', ev.target.checked);
                              }}
                            />
                          }
                          label="Create Account ?"
                        />
                      )}
                    />
                  )}
                </Col>
              </Row>
              {watch('add_user') || (!add_new_modal && single?.user_name) ? (
                <UsersForm
                  setValue={setValue}
                  control={control}
                  singleUser={single}
                />
              ) : null}
            </Modal.Body>
          </div>
          <Modal.Footer>
            {id ? (
              <div className="previewButtonsID">
                <LoadingButton
                  size="small"
                  className="login_submit_btn btn btn-primary "
                  loadingPosition="center"
                  variant="contained"
                  loading={actionStatus === 'pending'}
                  type={'submit'}
                >
                  <span>{actionStatus === 'pending' ? '' : 'Save'}</span>
                </LoadingButton>
                <Button
                  variant="secondary"
                  onClick={() => reset({ ...single })}
                >
                  Reset
                </Button>
              </div>
            ) : (
              <>
                <div className="previewButtons">
                  <LoadingButton
                    size="small"
                    className="login_submit_btn btn btn-primary "
                    loadingPosition="center"
                    variant="contained"
                    loading={actionStatus === 'pending'}
                    type={'submit'}
                  >
                    <span>{actionStatus === 'pending' ? '' : 'Save'}</span>
                  </LoadingButton>
                  <Button variant="secondary" onClick={handleClose}>
                    Cancel
                  </Button>
                </div>
              </>
            )}
          </Modal.Footer>
        </Form>
      </FormProvider>
    </>
  );
}

export default SuppliersForm;
