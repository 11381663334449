import _, {isEmpty} from 'lodash';
import dayjs from "dayjs";

export function customStyleGray(validated) {
    return {
        control: styles => ({
            ...styles,
            borderColor: !!validated ? '' : '#ced4da',
        }),
        option: styles => {
            return {
                ...styles,
                ':active': {
                    ...styles[':active'],
                    backgroundColor: `var(--primary_light)`,
                },
            };
        },
    };
}

export function customRequiredStyle(validated) {
    return {
        control: styles => ({
            ...styles,
            borderColor: !!validated ? '#dc3545' : '#ced4da',
        }),
        option: styles => {
            return {
                ...styles,
                ':active': {
                    ...styles[':active'],
                    backgroundColor: `var(--primary_light)`,
                },
            };
        },
    };
}

export function directionCutter(bokun_info) {
    if (bokun_info) {
        const keyValuePairs = bokun_info.split(';');

        const directionPair = _.find(keyValuePairs, pair =>
            _.startsWith(pair, 'Direction'),
        );

        return _.last(directionPair.split('-')).trim();
    } else {
        return '';
    }
}

export function findVouchersBokunDifference(voucherHistory) {
    let result = [];
    if (!isEmpty(voucherHistory)) {
        const findInKeys = [
            'reference_number',
            'partner_ref_number',
            'passenger_name',
            'passenger_phone',
            'accommodation',
            'adult_members',
            'child_members',
            'infant_members',
            'booking_direction',
            'service_date',
            'state',
            'status',
            'sales_agent_name',
            'tour_name',
            'direction_name',
            'service_date',
            'nationality',
            'cancellation_reason',
        ];
        // voucherHistory.map((voucher, index) => {
        //     let updatedData = voucher || {};
        //     let resultStr = ''
        //     if (voucherHistory[index] && voucherHistory[index + 1]) {
        //         findInKeys.forEach(key => {
        //             if (voucherHistory[index + 1][key] !== updatedData[key]) {
        //                 resultStr =
        //                     `Updated Date: ${dayjs(voucher?.updated_at).format('YYYY-MM-DD HH:mm:ss')},\n
        //                     ${key === 'state'
        //                         ? 'Status'
        //                         : key.replaceAll('_', ' ').replace(/\b\w/g, char => char.toUpperCase())}: ${updatedData[key]} =>
        //             ${voucherHistory[index + 1][key]},\n` + resultStr;
        //             }
        //         });
        //         result.push(resultStr)
        //     }
        // })

        voucherHistory.map((voucher, index) => {
            let updatedData = voucher || {};
            let changes = [];

            if (voucherHistory[index] && voucherHistory[index + 1]) {
                let serviceDateProcessed = false;
                findInKeys.forEach(key => {
                    let oldValue = updatedData[key];
                    let newValue = voucherHistory[index + 1][key];
                    if(key === 'service_date') {
                        oldValue = dayjs(oldValue).isValid() ? dayjs(oldValue).format('MM/DD/YYYY') : oldValue;
                        newValue = dayjs(newValue).isValid() ? dayjs(newValue).format('MM/DD/YYYY') : newValue;
                        if(serviceDateProcessed) {
                            return
                        }
                        serviceDateProcessed = true;
                    }
                    if (newValue !== oldValue) {
                        let keyLabel = key === 'state' ? 'Status' : key.replaceAll('_', ' ').replace(/\b\w/g, char => char.toUpperCase());
                        changes.push(`${keyLabel}: ${oldValue} => ${newValue}`);
                    }
                });

                if (changes.length > 0) {
                    let resultStr =
                        `<b>Modified By: ${voucher?.created_by_name}</b>;
                         <b>Modified Date: ${dayjs(voucher?.created_at).format('DD/MM/YYYY HH:mm:ss')}</b>;\n${changes.join(';\n')}`;
                    result.push(resultStr);
                }
            }

    });

    }
    return result.reverse();
}

