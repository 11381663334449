import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { sortAction } from '../../store/actions/search';
import { getColumns } from './column';
import TableTitle from '../../components/table_title/TableTitle';
import SearchFilterBar from '../../components/search_filter_bar/SearchFilterBar';
import Table from '../../components/table';
import { Modal } from 'react-bootstrap';
import UsersForm from './userForms/UsersForm';
import UpdateStatusModal from '../../components/modals/UpdateStatusModal';
import DeleteModal2 from '../../components/modals/DeleteModal2';
import Pagination from '../../components/pagination/Pagination';
import {
  deleteUser,
  exportExcelUserAction,
  getUsersListAction,
  updateUserStatus,
} from '../../store/actions/users';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import SuppliersForm from './userForms/SuppliersForm';
import DriversForm from './userForms/DriversForm';
import MobileLayout from '../../components/mobileLayout/MobileLayout';
import { user_mobile_column } from '../../staticVariables/mobileColumns';
import SalesAgentForm from './userForms/SalesAgentForm';

export const userStatus = [
  {
    id: 'Active',
    color: 'green',
  },
  {
    id: 'Inactive',
    color: '#dc3545',
  },
];

function Users(props) {
  let rows = useSelector(store => store.users.usersList);
  let rowStatus = useSelector(store => store.users.usersListRequestStatus);
  let searchConfig = useSelector(store => store.search);
  let actionStatus = useSelector(store => store?.users?.actionStatus);
  let exportExcelStatus = useSelector(store => store.users.exportExcelStatus);
  const count = useSelector(store => store?.users?.pageCount);
  const [showDelete, setShowDelete] = useState(false);
  const [showSupplier, setShowSupplier] = useState(false);
  const [showDrivers, setShowDrivers] = useState(false);
  const [showSaleAgent, setShowSalesAgent] = useState(false);
  const [formData, setFormData] = useState({});
  const [showStatus, setShowStatus] = useState(false);
  const [showSelectedDelete, setShowSelectedDelete] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [popupTitle, setPopupTitle] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [rowId, setRowId] = useState('');
  const dispatch = useDispatch();
  const [showFilter, setShowFilter] = useState(false);
  const [noteValidate, setNoteValidate] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    getUsersList(searchConfig);
  }, []);

  const getUsersList = searchConfig => {
    dispatch(getUsersListAction(searchConfig));
  };

  // Redirect to preview page on click the data of the table
  const redirectToPages = event => {
    if (event.field == 'recovery_email' && event.row.recovery_email != null) {
      for (var value of rows) {
        if (value.email == event.formattedValue) {
          navigate(`/admin/users/${value.id}`);
        }
      }
    }
  };

  const redirectToPreview = useCallback(params => {
    navigate(`/admin/users/${params}`);
  }, []);

  const handleClose = () => {
    setShowDelete(false);
    setDisabled(false);
    setShowSupplier(false);
    setShowDrivers(false);
    setShowSalesAgent(false);
    setPopupTitle('');
    setFormData({});
    setShowStatus(false);
    setNoteValidate(true);
  };

  const handleDelete = rowId => {
    setShowDelete(true);
    setRowId(rowId);
  };

  const handleConfirmDelete = (e, id) => {
    setDisabled(true);
    dispatch(
      deleteUser(rowId.id, (err, data) => {
        if (data) {
          dispatch(getUsersListAction(searchConfig));
          toast.success('Successfully Deleted', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 10000,
          });
          handleClose();
        } else if (err) {
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          handleClose();
        }
      }),
    );
  };

  const handleShow = modal => {
    switch (modal) {
      case 'driver': {
        setShowDrivers(true);
        break;
      }
      case 'supplier': {
        setShowSupplier(true);
        break;
      }
      case 'sales agent': {
        setShowSalesAgent(true);
        break;
      }
      default: {
        setShowSupplier(true);
        break;
      }
    }
  };

  const handleSort = (model, page) => {
    if (model.length) {
      searchConfig.sort = model;
      dispatch(sortAction(model));
    } else {
      model = searchConfig.sort;
    }
    getUsersList(searchConfig);
  };

  const exportExcel = params => {
    dispatch(exportExcelUserAction(searchConfig));
  };

  const deleteSelectedRows = () => {
    setShowSelectedDelete(true);
  };
  const deleteSelectedRowsConfirm = reason => {};

  const handleUpdateStatus = params => {
    setRowId(params);
    setShowStatus(true);
  };

  const handleConfirmUpdateStatus = () => {
    if (!isEmpty(rowId)) {
      const status = rowId?.status === 'Active' ? 'Inactive' : 'Active';
      dispatch(
        updateUserStatus(status, rowId.id, (err, data) => {
          if (data) {
            toast.success(
              data.status === 'Inactive'
                ? 'The selected user no longer has access to the system'
                : 'Access to the system is enabled for the selected user',
              {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 10000,
              },
            );
            setShowStatus(false);
            setRowId('');
            getUsersList(searchConfig);
          } else if (err) {
            toast.error('Something went wrong!', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 10000,
            });
          }
        }),
      );
    }
  };

  const columns = getColumns({
    handleUpdateStatus,
    rows,
    redirectToPreview,
    handleDelete,
    navigate,
  });

  return (
    <div className="admin_tables_block">
      <TableTitle
        dropDown={true}
        handleShow={handleShow}
        title={'Users'}
        addNew={true}
        exportExcel={exportExcel}
        selectedRows={selectedRows}
        deleteSelectedRows={deleteSelectedRows}
        exportExcelStatus={exportExcelStatus}
      />
      <SearchFilterBar
        getList={getUsersList}
        columns={columns}
        setShowFilter={setShowFilter}
        showFilter={showFilter}
        usersPage={true}
      />
      <Table
        status={rowStatus}
        rows={rows}
        columns={columns}
        setPopupTitle={setPopupTitle}
        redirectToPages={redirectToPages}
        parentTitle={'Users'}
        link="/admin/users"
        fetch={true}
        setSort={handleSort}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
      />
      <MobileLayout
        rows={rows || []}
        rowStatus={rowStatus}
        columns={columns}
        setShow={setShowDrivers}
        setPopupTitle={setPopupTitle}
        redirectToPages={redirectToPages}
        changeStatusHandler={handleConfirmUpdateStatus}
        parentTitle={'Users'}
        link="/admin/users"
        mobileCol={user_mobile_column}
        status={userStatus}
        search={getUsersList}
      />
      <Modal
        size="xl"
        show={showSupplier}
        onHide={handleClose}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <SuppliersForm
          popupTitle={popupTitle}
          setFormData={setFormData}
          title="add"
          handleClose={handleClose}
          getUsersList={getUsersList}
          add_new_modal={true}
          actionStatus={actionStatus}
        />
      </Modal>
      <Modal
        size="xl"
        show={showDrivers}
        onHide={handleClose}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <DriversForm
          popupTitle={popupTitle}
          setFormData={setFormData}
          title="add"
          handleClose={handleClose}
          getUsersList={getUsersList}
          add_new_modal={true}
          actionStatus={actionStatus}
        />
      </Modal>
      <Modal
        size="xl"
        show={showSaleAgent}
        onHide={handleClose}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <SalesAgentForm
          popupTitle={popupTitle}
          setFormData={setFormData}
          title="add"
          handleClose={handleClose}
          getUsersList={getUsersList}
          add_new_modal={true}
          actionStatus={actionStatus}
        />
      </Modal>

      <UpdateStatusModal
        show={showStatus}
        handleConfirmUpdate={handleConfirmUpdateStatus}
        handleClose={handleClose}
        text={`You're about to ${
          rowId?.status === 'Active' ? 'disable' : 'enable'
        }  login for the ${rowId?.name} user to the System. Are you sure?`}
        title={rowId?.status === 'Active' ? 'Disable Login' : 'Enable Login'}
        actionStatus={actionStatus}
      />
      <DeleteModal2
        show={showDelete}
        handleClose={() => handleClose()}
        confirm={e => handleConfirmDelete(e, rowId?.id)}
        content={`You are about to delete the #${rowId.id} user. Are you sure?`}
        title={'Delete'}
        actionStatus={actionStatus}
      />
      <DeleteModal2
        show={showSelectedDelete}
        handleClose={() => setShowSelectedDelete(false)}
        confirm={() => deleteSelectedRowsConfirm()}
        content={'You are about to delete selected items. Are you sure?'}
        title={'Delete'}
        actionStatus={actionStatus}
      />
      <Pagination
        rowStatus={rowStatus}
        count={count}
        limit={10}
        getList={getUsersList}
      />
    </div>
  );
}

export default Users;
