import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageTitle from '../../components/PageTitle.js';
import { useParams } from 'react-router-dom';
import { FadeLoader } from 'react-spinners';
import CustomSettingsForm from './CustomSettingsForm';
import { getSingleUserAction } from '../../store/actions/users';
import { getSingleCustomSettingAction } from '../../store/actions/customSettings';

function PreviewSettings() {
  const rowStatus = useSelector(store => store?.customSettings?.status);
  const actionStatus = useSelector(
    store => store?.customSettings?.actionStatus,
  );
  const [formData, setFormData] = useState({});
  const [formValidation, setFormValidation] = useState({});
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    handleGetSingle(id);
  }, []);

  // Get the row data to edit
  const handleGetSingle = id => {
    dispatch(
      getSingleCustomSettingAction(id, (err, data) => {
        if (data) {
          setFormData({ ...data.result });
        }
      }),
    );
  };

  // Close Add/Edit modal
  const handleClose = () => {
    setFormData({});
  };

  return (
    <>
      <PageTitle
        parentTitle={'Settings'}
        childTitle={formData.name || ''}
        parentLink={'/admin/users'}
      />
      <div className="form-block">
        {rowStatus === 'success' ? (
          <CustomSettingsForm
            title="edit"
            formValidation={formValidation}
            handleClose={handleClose}
            singleUser={formData}
            handleEdit={handleGetSingle}
            actionStatus={actionStatus}
          />
        ) : (
          <div className="form-loading">
            <FadeLoader
              color={`var(--primary_main)`}
              loading={rowStatus === 'pending'}
              size={10}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )}
      </div>
    </>
  );
}

export default PreviewSettings;
