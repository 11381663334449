export const page_limit = 10;
export const order = [{ field: 'updated_at', sort: 'desc' }];
export const defaultSearchConfigs = {
  search: '',
  filter: {},
  sort: [
    {
      field: 'updated_at',
      sort: 'desc',
    },
  ],
  page: 1,
  limit: 10,
  defaultFilter: false,
};
