import { GridActionsCellItem } from '@mui/x-data-grid';
import {
  MdDelete,
  MdEdit,
  MdMyLocation,
  MdOutlineEditLocation,
} from 'react-icons/md';
import { FaToggleOff, FaToggleOn } from 'react-icons/fa';
import React from 'react';
import moment from 'moment';
import { TbTableExport } from 'react-icons/tb';

const { REACT_APP_API_URL } = process.env;

export const getColumns = ({
  handleUpdateStatus,
  redirectToPreview,
  redirectToPreviewVoucher,
  handleDelete,
  handleEditPickUpPoint,
  navigate,
  role,
  handleOpenDriverModal,
}) => {
  return [
    {
      access: ['Admin', 'Supplier'],
      field: 'id',
      headerName: '#',
      width: 70,
    },
    {
      access: ['Admin', 'Supplier'],
      field: 'status',
      headerName: 'Status',
      width: 100,
    },
    {
      access: ['Admin', 'Supplier'],
      field: 'tour_name',
      headerName: 'Tour name',
      width: 100,
    },
    {
      access: ['Admin', 'Supplier'],
      field: 'direction.direction_name',
      headerName: 'Direction Name',
      width: 150,
      renderCell: ({ row }) => {
        if (role === 'Supplier') {
          return row?.directionId?.direction_name || '';
        } else {
          return (
            <span
              onClick={ev =>
                navigate(`/admin/directions/${row?.directionId?.id}`)
              }
              className="link-span-in-table"
            >
              {row?.directionId?.direction_name || ''}
            </span>
          );
        }
      },
    },
    {
      access: ['Admin', 'Supplier'],
      field: 'driver_location_url',
      headerName: 'Driver Location Url',
      width: 150,
      renderCell: params => {
        const url =
          params?.driver_location_url || params.row?.driver_location_url;
        if (url) {
          return (
            <span className="link-span-in-table">
              <a href={url} target="_blank">
                {url}
              </a>
            </span>
          );
        } else {
          return '';
        }
      },
    },
    {
      access: ['Admin', 'Supplier'],
      field: 'supplierUser.name',
      headerName: 'Supplier Name',
      width: 150,
      renderCell: ({ row }) => {
        if (role === 'Supplier') {
          return row?.supplierUserId?.name || '';
        } else {
          return (
            <span
              onClick={ev =>
                navigate(`/admin/users/${row?.supplierUserId?.id}`)
              }
              className="link-span-in-table"
            >
              {row?.supplierUserId?.name || ''}
            </span>
          );
        }
      },
    },
    {
      access: ['Admin', 'Supplier'],
      field: 'pickUp.pick_up_name',
      headerName: 'Pick-Up Name',
      width: 150,
      renderCell: ({ row }) => {
        if (role === 'Supplier') {
          return row?.pick_upId?.pick_up_name || '';
        } else {
          return (
            <span
              onClick={ev =>
                navigate(`/admin/pick_up_points/${row?.pick_upId?.id}`)
              }
              className="link-span-in-table"
            >
              {row?.pick_upId?.pick_up_name || ''}
            </span>
          );
        }
      },
    },
    {
      access: ['Admin', 'Supplier'],
      field: 'service_date',
      headerName: 'Service Date',
      width: 150,
      renderCell: ({ row }) => {
        if (row?.service_date) {
          return moment(row.service_date).format('MM/DD/YYYY');
        }
      },
    },
    {
      access: ['Admin', 'Supplier'],
      field: 'end_date',
      headerName: 'End Date',
      width: 150,
      renderCell: ({ row }) => {
        if (row?.end_date) {
          return moment(row.end_date).format('MM/DD/YYYY');
        }
      },
    },
    {
      access: ['Admin', 'Supplier'],
      field: 'pick_up_time',
      headerName: 'Pick Up Time',
      width: 150,
      renderCell: ({ row }) => {
        if (row?.pick_up_time) {
          const formattedTime = moment(row.pick_up_time, 'HH:mm').format(
            'hh:mm A',
          );
          return <span>{formattedTime}</span>;
        }
      },
    },
    {
      access: ['Admin', 'Supplier'],
      field: 'start_time',
      headerName: 'Start Time',
      width: 150,
      renderCell: ({ row }) => {
        if (row?.start_time) {
          const formattedTime = moment(row.start_time, 'HH:mm').format(
            'hh:mm A',
          );
          return <span>{formattedTime}</span>;
        }
      },
    },
    {
      access: ['Admin', 'Supplier'],
      field: 'end_time',
      headerName: 'End Time',
      width: 150,
      renderCell: ({ row }) => {
        if (row?.end_time) {
          const formattedTime = moment(row.end_time, 'HH:mm').format('hh:mm A');
          return <span>{formattedTime}</span>;
        }
      },
    },
    {
      access: ['Admin', 'Supplier'],
      field: 'contact_info',
      headerName: 'Contact Info',
      width: 200,
    },
    {
      access: ['Admin', 'Supplier'],
      field: 'adultSum',
      headerName: 'Adults',
      width: 80,
    },
    {
      access: ['Admin', 'Supplier'],
      field: 'childSum',
      headerName: 'Children',
      width: 80,
    },
    {
      access: ['Admin', 'Supplier'],
      field: 'infantSum',
      headerName: 'Infants',
      width: 80,
    },
    {
      access: ['Admin', 'Supplier'],
      field: 'total_passengers',
      headerName: 'Total Passengers',
      width: 100,
      renderCell: ({ row }) => {
        return (
          row?.allPassengers?.toString() ||
          row?.total_passengers?.toString() ||
          '0'
        );
      },
    },
    {
      access: ['Admin'],
      field: 'created_at',
      headerName: 'Created Date',
      width: 200,
      renderCell: params =>
        moment(params?.created_at || params?.row.created_at).format(
          'MM/DD/YYYY HH:mm:ss',
        ),
    },
    {
      access: ['Admin',],
      field: 'createdBy.name',
      headerName: 'Created By',
      width: 150,
      renderCell: ({ row }) =>  row?.createdBy?.name || ''
    },
    {
      access: ['Admin'],
      field: 'updated_at',
      headerName: 'Modified Date',
      width: 200,
      renderCell: params =>
        moment(params?.updated_at || params?.row.updated_at).format(
          'MM/DD/YYYY HH:mm:ss',
        ),
    },
    {
      access: ['Admin',],
      field: 'updatedBy.name',
      headerName: 'Modified By',
      width: 150,
      renderCell: ({ row }) =>  row?.updatedBy?.name || ''
    },
    {
      headerName: 'Actions',
      field: 'actions',
      cellClassName: 'actions',
      type: 'actions',
      access: ['Admin', 'Supplier'],
      width: 200,
      getActions: params =>
        [
          <GridActionsCellItem
            onClick={() =>
              redirectToPreviewVoucher(params?.row?.id || params?.id)
            }
            className="test1"
            icon={<TbTableExport size={21} style={{ fill: 'none' }} />}
            label="Edit"
            title="Vouchers"
            access={['Admin', 'Supplier']}
            width={50}
          />,
          <GridActionsCellItem
            onClick={() => handleEditPickUpPoint(params?.row)}
            icon={<MdOutlineEditLocation size={19} />}
            label="Edit Pick-Up Point"
            title="Edit Pick-Up Point"
            className="test1"
            access={['Supplier']}
          />,
          <GridActionsCellItem
            onClick={() => handleOpenDriverModal(params?.row)}
            icon={<MdMyLocation size={19} />}
            label="Edit Driver Location Url"
            title="Edit Driver Location Url"
            className="test1"
            access={['Supplier']}
          />,
          <GridActionsCellItem
            onClick={() => redirectToPreview(params?.row?.id || params?.id)}
            className="test1"
            icon={<MdEdit />}
            label="Edit"
            title="View/Edit"
            access={['Admin']}
            width={50}
          />,
          <GridActionsCellItem
            style={
              // params.status || params.row.status?
              {
                cursor: 'pointer',
              }
              // : {
              //     cursor: 'auto',
              //     opacity: 0.4,
              //   }
            }
            onClick={ev => handleUpdateStatus(params?.row || params)}
            icon={
              params?.status === 'Active' ||
              params?.row?.status === 'Active' ? (
                <FaToggleOn />
              ) : (
                <FaToggleOff />
              )
            }
            label="Status"
            title="Change Status"
            access={['Admin']}
            width={50}
          />,
          <GridActionsCellItem
            icon={<MdDelete />}
            label="Delete"
            title="Delete"
            onClick={ev => handleDelete(ev, params?.row || params)}
            access={['Admin']}
            width={50}
          />,
        ].filter(({ props }) => {
          return props.access.includes(role);
        }),
    },
  ].filter(r => r.access.includes(role));
};
