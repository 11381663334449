import {call, put, takeLatest} from 'redux-saga/effects';
import {
    ADD_EMAIL_SETUP_FAIL,
    ADD_EMAIL_SETUP_REQUEST,
    ADD_EMAIL_SETUP_SUCCESS,
    DELETE_EMAIL_SETUP_FAIL,
    DELETE_EMAIL_SETUP_REQUEST,
    DELETE_EMAIL_SETUP_SUCCESS,
    EDIT_EMAIL_SETUP_FAIL,
    EDIT_EMAIL_SETUP_REQUEST,
    EDIT_EMAIL_SETUP_SUCCESS,
    GET_ALL_EMAIL_SETUP_FAIL,
    GET_ALL_EMAIL_SETUP_REQUEST,
    GET_ALL_EMAIL_SETUP_SUCCESS,
    GET_EMAIL_SETUPS_LIST_FAIL,
    GET_EMAIL_SETUPS_LIST_REQUEST,
    GET_EMAIL_SETUPS_LIST_SUCCESS,
    GET_SINGLE_EMAIL_SETUP_FAIL,
    GET_SINGLE_EMAIL_SETUP_REQUEST,
    GET_SINGLE_EMAIL_SETUP_SUCCESS
} from "../actions/emailSetups";
import EmailSetups from "../../api/emailSetups";

export default function* watcher() {
    yield takeLatest(GET_EMAIL_SETUPS_LIST_REQUEST, getSetups);
    yield takeLatest(DELETE_EMAIL_SETUP_REQUEST, deleteSetups);
    yield takeLatest(ADD_EMAIL_SETUP_REQUEST, addSetup);
    yield takeLatest(GET_SINGLE_EMAIL_SETUP_REQUEST, getSingleSetup);
    yield takeLatest(EDIT_EMAIL_SETUP_REQUEST, updateSetup);
    yield takeLatest(GET_ALL_EMAIL_SETUP_REQUEST, handleGetEmail);


}

function* handleGetEmail(action) {
  try {
    const { type } = action.payload;
    const { data } = yield call(EmailSetups.getAllEmailSetups, type);
    yield put({
      type: GET_ALL_EMAIL_SETUP_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    console.log(e);
    yield put({
      type: GET_ALL_EMAIL_SETUP_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}

function* addSetup(action) {
    try {
        const {formData} = action.payload;
        const {data} = yield call(EmailSetups.addEmailSetup, formData);
        yield put({
            type: ADD_EMAIL_SETUP_SUCCESS,
            payload: {
                data: data.result,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(null, data);
        }
    } catch (e) {
        yield put({
            type: ADD_EMAIL_SETUP_FAIL,
            payload: {
                massage: e.massage,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(e, null);
        }
    }
}

function* getSetups(action) {
    try {
        const {page, limit, sort, search, filter} = action.payload?.formData;

        const {data} = yield call(
            EmailSetups.getEmailSetups,
            page,
            limit,
            search,
            filter,
            sort,
        );
        yield put({
            type: GET_EMAIL_SETUPS_LIST_SUCCESS,
            payload: {
                data: data,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(null, data);
        }
    } catch (e) {
        yield put({
            type: GET_EMAIL_SETUPS_LIST_FAIL,
            payload: {
                massage: e.massage,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(e, null);
        }
    }
}

function* deleteSetups(action) {
    try {
        const id = action.payload.formData;
        const {data} = yield call(EmailSetups.deleteEmailSetup, id);
        yield put({
            type: DELETE_EMAIL_SETUP_SUCCESS,
            payload: {
                data: data.result,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(null, data);
        }
    } catch (e) {
        yield put({
            type: DELETE_EMAIL_SETUP_FAIL,
            payload: {
                massage: e.massage,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(e.response?.data, null);
        }
    }
}


function* getSingleSetup(action) {
    try {
        const id = action.payload.formData;
        const {data} = yield call(EmailSetups.getSingleEmailSetup, id);
        yield put({
            type: GET_SINGLE_EMAIL_SETUP_SUCCESS,
            payload: {
                data: data,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(null, data);
        }
    } catch (e) {
        console.log(e);
        yield put({
            type: GET_SINGLE_EMAIL_SETUP_FAIL,
            payload: {
                massage: e.massage,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(e, null);
        }
    }
}

function* updateSetup(action) {
    try {
        const {formData} = action.payload;
        const {data} = yield call(EmailSetups.updateEmailSetup, formData);
        yield put({
            type: EDIT_EMAIL_SETUP_SUCCESS,
            payload: {
                data: data.result,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(null, data);
        }
    } catch (e) {
        yield put({
            type: EDIT_EMAIL_SETUP_FAIL,
            payload: {
                massage: e.massage,
            },
        });
        if (action.payload.cb) {
            action.payload.cb(e, null);
        }
    }
}





