import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './generatePdf.scss';
import Grid from '@mui/material/Unstable_Grid2';
import AdCard from '../../components/adCard/AdCard';
import { getAllAdvertisementsAction } from '../../store/actions/advertisements';
import { useParams } from 'react-router-dom';
import {
  checkVoucherAction,
  downloadVoucherAction,
  sendEmailTourVoucherAction,
  shareMyLocation,
} from '../../store/actions/tourVouchers';
import Stack from '@mui/material/Stack';
import VoucherTemplate from '../../components/pdfTemplates/VoucherTemplate';
import TransferTemplate from '../../components/pdfTemplates/TransferTemplate';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import { Modal } from 'react-bootstrap';
import DynamicEmailForm from './DynamicEmailForm';
import { emailValidateForSend } from '../../services/emailValidate';
import SeeLocationModal from '../../components/modals/SeeLocationModal';
import {
  checkTransferVoucherAction,
  downloadTransferVoucherAction,
  sendEmailTransferVoucherAction,
  shareMyLocationForTransfer,
} from '../../store/actions/transferVouchers';

export default function GeneratePdf() {
  const dispatch = useDispatch();
  const params = useParams();
  const transferDownloadStatus = useSelector(
    store => store.toursTransfers.downloadStatus,
  );
  const voucherDownloadStatus = useSelector(
    store => store.tourVouchers.downloadStatus,
  );
  const shareLocationStatus = useSelector(
    store => store.tourVouchers.shareLocationStatus,
  );
  const shareLocationStatusTransfer = useSelector(
    store => store.transferVouchers.shareLocationStatus,
  );
  const [ads, setAds] = useState([]);
  const [pdfData, setPdfData] = useState({});
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [formData, setFormData] = useState({});
  const [submit, setSubmit] = useState(false);
  const [validated, setValidated] = useState(false);
  const [pdfVisible, setPdfVisible] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isValidCC, setIsValidCC] = useState(false);
  const [isValidBCC, setIsValidBCC] = useState(false);
  const [showDriversLocation, setShowDriversLocation] = useState(false);

  useEffect(() => {
    getAdvertisementList();
    if (params?.page === 'transfer') {
      checkVoucherTransfer();
    }
    if (params?.page === 'voucher') {
      checkVoucher();
    }
  }, []);

  const handleClose = () => {
    setShowEmailModal(false);
    setFormData({});
    setIsValidEmail(false);
    setIsValidCC(false);
    setIsValidBCC(false);
    setValidated(false);
    setSubmit(false);
    setShowDriversLocation(false);
  };

  const handleChange = (key, value) => {
    if (['email', 'cc', 'bcc'].includes(key)) {
      emailValidateForSend(
        key,
        value,
        setIsValidEmail,
        setIsValidBCC,
        setIsValidCC,
      );
      if (isValidEmail) {
        setFormData({ ...formData, email: value });
      }
      if (isValidBCC) {
        setFormData({ ...formData, bcc: value });
      }
      if (isValidCC) {
        setFormData({ ...formData, cc: value });
      }
    }
    setFormData({ ...formData, [key]: value });
  };
  const getAdvertisementList = () => {
    dispatch(
      getAllAdvertisementsAction((err, data) => {
        if (data) {
          setAds(data?.result);
        }
      }),
    );
  };
  const checkVoucherTransfer = () => {
    dispatch(
      checkTransferVoucherAction(params, (err, data) => {
        if (data?.status == 200) {
          setPdfData(data?.result);
        }
        if (err) {
          toast.error(err);
        }
      }),
    );
  };
  const checkVoucher = () => {
    dispatch(
      checkVoucherAction(params, (err, data) => {
        if (data?.status == 200) {
          setPdfData(data?.result);
        }
        if (err) {
          toast.error(err);
        }
      }),
    );
  };

  const sendVoucherEmail = () => {
    dispatch(
      sendEmailTourVoucherAction(formData, pdfData?.id, (err, data) => {
        if (data?.status == 200) {
          handleClose();
          toast.success('Successfully Sent', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 10000,
          });
          setFormData({});
        }
      }),
    );
  };

  const sendTransferEmail = () => {
    dispatch(
      sendEmailTransferVoucherAction(formData, pdfData?.id, (err, data) => {
        if (data?.status == 200) {
          handleClose();
          toast.success('Successfully Sent', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 10000,
          });
          setFormData({});
        }
      }),
    );
  };

  const downloadVoucherPdf = () => {
    const role = '';
    dispatch(
      downloadVoucherAction(
        pdfData?.id,
        pdfData?.ag_ref_number,
        params?.page,
        role,
        (err, data) => {
          if (data?.status == 200) {
            toast.success('Successfully Downloaded', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 10000,
            });
          }
        },
      ),
    );
  };
  const downloadTransferPdf = () => {
    dispatch(
      downloadTransferVoucherAction(
        pdfData?.id,
        pdfData?.reference_number,
        params?.page,
        (err, data) => {
          if (data?.status == 200) {
            toast.success('Successfully Downloaded', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 10000,
            });
          }
        },
      ),
    );
  };

  const downloadPdf = () => {
    if (params?.page === 'transfer') {
      downloadTransferPdf();
    }
    if (params?.page === 'voucher') {
      downloadVoucherPdf();
    }
  };

  // const downloadPdf = async () => {
  //     setPdfVisible(true);
  //     setTimeout(() => {
  //         setPdfVisible(false);
  //     }, 1000);
  // };

  const handleSendEmail = () => setShowEmailModal(true);
  const confirmSendEmail = event => {
    event.preventDefault();
    event.stopPropagation();
    setSubmit(true);
    const form = event.currentTarget;
    // if (form.checkValidity() === false || !isValidEmail) {
    if (!isFormValid()) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      if (params?.page === 'transfer') {
        sendTransferEmail();
      }
      if (params?.page === 'voucher') {
        sendVoucherEmail();
      }
    }
  };
  const isFormValid = () => {
    if (
      !formData.email ||
      (formData.email && !isValidEmail) ||
      !formData.email_body ||
      (formData.cc && !isValidCC) ||
      (formData.bcc && !isValidBCC)
    )
      return false;
    return true;
  };
  const shareLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords;
          if (
            latitude !== undefined &&
            longitude !== undefined &&
            pdfData?.id
          ) {
            let voucher_id = pdfData?.id;
            if (params?.page === 'voucher') {
              dispatch(
                shareMyLocation(
                  latitude,
                  longitude,
                  voucher_id,
                  (err, data) => {
                    if (!err && data) {
                      toast.success('Location successfully shared');
                    } else {
                      toast.error('Something went wrong!');
                    }
                  },
                ),
              );
            }
            if (params?.page === 'transfer') {
              dispatch(
                shareMyLocationForTransfer(
                  latitude,
                  longitude,
                  voucher_id,
                  (err, data) => {
                    if (!err && data) {
                      toast.success('Location successfully shared');
                    } else {
                      toast.error('Something went wrong!');
                    }
                  },
                ),
              );
            }
          }
        },
        error => {
          console.error('Error getting location:', error);
        },
      );
    } else {
      console.error('Geolocation is not supported in this browser.');
    }
  };
  const getDriverLocation = () => {
    const driverUrl =
      pdfData?.transfer?.driver_location_url ||
      pdfData.tourId?.driver_location_url;
    if (pdfData && driverUrl) {
      setShowDriversLocation(true);
    } else {
      toast.error('Driver has not shared the location yet.');
    }
  };

  return (
    <div className="web_content">
      <div className="web_container">
        <Grid container spacing={5} className="generate_grid_block">
          <Grid sm={2.5} xs={12} className="left_ads">
            <Grid container spacing={4} className="ad_block">
              {ads?.map(
                ad =>
                  ad?.column === 'left' && (
                    <Grid xs={12} key={ad?.id} style={{ width: '100%' }}>
                      <AdCard data={ad} />
                    </Grid>
                  ),
              )}
            </Grid>
          </Grid>
          <Grid sm={7} xs={12} className="pdf_grid_item">
            <div className="pdf_block">
              <Stack
                spacing={2}
                direction="row"
                className="template_button_block"
              >
                <LoadingButton
                  color="primary"
                  onClick={handleSendEmail}
                  variant="contained"
                  loading={false}
                  loadingPosition="center"
                >
                  <span>Send Via Email</span>
                </LoadingButton>
                <LoadingButton
                  onClick={downloadPdf}
                  color="primary"
                  variant="contained"
                  loading={
                    transferDownloadStatus === 'pending' ||
                    voucherDownloadStatus === 'pending'
                  }
                  loadingPosition="center"
                >
                  <span
                    style={
                      transferDownloadStatus === 'pending' ||
                      voucherDownloadStatus === 'pending'
                        ? { opacity: 0 }
                        : { opacity: 1 }
                    }
                  >
                    Download Pdf
                  </span>
                </LoadingButton>
                <LoadingButton
                  onClick={getDriverLocation}
                  color="primary"
                  variant="contained"
                  loadingPosition="center"
                >
                  See Driver's Location
                </LoadingButton>
                <LoadingButton
                  onClick={shareLocation}
                  color="primary"
                  variant="contained"
                  loading={
                    params?.page === 'voucher'
                      ? shareLocationStatus === 'pending'
                      : shareLocationStatusTransfer === 'pending'
                  }
                  loadingPosition="center"
                >
                  <span
                    style={
                      shareLocationStatus === 'pending' ||
                      shareLocationStatusTransfer === 'pending'
                        ? { opacity: 0 }
                        : { opacity: 1 }
                    }
                  >
                    Share Location
                  </span>
                </LoadingButton>
              </Stack>
              {params?.page === 'voucher' ? (
                <div style={{ position: 'relative' }}>
                  <VoucherTemplate data={pdfData} />
                </div>
              ) : params?.page === 'transfer' ? (
                <div style={{ position: 'relative' }}>
                  <TransferTemplate data={pdfData} />
                </div>
              ) : null}
            </div>
          </Grid>
          <Grid sm={2.5} xs={12} className="right_ads">
            <Grid container spacing={2} className="ad_block">
              {ads?.map(
                ad =>
                  ad?.column === 'right' && (
                    <Grid xs={12} key={ad?.id} style={{ width: '100%' }}>
                      <AdCard data={ad} />
                    </Grid>
                  ),
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        show={showEmailModal}
        onHide={handleClose}
        size="lg"
        centered
      >
        <DynamicEmailForm
          handleSubmit={confirmSendEmail}
          handleChange={handleChange}
          handleClose={handleClose}
          validated={validated}
          formData={formData}
          isValidEmail={isValidEmail}
          isValidCC={isValidCC}
          isValidBCC={isValidBCC}
          submit={submit}
          page={params?.page}
          forAdmin={false}
        />
      </Modal>
      <SeeLocationModal
        data={pdfData}
        type="generated_pdf"
        handleClose={handleClose}
        show={showDriversLocation}
        title={`Driver's Location`}
        url={
          pdfData &&
          (pdfData?.transfer?.driver_location_url ||
            pdfData?.tourId?.driver_location_url)
        }
      />
    </div>
  );
}
