import { GridActionsCellItem } from '@mui/x-data-grid';
import { MdAttachEmail, MdDelete, MdEdit } from 'react-icons/md';
import moment from 'moment/moment';
import { BsChatText, BsChatTextFill } from 'react-icons/bs';
import { FaFilePdf, FaHistory } from 'react-icons/fa';
import React from 'react';
import { AiFillCopy } from 'react-icons/ai';
import { ImLocation2 } from 'react-icons/im';
import { LoadingButton } from '@mui/lab';
import { FaToggleOff, FaToggleOn } from 'react-icons/fa';
import { Typography } from '@material-ui/core';
import { BiCommentDetail } from 'react-icons/bi';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: '400px',
    display: 'flex',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

export const getColumns = ({
  redirectToPreview,
  handleDelete,
  handleUpdateStatus,
  downloadStatus,
  downloadStatusId,
  openCommentModal,
  openCopyModal,
  openEmailModal,
  handleState,
  role,
  handleDownloadPdf,
  handleNavigateToMap,
  // getPassengerLocation,
  navigate,
}) => {
  return [
    {
      access: ['Admin', 'Driver', 'Sales Agent'],
      field: 'id',
      headerName: '#',
      width: 70,
    },
    {
      access: ['Admin', 'Driver', 'Sales Agent'],
      field: 'ag_ref_number',
      headerName: 'Ag. Reference Number',
      width: 200,
    },
    {
      access: ['Admin', 'Sales Agent'],
      field: 'reference_number',
      headerName: 'Reference Number',
      width: 200,
    },
    {
      access: ['Admin', 'Driver', 'Sales Agent'],
      field: 'passenger_name',
      headerName: 'Passenger Name',
      width: 180,
    },
    {
      access: ['Admin', 'Driver', 'Sales Agent'],
      field: 'transfer.transfer_name',
      headerName: 'Transfer Name',
      width: 180,
      renderCell: ({ row }) => {
        if (row.transfer?.transfer_name) {
          if (role === 'Driver' || role === 'Sales Agent') {
            return row.transfer?.transfer_name;
          } else {
            return (
              <span
                onClick={ev =>
                  navigate(`/admin/transfers/${row?.transfer?.id}`)
                }
                className="link-span-in-table"
              >
                {row?.transfer?.transfer_name || ''}
              </span>
            );
          }
        }
      },
    },
    {
      access: ['Admin', 'Driver', 'Sales Agent'],
      field: 'transfer.service_date',
      headerName: 'Service Date',
      width: 150,
      renderCell: ({ row }) => {
        if (row?.transfer?.service_date) {
          return moment(row.transfer.service_date).format('MM/DD/YYYY');
        }
      },
    },
    {
      access: ['Admin', 'Driver', 'Sales Agent'],
      field: 'driverUser.name',
      headerName: 'Driver',
      width: 180,
      renderCell: ({ row }) => {
        if (row.transfer?.driverUserId?.name) {
          if (role === 'Driver' || role === 'Sales Agent') {
            return row.transfer?.driverUserId?.name;
          } else {
            return (
              <span
                onClick={ev =>
                  navigate(`/admin/users/${row.transfer.driverUserId?.id}`)
                }
                className="link-span-in-table"
              >
                {row.transfer?.driverUserId?.name || ''}
              </span>
            );
          }
        }
      },
    },

    {
      access: ['Admin', 'Driver', 'Sales Agent'],
      field: 'state',
      headerName: 'Status',
      width: 120,
      renderCell: ({ row }) => {
        if (row?.state === 'Cancelled' || row?.state === 'Rejected') {
          return (
            <div style={{ display: 'flex', cursor: 'pointer' }}>
              <HtmlTooltip
                className="loggerTooltip"
                style={{
                  flexWrap: 'wrap',
                  backgroundColor: 'transparent',
                  width: '300px',
                  cursor: 'pointer',
                }}
                title={
                  window?.innerWidth < 1024 ? null : (
                    <React.Fragment>
                      <div
                        style={{
                          overflowY: 'hidden',
                        }}
                      >
                        <Typography color="inherit">
                          <p style={{ width: '100%', fontSize: '0.9em' }}>
                            {row?.cancellation_reason}
                          </p>
                        </Typography>
                      </div>
                    </React.Fragment>
                  )
                }
              >
                <div style={{ display: 'flex' }}>
                  {row?.state}{' '}
                  <BiCommentDetail size={18} style={{ marginLeft: '10px' }} />
                </div>
              </HtmlTooltip>
            </div>
          );
        } else {
          return row?.state;
        }
      },
    },
    {
      access: ['Admin', 'Sales Agent'],
      field: 'pick_up_time',
      headerName: 'Pick Up Time',
      width: 200,
    },
    {
      access: ['Admin', 'Driver', 'Sales Agent'],
      field: 'pick_up_address',
      headerName: 'Pick Up Point',
      width: 180,
      // renderCell: ({row}) => {
      //     if (row.transfer?.pick_up_point?.pick_up_name) {
      //         if (role === "Driver") {
      //             return row.transfer?.pick_up_point?.pick_up_name
      //         } else {
      //             return (
      //                 <span
      //                     onClick={ev =>
      //                         navigate(`/admin/pick_up_points/${row.transfer?.pick_up_point?.id}`)
      //                     }
      //                     className="link-span-in-table"
      //                 >
      //   {row.transfer?.pick_up_point?.pick_up_name || ''}
      // </span>
      //             );
      //         }

      // }
      // },
    },
    {
      access: ['Admin', 'Sales Agent'],
      field: 'pick_up_point.pick_up_name',
      headerName: 'Starting point',
      width: 180,
      renderCell: ({ row }) => {
        return row?.transfer?.pick_up_point?.pick_up_name;
      },
    },
    {
      access: ['Admin', 'Sales Agent'],
      field: 'flight_time',
      headerName: 'Flight Time',
      width: 150,
    },
    {
      access: ['Admin', 'Sales Agent'],
      field: 'flight_number',
      headerName: 'Flight No',
      width: 150,
    },
    {
      access: ['Admin', 'Sales Agent'],
      field: 'original_point_of_rental',
      headerName: 'Original Point of Rental',
      width: 200,
    },
    {
      access: ['Admin', 'Sales Agent'],
      field: 'payment_amount',
      headerName: 'Payment Amount',
      width: 150,
    },
    {
      access: ['Admin', 'Sales Agent'],
      field: 'currencyId.currency_name',
      headerName: 'Currency',
      width: 100,
      renderCell: ({ row }) => {
        return row.currencyId?.currency_name;
      },
    },
    {
      access: ['Admin', 'Driver', 'Sales Agent'],
      field: 'passenger_passport_details',
      headerName: 'Passport/ID No',
      width: 150,
    },
    {
      access: ['Admin', 'Driver', 'Sales Agent'],
      field: 'phone',
      headerName: 'Phone Number',
      width: 150,
      renderCell: ({ row }) => {
        return row.phone ? `+${row.phone}` : '';
      },
    },
    {
      access: ['Admin', 'Driver', 'Sales Agent'],
      field: 'reservation_date',
      headerName: 'Reservation Date',
      width: 150,
    },
    {
      access: ['Admin', 'Driver', 'Sales Agent'],
      field: 'adult_members',
      headerName: 'Adults',
      width: 80,
    },
    {
      access: ['Admin', 'Driver', 'Sales Agent'],
      field: 'child',
      headerName: 'Children',
      width: 80,
    },
    {
      access: ['Admin', 'Driver', 'Sales Agent'],
      field: 'infant',
      headerName: 'Infants',
      width: 80,
    },
    {
      access: ['Admin', 'Driver', 'Sales Agent'],
      field: 'total_passengers',
      headerName: 'Total Passengers',
      width: 100,
      renderCell: ({ row }) => {
        const sum = +row?.adult_members + +row?.child + +row?.infant;
        return sum || 0;
      },
    },
    {
      access: ['Admin', 'Supplier'],
      field: 'salesAgent.name',
      headerName: 'Sales Agent',
      width: 180,
      renderCell: ({ row }) => {
        if (row?.sales_agent_id?.name) {
          if (role === 'Supplier') {
            return row?.sales_agent_id?.name;
          } else {
            return (
              <span
                onClick={ev =>
                  navigate(`/admin/users/${row?.sales_agent_id?.id}`)
                }
                className="link-span-in-table"
              >
                {row?.sales_agent_id?.name || ''}
              </span>
            );
          }
        }
      },
    },
    {
      access: ['Admin', 'Sales Agent'],
      field: 'created_at',
      headerName: 'Created Date',
      width: 200,
      renderCell: params => {
        if (params?.created_at || params?.row?.created_at) {
          return moment(params?.created_at || params?.row?.created_at).format(
            'MM/DD/YYYY HH:mm:ss',
          );
        }
      },
    },
    {
      access: ['Admin', 'Sales Agent'],
      field: 'createdBy.name',
      headerName: 'Created By',
      width: 150,
      renderCell: ({ row }) => row?.createdBy?.name || '',
    },
    {
      access: ['Admin', 'Sales Agent'],
      field: 'updated_at',
      headerName: 'Modified Date',
      width: 200,
      renderCell: params => {
        if (params?.updated_at || params?.row?.updated_at) {
          return moment(params?.updated_at || params?.row?.updated_at).format(
            'MM/DD/YYYY HH:mm:ss',
          );
        }
      },
    },
    {
      access: ['Admin', 'Sales Agent'],
      field: 'updatedBy.name',
      headerName: 'Modified By',
      width: 150,
      renderCell: ({ row }) => row?.updatedBy?.name || '',
    },
    {
      access: ['Admin', 'Driver', 'Sales Agent'],
      headerName: 'Actions',
      field: 'actions',
      width: role === 'Sales Agent' ? 100 : 280,
      cellClassName: 'actions',
      type: 'actions',
      getActions: params =>
        [
          <GridActionsCellItem
            onClick={() => redirectToPreview(params?.id)}
            className={''}
            icon={<MdEdit className={''} />}
            label="Edit"
            title="View/Edit"
            access={['Admin', 'Sales Agent']}
          />,
          <GridActionsCellItem
            onClick={() => {
              if (params?.latitude && params?.longitude) {
                // getPassengerLocation(row);
                handleNavigateToMap(params);
              }
            }}
            icon={<ImLocation2 />}
            label="Edit"
            title={
              params?.location_date &&
              `Shared At: ${moment(params?.location_date).format('L HH:mm:ss')}`
            }
            className={
              (!params?.latitude || !params?.longitude) && 'disabled_action'
            }
            access={['Admin', 'Driver']}
          />,
          <GridActionsCellItem
            onClick={() =>
              // row?.status != 'Inactive' &&
              openEmailModal(params)
            }
            // className={row?.status == 'Inactive' && 'disabled'}
            icon={
              <MdAttachEmail
                size={18}
                // className={row?.status == 'Inactive' && 'disabled'}
              />
            }
            label="Send Email"
            title="Send email to supplier"
            access={['Admin']}
            // disabled={row?.status == 'Inactive'}
          />,

          <GridActionsCellItem
            icon={
              <LoadingButton
                color="primary"
                onClick={ev =>
                  handleDownloadPdf(ev, {
                    id: params?.id,
                    reference_number:
                      params?.reference_number || params?.row?.reference_number,
                    ag_ref_number:
                      params?.ag_ref_number || params?.row?.ag_ref_number,
                  })
                }
                style={{ padding: 0, minWidth: 0 }}
                loading={
                  downloadStatus === 'pending' &&
                  downloadStatusId === params?.id
                }
                loadingPosition="center"
              >
                <FaFilePdf size={16} />
                {/*<BsFilePdfFill*/}
                {/*size={16}*/}
                {/*/>*/}
              </LoadingButton>
            }
            className="test1"
            label="Download Pdf"
            title="Download Pdf"
            access={['Admin', 'Driver']}
          />,

          <GridActionsCellItem
            onClick={() => openCommentModal(params)}
            className="test1"
            icon={
              params?.hidden_note || params?.row?.hidden_note ? (
                <BsChatTextFill size={18} />
              ) : (
                <BsChatText size={18} />
              )
            }
            label="Add Comment"
            title="Add Comment"
            access={['Admin']}
          />,
          // <GridActionsCellItem
          //   onClick={() => openCopyModal(params)}
          //   className="test1"
          //   icon={<AiFillCopy size={18} />}
          //   label="Copy"
          //   title="Copy"
          //   access={['Admin']}
          // />,
          <GridActionsCellItem
            icon={
              <FaHistory
              // className={row?.status == 'Inactive' && 'disabled'}
              />
            }
            label="State"
            title="Change Status"
            onClick={
              // row?.status == 'Inactive'
              //   ? null
              //   :
              ev => {
                ev.stopPropagation();
                handleState(ev, params);
              }
            }
            access={['Admin']}
            // disabled={row?.status == 'Inactive'}
          />,
          <GridActionsCellItem
            style={{
              cursor: 'pointer',
            }}
            onClick={ev => handleUpdateStatus(params)}
            icon={
              params?.status === 'Active' ? <FaToggleOn /> : <FaToggleOff />
            }
            label="Status"
            title="Change Status"
          />,
          <GridActionsCellItem
            icon={<MdDelete />}
            label="Delete"
            title="Delete"
            onClick={ev => handleDelete(ev, params?.id)}
            access={['Admin']}
          />,
        ].filter(({ props }) => {
          return props?.access?.includes(role);
        }),
    },
  ].filter(r => r?.access?.includes(role));
};
