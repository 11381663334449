import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import Select from 'react-select';
import { getToursAction } from '../../store/actions/tours';
import { useDispatch, useSelector } from 'react-redux';
import { getAllPickUpPointsAction } from '../../store/actions/pickUpPoints';

function EditDriverLocationModal({
  show,
  confirm,
  handleClose,
  title,
  value,
  setValue,
  tourId,
  setValidate,
  formData,
}) {
  const [pickUpForm, setPickUpForm] = useState(formData ? { ...formData } : {});
  const dispatch = useDispatch();

  const handleChange = (key, value) => {
    setPickUpForm({ ...pickUpForm, [key]: value });
  };

  useEffect(() => {
    return () => {
      setValidate(true);
    };
  }, []);

  useEffect(() => {
    dispatch(getAllPickUpPointsAction());
  }, []);

  const handleConfirm = () => {
    confirm({ ...pickUpForm });
  };

  const pickUpPoints = useSelector(state => state.pickUpPoints.allPickUpPoints);

  return (
    <Modal
      size="lg"
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        style={{ background: `var(--primary_main)` }}
        className="modal_header"
        closeButton
      >
        <Modal.Title className="modal_title">{title}</Modal.Title>
      </Modal.Header>
      <div className="form-block">
        <Modal.Body>
          <div>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Driver Location Url</Form.Label>
                  <Form.Control
                    value={pickUpForm?.driver_location_url || ''}
                    pattern="https?://.+"
                    onChange={e =>
                      handleChange('driver_location_url', e.target.value)
                    }
                    name="driver_location_url"
                  />
                  <Form.Control.Feedback type="invalid">
                    {formData.driver_location_url && 'Invalid format'}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </div>
      <Modal.Footer>
        <div className="previewButtons">
          <Button
            variant="primary"
            onClick={ev => {
              ev.stopPropagation();
              handleConfirm(pickUpForm);
            }}
          >
            Confirm
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default EditDriverLocationModal;
