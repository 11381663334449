import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Account from '../services/Account';
import { Outlet } from 'react-router-dom';
import { logOut } from '../store/actions/users';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function WrapperLogOut(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector(store => store.users.token);
  const account = useSelector(store => store.users.account);
  useEffect(() => {
    if (token && Account.getToken()) {
      navigate('/admin/dashboard', { redirect: true });
    } else {
      dispatch(logOut());
    }
  }, [token]);

  if (token && localStorage.getItem('account')) {
    return null;
  }
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Travel Path</title>
          <link rel="icon" href="./images/favicon1.svg" />
        </Helmet>
      </HelmetProvider>
      <Outlet />
    </>
  );
}

export default WrapperLogOut;
