import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Table from '../../components/table';
import Pagination from '../../components/pagination/Pagination';
import SearchFilterBar from '../../components/search_filter_bar/SearchFilterBar';
import TableTitle from '../../components/table_title/TableTitle';
import { setPage, sortAction } from '../../store/actions/search';
import DeleteModal2 from '../../components/modals/DeleteModal2';
import { getColumns } from './column';
import TransferTypesForm from './TransferTypesForm';
import {
  deleteSelectedTransferTypes,
  deleteTransferTypeAction,
  exportExcelTransferTypesAction,
  getTransferTypesAction,
  updateStatusTransferTypeAction,
} from '../../store/actions/transferTypes';
import UpdateStatusModal from '../../components/modals/UpdateStatusModal';
import MobileLayout from '../../components/mobileLayout/MobileLayout';
import { transfer_types_mobile_column } from '../../staticVariables/mobileColumns';

export const transferTypesStatus = [
  {
    id: 'Active',
    color: 'green',
  },
  {
    id: 'Inactive',
    color: '#dc3545',
  },
];

function TransferTypes() {
  let rows = useSelector(store => store?.transferTypes?.transferTypes);
  let rowStatus = useSelector(store => store?.transferTypes?.status);
  let actionStatus = useSelector(store => store?.transferTypes?.actionStatus);
  let exportExcelStatus = useSelector(
    store => store?.transferTypes?.exportExcelStatus,
  );
  const accessList = useSelector(store => store.users.accessList);
  let searchConfig = useSelector(store => store.search);
  const count = useSelector(store => store.transferTypes?.count);
  const [showDelete, setShowDelete] = useState(false);
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({});
  const [showStatus, setShowStatus] = useState(false);
  const [showSelectedDelete, setShowSelectedDelete] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [popupTitle, setPopupTitle] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [rowId, setRowId] = useState('');
  const dispatch = useDispatch();
  const [showFilter, setShowFilter] = useState(false);
  const [noteValidate, setNoteValidate] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    getTransferTypesList(searchConfig);
  }, []);
  const getTransferTypesList = searchConfig => {
    dispatch(getTransferTypesAction(searchConfig));
  };
  // Redirect to preview page on click the data of the table
  const redirectToPages = event => {
    if (event.field == 'recovery_email' && event.row.recovery_email != null) {
      for (var value of rows) {
        if (value.email == event.formattedValue) {
          navigate(`/admin-data/states/${value.id}`);
        }
      }
    }
  };

  const redirectToPreview = useCallback(params => {
    navigate(`/admin/setup/transfer_types/${params}`);
  }, []);

  const handleClose = () => {
    setShowDelete(false);
    setDisabled(false);
    setShow(false);
    setPopupTitle('');
    setFormData({});
    setShowStatus(false);
    setNoteValidate(true);
  };

  const handleDelete = (ev, params) => {
    ev.stopPropagation();
    setShowDelete(true);
    setRowId(params);
  };

  const handleConfirmDelete = (e, id) => {
    setDisabled(true);
    dispatch(
      deleteTransferTypeAction(rowId?.id, (err, data) => {
        if (data) {
          handleClose();
          toast.success('Successfully Deleted', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 10000,
          });
          if (rows && rows.length === 1 && searchConfig.page !== 1) {
            searchConfig.page = searchConfig.page - 1;
            dispatch(setPage(searchConfig.page));
          }
          getTransferTypesList(searchConfig);
        }
        if (err) {
          toast.error(err.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 10000,
          });
          handleClose();
        }
      }),
    );
  };

  const handleShow = title => {
    setShow(true);
    setPopupTitle(title);
  };

  const handleSort = (model, page) => {
    if (model.length) {
      searchConfig.sort = model;
      dispatch(sortAction(model));
    } else {
      model = searchConfig.sort;
    }
    getTransferTypesList(searchConfig);
  };

  const exportExcel = params => {
    dispatch(exportExcelTransferTypesAction(searchConfig));
  };

  const deleteSelectedRows = () => {
    setShowSelectedDelete(true);
  };
  const deleteSelectedRowsConfirm = reason => {
    dispatch(
      deleteSelectedTransferTypes(selectedRows, (err, data) => {
        if (data) {
          setSelectedRows([]);
          getTransferTypesList(searchConfig);
          toast.success('Successfully Deleted', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 10000,
          });
          setShowSelectedDelete(false);
        }
      }),
    );
  };

  const handleUpdateStatus = (e, params) => {
    setRowId(params);
    setShowStatus(true);
  };

  const handleConfirmUpdateStatus = () => {
      dispatch(
        updateStatusTransferTypeAction(rowId?.id, (err, data) => {
          if (data) {
            setShowStatus(false);
            getTransferTypesList(searchConfig);
          } else if (err) {
            toast.error(err.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 10000,
            });
          }
          setShowStatus(false);
        }),
      );
  };

  const columns = getColumns({
    rows,
    accessList,
    redirectToPreview,
    handleDelete,
    handleUpdateStatus,
  });

  return (
    <div className="admin_tables_block">
      <TableTitle
        handleShow={handleShow}
        title={'Transfer Types'}
        addNew={true}
        exportExcel={exportExcel}
        selectedRows={selectedRows}
        deleteSelectedRows={deleteSelectedRows}
        exportExcelStatus={exportExcelStatus}
      />
      <SearchFilterBar
        getList={getTransferTypesList}
        columns={columns}
        setShowFilter={setShowFilter}
        showFilter={showFilter}
      />
      <Table
        status={rowStatus}
        rows={rows}
        columns={columns}
        setShow={setShow}
        setPopupTitle={setPopupTitle}
        redirectToPages={redirectToPages}
        setSort={handleSort}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        checkboxSelection={accessList && accessList.includes('176')}
      />
      <MobileLayout
        rows={rows || []}
        rowStatus={rowStatus}
        columns={columns}
        setShow={setShow}
        setPopupTitle={setPopupTitle}
        redirectToPages={redirectToPages}
        changeStatusHandler={handleConfirmUpdateStatus}
        parentTitle={'Transfer Types'}
        link="/admin/transfer_types"
        mobileCol={transfer_types_mobile_column}
        status={transferTypesStatus}
        search={getTransferTypesList}
      />
      <Modal
        size="xl"
        show={show}
        onHide={handleClose}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <TransferTypesForm
          popupTitle={popupTitle}
          setFormData={setFormData}
          title="add"
          handleClose={handleClose}
          actionStatus={actionStatus}
        />
      </Modal>
      <UpdateStatusModal
        show={showStatus}
        handleConfirmUpdate={handleConfirmUpdateStatus}
        handleClose={handleClose}
        text={`You're about to ${
          rowId?.status === 'Active' ? 'de-activate' : 'activate'
        }  the #${rowId?.id} transfer type. Are you sure?`}
        title={'Update Transfer Type'}
        actionStatus={actionStatus}
      />
      <DeleteModal2
        show={showSelectedDelete}
        handleClose={() => setShowSelectedDelete(false)}
        confirm={() => deleteSelectedRowsConfirm()}
        content={'You are about to delete selected items. Are you sure?'}
        title={'Delete'}
        actionStatus={actionStatus}
      />
      <DeleteModal2
        show={showDelete}
        handleClose={() => handleClose()}
        confirm={e => handleConfirmDelete(e, rowId)}
        content={`You are about to delete the #${rowId.id} transfer type. Are you sure?`}
        title={'Delete'}
        actionStatus={actionStatus}
      />
      <Pagination
        rowStatus={rowStatus}
        count={count}
        limit={10}
        getList={getTransferTypesList}
      />
    </div>
  );
}

export default TransferTypes;
