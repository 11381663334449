import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './assets/styles/index.scss';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, legacy_createStore } from 'redux';
import reducers from './store/reducers';
import sagas from './store/saga';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import configureStore from './middlewares';

const saga = createSagaMiddleware();
const middleWare = [thunk];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = configureStore();
store.runSaga(sagas);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);
