import {
  GET_TOURS_REQUEST,
  GET_TOURS_SUCCESS,
  GET_SINGLE_TOUR_REQUEST,
  GET_SINGLE_TOUR_SUCCESS,
  GET_TOURS_FAIL,
  GET_ALL_TOURS_REQUEST,
  GET_ALL_TOURS_SUCCESS,
  GET_SELECT_TOURS_SUCCESS,
  GET_SELECT_TOURS_REQUEST,
  EXPORT_TOURS_REQUEST,
  EXPORT_TOURS_SUCCESS,
  EXPORT_TOURS_FAIL,
  ADD_TOUR_REQUEST,
  ADD_TOUR_SUCCESS,
  ADD_TOUR_FAIL,
  DELETE_TOUR_REQUEST,
  DELETE_TOUR_SUCCESS,
  DELETE_TOUR_FAIL,
  UPDATE_TOUR_REQUEST,
  UPDATE_TOUR_SUCCESS,
  UPDATE_TOUR_FAIL,
  UPDATE_TOUR_PICKUP_REQUEST,
  UPDATE_TOUR_PICKUP_SUCCESS,
  UPDATE_TOUR_PICKUP_FAIL,
  STATUS_TOUR_REQUEST,
  STATUS_TOUR_SUCCESS,
  STATUS_TOUR_FAIL,
} from '../actions/tours';

const initialState = {
  tours: [],
  singleTour: {},
  allTours: [],
  selectTours: [],
  status: '',
  getSelectionToursStatus: '',
  exportExcelStatus: '',
  actionStatus: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_TOURS_REQUEST: {
      return {
        ...state,
        status: 'pending',
      };
    }
    case GET_TOURS_SUCCESS: {
      return {
        ...state,
        tours: action.payload.data.result,
        status: 'success',
        count: action.payload.data.count,
      };
    }
    case GET_TOURS_FAIL: {
      return {
        ...state,
        status: 'fail',
      };
    }

    case GET_ALL_TOURS_REQUEST: {
      return {
        ...state,
        status: 'pending',
      };
    }
    case GET_ALL_TOURS_SUCCESS: {
      return {
        ...state,
        allTours: action.payload.data.result,
        status: 'success',
      };
    }

    case GET_SELECT_TOURS_REQUEST: {
      return {
        ...state,
        status: 'pending',
      };
    }

    case GET_SELECT_TOURS_SUCCESS: {
      return {
        ...state,
        selectTours: action.payload.data,
        status: 'success',
      };
    }

    case GET_SINGLE_TOUR_REQUEST: {
      return {
        ...state,
        status: 'pending',
      };
    }
    case GET_SINGLE_TOUR_SUCCESS: {
      return {
        ...state,
        singleTour: action.payload.data.result,
        status: 'success',
      };
    }

    case ADD_TOUR_REQUEST: {
      return {
        ...state,
        actionStatus: 'pending',
      };
    }

    case ADD_TOUR_SUCCESS: {
      return {
        ...state,
        actionStatus: 'success',
      };
    }

    case ADD_TOUR_FAIL: {
      return {
        ...state,
        actionStatus: 'fail',
      };
    }

    case UPDATE_TOUR_REQUEST: {
      return {
        ...state,
        actionStatus: 'pending',
      };
    }

    case UPDATE_TOUR_SUCCESS: {
      return {
        ...state,
        actionStatus: 'success',
      };
    }

    case UPDATE_TOUR_FAIL: {
      return {
        ...state,
        actionStatus: 'fail',
      };
    }

    case DELETE_TOUR_REQUEST: {
      return {
        ...state,
        actionStatus: 'pending',
      };
    }

    case DELETE_TOUR_SUCCESS: {
      return {
        ...state,
        actionStatus: 'success',
      };
    }

    case DELETE_TOUR_FAIL: {
      return {
        ...state,
        actionStatus: 'fail',
      };
    }
    case UPDATE_TOUR_PICKUP_REQUEST: {
      return {
        ...state,
        actionStatus: 'pending',
      };
    }

    case UPDATE_TOUR_PICKUP_SUCCESS: {
      return {
        ...state,
        actionStatus: 'success',
      };
    }

    case UPDATE_TOUR_PICKUP_FAIL: {
      return {
        ...state,
        actionStatus: 'fail',
      };
    }
    case STATUS_TOUR_REQUEST: {
      return {
        ...state,
        actionStatus: 'pending',
      };
    }

    case STATUS_TOUR_SUCCESS: {
      return {
        ...state,
        actionStatus: 'success',
      };
    }

    case STATUS_TOUR_FAIL: {
      return {
        ...state,
        actionStatus: 'fail',
      };
    }

    case EXPORT_TOURS_REQUEST: {
      return {
        ...state,
        exportExcelStatus: 'pending',
      };
    }
    case EXPORT_TOURS_SUCCESS: {
      return {
        ...state,
        exportExcelStatus: 'success',
      };
    }

    case EXPORT_TOURS_FAIL: {
      return {
        ...state,
        exportExcelStatus: 'fail',
      };
    }

    default: {
      return state;
    }
  }
}
