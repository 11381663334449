import React, {useCallback, useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import {useNavigate, useParams} from 'react-router-dom';
import Table from '../../components/table';
import Pagination from '../../components/pagination/Pagination';
import SearchFilterBar from '../../components/search_filter_bar/SearchFilterBar';
import TableTitle from '../../components/table_title/TableTitle';
import {
    clearSearchAction,
    setPage,
    sortAction,
} from '../../store/actions/search';
import DeleteModal2 from '../../components/modals/DeleteModal2';
import GenerateVouchersReportModal from '../../components/modals/GenerateVouchersReportModal';
import {getColumns} from './column';
import TourVouchersForm from './TourVouchersForm';
import {
    addCommentTourVoucherAction,
    changeTourVoucherStatus,
    copyTourVoucher,
    deleteSelectedTourVouchers,
    deleteTourVoucher,
    downloadVoucherAction,
    exportExcelVoucherAction,
    downloadReportVoucherAction,
    fetchTourVoucher,
    getSingleTourVoucher,
    getTourPreviewVouchersAction,
    getTourVouchersAction,
    sendEmailTourVoucherAction,
    updateTourVoucherState,
    downloadKPIReportVoucherAction,
} from '../../store/actions/tourVouchers';
import CommentModal from '../../components/modals/CommentModal';
import ConfirmModal from '../../components/modals/ConfirmModal';
import EmailModal from '../../components/modals/EmailModal';
import UpdateStatusModal from '../../components/modals/UpdateStatusModal';
import SelectModal from '../../components/modals/SelectModal';
import PageTitle from '../../components/PageTitle';
import {isEmpty} from 'lodash';
import SeeLocationModal from '../../components/modals/SeeLocationModal';
import './tourVoucher.scss';
import {defaultSearchConfigs} from '../../services/globalVariables';
import LocationMap from '../../components/location/LocationMap';
import NoLocationWindow from '../../components/location/NoLocationWindow';
import MobileLayout from '../../components/mobileLayout/MobileLayout';
import {tour_vouchers_mobile_column} from '../../staticVariables/mobileColumns';

const state = [
    {id: 1, status: 'New', value: 'New'},
    {id: 2, status: 'Confirmed', value: 'Confirmed'},
    {id: 3, status: 'Cancelled', value: 'Cancelled'},
    {id: 4, status: 'Reserved', value: 'Reserved'},
    {id: 5, status: 'Rejected', value: 'Rejected'},
];

export const tourVoucherStatus = [
    {
        id: 'Active',
        color: 'green',
    },
    {
        id: 'Inactive',
        color: '#dc3545',
    },
];

function TourVouchers() {
    let rows = useSelector(store => store?.tourVouchers?.tourVouchers);
    let coordinates = useSelector(store => store?.tourVouchers?.coordinates);
    let vouchersWithLocation = useSelector(
        store => store?.tourVouchers?.vouchersWithLocation,
    );
    let fetchStatus = useSelector(store => store?.tourVouchers?.fetchStatus);
    let actionStatus = useSelector(store => store?.tourVouchers?.actionStatus);
    let exportExcelStatus = useSelector(
        store => store?.tourVouchers?.exportExcelStatus,
    );
    let downloadReportStatus = useSelector(
        store => store?.tourVouchers?.downloadReportStatus,
    );
    let childTitle = useSelector(store => store?.tourVouchers?.tableTitle);
    let rowStatus = useSelector(store => store?.tourVouchers?.status);
    let tableTitle = useSelector(store => store?.tourVouchers?.tableTitle);
    const accessList = useSelector(store => store.users.accessList);
    const count = useSelector(store => store.tourVouchers.count);
    const account = useSelector(store => store.users.account);
    let searchConfig = useSelector(store => store.search);
    let downloadStatus = useSelector(store => store.tourVouchers.downloadStatus);
    let downloadStatusId = useSelector(
        store => store.tourVouchers.downloadStatusId,
    );
    const [showPassengersLocation, setShowPassengersLocation] = useState(false);
    const [passengerData, setPassengerData] = useState({});
    const [showSelectedDelete, setShowSelectedDelete] = useState(false);
    const [showDateRangeModal, setShowDateRangeModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [commentModal, setCommentModal] = useState(false);
    const [noteValidate, setNoteValidate] = useState(true);
    const [emailModal, setEmailModal] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [showState, setShowState] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [showStatus, setShowStatus] = useState(false);
    const [popupTitle, setPopupTitle] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [formData, setFormData] = useState({});
    const [comment, setComment] = useState('');
    const [show, setShow] = useState(false);
    const [row, setRow] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {id} = useParams();
    const [downloadedPdfStat, setDownloadedPdfStat] = useState('');
    const [isClickAllowed, setClickAllowed] = useState(true);
    useEffect(() => {
        searchConfig = defaultSearchConfigs;
        getTourVoucherList(searchConfig);
        return () => {
            dispatch(clearSearchAction());
        };
    }, [id]);

    const getTourVoucherList = searchConfig => {
        if (id) {
            dispatch(getTourPreviewVouchersAction(id, searchConfig));
        } else {
            dispatch(getTourVouchersAction(searchConfig));
        }
    };

    const getPreviewTourVoucherList = (searchConfig, tourId) => {
        dispatch(getTourPreviewVouchersAction(searchConfig, tourId || id));
    };
    // Redirect to preview page on click the data of the table
    const redirectToPages = event => {
        if (event.field == 'recovery_email' && event.row.recovery_email != null) {
            for (var value of rows) {
                if (value.email == event.formattedValue) {
                    navigate(`/admin-data/appliances/${value.id}`);
                }
            }
        }
    };

    const redirectToPreview = useCallback(params => {
        navigate(`/admin/vouchers/${params}`);
    }, []);

    const handleClose = () => {
        setShowDelete(false);
        setDisabled(false);
        setShow(false);
        setPopupTitle('');
        setFormData({});
        setShowStatus(false);
        setNoteValidate(true);
        setShowConfirmModal(false);
        setCommentModal(false);
        setEmailModal(false);
        setComment('');
        setShowState(false);
        setShowPassengersLocation(false);
        setShowDateRangeModal(false);
    };

    const handleDelete = (ev, params) => {
        ev.stopPropagation();
        setShowDelete(true);
        setRow(params);
    };
    const handleDownloadPdf = (ev, params) => {
        ev.preventDefault();
        ev.stopPropagation();
        if (!isClickAllowed || downloadedPdfStat === 'pending') return;
        setDownloadedPdfStat('pending');
        setClickAllowed(false);
        dispatch(
            downloadVoucherAction(
                params.id,
                params.ag_ref_number,
                'voucher',
                account.role,
                (err, data) => {
                    if (data) {
                        setDownloadedPdfStat('success');
                    } else {
                        setDownloadedPdfStat('error');
                    }
                    setTimeout(() => {
                        setDownloadedPdfStat('');
                        setClickAllowed(true);
                    }, 1000);
                }
            )
        );
    };

    const handleConfirmDelete = (e, id) => {
        setDisabled(true);
        dispatch(
            deleteTourVoucher(id, (err, data) => {
                if (data) {
                    handleClose();
                    toast.success('Successfully Deleted', {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 10000,
                    });
                    if (rows && rows.length === 1 && searchConfig.page !== 1) {
                        searchConfig.page = searchConfig.page - 1;
                        dispatch(setPage(searchConfig.page));
                    }
                    getTourVoucherList(searchConfig);
                }
            }),
        );
    };

    const handleShow = title => {
        setShow(true);
        setPopupTitle(title);
    };

    const handleSort = (model, page) => {
        if (model.length) {
            searchConfig.sort = model;
            dispatch(sortAction(model));
        } else {
            model = searchConfig.sort;
        }
        getTourVoucherList(searchConfig);
    };

    const exportExcel = () => {
        dispatch(exportExcelVoucherAction(searchConfig));
    };

    const downloadReport = () => {
        setShowDateRangeModal(true);
    };
    const downloadReportConfirm = data => {
        dispatch(
            downloadReportVoucherAction(data, (err, blob) => {
                if (!err && blob) {
                    handleClose();
                }
            }),
        );
    };
    const downloadKPIReportConfirm = data => {
        dispatch(
            downloadKPIReportVoucherAction(data, (err, blob) => {
                if (!err && blob) {
                    handleClose();
                }
            }),
        );
    };

    const deleteSelectedRows = () => {
        setShowSelectedDelete(true);
    };
    const deleteSelectedRowsConfirm = () => {
        dispatch(
            deleteSelectedTourVouchers(selectedRows, (err, data) => {
                if (data) {
                    if (selectedRows.length === rows.length && searchConfig.page > 1) {
                        searchConfig.page = searchConfig.page - 1;
                        dispatch(setPage(searchConfig.page));
                    }
                    setSelectedRows([]);
                    getTourVoucherList(searchConfig);
                    toast.success('Successfully Deleted', {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 10000,
                    });
                    setShowSelectedDelete(false);
                }
            }),
        );
    };

    const handleUpdateStatus = params => {
        setRow(params);
        setShowStatus(true);
    };

    const handleConfirmUpdateStatus = () => {
        dispatch(
            changeTourVoucherStatus(row?.id, (err, data) => {
                if (data) {
                    getTourVoucherList(searchConfig);
                    toast.success('Successfully Updated', {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 10000,
                    });
                } else if (err) {
                    toast.error(err.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 10000,
                    });
                }
                setShowStatus(false);
            }),
        );
    };

    const openCommentModal = params => {
        const com = params?.comment || params?.row?.comment;
        setRow(params.id || params.row.id);
        if (com) {
            setComment(com);
        } else {
            setComment('');
        }
        setCommentModal(true);
    };

    const handleAddComment = (ev, comment) => {
        dispatch(
            addCommentTourVoucherAction(row, comment, (err, data) => {
                if (data) {
                    handleClose();
                    dispatch(getTourVouchersAction(searchConfig));
                    toast.success('Successfully Updated', {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 10000,
                    });
                }
            }),
        );
    };

    const handleCopy = params => {
        dispatch(
            copyTourVoucher(row, (err, data) => {
                if (data) {
                    handleClose();
                    getTourVoucherList(searchConfig);
                    toast.success('Successfully Copied', {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 10000,
                    });
                }
            }),
        );
    };

    const openCopyModal = params => {
        setRow(params.id || params.row.id);
        setShowConfirmModal(true);
    };

    const openEmailModal = params => {
        setRow(params.id || params.row.id);
        setEmailModal(true);
    };

    const handleSendEmail = emailForm => {
        dispatch(
            sendEmailTourVoucherAction(emailForm, row, (err, data) => {
                if (data) {
                    handleClose();
                    toast.success('Successfully Sent', {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 10000,
                    });
                }
            }),
        );
    };
    const handleFetch = () => {
        dispatch(
            fetchTourVoucher((err, data) => {
                if (data) {
                    toast.success(
                        `Successfully Fetched ${
                            data?.result.createdVouchers + data?.result.updatedVouchers
                        } Records. (Created: ${data?.result.createdVouchers}, Updated: ${
                            data?.result.updatedVouchers
                        }  )`,
                        {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 10000,
                        },
                    );
                    getTourVoucherList(searchConfig);
                }
                if (err) {
                    toast.error(err || 'error', {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 10000,
                    });
                }
            }),
        );
    };
    const handleState = (ev, row) => {
        ev.stopPropagation();
        dispatch(
            getSingleTourVoucher(row.id, (err, data) => {
                if (data) {
                    setFormData(data.result);
                    setShowState(true);
                }
            }),
        );

        setRow(row);
    };

    const handleConfirmState = data => {
        setDisabled(true);
        dispatch(
            updateTourVoucherState(data, (err, data) => {
                if (data) {
                    getTourVoucherList(searchConfig);
                    toast.success('Successfully Updated', {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 10000,
                    });
                }
                if (err) {
                    toast.error(err.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 10000,
                    });
                }
                setShowState(false);
            }),
        );
    };

    // const getPassengerLocation = data => {
    //   if (data && data.latitude && data.longitude) {
    //     setPassengerData({ ...data });
    //     setShowPassengersLocation(true);
    //   } else {
    //     toast.error('Url does not exist');
    //   }
    // };

    const handleNavigateToMap = data => {
        // dispatch(
        //   getVoucherLocation(id, (err, data) => {
        //     if (data && data.latitude && data.longitude) {
        //       const url = `https://www.google.com/maps/search/${data.latitude}+${data.longitude}/`;
        //       const newTab = window.open(url, '_blank');
        //       newTab.focus();
        //     } else {
        //       toast.error('No passenger location', {
        //         position: toast.POSITION.TOP_RIGHT,
        //         autoClose: 10000,
        //       });
        //     }
        //   }),
        // );
        if (data && data.latitude && data.longitude) {
            const url = `https://www.google.com/maps/search/${data.latitude}+${data.longitude}/`;
            const newTab = window.open(url, '_blank');
            newTab.focus();
        }
    };

    const columns = getColumns({
        handleUpdateStatus,
        downloadStatus,
        downloadStatusId,
        downloadedPdfStat,
        rows,
        redirectToPreview,
        handleDelete,
        handleDownloadPdf,
        isClickAllowed,
        navigate,
        openCommentModal,
        openCopyModal,
        openEmailModal,
        handleState,
        // getPassengerLocation,
        handleNavigateToMap,
        role: !isEmpty(account) ? account.role : '',
    });
    return (
        <div className="admin_tables_block">
            {id ? (
                <PageTitle
                    parentTitle={'Tours'}
                    childTitle={id && tableTitle ? tableTitle : childTitle || ''}
                    parentLink={`/admin/tours/`}
                />
            ) : (
                <TableTitle
                    handleShow={handleShow}
                    title={'Tour Vouchers'}
                    addNew={
                        !isEmpty(account) && ['Admin', 'Supplier'].includes(account.role)
                    }
                    fetch={
                        !isEmpty(account) && ['Admin'].includes(account.role)
                            ? handleFetch
                            : false
                    }
                    downloadReport={
                        !isEmpty(account) && ['Admin'].includes(account.role)
                            ? downloadReport
                            : null
                    }
                    exportExcel={exportExcel}
                    selectedRows={selectedRows}
                    deleteSelectedRows={deleteSelectedRows}
                    fetchStatus={fetchStatus}
                    exportExcelStatus={exportExcelStatus}
                    downloadReportStatus={downloadReportStatus}
                />
            )}

            <SearchFilterBar
                getList={getTourVoucherList}
                columns={columns}
                setShowFilter={setShowFilter}
                showFilter={showFilter}
            />
            <Table
                status={rowStatus}
                rows={rows}
                columns={columns}
                setShow={setShow}
                setPopupTitle={setPopupTitle}
                redirectToPages={redirectToPages}
                setSort={handleSort}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                checkboxSelection={accessList && accessList.includes('176')}
            />
            <MobileLayout
                rows={rows || []}
                rowStatus={rowStatus}
                columns={columns}
                setShow={setShow}
                setPopupTitle={setPopupTitle}
                redirectToPages={redirectToPages}
                changeStatusHandler={handleConfirmUpdateStatus}
                parentTitle={'Tour Vouchers'}
                link="/admin/vouchers"
                mobileCol={tour_vouchers_mobile_column}
                status={tourVoucherStatus}
                search={getTourVoucherList}
            />
            <Modal
                size="xl"
                show={show}
                onHide={handleClose}
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <TourVouchersForm
                    popupTitle={popupTitle}
                    setFormData={setFormData}
                    title="add"
                    handleClose={handleClose}
                    actionStatus={actionStatus}
                />
            </Modal>
            <DeleteModal2
                show={showSelectedDelete}
                handleClose={() => setShowSelectedDelete(false)}
                confirm={() => deleteSelectedRowsConfirm()}
                content={'You are about to delete selected items. Are you sure?'}
                title={'Delete'}
                actionStatus={actionStatus}
            />
            <DeleteModal2
                show={showDelete}
                handleClose={() => handleClose()}
                confirm={e => handleConfirmDelete(e, row)}
                content={`You are about to delete the #${row} tour. Are you sure?`}
                title={'Delete'}
                actionStatus={actionStatus}
            />
            <CommentModal
                show={commentModal}
                handleClose={handleClose}
                title="Add Comment"
                confirm={handleAddComment}
                note={comment}
                clearNote={setComment}
                actionStatus={actionStatus}
            />
            <EmailModal
                show={emailModal}
                handleClose={handleClose}
                title="Send Email"
                confirm={handleSendEmail}
                page="Voucher"
                actionStatus={actionStatus}
            />
            <ConfirmModal
                show={showConfirmModal}
                handleClose={handleClose}
                title="Copy Tour Voucher"
                handleConfirm={handleCopy}
                content={'Make sure to update the data after the copy.'}
                actionStatus={actionStatus}
            />
            <UpdateStatusModal
                show={showStatus}
                handleConfirmUpdate={handleConfirmUpdateStatus}
                handleClose={handleClose}
                text={`You're about to ${
                    row?.status === 'Active' ? 'de-activate' : 'activate'
                }  the #${row?.id} Tour Voucher. Are you sure?`}
                title={'Update Tour Voucher'}
                actionStatus={actionStatus}
            />
            <SelectModal
                className="react-select"
                classNamePrefix="react-select"
                Modal
                // title={'Change Tour Voucher State'}
                title={'Change Tour Voucher Status'}
                select={state}
                confirm={handleConfirmState}
                show={showState}
                handleClose={handleClose}
                setValidate={setNoteValidate}
                validate={noteValidate}
                formData={formData}
                setFormData={setFormData}
                single={row}
                actionStatus={actionStatus}
            />
            <GenerateVouchersReportModal
                show={showDateRangeModal}
                handleClose={handleClose}
                downloadReport={downloadReportConfirm}
                downloadKPIReport={downloadKPIReportConfirm}
                actionStatus={downloadReportStatus}
            />
            <Pagination
                rowStatus={rowStatus}
                count={count}
                limit={10}
                getList={getTourVoucherList}
            />
            <SeeLocationModal
                type="admin_table"
                data={passengerData}
                handleClose={handleClose}
                show={showPassengersLocation}
                title={`Passenger's Location`}
                url={`https://www.google.com/maps/search/${passengerData?.latitude}+${passengerData?.longitude}/`}
            />
            <div>
                {id && (
                    <h4 className="passenger_location_title">Passenger's Locations</h4>
                )}
                {id && vouchersWithLocation?.length ? (
                    <LocationMap
                        usersLocations={vouchersWithLocation}
                        coordinates={coordinates}
                    />
                ) : id && !vouchersWithLocation?.length ? (
                    <NoLocationWindow/>
                ) : null}
            </div>
        </div>
    );
}

export default TourVouchers;
