import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Table from '../../components/table';
import Pagination from '../../components/pagination/Pagination';
import SearchFilterBar from '../../components/search_filter_bar/SearchFilterBar';
import TableTitle from '../../components/table_title/TableTitle';
import { setPage, sortAction } from '../../store/actions/search';
import DeleteModal2 from '../../components/modals/DeleteModal2';
import { getColumns } from './column';
import {
  getPickUpPointsAction,
  deletePickUpPoint,
  deleteSelectedPickUpPoints,
  updateStatusPickUpPointAction,
  exportExcelPickUpPointsAction,
} from '../../store/actions/pickUpPoints';
import PickUpPointsForm from './PickUpPointsForm';
import UpdateStatusModal from '../../components/modals/UpdateStatusModal';
import MobileLayout from '../../components/mobileLayout/MobileLayout';
import { pickup_points_mobile_column } from '../../staticVariables/mobileColumns';


export const pickUpPointStatus = [      
  {
    id: 'Active',
    color: 'green',
  },
  {
    id: 'Inactive',
    color: '#dc3545',
  },
];

function PickUpPoints() {
  let rows = useSelector(store => store?.pickUpPoints?.pickUpPoints);
  let rowStatus = useSelector(store => store?.pickUpPoints?.status);
  let exportExcelStatus = useSelector(
    store => store?.pickUpPoints?.exportExcelStatus,
  );
  let searchConfig = useSelector(store => store.search);
  const count = useSelector(store => store?.pickUpPoints?.count);
  const actionStatus = useSelector(store => store.pickUpPoints.actionStatus);
  const [showDelete, setShowDelete] = useState(false);
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({});
  const [showStatus, setShowStatus] = useState(false);
  const [showSelectedDelete, setShowSelectedDelete] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [popupTitle, setPopupTitle] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [rowId, setRowId] = useState('');
  const [showFilter, setShowFilter] = useState(false);
  const [noteValidate, setNoteValidate] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    getPickUpPointsList(searchConfig);
  }, []);
  const getPickUpPointsList = searchConfig => {
    dispatch(getPickUpPointsAction(searchConfig));
  };
  // Redirect to preview page on click the data of the table

  const redirectToPreview = useCallback(params => {
    navigate(`/admin/pick_up_points/${params}`);
  }, []);

  const handleClose = () => {
    setShowDelete(false);
    setDisabled(false);
    setShow(false);
    setPopupTitle('');
    setFormData({});
    setShowStatus(false);
    setNoteValidate(true);
  };

  const handleDelete = (ev, params) => {
    ev.stopPropagation();
    setShowDelete(true);
    setRowId(params);
  };

  const handleConfirmDelete = (e, id) => {
    setDisabled(true);
    dispatch(
      deletePickUpPoint(id, (err, data) => {
        if (data) {
          handleClose();
          toast.success('Successfully Deleted', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 10000,
          });
          if (rows && rows.length === 1 && searchConfig.page !== 1) {
            searchConfig.page = searchConfig.page - 1;
            dispatch(setPage(searchConfig.page));
          }
          getPickUpPointsList(searchConfig);
        } else if (err) {
          toast.error(err.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 10000,
          });
        }
        handleClose();
      }),
    );
  };

  const handleShow = title => {
    setShow(true);
    setPopupTitle(title);
  };

  const handleSort = (model, page) => {
    if (model.length) {
      searchConfig.sort = model;
      dispatch(sortAction(model));
    } else {
      model = searchConfig.sort;
    }
    getPickUpPointsList(searchConfig);
  };

  const exportExcel = params => {
    dispatch(exportExcelPickUpPointsAction(searchConfig));
  };

  const deleteSelectedRows = () => {
    setShowSelectedDelete(true);
  };
  const deleteSelectedRowsConfirm = reason => {
    dispatch(
      deleteSelectedPickUpPoints(selectedRows, (err, data) => {
        if (data) {
          setSelectedRows([]);
          getPickUpPointsList(searchConfig);
          toast.success('Successfully Deleted', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 10000,
          });
          setShowSelectedDelete(false);
        }
      }),
    );
  };
  const handleConfirmUpdateStatus = () => {
    dispatch(
      updateStatusPickUpPointAction(rowId?.id, (err, data) => {
        if (data) {
          dispatch(getPickUpPointsAction(searchConfig));
          toast.success('Successfully Updated', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 10000,
          });
        } else if (err) {
          toast.error(err.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 10000,
          });
        }
        setShowStatus(false);
      }),
    );
  };

  const handleUpdateStatus = (e, params) => {
    setRowId(params);
    setShowStatus(true);
  };

  const columns = getColumns({
    rows,
    redirectToPreview,
    handleDelete,
    handleUpdateStatus,
    navigate,
  });
  return (
    <div className="admin_tables_block">
      <TableTitle
        handleShow={handleShow}
        title={'Pick-Up Points'}
        addNew={true}
        exportExcel={exportExcel}
        selectedRows={selectedRows}
        deleteSelectedRows={deleteSelectedRows}
        exportExcelStatus={exportExcelStatus}
      />
      <SearchFilterBar
        getList={getPickUpPointsList}
        columns={columns}
        setShowFilter={setShowFilter}
        showFilter={showFilter}
      />
      <Table
        status={rowStatus}
        rows={rows}
        columns={columns}
        setShow={setShow}
        setPopupTitle={setPopupTitle}
        setSort={handleSort}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        checkboxSelection={false}
      />
      <MobileLayout
        rows={rows || []}
        rowStatus={rowStatus}
        columns={columns}
        setShow={setShow}
        setPopupTitle={setPopupTitle}
        redirectToPages={redirectToPreview}
        changeStatusHandler={handleConfirmUpdateStatus}
        parentTitle={'Pick-Up Points'}
        link="/admin/pick_up_points"
        mobileCol={pickup_points_mobile_column}
        status={pickUpPointStatus}
        search={getPickUpPointsList}
      />
      <Modal
        size="xl"
        show={show}
        onHide={handleClose}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <PickUpPointsForm
          popupTitle={popupTitle}
          setFormData={setFormData}
          title="add"
          handleClose={handleClose}
        />
      </Modal>
      <UpdateStatusModal
        show={showStatus}
        handleConfirmUpdate={handleConfirmUpdateStatus}
        handleClose={handleClose}
        text={`You're about to ${
          rowId?.status === 'Active' ? 'de-activate' : 'activate'
        }  the ${rowId?.pick_up_name} pick-up point. Are you sure?`}
        title={'Update Pick-Up point'}
        actionStatus={actionStatus}
      />
      <DeleteModal2
        show={showSelectedDelete}
        handleClose={() => setShowSelectedDelete(false)}
        confirm={() => deleteSelectedRowsConfirm()}
        content={'You are about to delete selected items. Are you sure?'}
        title={'Delete'}
      />
      <DeleteModal2
        show={showDelete}
        handleClose={() => handleClose()}
        confirm={e => handleConfirmDelete(e, rowId)}
        content={`You are about to delete the #${rowId} pick-up point. Are you sure?`}
        title={'Delete'}
        actionStatus={actionStatus}
      />
      <Pagination
        rowStatus={rowStatus}
        count={count}
        limit={10}
        getList={getPickUpPointsList}
      />
    </div>
  );
}

export default PickUpPoints;
