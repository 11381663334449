import {
  ADD_CUSTOM_SETTING_FAIL,
  ADD_CUSTOM_SETTING_REQUEST,
  ADD_CUSTOM_SETTING_SUCCESS,
  CLEAR_DASHBOARD_INFO,
  DELETE_CUSTOM_SETTING_FAIL,
  DELETE_CUSTOM_SETTING_REQUEST,
  DELETE_CUSTOM_SETTING_SUCCESS,
  EDIT_CUSTOM_SETTING_FAIL,
  EDIT_CUSTOM_SETTING_REQUEST,
  EDIT_CUSTOM_SETTING_SUCCESS,
  GET_CUSTOM_SETTINGS_LIST_REQUEST,
  GET_CUSTOM_SETTINGS_LIST_SUCCESS,
  GET_DASHBOARD_INFO_REQUEST,
  GET_DASHBOARD_INFO_SUCCESS,
  GET_SINGLE_CUSTOM_SETTING_REQUEST,
  GET_SINGLE_CUSTOM_SETTING_SUCCESS,
} from '../actions/customSettings';
import { LOG_OUT } from '../actions/users';

const initialState = {
  settings: [],
  singleSetting: {},
  status: '',
  dashboardData: {},
  actionStatus: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_CUSTOM_SETTINGS_LIST_REQUEST: {
      return {
        ...state,
        status: 'pending',
      };
    }
    case GET_CUSTOM_SETTINGS_LIST_SUCCESS: {
      return {
        ...state,
        settings: action.payload.data.result,
        status: 'success',
        count: action.payload.data.count,
      };
    }
    case GET_SINGLE_CUSTOM_SETTING_REQUEST: {
      return {
        ...state,
        status: 'pending',
      };
    }
    case GET_SINGLE_CUSTOM_SETTING_SUCCESS: {
      return {
        ...state,
        singleTransferType: action.payload.data.result,
        status: 'success',
      };
    }
    case EDIT_CUSTOM_SETTING_REQUEST: {
      return {
        ...state,
        actionStatus: 'pending',
      };
    }
    case EDIT_CUSTOM_SETTING_SUCCESS: {
      return {
        ...state,
        actionStatus: 'success',
      };
    }

    case EDIT_CUSTOM_SETTING_FAIL: {
      return {
        ...state,
        actionStatus: 'fail',
      };
    }

    case GET_DASHBOARD_INFO_REQUEST: {
      return {
        ...state,
        status: 'pending',
      };
    }

    case CLEAR_DASHBOARD_INFO: {
      return {
        ...state,
        dashboardData: {},
      };
    }
    case LOG_OUT: {
      return {
        ...state,
        dashboardData: {},
      };
    }
    case GET_DASHBOARD_INFO_SUCCESS: {
      return {
        ...state,
        status: 'success',
        dashboardData: action.payload.data.result,
      };
    }
    // case GET_ALL_TRANSFER_TYPES_REQUEST: {
    //   return {
    //     ...state,
    //     status: 'pending',
    //   };
    // }
    // case GET_ALL_TRANSFER_TYPES_SUCCESS: {
    //   return {
    //     ...state,
    //     allTransferTypes: action.payload.data.result,
    //     status: 'success',
    //   };
    // }
    // case GET_ALL_TRANSFER_TYPES_FAIL: {
    //   return {
    //     ...state,
    //     status: 'fail',
    //   };
    // }

    case ADD_CUSTOM_SETTING_REQUEST: {
      return {
        ...state,
        actionStatus: 'pending',
      };
    }
    case ADD_CUSTOM_SETTING_SUCCESS: {
      return {
        ...state,
        actionStatus: 'success',
      };
    }
    case ADD_CUSTOM_SETTING_FAIL: {
      return {
        ...state,
        actionStatus: 'fail',
      };
    }

    case DELETE_CUSTOM_SETTING_REQUEST: {
      return {
        ...state,
        actionStatus: 'pending',
      };
    }
    case DELETE_CUSTOM_SETTING_SUCCESS: {
      return {
        ...state,
        actionStatus: 'success',
      };
    }
    case DELETE_CUSTOM_SETTING_FAIL: {
      return {
        ...state,
        actionStatus: 'fail',
      };
    }

    default: {
      return state;
    }
  }
}
