import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import moment from 'moment';

function SeeLocationModal({ show, type, title, url, data, handleClose }) {
  return (
    <Modal
      size="lg"
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        className="modal_header"
        closeButton
        style={
          type === 'generated_pdf'
            ? { background: '#f88f35', padding: '15px' }
            : null
        }
      >
        <Modal.Title className="modal_title">{title}</Modal.Title>
      </Modal.Header>
      <div className="form-block">
        <Modal.Body>
          {type === 'admin_table' && data?.location_date && (
            <div>
              <strong>Shared at: </strong>
              {data?.location_date &&
                moment(data?.location_date).format('L HH:mm:ss')}
            </div>
          )}
          <div
            style={{ width: '100%', height: '280px' }}
            className="map_iframe"
          >
            <iframe width="100%" height="280" src={url}></iframe>
          </div>
        </Modal.Body>
      </div>
      <Modal.Footer>
        <div className="previewButtons">
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default SeeLocationModal;
