import {
  GET_ADVERTISEMENTS_REQUEST,
  GET_ADVERTISEMENTS_SUCCESS,
  GET_ADVERTISEMENTS_FAIL,
  GET_SINGLE_ADVERTISEMENT_REQUEST,
  GET_SINGLE_ADVERTISEMENT_SUCCESS,
  GET_ALL_ADVERTISEMENTS_SUCCESS,
  EXPORT_ADVERTISEMENTS_REQUEST,
  EXPORT_ADVERTISEMENTS_SUCCESS,
  EXPORT_ADVERTISEMENTS_FAIL,
  ADD_ADVERTISEMENT_REQUEST,
  ADD_ADVERTISEMENT_SUCCESS,
  ADD_ADVERTISEMENT_FAIL,
  DELETE_ADVERTISEMENT_FAIL,
  DELETE_ADVERTISEMENT_REQUEST,
  DELETE_ADVERTISEMENT_SUCCESS,
  DELETE_SELECTED_ADVERTISEMENTS_REQUEST,
  DELETE_SELECTED_ADVERTISEMENTS_SUCCESS,
  DELETE_SELECTED_ADVERTISEMENTS_FAIL,
  UPDATE_ADVERTISEMENT_REQUEST,
  UPDATE_ADVERTISEMENT_SUCCESS,
  UPDATE_ADVERTISEMENT_FAIL,
  UPDATE_STATUS_ADVERTISEMENT_REQUEST,
  UPDATE_STATUS_ADVERTISEMENT_SUCCESS,
  UPDATE_STATUS_ADVERTISEMENT_FAIL,
} from '../actions/advertisements';

const initialState = {
  ads: [],
  allAds: [],
  singleAd: {},
  status: '',
  exportExcelStatus: '',
  actionStatus: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_ADVERTISEMENTS_REQUEST: {
      return {
        ...state,
        status: 'pending',
      };
    }
    case GET_ADVERTISEMENTS_SUCCESS: {
      return {
        ...state,
        ads: action?.payload?.data?.result,
        status: 'success',
        count: action?.payload?.data?.count,
      };
    }
    case GET_ADVERTISEMENTS_FAIL: {
      return {
        ...state,
        status: 'fail',
      };
    }

    case GET_SINGLE_ADVERTISEMENT_REQUEST: {
      return {
        ...state,
        status: 'pending',
      };
    }
    case GET_SINGLE_ADVERTISEMENT_SUCCESS: {
      return {
        ...state,
        singleAd: action.payload.data.result,
        status: 'success',
      };
    }
    case GET_ALL_ADVERTISEMENTS_SUCCESS: {
      return {
        ...state,
        allAds: action.payload?.data?.result,
      };
    }

    case ADD_ADVERTISEMENT_REQUEST: {
      return {
        ...state,
        actionStatus: 'pending',
      };
    }
    case ADD_ADVERTISEMENT_SUCCESS: {
      return {
        ...state,
        actionStatus: 'success',
      };
    }
    case ADD_ADVERTISEMENT_FAIL: {
      return {
        ...state,
        actionStatus: 'fail',
      };
    }

    case DELETE_ADVERTISEMENT_REQUEST: {
      return {
        ...state,
        actionStatus: 'pending',
      };
    }
    case DELETE_ADVERTISEMENT_SUCCESS: {
      return {
        ...state,
        actionStatus: 'success',
      };
    }
    case DELETE_ADVERTISEMENT_FAIL: {
      return {
        ...state,
        actionStatus: 'fail',
      };
    }

    case DELETE_SELECTED_ADVERTISEMENTS_REQUEST: {
      return {
        ...state,
        actionStatus: 'pending',
      };
    }
    case DELETE_SELECTED_ADVERTISEMENTS_SUCCESS: {
      return {
        ...state,
        actionStatus: 'success',
      };
    }
    case DELETE_SELECTED_ADVERTISEMENTS_FAIL: {
      return {
        ...state,
        actionStatus: 'fail',
      };
    }

    case UPDATE_ADVERTISEMENT_REQUEST: {
      return {
        ...state,
        actionStatus: 'pending',
      };
    }
    case UPDATE_ADVERTISEMENT_SUCCESS: {
      return {
        ...state,
        actionStatus: 'success',
      };
    }
    case UPDATE_ADVERTISEMENT_FAIL: {
      return {
        ...state,
        actionStatus: 'fail',
      };
    }

    case UPDATE_STATUS_ADVERTISEMENT_REQUEST: {
      return {
        ...state,
        actionStatus: 'pending',
      };
    }
    case UPDATE_STATUS_ADVERTISEMENT_SUCCESS: {
      return {
        ...state,
        actionStatus: 'success',
      };
    }
    case UPDATE_STATUS_ADVERTISEMENT_FAIL: {
      return {
        ...state,
        actionStatus: 'fail',
      };
    }

    case EXPORT_ADVERTISEMENTS_REQUEST: {
      return {
        ...state,
        exportExcelStatus: 'pending',
      };
    }
    case EXPORT_ADVERTISEMENTS_SUCCESS: {
      return {
        ...state,
        exportExcelStatus: 'success',
      };
    }
    case EXPORT_ADVERTISEMENTS_FAIL: {
      return {
        ...state,
        exportExcelStatus: 'fail',
      };
    }
    default: {
      return state;
    }
  }
}
