export const GET_TRANSFER_VOUCHERS_REQUEST = 'GET_TRANSFER_VOUCHERS_REQUEST';
export const GET_TRANSFER_VOUCHERS_SUCCESS = 'GET_TRANSFER_VOUCHERS_SUCCESS';
export const GET_TRANSFER_VOUCHERS_FAIL = 'GET_TRANSFER_VOUCHERS_FAIL';

export function getTransferVouchersAction(searchConfig, cb) {
  return {
    type: GET_TRANSFER_VOUCHERS_REQUEST,
    payload: {
      searchConfig,
      cb,
    },
  };
}

export const GET_TRANSFER_PREVIEW_VOUCHERS_REQUEST =
  'GET_TRANSFER_PREVIEW_VOUCHERS_REQUEST';
export const GET_TRANSFER_PREVIEW_VOUCHERS_SUCCESS =
  'GET_TRANSFER_PREVIEW_VOUCHERS_SUCCESS';
export const GET_TRANSFER_PREVIEW_VOUCHERS_FAIL =
  'GET_TRANSFER_PREVIEW_VOUCHERS_FAIL';

export function getTransferPreviewVouchersAction(id, searchConfig, cb) {
  return {
    type: GET_TRANSFER_PREVIEW_VOUCHERS_REQUEST,
    payload: {
      id,
      searchConfig,
      cb,
    },
  };
}

export const GET_ALL_TRANSFER_VOUCHERS_REQUEST =
  'GET_ALL_TRANSFER_VOUCHERS_REQUEST';
export const GET_ALL_TRANSFER_VOUCHERS_SUCCESS =
  'GET_ALL_TRANSFER_VOUCHERS_SUCCESS';
export const GET_ALL_TRANSFER_VOUCHERS_FAIL = 'GET_ALL_TRANSFER_VOUCHERS_FAIL';

export function getAllTransferVouchers(cb) {
  return {
    type: GET_ALL_TRANSFER_VOUCHERS_REQUEST,
    payload: {
      cb,
    },
  };
}

export const ADD_TRANSFER_VOUCHER_REQUEST = 'ADD_TRANSFER_VOUCHER_REQUEST';
export const ADD_TRANSFER_VOUCHER_SUCCESS = 'ADD_TRANSFER_VOUCHER_SUCCESS';
export const ADD_TRANSFER_VOUCHER_FAIL = 'ADD_TRANSFER_VOUCHER_FAIL';

export function addTransferVoucherAction(formData, cb) {
  return {
    type: ADD_TRANSFER_VOUCHER_REQUEST,
    payload: {
      formData,
      cb,
    },
  };
}

export const DELETE_TRANSFER_VOUCHER_REQUEST =
  'DELETE_TRANSFER_VOUCHER_REQUEST';
export const DELETE_TRANSFER_VOUCHER_SUCCESS =
  'DELETE_TRANSFER_VOUCHER_SUCCESS';
export const DELETE_TRANSFER_VOUCHER_FAIL = 'DELETE_TRANSFER_VOUCHER_FAIL';

export function deleteTransferVoucher(id, cb) {
  return {
    type: DELETE_TRANSFER_VOUCHER_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const DELETE_SELECTED_TRANSFER_VOUCHERS_REQUEST =
  'DELETE_SELECTED_TRANSFER_VOUCHERS_REQUEST';
export const DELETE_SELECTED_TRANSFER_VOUCHERS_SUCCESS =
  'DELETE_SELECTED_TRANSFER_VOUCHERS_SUCCESS';
export const DELETE_SELECTED_TRANSFER_VOUCHERS_FAIL =
  'DELETE_SELECTED_TRANSFER_VOUCHERS_FAIL';

export function deleteSelectedTransferVouchers(selectedRows, cb) {
  return {
    type: DELETE_SELECTED_TRANSFER_VOUCHERS_REQUEST,
    payload: {
      selectedRows,
      cb,
    },
  };
}

export const UPDATE_TRANSFER_VOUCHER_REQUEST =
  'UPDATE_TRANSFER_VOUCHER_REQUEST';
export const UPDATE_TRANSFER_VOUCHER_SUCCESS =
  'UPDATE_TRANSFER_VOUCHER_SUCCESS';
export const UPDATE_TRANSFER_VOUCHER_FAIL = 'UPDATE_TRANSFER_VOUCHER_FAIL';

export function updateTransferVoucher(formData, cb) {
  return {
    type: UPDATE_TRANSFER_VOUCHER_REQUEST,
    payload: {
      formData,
      cb,
    },
  };
}

export const UPDATE_TRANSFER_VOUCHER_STATE_REQUEST =
  'UPDATE_TRANSFER_VOUCHER_STATE_REQUEST';
export const UPDATE_TRANSFER_VOUCHER_STATE_SUCCESS =
  'UPDATE_TRANSFER_VOUCHER_STATE_SUCCESS';
export const UPDATE_TRANSFER_VOUCHER_STATE_FAIL =
  'UPDATE_TRANSFER_VOUCHER_STATE_FAIL';

export function updateTransferVoucherState(formData, cb) {
  return {
    type: UPDATE_TRANSFER_VOUCHER_STATE_REQUEST,
    payload: {
      formData,
      cb,
    },
  };
}

export const GET_TRANSFER_LOCATION_REQUEST = 'GET_TRANSFER_LOCATION_REQUEST';
export const GET_TRANSFER_LOCATION_SUCCESS = 'GET_TRANSFER_LOCATION_SUCCESS';
export const GET_TRANSFER_LOCATION_FAIL = 'GET_TRANSFER_LOCATION_FAIL';

export function getTransferVoucherLocation(id, cb) {
  return {
    type: GET_TRANSFER_LOCATION_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const ADD_COMMENT_TRANSFER_VOUCHER_REQUEST =
  'ADD_COMMENT_TRANSFER_VOUCHER_REQUEST';
export const ADD_COMMENT_TRANSFER_VOUCHER_SUCCESS =
  'ADD_COMMENT_TRANSFER_VOUCHER_SUCCESS';
export const ADD_COMMENT_TRANSFER_VOUCHER_FAIL =
  'ADD_COMMENT_TRANSFER_VOUCHER_FAIL';

export function addCommentTransferVoucherAction(id, comment, cb) {
  return {
    type: ADD_COMMENT_TRANSFER_VOUCHER_REQUEST,
    payload: {
      comment,
      id,
      cb,
    },
  };
}

export const GET_SINGLE_TRANSFER_VOUCHER_REQUEST =
  'GET_SINGLE_TRANSFER_VOUCHER_REQUEST';
export const GET_SINGLE_TRANSFER_VOUCHER_SUCCESS =
  'GET_SINGLE_TRANSFER_VOUCHER_SUCCESS';
export const GET_SINGLE_TRANSFER_VOUCHER_FAIL =
  'GET_SINGLE_TRANSFER_VOUCHER_FAIL';

export function getSingleTransferVoucher(id, cb) {
  return {
    type: GET_SINGLE_TRANSFER_VOUCHER_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const CHANGE_TRANSFER_VOUCHER_STATUS_REQUEST =
  'CHANGE_TRANSFER_VOUCHER_STATUS_REQUEST';
export const CHANGE_TRANSFER_VOUCHER_STATUS_SUCCESS =
  'CHANGE_TRANSFER_VOUCHER_STATUS_SUCCESS';
export const CHANGE_TRANSFER_VOUCHER_STATUS_FAIL =
  'CHANGE_TRANSFER_VOUCHER_STATUS_FAIL';

export function changeTransferVoucherStatus(id, cb) {
  return {
    type: CHANGE_TRANSFER_VOUCHER_STATUS_REQUEST,
    payload: { id, cb },
  };
}

export const EXPORT_TRANSFER_VOUCHERS_REQUEST =
  'EXPORT_TRANSFER_VOUCHERS_REQUEST';
export const EXPORT_TRANSFER_VOUCHERS_SUCCESS =
  'EXPORT_TRANSFER_VOUCHERS_SUCCESS';
export const EXPORT_TRANSFER_VOUCHERS_FAIL = 'EXPORT_TRANSFER_VOUCHERS_FAIL';

export function exportExcelTransferVoucherAction(searchConfig, cb) {
  return {
    type: EXPORT_TRANSFER_VOUCHERS_REQUEST,
    payload: { searchConfig, cb },
  };
}

export const COPY_TRANSFER_VOUCHER_REQUEST = 'COPY_TRANSFER_VOUCHER_REQUEST';
export const COPY_TRANSFER_VOUCHER_SUCCESS = 'COPY_TRANSFER_VOUCHER_SUCCESS';
export const COPY_TRANSFER_VOUCHER_FAIL = 'COPY_TRANSFER_VOUCHER_FAIL';

export function copyTransferVoucher(id, cb) {
  return {
    type: COPY_TRANSFER_VOUCHER_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const SEND_EMAIL_TRANSFER_VOUCHER_REQUEST =
  'SEND_EMAIL_TRANSFER_VOUCHER_REQUEST';
export const SEND_EMAIL_TRANSFER_VOUCHER_SUCCESS =
  'SEND_EMAIL_TRANSFER_VOUCHER_SUCCESS';
export const SEND_EMAIL_TRANSFER_VOUCHER_FAIL =
  'SEND_EMAIL_TRANSFER_VOUCHER_FAIL';

export function sendEmailTransferVoucherAction(formData, id, cb) {
  return {
    type: SEND_EMAIL_TRANSFER_VOUCHER_REQUEST,
    payload: {
      formData,
      id,
      cb,
    },
  };
}

export const DOWNLOAD_TRANSFER_VOUCHER_REQUEST =
  'DOWNLOAD_TRANSFER_VOUCHER_REQUEST';
export const DOWNLOAD_TRANSFER_VOUCHER_SUCCESS =
  'DOWNLOAD_TRANSFER_VOUCHER_SUCCESS';
export const DOWNLOAD_TRANSFER_VOUCHER_FAIL = 'DOWNLOAD_TRANSFER_VOUCHER_FAIL';

export function downloadTransferVoucherAction(
  id,
  ag_ref_number,
  page,
  role,
  cb,
) {
  return {
    type: DOWNLOAD_TRANSFER_VOUCHER_REQUEST,
    payload: {
      id,
      ag_ref_number,
      page,
      role,
      cb,
    },
  };
}

export const CHECK_TRANSFER_VOUCHER_REQUEST = 'CHECK_TRANSFER_VOUCHER_REQUEST';
export const CHECK_TRANSFER_VOUCHER_SUCCESS = 'CHECK_TRANSFER_VOUCHER_SUCCESS';
export const CHECK_TRANSFER_VOUCHER_FAIL = 'CHECK_TRANSFER_VOUCHER_FAIL';

export function checkTransferVoucherAction(formData, cb) {
  return {
    type: CHECK_TRANSFER_VOUCHER_REQUEST,
    payload: {
      formData,
      cb,
    },
  };
}

export const FETCH_TRANSFER_VOUCHER_REQUEST = 'FETCH_TRANSFER_VOUCHER_REQUEST';
export const FETCH_TRANSFER_VOUCHER_SUCCESS = 'FETCH_TRANSFER_VOUCHER_SUCCESS';
export const FETCH_TRANSFER_VOUCHER_FAIL = 'FETCH_TRANSFER_VOUCHER_FAIL';

export function fetchTourVoucher(cb) {
  return {
    type: FETCH_TRANSFER_VOUCHER_REQUEST,
    payload: {
      cb,
    },
  };
}

export const GET_DRIVERS_LOCATION_REQUEST = 'GET_DRIVERS_LOCATION_REQUEST';
export const GET_DRIVERS_LOCATION_SUCCESS = 'GET_DRIVERS_LOCATION_SUCCESS';
export const GET_DRIVERS_LOCATION_FAIL = 'GET_DRIVERS_LOCATION_FAIL';

export function getDriversLocation(location, cb) {
  return {
    type: GET_DRIVERS_LOCATION_REQUEST,
    payload: {
      location,
      cb,
    },
  };
}
export const SHARE_MY_LOCATION_TRANSFER_REQUEST =
  'SHARE_MY_LOCATION_TRANSFER_REQUEST';
export const SHARE_MY_LOCATION_TRANSFER_SUCCESS =
  'SHARE_MY_LOCATION_TRANSFER_SUCCESS';
export const SHARE_MY_LOCATION_TRANSFER_FAIL =
  'SHARE_MY_LOCATION_TRANSFER_FAIL';

export function shareMyLocationForTransfer(
  latitude,
  longitude,
  voucher_id,
  cb,
) {
  return {
    type: SHARE_MY_LOCATION_TRANSFER_REQUEST,
    payload: {
      latitude,
      longitude,
      voucher_id,
      cb,
    },
  };
}
export const DOWNLOAD_TRANSFER_VOUCHERS_REPORT_REQUEST = 'DOWNLOAD_TRANSFER_VOUCHERS_REPORT_REQUEST';
export const DOWNLOAD_TRANSFER_VOUCHERS_REPORT_SUCCESS = 'DOWNLOAD_TRANSFER_VOUCHERS_REPORT_SUCCESS';
export const DOWNLOAD_TRANSFER_VOUCHERS_REPORT_FAIL = 'DOWNLOAD_TRANSFER_VOUCHERS_REPORT_FAIL';

export function downloadReportTransferVouchersAction(data, cb) {
  return {
    type: DOWNLOAD_TRANSFER_VOUCHERS_REPORT_REQUEST,
    payload: { data, cb },
  };
}

export const GET_TRANSFER_VOUCHERS_FOR_KPI_REPORT_REQUEST = 'GET_TRANSFER_VOUCHERS_FOR_KPI_REPORT_REQUEST';
export const GET_TRANSFER_VOUCHERS_FOR_KPI_REPORT_SUCCESS = 'GET_TRANSFER_VOUCHERS_FOR_KPI_REPORT_SUCCESS';
export const GET_TRANSFER_VOUCHERS_FOR_KPI_REPORT_FAIL = 'GET_TRANSFER_VOUCHERS_FOR_KPI_REPORT_FAIL';

export function getTransferVouchersForKPIReport(cb) {
  return {
    type: GET_TRANSFER_VOUCHERS_FOR_KPI_REPORT_REQUEST,
    payload: { cb },
  };
}


export const DOWNLOAD_TRANSFER_VOUCHERS_KPI_REPORT_REQUEST = 'DOWNLOAD_TRANSFER_VOUCHERS_KPI_REPORT_REQUEST';
export const DOWNLOAD_TRANSFER_VOUCHERS_KPI_REPORT_SUCCESS = 'DOWNLOAD_TRANSFER_VOUCHERS_KPI_REPORT_SUCCESS';
export const DOWNLOAD_TRANSFER_VOUCHERS_KPI_REPORT_FAIL = 'DOWNLOAD_TRANSFER_VOUCHERS_KPI_REPORT_FAIL';

export function downloadKPIReportTransferVouchersAction(data, cb) {
  return {
    type: DOWNLOAD_TRANSFER_VOUCHERS_KPI_REPORT_REQUEST,
    payload: { data, cb },
  };
}


