export const GET_ALL_DIRECTIONS_REQUEST = 'GET_ALL_DIRECTIONS_REQUEST';
export const GET_ALL_DIRECTIONS_SUCCESS = 'GET_ALL_DIRECTIONS_SUCCESS';
export const GET_ALL_DIRECTIONS_FAIL = 'GET_ALL_DIRECTIONS_FAIL';

export function getAllDirectionsAction(cb) {
  return {
    type: GET_ALL_DIRECTIONS_REQUEST,
    payload: {
      cb,
    },
  };
}

export const GET_DIRECTIONS_REQUEST = 'GET_DIRECTIONS_REQUEST';
export const GET_DIRECTIONS_SUCCESS = 'GET_DIRECTIONS_SUCCESS';
export const GET_DIRECTIONS_FAIL = 'GET_DIRECTIONS_FAIL';

export function getDirectionsAction(searchConfig, cb) {
  return {
    type: GET_DIRECTIONS_REQUEST,
    payload: {
      searchConfig,
      cb,
    },
  };
}

export const ADD_DIRECTION_REQUEST = 'ADD_DIRECTION_REQUEST';
export const ADD_DIRECTION_SUCCESS = 'ADD_DIRECTION_SUCCESS';
export const ADD_DIRECTION_FAIL = 'ADD_DIRECTION_FAIL';

export function addDirectionAction(formData, cb) {
  return {
    type: ADD_DIRECTION_REQUEST,
    payload: {
      formData,
      cb,
    },
  };
}

export const DELETE_DIRECTION_REQUEST = 'DELETE_DIRECTION_REQUEST';
export const DELETE_DIRECTION_SUCCESS = 'DELETE_DIRECTION_SUCCESS';
export const DELETE_DIRECTION_FAIL = 'DELETE_DIRECTION_FAIL';

export function deleteDirection(id, cb) {
  return {
    type: DELETE_DIRECTION_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const DELETE_SELECTED_DIRECTIONS_REQUEST =
  'DELETE_SELECTED_DIRECTIONS_REQUEST';
export const DELETE_SELECTED_DIRECTIONS_SUCCESS =
  'DELETE_SELECTED_DIRECTIONS_SUCCESS';
export const DELETE_SELECTED_DIRECTIONS_FAIL =
  'DELETE_SELECTED_DIRECTIONS_FAIL';

export function deleteSelectedDirections(selectedRows, cb) {
  return {
    type: DELETE_SELECTED_DIRECTIONS_REQUEST,
    payload: {
      selectedRows,
      cb,
    },
  };
}

export const UPDATE_DIRECTION_REQUEST = 'UPDATE_DIRECTION_REQUEST';
export const UPDATE_DIRECTION_SUCCESS = 'UPDATE_DIRECTION_SUCCESS';
export const UPDATE_DIRECTION_FAIL = 'UPDATE_DIRECTION_FAIL';

export function updateDirection(formData, cb) {
  return {
    type: UPDATE_DIRECTION_REQUEST,
    payload: {
      formData,
      cb,
    },
  };
}

export const STATUS_DIRECTION_REQUEST = 'STATUS_DIRECTION_REQUEST';
export const STATUS_DIRECTION_SUCCESS = 'STATUS_DIRECTION_SUCCESS';
export const STATUS_DIRECTION_FAIL = 'STATUS_DIRECTION_FAIL';

export function changeDirectionStatusAction(id, cb) {
  return {
    type: STATUS_DIRECTION_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const GET_SINGLE_DIRECTION_REQUEST = 'GET_SINGLE_DIRECTION_REQUEST';
export const GET_SINGLE_DIRECTION_SUCCESS = 'GET_SINGLE_DIRECTION_SUCCESS';
export const GET_SINGLE_DIRECTION_FAIL = 'GET_SINGLE_DIRECTION_FAIL';

export function getSingleDirection(id, cb) {
  return {
    type: GET_SINGLE_DIRECTION_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const EXPORT_DIRECTIONS_REQUEST = 'EXPORT_DIRECTIONS_REQUEST';
export const EXPORT_DIRECTIONS_SUCCESS = 'EXPORT_DIRECTIONS_SUCCESS';
export const EXPORT_DIRECTIONS_FAIL = 'EXPORT_DIRECTIONS_FAIL';

export function exportExcelDirectionsAction(searchConfig, cb) {
  return {
    type: EXPORT_DIRECTIONS_REQUEST,
    payload: { searchConfig, cb },
  };
}
