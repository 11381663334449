import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import Table from '../../components/table';
import Pagination from '../../components/pagination/Pagination';
import SearchFilterBar from '../../components/search_filter_bar/SearchFilterBar';
import TableTitle from '../../components/table_title/TableTitle';
import {
  clearSearchAction,
  setPage,
  sortAction,
} from '../../store/actions/search';
import DeleteModal2 from '../../components/modals/DeleteModal2';
import { getColumns } from './column';
import TransferVouchersForm from './TransferVouchersForm';
import {
  addCommentTransferVoucherAction,
  changeTransferVoucherStatus,
  copyTransferVoucher,
  deleteTransferVoucher, downloadReportTransferVouchersAction,
  downloadTransferVoucherAction,
  exportExcelTransferVoucherAction,
  fetchTourVoucher,
  getSingleTransferVoucher,
  getTransferPreviewVouchersAction,
  getTransferVouchersAction,
  sendEmailTransferVoucherAction,
  updateTransferVoucherState,
  downloadKPIReportTransferVouchersAction
} from '../../store/actions/transferVouchers';
import CommentModal from '../../components/modals/CommentModal';
import ConfirmModal from '../../components/modals/ConfirmModal';
import EmailModal from '../../components/modals/EmailModal';
import UpdateStatusModal from '../../components/modals/UpdateStatusModal';
import SelectModal from '../../components/modals/SelectModal';
import PageTitle from '../../components/PageTitle';
import { isEmpty } from 'lodash';
import SeeLocationModal from '../../components/modals/SeeLocationModal';
import './transferVoucher.scss';
import { defaultSearchConfigs } from '../../services/globalVariables';
import NoLocationWindow from '../../components/location/NoLocationWindow';
import LocationMapTransfer from '../../components/location/LocationMapTransfer';
import {deleteSelectedTourVouchers} from '../../store/actions/tourVouchers';
import MobileLayout from '../../components/mobileLayout/MobileLayout';
import { transfer_vouchers_mobile_column } from '../../staticVariables/mobileColumns';
import GenerateTransfersReportModal from "../../components/modals/GenerateTransfersReportModal";

const state = [
  { id: 1, status: 'New', value: 'New' },
  { id: 2, status: 'Confirmed', value: 'Confirmed' },
  { id: 3, status: 'Cancelled', value: 'Cancelled' },
  { id: 4, status: 'Reserved', value: 'Reserved' },
  { id: 5, status: 'Rejected', value: 'Rejected' },
];

export const transferVoucherStatus = [
  {
    id: 'Active',
    color: 'green',
  },
  {
    id: 'Inactive',
    color: '#dc3545',
  },
];

function TransferVouchers() {
  let rows = useSelector(store => store?.transferVouchers?.transferVouchers);
  let coordinates = useSelector(store => store?.transferVouchers?.coordinates);
  let vouchersWithLocation = useSelector(
    store => store?.transferVouchers?.transfersWithLocation,
  );
  let fetchStatus = useSelector(store => store?.transferVouchers?.fetchStatus);
  let actionStatus = useSelector(
    store => store?.transferVouchers?.actionStatus,
  );
  let exportExcelStatus = useSelector(
    store => store?.transferVouchers?.exportExcelStatus,
  );
  let childTitle = useSelector(store => store?.transferVouchers?.tableTitle);
  let rowStatus = useSelector(store => store?.transferVouchers?.status);
  let tableTitle = useSelector(store => store?.transferVouchers?.tableTitle);
  const accessList = useSelector(store => store.users.accessList);
  const count = useSelector(store => store.transferVouchers.count);
  const account = useSelector(store => store.users.account);
  let searchConfig = useSelector(store => store.search);
  let downloadStatus = useSelector(
    store => store.transferVouchers.downloadStatus,
  );
  let downloadStatusId = useSelector(
    store => store.transferVouchers.downloadStatusId,
  );
  let downloadReportStatus = useSelector(
      store => store?.transferVouchers?.downloadReportStatus,
  );

  const [showPassengersLocation, setShowPassengersLocation] = useState(false);
  const [passengerData, setPassengerData] = useState({});
  const [showSelectedDelete, setShowSelectedDelete] = useState(false);
  const [showDateRangeModal, setShowDateRangeModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [commentModal, setCommentModal] = useState(false);
  const [noteValidate, setNoteValidate] = useState(true);
  const [emailModal, setEmailModal] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showState, setShowState] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showStatus, setShowStatus] = useState(false);
  const [popupTitle, setPopupTitle] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [formData, setFormData] = useState({});
  const [comment, setComment] = useState('');
  const [show, setShow] = useState(false);
  const [row, setRow] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    searchConfig = defaultSearchConfigs;
    getTransferVoucherList(searchConfig);
    return () => {
      dispatch(clearSearchAction());
    };
  }, [id]);

  const getTransferVoucherList = searchConfig => {
    if (id) {
      dispatch(getTransferPreviewVouchersAction(id, searchConfig));
    } else {
      dispatch(getTransferVouchersAction(searchConfig));
    }
  };

  const getPreviewTourVoucherList = (searchConfig, tourId) => {
    dispatch(getTransferPreviewVouchersAction(searchConfig, tourId || id));
  };
  // Redirect to preview page on click the data of the table
  const redirectToPages = event => {
    if (event.field == 'recovery_email' && event.row.recovery_email != null) {
      for (var value of rows) {
        if (value.email == event.formattedValue) {
          navigate(`/admin-data/appliances/${value.id}`);
        }
      }
    }
  };

  const redirectToPreview = useCallback(params => {
    navigate(`/admin/transfer_vouchers/${params}`);
  }, []);

  const handleClose = () => {
    setShowDelete(false);
    setDisabled(false);
    setShow(false);
    setPopupTitle('');
    setFormData({});
    setShowStatus(false);
    setNoteValidate(true);
    setShowConfirmModal(false);
    setCommentModal(false);
    setEmailModal(false);
    setComment('');
    setShowState(false);
    setShowPassengersLocation(false);
    setShowDateRangeModal(false);
  };

  const handleDelete = (ev, params) => {
    ev.stopPropagation();
    setShowDelete(true);
    setRow(params);
  };
  const handleDownloadPdf = (ev, params) => {
    ev.preventDefault();
    ev.stopPropagation();
    dispatch(
      downloadTransferVoucherAction(
        params.id,
        params.ag_ref_number,
        'voucher',
        account.role,
        (err, data) => {
          if (data?.status == 200) {
            toast.success('Successfully Downloaded', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 10000,
            });
          }
        },
      ),
    );
  };

  const handleConfirmDelete = (e, id) => {
    setDisabled(true);
    dispatch(
      deleteTransferVoucher(id, (err, data) => {
        if (data) {
          handleClose();
          toast.success('Successfully Deleted', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 10000,
          });
          if (rows && rows.length === 1 && searchConfig.page !== 1) {
            searchConfig.page = searchConfig.page - 1;
            dispatch(setPage(searchConfig.page));
          }
        }
        if (err) {
          toast.error(err.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 10000,
          });
          handleClose();
        }
        getTransferVoucherList(searchConfig);
      }),
    );
  };

  const handleShow = title => {
    setShow(true);
    setPopupTitle(title);
  };

  const handleSort = (model, page) => {
    if (model.length) {
      searchConfig.sort = model;
      dispatch(sortAction(model));
    } else {
      model = searchConfig.sort;
    }
    getTransferVoucherList(searchConfig);
  };

  const exportExcel = params => {
    dispatch(exportExcelTransferVoucherAction(searchConfig));
  };

  const deleteSelectedRows = () => {
    setShowSelectedDelete(true);
  };
  const deleteSelectedRowsConfirm = reason => {
    dispatch(
      deleteSelectedTourVouchers(selectedRows, (err, data) => {
        if (data) {
          if (selectedRows.length === rows.length && searchConfig.page > 1) {
            searchConfig.page = searchConfig.page - 1;
            dispatch(setPage(searchConfig.page));
          }
          setSelectedRows([]);
          getTransferVoucherList(searchConfig);
          toast.success('Successfully Deleted', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 10000,
          });
          setShowSelectedDelete(false);
        }
      }),
    );
  };

  const handleUpdateStatus = params => {
    setRow(params);
    setShowStatus(true);
  };

  const handleConfirmUpdateStatus = () => {
    dispatch(
      changeTransferVoucherStatus(row?.id, (err, data) => {
        if (data) {
          getTransferVoucherList(searchConfig);
          toast.success('Successfully Updated', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 10000,
          });
        } else if (err) {
          toast.error(err.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 10000,
          });
        }
        setShowStatus(false);
      }),
    );
  };

  const openCommentModal = params => {
    const note = params?.hidden_note || params.row?.hidden_note;
    setRow(params.id || params.row.id);
    if (note) {
      setComment(note);
    } else {
      setComment('');
    }
    setCommentModal(true);
  };

  const handleAddComment = (ev, comment) => {
    dispatch(
      addCommentTransferVoucherAction(row, comment, (err, data) => {
        if (data) {
          handleClose();
          dispatch(getTransferVouchersAction(searchConfig));
          toast.success('Successfully Updated', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 10000,
          });
        }
      }),
    );
  };

  const handleCopy = params => {
    dispatch(
      copyTransferVoucher(row, (err, data) => {
        if (data) {
          handleClose();
          getTransferVoucherList(searchConfig);
          toast.success('Successfully Copied', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 10000,
          });
        }
      }),
    );
  };

  const openCopyModal = params => {
    setRow(params?.id || params?.row?.id);
    setShowConfirmModal(true);
  };

  const openEmailModal = params => {
    setRow(params?.id || params?.row?.id);
    setEmailModal(true);
  };

  const handleSendEmail = emailForm => {
    dispatch(
      sendEmailTransferVoucherAction(emailForm, row, (err, data) => {
        if (data) {
          handleClose();
          toast.success('Successfully Sent', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 10000,
          });
        }
      }),
    );
  };
  const handleFetch = () => {
    dispatch(
      fetchTourVoucher((err, data) => {
        if (data) {
          getTransferVoucherList(searchConfig);
          toast.success(`Successfully Fetched ${data?.result} records`, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 10000,
          });
        }
        if (err) {
          toast.error(err || 'error', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 10000,
          });
        }
      }),
    );
  };
  const handleState = (ev, row) => {
    ev.stopPropagation();
    dispatch(
      getSingleTransferVoucher(row.id, (err, data) => {
        if (data) {
          setFormData(data.result);
          setShowState(true);
        }
      }),
    );

    setRow(row);
  };

  const handleConfirmState = data => {
    setDisabled(true);
    dispatch(
      updateTransferVoucherState(data, (err, data) => {
        if (data) {
          getTransferVoucherList(searchConfig);
          toast.success('Successfully Updated', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 10000,
          });
        }
        if (err) {
          toast.error(err.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 10000,
          });
        }
        setShowState(false);
      }),
    );
  };

  // const getPassengerLocation = data => {
  //   if (data && data.latitude && data.longitude) {
  //     setPassengerData({ ...data });
  //     setShowPassengersLocation(true);
  //   } else {
  //     toast.error('Url does not exist');
  //   }
  // };

  const handleNavigateToMap = data => {
    // dispatch(
    //   getVoucherLocation(id, (err, data) => {
    //     if (data && data.latitude && data.longitude) {
    //       const url = `https://www.google.com/maps/search/${data.latitude}+${data.longitude}/`;
    //       const newTab = window.open(url, '_blank');
    //       newTab.focus();
    //     } else {
    //       toast.error('No passenger location', {
    //         position: toast.POSITION.TOP_RIGHT,
    //         autoClose: 10000,
    //       });
    //     }
    //   }),
    // );
    if (data && data?.latitude && data?.longitude) {
      const url = `https://www.google.com/maps/search/${data.latitude}+${data.longitude}/`;
      const newTab = window.open(url, '_blank');
      newTab.focus();
    }
  };

  const downloadReport = () => {
    setShowDateRangeModal(true);
  };
  const downloadReportConfirm = data => {
    dispatch(
        downloadReportTransferVouchersAction(data, (err, blob) => {
          if (!err && blob) {
            handleClose();
          }
        }),
    );
  };
  const downloadKPIReportConfirm = data => {
    dispatch(
        downloadKPIReportTransferVouchersAction(data, (err, blob) => {
          if (!err && blob) {
            handleClose();
          }
        }),
    );
  };


  const columns = getColumns({
    handleUpdateStatus,
    downloadStatus,
    downloadStatusId,
    rows,
    redirectToPreview,
    handleDelete,
    handleDownloadPdf,
    navigate,
    openCommentModal,
    openCopyModal,
    openEmailModal,
    handleState,
    // getPassengerLocation,
    handleNavigateToMap,
    role: !isEmpty(account) ? account.role : '',
  });

  return (
    <div className="admin_tables_block">
      {id ? (
        <PageTitle
          parentTitle={'Transfers'}
          childTitle={id && tableTitle ? tableTitle : childTitle || ''}
          parentLink={`/admin/transfers/`}
        />
      ) : (
        <TableTitle
          handleShow={handleShow}
          title={'Transfer Vouchers'}
          addNew={
            !isEmpty(account) && ['Admin', 'Supplier'].includes(account.role)
          }
          exportExcel={exportExcel}
          selectedRows={selectedRows}
          deleteSelectedRows={deleteSelectedRows}
          fetchStatus={fetchStatus}
          exportExcelStatus={exportExcelStatus}
          downloadReport={downloadReport}
          downloadReportStatus={downloadReportStatus}
        />
      )}

      <SearchFilterBar
        getList={getTransferVoucherList}
        columns={columns}
        setShowFilter={setShowFilter}
        showFilter={showFilter}
      />
      <Table
        status={rowStatus}
        rows={rows}
        columns={columns}
        setShow={setShow}
        setPopupTitle={setPopupTitle}
        redirectToPages={redirectToPages}
        setSort={handleSort}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        checkboxSelection={accessList && accessList.includes('176')}
      />
      <MobileLayout
        rows={rows || []}
        rowStatus={rowStatus}
        columns={columns}
        setShow={setShow}
        setPopupTitle={setPopupTitle}
        redirectToPages={redirectToPages}
        changeStatusHandler={handleConfirmUpdateStatus}
        parentTitle={'Transfer Vouchers'}
        link="/admin/transfer_vouchers"
        mobileCol={transfer_vouchers_mobile_column}
        status={transferVoucherStatus}
        search={getTransferVoucherList}
      />
      <Modal
        size="xl"
        show={show}
        onHide={handleClose}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <TransferVouchersForm
          popupTitle={popupTitle}
          setFormData={setFormData}
          title="add"
          handleClose={handleClose}
          actionStatus={actionStatus}
        />
      </Modal>
      <DeleteModal2
        show={showSelectedDelete}
        handleClose={() => setShowSelectedDelete(false)}
        confirm={() => deleteSelectedRowsConfirm()}
        content={'You are about to delete selected items. Are you sure?'}
        title={'Delete'}
        actionStatus={actionStatus}
      />
      <DeleteModal2
        show={showDelete}
        handleClose={() => handleClose()}
        confirm={e => handleConfirmDelete(e, row)}
        content={`You are about to delete the #${row} tour. Are you sure?`}
        title={'Delete'}
        actionStatus={actionStatus}
      />
      <CommentModal
        show={commentModal}
        handleClose={handleClose}
        title="Add Comment"
        confirm={handleAddComment}
        note={comment}
        clearNote={setComment}
        actionStatus={actionStatus}
      />
      <EmailModal
        show={emailModal}
        handleClose={handleClose}
        title="Send Email"
        confirm={handleSendEmail}
        page="Transfer"
        actionStatus={actionStatus}
      />
      <ConfirmModal
        show={showConfirmModal}
        handleClose={handleClose}
        title="Copy Tour Voucher"
        handleConfirm={handleCopy}
        content={'Make sure to update the data after the copy.'}
        actionStatus={actionStatus}
      />
      <UpdateStatusModal
        show={showStatus}
        handleConfirmUpdate={handleConfirmUpdateStatus}
        handleClose={handleClose}
        text={`You're about to ${
          row?.status === 'Active' ? 'de-activate' : 'activate'
        }  the #${row?.id} Tour Voucher. Are you sure?`}
        title={'Update Tour Voucher'}
        actionStatus={actionStatus}
      />
      <SelectModal
        className="react-select"
        classNamePrefix="react-select"
        Modal
        // title={'Change Tour Voucher State'}
        title={'Change Transfer Voucher Status'}
        select={state}
        confirm={handleConfirmState}
        show={showState}
        handleClose={handleClose}
        setValidate={setNoteValidate}
        validate={noteValidate}
        formData={formData}
        setFormData={setFormData}
        single={row}
        actionStatus={actionStatus}
      />
      <Pagination
        rowStatus={rowStatus}
        count={count}
        limit={10}
        getList={getTransferVoucherList}
      />
      <GenerateTransfersReportModal
          show={showDateRangeModal}
          handleClose={handleClose}
          downloadReport={downloadReportConfirm}
          downloadKPIReport={downloadKPIReportConfirm}
          actionStatus={downloadReportStatus}
      />
      <SeeLocationModal
        type="admin_table"
        data={passengerData}
        handleClose={handleClose}
        show={showPassengersLocation}
        title={`Passenger's Location`}
        url={`https://www.google.com/maps/search/${passengerData?.latitude}+${passengerData?.longitude}/`}
      />
      <div>
        {id && (
          <h4 className="passenger_location_title">Passenger's Locations</h4>
        )}
        {id && coordinates?.length ? (
          <LocationMapTransfer coordinates={coordinates} />
        ) : id && !coordinates?.length ? (
          <NoLocationWindow />
        ) : null}
      </div>
    </div>
  );
}

export default TransferVouchers;
