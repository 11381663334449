import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import {
  addTransferTypeAction,
  getSingleTransferTypeAction,
  getTransferTypesAction,
  updateTransferTypeAction,
} from '../../store/actions/transferTypes';
import { LoadingButton } from '@mui/lab';
import { blockPageAction } from '../../store/actions/users';

function TransferTypesForm({
  popupTitle,
  handleClose,
  singleTransferType,
  actionStatus,
}) {
  const searchConfig = useSelector(store => store.search);
  const blockedPage = useSelector(store => store.search.blockedPage);
  const [formValidation, setFormValidation] = useState({});
  const [formData, setFormData] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [validated, setValidated] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isEmpty(singleTransferType)) {
      setFormData(singleTransferType);
    }
  }, [singleTransferType]);

  useEffect(() => {
    if (
      id &&
      typeof formData === typeof singleTransferType &&
      !isEmpty(singleTransferType)
    ) {
      if (
        JSON.stringify(formData) !== JSON.stringify(singleTransferType) &&
        !blockedPage
      ) {
        dispatch(blockPageAction('/user-management/users/41'));
      }
      if (
        JSON.stringify(formData) === JSON.stringify(singleTransferType) &&
        blockedPage
      ) {
        dispatch(blockPageAction(''));
      }
    }
  }, [formData]);

  const handleChange = (key, val) => {
    setFormData({ ...formData, [key]: val });
    setFormValidation({ ...formValidation, [key]: val });
  };
  const handleEdit = params => {
    setFormData(formData);
    setFormValidation(!!formValidation);
    dispatch(getSingleTransferTypeAction(params));
  };

  const onSubmit = event => {
    event.preventDefault();
    setSubmitted(true);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      setDisabled(true);
      dispatch(
        addTransferTypeAction(formData, (err, data) => {
          if (data) {
            toast.success('Successfully Created', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });
            setValidated(false);
            setFormData({ brand_name: '' });
            dispatch(getTransferTypesAction(searchConfig));
            setDisabled(false);
            handleClose();
            form.reset();
          } else {
            toast.error(err.response.data.errors[0].message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });
          }
        }),
      );
    }
  };

  const onEdit = ev => {
    ev.preventDefault();
    setSubmitted(true);
    const form = ev.currentTarget;
    if (form.checkValidity() === false) {
      ev.preventDefault();
      ev.stopPropagation();
      setValidated(true);
    } else {
      setDisabled(true);
      setValidated(true);
      dispatch(
        updateTransferTypeAction(formData, (err, data) => {
          if (err) {
            toast.error(err.errors.error ? err.errors.error : err.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 10000,
            });
            setValidated(false);
          } else {
            if (data) {
              toast.success('Successfully Updated', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 10000,
              });
              setTimeout(() => {
                setDisabled(false);
              }, 2000);

              // dispatch(clearSearchAction());
              form.reset();
              navigate('/admin/setup/transfer_types');
            }
          }
        }),
      );
    }
  };

  return (
    <>
      <Form
        onSubmit={event => {
          if (id) {
            onEdit(event);
          } else {
            onSubmit(event);
          }
        }}
        noValidate
        validated={validated}
      >
        <Modal.Header closeButton={!id}>
          <Modal.Title>{!id && `${popupTitle} Transfer Types`}</Modal.Title>
        </Modal.Header>
        <div className="form-block">
          <Modal.Body>
            <Row>
              <Col sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Type
                    <span style={{ color: 'red' }}> *</span>
                  </Form.Label>
                  <Form.Control
                    id="transfer_type"
                    name="type"
                    maxLength={254}
                    value={formData?.type}
                    required={true}
                    onChange={e => handleChange('type', e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Required Field
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
        </div>
        <Modal.Footer>
          <div className={id ? 'previewButtonsID' : 'previewButtons'}>
            <LoadingButton
              size="small"
              className="login_submit_btn btn btn-primary "
              loadingPosition="center"
              variant="contained"
              loading={actionStatus === 'pending'}
              type={'submit'}
            >
              <span>{actionStatus === 'pending' ? '' : 'Save'}</span>
            </LoadingButton>
            {id ? (
              <Button variant="secondary" onClick={() => handleEdit(id)}>
                Reset
              </Button>
            ) : (
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Form>
    </>
  );
}

export default TransferTypesForm;
