import { GridActionsCellItem } from '@mui/x-data-grid';
import { MdDelete, MdEdit } from 'react-icons/md';
import { FaToggleOff, FaToggleOn } from 'react-icons/fa';
import React from 'react';
import moment from 'moment';

const { REACT_APP_API_URL } = process.env;

export const getColumns = ({
  handleUpdateStatus,
  redirectToPreview,
  handleDelete,
}) => {
  return [
    {
      field: 'id',
      headerName: '#',
      width: 60,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 100,
    },
    {
      field: 'role',
      headerName: 'Role',
      width: 100,
    },
    {
      field: 'supplier_logo',
      headerName: 'Logo',
      width: 100,
      renderCell: ({ row }) =>
        row?.supplier_logo && (
          <img
            style={{ objectFit: 'contain' }}
            src={`${REACT_APP_API_URL}/supplier_logos/${row?.supplier_logo}`}
            alt=""
          />
        ),
    },
    {
      field: 'name',
      headerName: 'Full Name',
      width: 200,
    },
    {
      field: 'user_name',
      headerName: 'Username',
      width: 200,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 200,
    },
    {
      field: 'phone_number',
      headerName: 'Phone Number',
      width: 160,
      renderCell: ({ row }) => (
        <span style={{ cursor: 'pointer', color: '#0d6efd' }}>
          <a
            onClick={e => e.stopPropagation()}
            style={{ color: '#0d6efd', textDecoration: 'none' }}
            href={`tel:${row['phone_number']}`}
          >
            {row['phone_number']
              ? !row['phone_number'].startsWith('+')
                ? `+${row['phone_number']}`
                : row['phone_number']
              : ''}
          </a>
        </span>
      ),
    },
    {
      field: 'driving_license_number',
      headerName: 'Licence Number',
      width: 160,
    },
    {
      field: 'passport_details',
      headerName: 'Passport Details',
      width: 160,
    },
    {
      field: 'registration_number',
      headerName: 'Registration Number',
      width: 160,
    },
    {
      field: 'vehicle_type',
      headerName: 'Vehicle Type',
      width: 160,
    },
    {
      field: 'created_at',
      headerName: 'Created Date',
      width: 200,
      renderCell: params =>
        moment(params?.created_at || params?.row.created_at).format(
          'MM/DD/YYYY HH:mm:ss',
        ),
    },
    {
      field: 'createdBy.name',
      headerName: 'Created By',
      width: 150,
      renderCell: ({ row }) => row?.createdBy?.name || '',
    },
    {
      field: 'updated_at',
      headerName: 'Modified Date',
      width: 200,
      renderCell: params =>
        moment(params?.updated_at || params?.row.updated_at).format(
          'MM/DD/YYYY HH:mm:ss',
        ),
    },
     {
      field: 'updatedBy.name',
      headerName: 'Modified By',
      width: 150,
      renderCell: ({ row }) => row?.updatedBy?.name || '',
    },
    {
      headerName: 'Actions',
      field: 'actions',
      width: 150,
      cellClassName: 'actions',
      type: 'actions',
      getActions: params => [
        <GridActionsCellItem
          onClick={() => {
            if (
              params?.row?.status === 'Active' ||
              params?.status === 'Active'
            ) {
              redirectToPreview(params?.row?.id || params?.id);
            }
          }}
          style={
            params?.row?.status === 'Active' || params?.status === 'Active'
              ? {
                  cursor: 'pointer',
                }
              : {
                  cursor: 'auto',
                  opacity: 0.4,
                }
          }
          className="test1"
          icon={<MdEdit />}
          label="Edit"
          title="View/Edit"
        />,
        <GridActionsCellItem
          onClick={ev => handleUpdateStatus(params?.row)}
          icon={
            params?.status === 'Active' || params?.row?.status === 'Active' ? (
              <FaToggleOn />
            ) : (
              <FaToggleOff />
            )
          }
          label="Status"
          title={
            params?.status === 'Active' || params.row?.status === 'Active'
              ? 'Disable Login'
              : 'Enable login'
          }
        />,
        <GridActionsCellItem
          onClick={ev => handleDelete(params?.row)}
          label="Delete"
          title={'Delete'}
          icon={<MdDelete />}
        />,
      ],
    },
  ];
};
