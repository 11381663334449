import { api } from './Api';
import axios from 'axios';
import Account from '../services/Account';
const { REACT_APP_API_URL } = process.env;
export default class Settings {
  static getSettings() {
    return api.get(`/settings/get_settings`);
  }

  static updateSettings({
    company_name,
    logo,
    favicon,
    logo_file,
    favicon_file,
  }) {
    const formData = new FormData();
    formData.append('company_name', company_name);
    formData.append('logo', logo);
    formData.append('favicon', favicon);
    formData.append('logo_file', logo_file);
    formData.append('favicon_file', favicon_file);

    const res = axios.post(`${REACT_APP_API_URL}/settings/update`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: '*/*',
        Authorization: Account.getToken() && `Bearer ${Account.getToken()}`,
      },
    });
    return res;
  }
  static getTheme(mode) {
    return api.get(`/settings/get_theme/${mode}`);
  }
  static getThemeList() {
    return api.get(`/settings/get_theme_list`);
  }
  static setTheme(palette_id, mode) {
    return api.put(`/settings/set_theme`, {
      palette_id,
      mode,
    });
  }
  static changeThemeMode(palette_id, mode) {
    return api.put(`/settings/theme_mode_change`, {
      palette_id,
      mode,
    });
  }
  static toDefaultTheme() {
    return api.put(`/settings/reset_theme`);
  }
}
