import { Button, Col, Form, Modal, Row, InputGroup } from 'react-bootstrap';
import React, { useEffect, useRef } from 'react';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import 'react-datepicker/dist/react-datepicker.css';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { MdOutlineClear } from 'react-icons/md';
import PhoneInput from 'react-phone-input-2';
import { yupResolver } from '@hookform/resolvers/yup';
import { supplierValidation } from './validationSchema';
import { handleUpdateMyProfile } from '../../../store/actions/users';
import { LoadingButton } from '@mui/lab';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { Riple } from 'react-loading-indicators';

function SuppliersForm({ single, actionStatus, getMyAccountData }) {
  const [pass, setPass] = React.useState({
    old: true,
    new: true,
    new_confirm: true,
  });
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors, isValid },
    setError,
    reset,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(supplierValidation),
    defaultValues: { ...single } || {},
  });

  const handlePassVis = key => {
    if (pass[key] === true) {
      setPass({ ...pass, [key]: false });
    } else {
      setPass({ ...pass, [key]: true });
    }
  };

  useEffect(() => {
    if (single) {
      for (const [key, value] of Object.entries(single)) {
        setValue(key, value);
      }
    }
  }, [single, setValue]);

  const handlePhotoChange = (key, val) => {
    if (val) {
      setValue(key, val.name);
      setValue(`${key}_file`, val);
    }
  };

  const handleRemoveImg = () => {
    setValue('supplier_logo', null);
    setValue('supplier_logo_file', null);
  };

  const onEdit = data => {
    if (data.password && data.confirm_password && !data.old_password) {
      setError('old_password', { type: 'custom', message: 'Required Field' });
      return;
    }
    dispatch(
      handleUpdateMyProfile(data, (err, data) => {
        if (err) {
          toast.error(err.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 10000,
          });
        } else {
          if (data && data?.status === 200) {
            toast.success('Successfully Updated', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 10000,
            });
            setValue('old_password', '');
            setValue('password', '');
            setValue('confirm_password', '');
          }
        }
      }),
    );
  };
  const formData = watch();
  return (
    <>
      {actionStatus === 'pending' ? (
        <div className="dashboard-empty-div">
          <Riple color="#134074" size="large" text="" textColor="" />
        </div>
      ) : (
        <FormProvider
          {...{
            control,
            register,
            handleSubmit,
            setValue,
            watch,
            formState: { errors, isValid },
          }}
        >
          <Form onSubmit={handleSubmit(onEdit)} noValidate>
            <Modal.Body>
              <div>
                <h5 style={{ fontSize: 17 }}>Personal Information</h5>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Full Name<span style={{ color: 'red' }}> *</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        minLength={2}
                        maxLength={254}
                        {...register('name', { required: true })}
                        isInvalid={errors?.name}
                      />
                      <Form.Control.Feedback type="invalid">
                        Required Field
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Label>
                      Email<span style={{ color: 'red' }}> *</span>
                    </Form.Label>
                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <Form.Control
                          type="email"
                          {...field}
                          isInvalid={errors.email}
                        />
                      )}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email?.message}
                    </Form.Control.Feedback>
                  </Col>
                  <Col>
                    <Form.Label>
                      Phone Number<span style={{ color: 'red' }}> *</span>
                    </Form.Label>
                    <PhoneInput
                      inputProps={{
                        name: 'phone_number',
                      }}
                      value={watch('phone_number') || ''}
                      onChange={value => {
                        setValue('phone_number', value);
                      }}
                    />
                    {errors.phone_number?.message && (
                      <span className="invalid_message">
                        {errors.phone_number?.message}
                      </span>
                    )}
                  </Col>
                  <Col sm={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>Supplier Logo</Form.Label>
                      <div className="file-input file-upload">
                        <label htmlFor="supplier_logo">
                          <input
                            className="choose-btn"
                            type="button"
                            value="Choose"
                            onClick={() => fileInputRef.current.click()}
                          />
                        </label>
                        <input
                          className="border-none"
                          type="text"
                          value={formData.supplier_logo || ''}
                          readOnly
                        />
                        <input
                          type="file"
                          id="supplier_logo"
                          accept="image/*"
                          ref={fileInputRef}
                          onChange={e =>
                            handlePhotoChange(
                              'supplier_logo',
                              e.target.files[0],
                            )
                          }
                        />
                        {formData.supplier_logo && (
                          <MdOutlineClear
                            className="remove_img"
                            onClick={handleRemoveImg}
                          />
                        )}
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={5}
                      {...register('description')}
                      maxLength={4000}
                      value={watch('description') || ''}
                    />
                    <div className="max_style">
                      Max length of description is 4000 characters
                    </div>
                  </Col>
                </Row>
              </div>
              <div>
                <h5 style={{ fontSize: 17 }}>Change Password</h5>
                <Row>
                  <Col sm={4}>
                    <Form.Group className="mb-3 pass_input">
                      <Form.Group className="mb-3">
                        <Form.Label>Current Password</Form.Label>
                        <InputGroup className="input_block">
                          <Form.Control
                            type={pass.old ? 'password' : 'text'}
                            autoComplete="off"
                            name="old_password"
                            value={watch('old_password') || ''}
                            {...register('old_password')}
                            className={errors?.old_password && 'is-invalid'}
                          />
                          <InputGroup.Text
                            id="basic-addon1"
                            onClick={() => handlePassVis('old')}
                          >
                            {pass.old ? (
                              <FiEyeOff cursor="pointer" />
                            ) : (
                              <FiEye cursor="pointer" />
                            )}
                          </InputGroup.Text>
                        </InputGroup>
                        <span className="custom-invalid-feedback">
                          {errors.old_password?.message}
                        </span>
                      </Form.Group>
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    <Form.Group className="mb-3 pass_input">
                      <Form.Group className="mb-3">
                        <Form.Label>New Password</Form.Label>
                        <InputGroup className="input_block">
                          <Form.Control
                            type={pass.new ? 'password' : 'text'}
                            name="password"
                            className={errors?.password && 'is-invalid'}
                            autoComplete="off"
                            value={watch('password') || ''}
                            {...register('password')}
                          />
                          <InputGroup.Text
                            id="basic-addon1"
                            onClick={() => handlePassVis('new')}
                          >
                            {pass.new ? (
                              <FiEyeOff cursor="pointer" />
                            ) : (
                              <FiEye cursor="pointer" />
                            )}
                          </InputGroup.Text>
                        </InputGroup>
                        <span className="custom-invalid-feedback">
                          {errors.password?.message}
                        </span>
                      </Form.Group>
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    <Form.Group className="mb-3 pass_input">
                      <Form.Group className="mb-3">
                        <Form.Label>Confirm Password</Form.Label>
                        <InputGroup className="input_block">
                          <Form.Control
                            type={pass.new_confirm ? 'password' : 'text'}
                            name="confirm_password"
                            className={errors?.confirm_password && 'is-invalid'}
                            autoComplete="off"
                            value={watch('confirm_password') || ''}
                            {...register('confirm_password')}
                          />
                          <InputGroup.Text
                            id="basic-addon1"
                            onClick={() => handlePassVis('new_confirm')}
                          >
                            {pass.new_confirm ? (
                              <FiEyeOff cursor="pointer" />
                            ) : (
                              <FiEye cursor="pointer" />
                            )}
                          </InputGroup.Text>
                        </InputGroup>
                        <span className="custom-invalid-feedback">
                          {errors.confirm_password?.message}
                        </span>
                      </Form.Group>
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="previewButtonsID">
                <LoadingButton
                  size="small"
                  className="login_submit_btn btn btn-primary "
                  loadingPosition="center"
                  variant="contained"
                  loading={actionStatus === 'pending'}
                  type={'submit'}
                >
                  <span>{actionStatus === 'pending' ? '' : 'Save'}</span>
                </LoadingButton>
                <Button variant="secondary" onClick={() => getMyAccountData()}>
                  Reset
                </Button>
              </div>
            </Modal.Footer>
          </Form>
        </FormProvider>
      )}
    </>
  );
}

export default SuppliersForm;
