import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageTitle from '../../components/PageTitle.js';
import { useParams } from 'react-router-dom';
import { FadeLoader } from 'react-spinners';
import DirectionsForm from './DirectionsForm.js';
import { getSingleDirection } from '../../store/actions/directions';
import { blockPageAction } from '../../store/actions/users';

function PreviewDirections() {
  const rowStatus = useSelector(store => store?.directions?.status);
  const actionStatus = useSelector(store => store?.directions?.actionStatus);
  const [formData, setFormData] = useState({});
  const [siteName, setSiteName] = useState('');
  const [formValidation, setFormValidation] = useState({});
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    handleGetSingle(id);
    return () => {
      dispatch(blockPageAction(''));
    };
  }, []);

  // Get the row data to edit
  const handleGetSingle = id => {
    dispatch(
      getSingleDirection(id, (err, data) => {
        if (data && data.result) {
          setFormData(data.result);
        }
        // setFormValidation(!!formValidation);
      }),
    );
    return () => {};
  };

  // Close Add/Edit modal
  const handleClose = () => {
    setFormData({});
  };

  return (
    <>
      <PageTitle
        parentTitle={'Directions'}
        childTitle={formData.direction_name || ''}
        parentLink={'/admin/directions'}
      />
      <div className="form-block">
        {rowStatus === 'success' ? (
          <DirectionsForm
            title="edit"
            formValidation={formValidation}
            handleClose={handleClose}
            singleDirection={formData}
            handleEdit={handleGetSingle}
            actionStatus={actionStatus}
          />
        ) : (
          <div className="form-loading">
            <FadeLoader
              color={`var(--primary_main)`}
              loading={rowStatus === 'pending'}
              size={10}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )}
      </div>
    </>
  );
}

export default PreviewDirections;
