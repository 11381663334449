import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Table from '../../components/table';
import Pagination from '../../components/pagination/Pagination';
import SearchFilterBar from '../../components/search_filter_bar/SearchFilterBar';
import TableTitle from '../../components/table_title/TableTitle';
import {
  clearSearchAction,
  setPage,
  sortAction,
} from '../../store/actions/search';
import DeleteModal2 from '../../components/modals/DeleteModal2';
import { getColumns } from './column';
import {
  changeTourStatusAction,
  deleteTourTransfer,
  deleteSelectedToursTransfers,
  getToursTransfersAction,
  addCommentTourTransfersAction,
  copyTourTransfer,
  sendEmailTourTransferAction,
  exportExcelTourTransfersAction,
  updateTourTransfer,
} from '../../store/actions/toursTransfers';
import TourTransfersForms from './TourTransfersForms';
import { isEmpty } from 'lodash';
import CommentModal from '../../components/modals/CommentModal';
import ConfirmModal from '../../components/modals/ConfirmModal';
import EmailModal from '../../components/modals/EmailModal';
import UpdateStatusModal from '../../components/modals/UpdateStatusModal';
import { updateTourPickUpId } from '../../store/actions/tours';
import EditDriverLocationModal from '../../components/modals/EditDriverLocationModal';
import MobileLayout from '../../components/mobileLayout/MobileLayout';
import { transfers_mobile_column } from '../../staticVariables/mobileColumns';

export const transfersStatus = [
  {
    id: 'Active',
    color: 'green',
  },
  {
    id: 'Inactive',
    color: '#dc3545',
  },
];

function TourTransfers() {
  let rows = useSelector(store => store?.toursTransfers?.toursTransfers);
  let rowStatus = useSelector(store => store?.toursTransfers?.status);
  let actionStatus = useSelector(store => store?.toursTransfers?.actionStatus);
  let exportExcelStatus = useSelector(
    store => store?.toursTransfers?.exportExcelStatus,
  );
  let searchConfig = useSelector(store => store.search);
  const count = useSelector(store => store.toursTransfers.count);
  const account = useSelector(store => store.users.account);
  const [showSelectedDelete, setShowSelectedDelete] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showDriverLocationModal, setShowDriverLocationModal] = useState(false);
  const [commentModal, setCommentModal] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const [noteValidate, setNoteValidate] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [popupTitle, setPopupTitle] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [formData, setFormData] = useState({});
  const [rowNote, setRowNote] = useState('');
  const [show, setShow] = useState(false);
  const [rowId, setRowId] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    getTourTransfers(searchConfig);
    return () => {
      dispatch(clearSearchAction());
    };
  }, []);

  const getTourTransfers = searchConfig => {
    dispatch(getToursTransfersAction(searchConfig));
  };

  // Redirect to preview page on click the data of the table
  const redirectToPages = event => {
    if (event.field == 'recovery_email' && event.row.recovery_email != null) {
      for (var value of rows) {
        if (value.email == event.formattedValue) {
          navigate(`/admin/transfers/${value.id}`);
        }
      }
    }
  };

  const redirectToPreview = useCallback(params => {
    navigate(`/admin/transfers/${params}`);
  }, []);

  const handleClose = () => {
    setShowDelete(false);
    setDisabled(false);
    setShow(false);
    setPopupTitle('');
    setFormData({});
    setShowStatus(false);
    setNoteValidate(true);
    setCommentModal(false);
    setShowConfirmModal(false);
    setEmailModal(false);
    setRowNote('');
  };

  const handleDelete = (ev, params) => {
    ev.stopPropagation();
    setShowDelete(true);
    setRowId(params.id);
  };

  const handleConfirmDelete = (e, id) => {
    setDisabled(true);
    dispatch(
      deleteTourTransfer(id, (err, data) => {
        if (data) {
          toast.success('Successfully Deleted', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 10000,
          });
          if (rows && rows.length === 1 && searchConfig.page !== 1) {
            searchConfig.page = searchConfig.page - 1;
            dispatch(setPage(searchConfig.page));
          }
          setRowId(null);
          setShowDelete(false);
        }
        if (err?.response?.status === 403) {
          toast.error(err?.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 10000,
          });
          handleClose();
        }
        getTourTransfers(searchConfig);
      }),
    );
  };

  const handleShow = title => {
    setShow(true);
    setPopupTitle(title);
  };

  const handleSort = (model, page) => {
    if (model.length) {
      searchConfig.sort = model;
      dispatch(sortAction(model));
    } else {
      model = searchConfig.sort;
    }
    getTourTransfers(searchConfig);
  };

  const exportExcel = params => {
    dispatch(exportExcelTourTransfersAction(searchConfig));
  };

  const deleteSelectedRows = () => {
    setShowSelectedDelete(true);
  };
  const deleteSelectedRowsConfirm = reason => {
    dispatch(
      deleteSelectedToursTransfers(selectedRows, (err, data) => {
        if (data) {
          setSelectedRows([]);
          getTourTransfers(searchConfig);
          isEmpty(data)
            ? toast.success(`Successfully Deleted.`, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 10000,
              })
            : toast.success(
                `Successfully Deleted. Some of selected transfers can't be deleted.`,
                {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 10000,
                },
              );

          setShowSelectedDelete(false);
        }
      }),
    );
  };

  const handleUpdateStatus = params => {
    setRowId(params);
    setShowStatus(true);
  };

  const handleConfirmUpdateStatus = () => {
    dispatch(
      changeTourStatusAction(rowId?.id, (err, data) => {
        if (data) {
          toast.success('Successfully Updated', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 10000,
          });
        } else if (err?.response?.status === 403) {
          toast.error(err?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 10000,
          });
          handleClose();
        } else if (err) {
          toast.error(err?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 10000,
          });
        }
        getTourTransfers(searchConfig);
        setShowStatus(false);
      }),
    );
  };

  const openCommentModal = params => {
    setRowId(params.id || params.row.id);
    const row = rows.find(r => r.id === params.id);
    if (row && row.hidden_note) {
      setRowNote(row.hidden_note);
    } else {
      setRowNote('');
    }
    setCommentModal(true);
  };

  const handleAddComment = (ev, hidden_note) => {
    dispatch(
      addCommentTourTransfersAction(rowId, hidden_note, (err, data) => {
        if (data) {
          handleClose();
          getTourTransfers(searchConfig);
        }
      }),
    );
  };

  const handleCopy = params => {
    dispatch(
      copyTourTransfer(rowId, (err, data) => {
        if (data) {
          handleClose();
          getTourTransfers(searchConfig);
          toast.success('Successfully Copied', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 10000,
          });
        }
      }),
    );
  };
  const openCopyModal = params => {
    setRowId(params.id || params.row.id);
    setShowConfirmModal(true);
  };

  const openEmailModal = params => {
    setRowId(params.id || params.row.id);
    setEmailModal(true);
  };

  const handleSendEmail = emailForm => {
    dispatch(
      sendEmailTourTransferAction(emailForm, rowId, (err, data) => {
        if (data) {
          handleClose();
          toast.success('Successfully Sent', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 10000,
          });
        }
      }),
    );
  };
  const redirectToPreviewTransferVoucher = useCallback(params => {
    navigate(`/admin/transfers_vouchers/${params}`);
  }, []);

  const handleEditDriverLocation = data => {
    dispatch(
      updateTourTransfer({ ...data }, (err, data) => {
        if (err) {
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
        } else {
          if (data) {
            toast.success('Successfully Updated', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 10000,
            });

            handleCloseDriverModal();
            getTourTransfers(searchConfig);
          }
        }
      }),
    );
  };

  const handleOpenDriverModal = tour => {
    setFormData({ ...tour });
    setShowDriverLocationModal(true);
  };

  const handleCloseDriverModal = tour => {
    setFormData({});
    setShowDriverLocationModal(false);
  };

  const columns = getColumns({
    handleUpdateStatus,
    rows,
    redirectToPreview,
    handleDelete,
    navigate,
    redirectToPreviewTransferVoucher,
    openCommentModal,
    openCopyModal,
    openEmailModal,
    handleOpenDriverModal,
    role: !isEmpty(account) ? account.role : '',
  });

  return (
    <div className="admin_tables_block">
      <TableTitle
        handleShow={handleShow}
        title={'Transfers'}
        addNew={account && account.role === 'Admin'}
        exportExcel={
          !isEmpty(account) && account.role === 'Admin' ? exportExcel : null
        }
        batchAccess={'1103'}
        batchDelete={'1108'}
        selectedRows={selectedRows}
        deleteSelectedRows={deleteSelectedRows}
        exportExcelStatus={exportExcelStatus}
      />
      <SearchFilterBar
        getList={getTourTransfers}
        columns={columns}
        setShowFilter={setShowFilter}
        showFilter={showFilter}
      />
      <Table
        status={rowStatus}
        rows={rows}
        columns={columns}
        setShow={setShow}
        setPopupTitle={setPopupTitle}
        redirectToPages={redirectToPages}
        parentTitle={'companies'}
        link="/admin/transfers"
        fetch={true}
        setSort={handleSort}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
      />
      <MobileLayout
        rows={rows || []}
        rowStatus={rowStatus}
        columns={columns}
        setShow={setShow}
        setPopupTitle={setPopupTitle}
        redirectToPages={redirectToPages}
        changeStatusHandler={handleConfirmUpdateStatus}
        parentTitle={'Transfers'}
        link="/admin/transfers"
        mobileCol={transfers_mobile_column}
        status={transfersStatus}
        search={getTourTransfers}
      />
      <Modal
        size="xl"
        show={show}
        onHide={handleClose}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <TourTransfersForms
          popupTitle={popupTitle}
          setFormData={setFormData}
          title="add"
          handleClose={handleClose}
          actionStatus={actionStatus}
        />
      </Modal>
      <DeleteModal2
        show={showSelectedDelete}
        handleClose={() => setShowSelectedDelete(false)}
        confirm={() => deleteSelectedRowsConfirm()}
        content={'You are about to delete selected items. Are you sure?'}
        title={'Delete'}
        actionStatus={actionStatus}
      />
      <DeleteModal2
        show={showDelete}
        handleClose={() => handleClose()}
        confirm={e => handleConfirmDelete(e, rowId)}
        content={`You are about to delete the #${rowId} transfer. Are you sure?`}
        title={'Delete'}
        actionStatus={actionStatus}
      />
      <CommentModal
        show={commentModal}
        handleClose={handleClose}
        title="Add Comment"
        confirm={handleAddComment}
        note={rowNote}
        clearNote={setRowNote}
        actionStatus={actionStatus}
      />
      <EmailModal
        show={emailModal}
        handleClose={handleClose}
        title="Send Email"
        confirm={handleSendEmail}
        actionStatus={actionStatus}
      />
      <ConfirmModal
        show={showConfirmModal}
        handleClose={handleClose}
        title="Copy Transfer Voucher"
        handleConfirm={handleCopy}
        content={'Make sure to update the data after the copy.'}
        actionStatus={actionStatus}
      />
      {!isEmpty(formData) && (
        <EditDriverLocationModal
          className="react-select"
          classNamePrefix="react-select"
          Modal
          title={'Change Driver Location Url'}
          confirm={handleEditDriverLocation}
          show={showDriverLocationModal}
          handleClose={handleCloseDriverModal}
          setValidate={setNoteValidate}
          validate={noteValidate}
          formData={formData}
          setFormData={setFormData}
          actionStatus={actionStatus}
        />
      )}
      <UpdateStatusModal
        show={showStatus}
        handleConfirmUpdate={handleConfirmUpdateStatus}
        handleClose={handleClose}
        text={`You're about to ${
          rowId?.status === 'Active' ? 'de-activate' : 'activate'
        }  the #${rowId?.id} Transfer Voucher. Are you sure?`}
        title={'Update Transfer Voucher'}
        actionStatus={actionStatus}
      />
      <Pagination
        rowStatus={rowStatus}
        count={count}
        limit={10}
        getList={getTourTransfers}
      />
    </div>
  );
}

export default TourTransfers;
