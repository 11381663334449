import {GridActionsCellItem} from '@mui/x-data-grid';
import {MdAttachEmail, MdDelete, MdEdit} from 'react-icons/md';
import moment from 'moment/moment';
import {BsChatText, BsChatTextFill} from 'react-icons/bs';
import {FaFilePdf, FaHistory} from 'react-icons/fa';
import React from 'react';
import {AiFillCopy} from 'react-icons/ai';
import {ImLocation2} from 'react-icons/im';
import {LoadingButton} from '@mui/lab';
import {styled} from '@mui/material/styles';
import Tooltip, {tooltipClasses} from '@mui/material/Tooltip';
import {Typography} from '@material-ui/core';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {BiCommentDetail} from 'react-icons/bi';

const HtmlTooltip = styled(({className, ...props}) => (
    <Tooltip {...props} classes={{popper: className}}/>))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: '400px',
        display: 'flex',
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));

export const getColumns = ({
                               redirectToPreview,
                               handleDelete,
                               handleUpdateStatus,
                               downloadStatus,
                               downloadStatusId,
                               downloadedPdfStat,
                               isClickAllowed,
                               openCommentModal,
                               openCopyModal,
                               openEmailModal,
                               handleState,
                               role,
                               handleDownloadPdf,
                               handleNavigateToMap, // getPassengerLocation,
                               navigate,
                           }) => {
    return [{
        access: ['Admin', 'Supplier', 'Sales Agent'], field: 'id', headerName: '#', width: 70,
    }, {
        access: ['Admin', 'Supplier', 'Sales Agent'],
        field: 'ag_ref_number',
        headerName: 'Ag. Reference Number',
        width: 200,
    }, {
        access: ['Admin', 'Sales Agent'],
        field: 'original_reference_number',
        headerName: 'Reference Number',
        width: 200,
    }, {
        access: ['Admin', 'Sales Agent'], field: 'reference_number', headerName: 'Confirmation Code', width: 200,
    }, {
        access: ['Admin', 'Supplier', 'Sales Agent'], field: 'passenger_name', headerName: 'Passenger Name', width: 180,
    }, {
        access: ['Admin', 'Supplier'],
        field: 'salesAgent.name',
        headerName: 'Sales Agent',
        width: 180,
        renderCell: ({row}) => {
            if (row?.sales_agent_id?.name) {
                if (role === 'Supplier' || role === 'Sales Agent') {
                    return row?.sales_agent_id?.name;
                } else {
                    return (<span
                        onClick={ev => navigate(`/admin/users/${row?.sales_agent_id?.id}`)}
                        className="link-span-in-table"
                    >
                {row?.sales_agent_id?.name || ''}
              </span>);
                }
            }
        },
    }, {
        access: ['Admin', 'Supplier', 'Sales Agent'],
        field: 'tour.tour_name',
        headerName: 'Tour Name',
        width: 180,
        renderCell: ({row}) => {
            if (row.tourId?.tour_name) {
                if (role === 'Supplier' || role === 'Sales Agent') {
                    return row.tourId?.tour_name;
                } else {
                    return (<span
                        onClick={ev => navigate(`/admin/tours/${row?.tourId?.id}`)}
                        className="link-span-in-table"
                    >
                {row?.tourId?.tour_name || ''}
              </span>);
                }
            }
        },
    }, {
        access: ['Admin', 'Supplier', 'Sales Agent'], field: 'accommodation', headerName: 'Accommodation', width: 150,
    }, {
        access: ['Admin', 'Supplier', 'Sales Agent'],
        field: 'tour.service_date',
        headerName: 'Service Date',
        width: 150,
        renderCell: ({row}) => {
            if (row?.tourId?.service_date) {
                return moment(row.tourId?.service_date).format('MM/DD/YYYY');
            }
        },
    }, {
        access: ['Admin', 'Supplier', 'Sales Agent'],
        field: 'supplierUser.name',
        headerName: 'Supplier Name',
        width: 180,
        renderCell: ({row}) => {
            if (row.tourId?.supplierUserId?.name) {
                if (role === 'Supplier' || role === 'Sales Agent') {
                    return row.tourId?.supplierUserId?.name;
                } else {
                    return (<span
                        onClick={ev => navigate(`/admin/users/${row?.tourId.supplierUserId?.id}`)}
                        className="link-span-in-table"
                    >
                {row.tourId?.supplierUserId?.name || ''}
              </span>);
                }
            }
        },
    }, {
        access: ['Admin', 'Supplier', 'Sales Agent'],
        field: 'status',
        headerName: 'Status',
        width: 120,
        renderCell: ({row}) => {
            if (row?.state === 'Cancelled' || row?.state === 'Rejected') {
                return (<div style={{display: 'flex', cursor: 'pointer'}}>
                    <HtmlTooltip
                        className="loggerTooltip"
                        style={{
                            flexWrap: 'wrap', backgroundColor: 'transparent', width: '300px', cursor: 'pointer',
                        }}
                        title={window?.innerWidth < 1024 ? null : (<React.Fragment>
                            <div
                                style={{
                                    overflowY: 'hidden',
                                }}
                            >
                                <Typography color="inherit">
                                    <p style={{width: '100%', fontSize: '0.9em'}}>
                                        {row?.cancellation_reason}
                                    </p>
                                </Typography>
                            </div>
                        </React.Fragment>)}
                    >
                        <div style={{display: 'flex'}}>
                            {row?.state}{' '}
                            <BiCommentDetail size={18} style={{marginLeft: '10px'}}/>
                        </div>
                    </HtmlTooltip>
                </div>);
            } else {
                return row?.state;
            }
        },
    }, {
        access: ['Admin', 'Sales Agent'], field: 'source', headerName: 'Source', width: 100,
    },

        {
            access: ['Admin', 'Supplier', 'Sales Agent'],
            field: 'partner_ref_number',
            headerName: 'Partner Reference Number',
            width: 200,
        },

        // {
        //   access: ['Admin', 'Supplier', 'Sales Agent'],
        //   field: 'tourId',
        //   headerName: 'Direction',
        //   width: 180,
        //   renderCell: ({ row }) => {
        //     if (row.tourId?.directionId?.direction_name) {
        //       return (
        //         <span
        //           onClick={ev =>
        //             navigate(`/admin/directions/${row.tourId?.directionId?.id}`)
        //           }
        //           className="link-span-in-table"
        //         >
        //           {row.tourId?.directionId?.direction_name || ''}
        //         </span>
        //       );
        //     }
        //   },
        // },
        {
            access: ['Admin', 'Sales Agent'], field: 'booking_direction', headerName: 'Booking Direction', width: 180,
        }, {
            access: ['Admin', 'Supplier', 'Sales Agent'],
            field: 'pickUp.pick_up_name',
            headerName: 'Pick-up Name',
            width: 180,
            renderCell: ({row}) => {
                if (row.tourId?.pick_upId?.pick_up_name) {
                    if (role === 'Supplier' || role === 'Sales Agent') {
                        return row.tourId?.pick_upId?.pick_up_name;
                    } else {
                        return (<span
                            onClick={ev => navigate(`/admin/pick_up_points/${row.tourId?.pick_upId?.id}`)}
                            className="link-span-in-table"
                        >
                {row.tourId?.pick_upId?.pick_up_name || ''}
              </span>);
                    }
                }
            },
        }, {
            access: ['Admin', 'Supplier', 'Sales Agent'], field: 'adult_members', headerName: 'Adults', width: 80,
        }, {
            access: ['Admin', 'Supplier', 'Sales Agent'], field: 'child_members', headerName: 'Children', width: 80,
        }, {
            access: ['Admin', 'Supplier', 'Sales Agent'], field: 'infant_members', headerName: 'Infants', width: 80,
        }, {
            access: ['Admin', 'Supplier', 'Sales Agent'],
            field: 'total_passengers',
            headerName: 'Total Passengers',
            width: 100,
            renderCell: ({row}) => {
                const sum = row?.adult_members + row?.child_members + row?.infant_members;
                return sum || 0;
            },
        }, {
            access: ['Admin', 'Sales Agent'],
            field: 'created_at',
            headerName: 'Created Date',
            width: 200,
            renderCell: params => {
                if (params?.created_at || params?.row?.created_at) {
                    return moment(params?.created_at || params?.row?.created_at).format('MM/DD/YYYY HH:mm:ss',);
                }
            },
        },

        {
            access: ['Admin', 'Sales Agent'],
            field: 'createdBy.name',
            headerName: 'Created By',
            width: 150,
            renderCell: ({row}) => row?.createdBy?.name || '',
        }, {
            access: ['Admin', 'Sales Agent'],
            field: 'updated_at',
            headerName: 'Modified Date',
            width: 200,
            renderCell: params => {
                if (params?.updated_at || params?.row?.updated_at) {
                    return moment(params?.updated_at || params?.row?.updated_at).format('MM/DD/YYYY HH:mm:ss',);
                }
            },
        }, {
            access: ['Admin', 'Sales Agent'],
            field: 'updatedBy.name',
            headerName: 'Modified By',
            width: 150,
            renderCell: ({row}) => row?.updatedBy?.name || '',
        }, {
            access: ['Admin', 'Supplier', 'Sales Agent'],
            headerName: 'Actions',
            field: 'actions',
            width: role === 'Sales Agent' ? 100 : 280,
            cellClassName: 'actions',
            type: 'actions',
            getActions: params => [<GridActionsCellItem
                onClick={() => redirectToPreview(params?.id)}
                className={''}
                icon={<MdEdit className={''}/>}
                label="Edit"
                title="View/Edit"
                access={['Admin', 'Sales Agent']}
            />, <GridActionsCellItem
                onClick={() => {
                    if (params?.latitude && params?.longitude) {
                        // getPassengerLocation(row);
                        handleNavigateToMap(params);
                    }
                }}
                icon={<ImLocation2/>}
                label="Edit"
                title={params?.location_date && `Shared At: ${moment(params?.location_date).format('L HH:mm:ss')}`}
                className={(!params?.latitude || !params?.longitude) && 'disabled_action'}
                access={['Admin', 'Supplier']}
            />, <GridActionsCellItem
                onClick={() => // row?.status != 'Inactive' &&
                    openEmailModal(params)}
                // className={row?.status == 'Inactive' && 'disabled'}
                icon={<MdAttachEmail
                    size={18}
                    // className={row?.status == 'Inactive' && 'disabled'}
                />}
                label="Send Email"
                title="Send email to supplier"
                access={['Admin']}
                // disabled={row?.status == 'Inactive'}
            />,

                <GridActionsCellItem
                    icon={
                        <LoadingButton
                            color="primary"
                            onClick={(ev) =>
                                handleDownloadPdf(ev, {
                                    id: params?.id,
                                    reference_number:
                                        params?.reference_number || params?.row?.reference_number,
                                    ag_ref_number:
                                        params?.ag_ref_number || params?.row?.ag_ref_number,
                                })
                            }
                            style={{ padding: 0, minWidth: 0 }}
                            loading={downloadedPdfStat === 'pending'}
                            disabled={downloadedPdfStat === 'pending'}
                            loadingPosition="center"
                        >
                            <FaFilePdf size={16} />
                        </LoadingButton>
                    }
                    className="test1"
                    label="Download Pdf"
                    title="Download Pdf"
                    access={['Admin', 'Supplier']}
                />,
                <GridActionsCellItem
                    onClick={() => openCommentModal(params)}
                    className="test1"
                    icon={params?.comment || params?.row?.comment ? (<BsChatTextFill size={18}/>) : (
                        <BsChatText size={18}/>)}
                    label="Add Comment"
                    title="Add Comment"
                    access={['Admin']}
                />, // <GridActionsCellItem
                //   onClick={() => openCopyModal(params)}
                //   className="test1"
                //   icon={<AiFillCopy size={18} />}
                //   label="Copy"
                //   title="Copy"
                //   access={['Admin']}
                // />,
                <GridActionsCellItem
                    icon={<FaHistory
                        // className={row?.status == 'Inactive' && 'disabled'}
                    />}
                    label="State"
                    title="Change Status"
                    onClick={// row?.status == 'Inactive'
                        //   ? null
                        //   :
                        ev => {
                            ev.stopPropagation();
                            handleState(ev, params);
                        }}
                    access={['Admin']}
                    // disabled={row?.status == 'Inactive'}
                />, // <GridActionsCellItem
                //   style={{
                //     cursor: 'pointer',
                //   }}
                //   onClick={ev => handleUpdateStatus(row)}
                //   icon={row?.status === 'Active' ? <FaToggleOn /> : <FaToggleOff />}
                //   label="Status"
                //   title="Change Status"
                // />,
                <GridActionsCellItem
                    icon={<MdDelete/>}
                    label="Delete"
                    title="Delete"
                    onClick={ev => handleDelete(ev, params?.id)}
                    access={['Admin']}
                />,].filter(({props}) => {
                return props?.access?.includes(role);
            }),
        },].filter(r => r?.access?.includes(role));
};
