import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import './web_search.scss';
import { useDispatch } from 'react-redux';
import { checkVoucherAction } from '../../store/actions/tourVouchers';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { checkTransferVoucherAction } from '../../store/actions/transferVouchers';

export default function WebSearch({ searchTitle, title }) {
  const [formData, setFormData] = useState({});
  const [submit, setSubmit] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setFormData({});
    setSubmit(false);
  }, [title]);

  const handleChange = (key, val) => {
    setFormData({ ...formData, [key]: val });
  };
  const checkTransfer = () => {
    dispatch(
      checkTransferVoucherAction(formData, (err, data) => {
        if (data?.status == 200) {
          navigate(`/generate_pdf/transfer/${data?.result?.ag_ref_number}`);
        }
        if (err) {
          toast.error(err);
        }
        setLoading(false);
      }),
    );
  };
  const checkVoucher = () => {
    dispatch(
      checkVoucherAction(formData, (err, data) => {
        if (data?.status == 200) {
          navigate(`/generate_pdf/voucher/${data?.result?.ag_ref_number}`);
        }
        if (err) {
          toast.error(err);
        }
        setLoading(false);
      }),
    );
  };

  const handleSearch = async () => {
    setSubmit(true);
    setLoading(true);
    if (searchTitle === 'Transfer') {
      checkTransfer();
    }
    if (searchTitle === 'Voucher') {
      checkVoucher();
    }
  };
  return (
    <>
      <div className="banner">
        <div className="web_container web_content">
          <h1>{title}</h1>
        </div>
      </div>
      <div className="main">
        <div className="web_container web_content">
          <div className="search_div">
            <h3 className="search_p">
              Check & Download Your {searchTitle} Here!
            </h3>
            <div className="input_div">
              <form onSubmit={handleSearch} className="check_form">
                <input
                  required
                  type="text"
                  name="reference_number"
                  value={formData.reference_number || ''}
                  placeholder="Reference Number *"
                  style={
                    !formData.reference_number && submit
                      ? { borderColor: '#e50404' }
                      : null
                  }
                  onChange={event =>
                    handleChange('reference_number', event.target.value)
                  }
                />
                <small className="input_small">
                  {!formData.reference_number && submit && 'Required Field'}
                </small>
                {/*<input type="button" value="CHECK" onClick={handleSearch} />*/}
                <LoadingButton
                  onClick={handleSearch}
                  type="submit"
                  color="primary"
                  variant="contained"
                  loading={isLoading}
                  loadingPosition="left"
                  disabled={isLoading}
                >
                  <span style={isLoading ? { opacity: 0 } : { opacity: 1 }}>
                    CHECK
                  </span>
                </LoadingButton>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
