import * as React from 'react';
import {useEffect} from 'react'
import './profileMenu.scss';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { VscAccount } from 'react-icons/vsc';
import { menuSideBarBottom } from '../../pagesData/pages';
import { useDispatch, useSelector } from 'react-redux';
import { clearSearchAction } from '../../store/actions/search';
import { toast } from 'react-toastify';

export default function ProfileMenu({ account, userLogOut, handleNavigate }) {
  const blockedPage = useSelector(store => store.search.blockedPage);
  const { theme } = useSelector(store => store?.settings?.theme);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isMenuVisible, setIsMenuVisible] = React.useState(window.innerWidth >= 1200);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();

  const handleClick = event => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const checkWindowSize = () => {
    setIsMenuVisible(window.innerWidth >= 1200);
  };

  useEffect(() => {
    checkWindowSize();
    window.addEventListener('resize', checkWindowSize);
    return () => {
      window.removeEventListener('resize', checkWindowSize);
    };
  }, []);

  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        style={{ display: 'flex' }}
      >
        <div className="profile-div">
          <h5 className="profile-h5 profile-small">
            {account ? account.name : ''}
          </h5>
          {/* <small className="profile-small">Administrator</small> */}
        </div>
        <div>
          <VscAccount
            style={{
              color: 'white',
              marginLeft: '8px',
            }}
            size={38}
            className="account_me_icon"
          />
        </div>
      </Button>
      {isMenuVisible && (<Menu
        className="profile_menu"
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {menuSideBarBottom?.map(({ id, title, path, icon }) => (
          <MenuItem
            key={id}
            className={
              location.pathname.includes(path)
                ? 'profile_menu_item active'
                : 'profile_menu_item'
            }
            onClick={() => {
              if (!blockedPage) {
                path == '/login' ? userLogOut() : handleNavigate(path),
                  dispatch(clearSearchAction());
                setAnchorEl(null);
              } else {
                toast.error(
                  'You have unsaved changes on this form. Please save them or click "Reset" to undo.',
                  {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 10000,
                  },
                );
              }
            }}
          >
            <span className="profile_menu_icon">{icon}</span>
            {title}
          </MenuItem>
        ))}
      </Menu> )}
    </>
  );
}
