import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import {
  addTourVoucherAction,
  getSingleTourVoucher,
  getTourVouchersAction,
  updateTourVoucher,
} from '../../store/actions/tourVouchers';
import { getAllPickUpPointsAction } from '../../store/actions/pickUpPoints';
import Select from 'react-select';
import { getAllToursAction, getSelectTours } from '../../store/actions/tours';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Stack from '@mui/material/Stack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { createTheme, TextField, ThemeProvider } from '@mui/material';
import dayjs from 'dayjs';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { getAllDirectionsAction } from '../../store/actions/directions';
import { FiFilter } from 'react-icons/fi';
import { LoadingButton } from '@mui/lab';
import {
  blockPageAction,
  getAllSalesAgentAction,
} from '../../store/actions/users';
import {MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp} from "react-icons/md";

function TourVouchersForm({
  popupTitle,
  handleClose,
  singleTourVoucher,
  actionStatus,
                            historyArr
}) {
  const searchConfig = useSelector(store => store.search);
  const blockedPage = useSelector(store => store.search.blockedPage);
  const allDirections = useSelector(store => store.directions.allDirections);
  const allSalesAgents = useSelector(store => store.users?.allSalesAgents);
  const allTours = useSelector(store => store.tours.selectTours);
  const account = useSelector(store => store.users.account);
  const [formValidation, setFormValidation] = useState({});
  const [formData, setFormData] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [inputDisabled, setInputDisabled] = useState(false);
  const [validated, setValidated] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [bookingInfo, setBookingInfo] = useState('');
  const [showAll, setShowAll] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllPickUpPointsAction());
    dispatch(getAllDirectionsAction());
    dispatch(getAllSalesAgentAction());
  }, []);

  useEffect(() => {
    if (!isEmpty(singleTourVoucher)) {
      if (
        singleTourVoucher?.service_date &&
        singleTourVoucher?.direction_id
      ) {
        const formatedService_date = dayjs(singleTourVoucher?.service_date).format('YYYY-MM-DD');
        handleGetTours(
          formatedService_date,
          singleTourVoucher?.direction_id,
        );
      }
      setFormData({
        ...singleTourVoucher,
      });
    }
  }, [singleTourVoucher]);

  useEffect(() => {
    if (
      id &&
      typeof formData === typeof singleTourVoucher &&
      !isEmpty(singleTourVoucher)
    ) {
      if (
        JSON.stringify(formData) !== JSON.stringify(singleTourVoucher) &&
        !blockedPage
      ) {
        dispatch(blockPageAction('/user-management/users/41'));
      }
      if (
        JSON.stringify(formData) === JSON.stringify(singleTourVoucher) &&
        blockedPage
      ) {
        dispatch(blockPageAction(''));
      }
    }
    if (formData && formData.bokun_info) {
      let bookingInfoStr = formData?.bokun_info;
      let parts = bookingInfoStr && bookingInfoStr.split(';');
      let joinedString = parts && parts.join('\n');
      let formattedString = joinedString.includes('undefined' || undefined)
        ? joinedString.replaceAll(/undefined/g, 'No data')
        : joinedString;
      setBookingInfo(formattedString);
    }
  }, [formData]);

  useEffect(() => {
    if (account?.role === 'Sales Agent') {
      setInputDisabled(true);
    }
  }, [account]);

  const handleChange = (key, val) => {
    const regex = /^[a-zA-Z0-9-_]*$/;
    if (
      (key === 'original_reference_number' || key === 'partner_ref_number') &&
      !regex.test(val)
    ) {
      return;
    }

    setFormData({ ...formData, [key]: val });
    setFormValidation({ ...formValidation, [key]: val });
  };
  const handleEdit = params => {
    setFormData(formData);
    setFormValidation(!!formValidation);
    dispatch(getSingleTourVoucher(params));
  };

  const onSubmit = event => {
    event.preventDefault();
    setSubmitted(true);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      setDisabled(true);
      dispatch(
        addTourVoucherAction(formData, (err, data) => {
          if (data && data.status === 200) {
            toast.success('Successfully Created', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });
            setValidated(false);
            setFormData({ brand_name: '' });
            dispatch(getTourVouchersAction(searchConfig));
            setDisabled(false);
            handleClose();
            form.reset();
          } else if (data && data.status === 400) {
            toast.error(data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });
          }
        }),
      );
      setTimeout(() => {
        setDisabled(false);
      }, 1500);
    }
  };

  const onEdit = ev => {
    ev.preventDefault();
    setSubmitted(true);
    const form = ev.currentTarget;
    if (form.checkValidity() === false) {
      ev.preventDefault();
      ev.stopPropagation();
      setValidated(true);
    } else {
      setDisabled(true);
      setValidated(true);
      dispatch(
        updateTourVoucher(formData, (err, data) => {
          if (data && data.status === 400) {
            toast.error(data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });
            // setValidated(false);
          } else {
            if (data && data.status === 200) {
              toast.success('Successfully Updated', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 10000,
              });
              setDisabled(false);
              form.reset();
              navigate('/admin/vouchers');
            }
          }
        }),
      );
      setTimeout(() => {
        setDisabled(false);
      }, 1500);
    }
  };

  const handleGetTours = (service_date, direction_id) => {
    dispatch(getSelectTours(service_date, direction_id));
  };

  const customStylesGreen = {
    control: styles => ({
      ...styles,
      borderColor: !!validated ? '#198754' : '#ced4da',
    }),
    option: styles => {
      return {
        ...styles,
        ':active': {
          ...styles[':active'],
          backgroundColor: `var(--primary_light)`,
        },
      };
    },
  };
  const customStyleGray = {
    control: styles => ({
      ...styles,
      borderColor: !!validated ? '' : '#ced4da',
    }),
    option: styles => {
      return {
        ...styles,
        ':active': {
          ...styles[':active'],
          backgroundColor: `var(--primary_light)`,
        },
      };
    },
  };
  const customRequiredStyle = {
    control: styles => ({
      ...styles,
      borderColor: !!validated ? '#dc3545' : '#ced4da',
    }),
    option: styles => {
      return {
        ...styles,
        ':active': {
          ...styles[':active'],
          backgroundColor: `var(--primary_light)`,
        },
      };
    },
  };

  const requiredTheme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: !!validated ? '#198754' : '#ced4da',
          },
        },
      },
    },
  });

  const theme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: !!validated ? '#ced4da' : '#ced4da',
          },
        },
      },
    },
  });

    const toggleShowAll = (ev) => {
        ev.stopPropagation();
        setShowAll(!showAll);
    };

  return (
    <>
      <Form
        onSubmit={event => {
          if (id) {
            onEdit(event);
          } else {
            onSubmit(event);
          }
        }}
        noValidate
        validated={validated}
      >
        <Modal.Header closeButton={!id}>
          <Modal.Title>{!id && popupTitle + ' ' + 'Tour Voucher'}</Modal.Title>
        </Modal.Header>
        <div className="form-block">
          <Modal.Body>
            <Row>
              <Col sm={6} md={id ? 6 : 4} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Agency Reference Number</Form.Label>
                  <Form.Control
                    id="tour_voucher_ag_ref_number"
                    name="ag_ref_number"
                    value={formData?.ag_ref_number || ''}
                    disabled={true}
                    onChange={e =>
                      handleChange('ag_ref_number', e.target.value)
                    }
                  />
                </Form.Group>
              </Col>
              <Col sm={6} md={id ? 6 : 4} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Reference Number
                    <span style={{ color: 'red' }}> *</span>
                  </Form.Label>
                  <Form.Control
                    className="inut_with_placeholder"
                    id="tour_voucher_original_reference_number"
                    name="original_reference_number"
                    maxLength={254}
                    required={true}
                    placeholder={'Allowed: a-zA-Z0-9-_'}
                    disabled={inputDisabled}
                    value={formData?.original_reference_number || ''}
                    onChange={e =>
                      handleChange('original_reference_number', e.target.value)
                    }
                  />
                  <div>
                    <Form.Group className="d-flex align-items-center">
                      <Form.Check
                        id={'show_on_pdf'}
                        name="show_on_pdf"
                        type="checkbox"
                        disabled={inputDisabled}
                        checked={formData?.show_reference_number}
                        onChange={ev =>
                          handleChange(
                            'show_reference_number',
                            ev.target.checked,
                          )
                        }
                      />
                      <Form.Check.Label
                        htmlFor="show_on_pdf"
                        className="p-0 px-1"
                        style={{ cursor: 'pointer' }}
                      >
                        Show on PDF
                      </Form.Check.Label>
                    </Form.Group>
                  </div>
                  <Form.Control.Feedback type="invalid">
                    Required Field
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              {id && (
                <Col sm={6} md={id ? 6 : 4} lg={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Confirmation Code
                      <span style={{ color: 'red' }}> *</span>
                    </Form.Label>
                    <Form.Control
                      id="tour_voucher_reference_number"
                      name="reference_number"
                      maxLength={254}
                      value={formData?.reference_number || ''}
                      required={true}
                      placeholder={'Allowed: a-zA-Z0-9-_'}
                      disabled={inputDisabled}
                      onChange={e =>
                        handleChange('reference_number', e.target.value)
                      }
                    />

                    <Form.Control.Feedback type="invalid">
                      Required Field
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              )}
              <Col sm={id ? 6 : 12} md={id ? 6 : 4} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Partner Reference Number</Form.Label>
                  <Form.Control
                    id="tour_voucher_partner_ref_number"
                    name="partner_ref_number"
                    maxLength={254}
                    disabled={inputDisabled}
                    placeholder={'Allowed: a-zA-Z0-9-_'}
                    value={formData?.partner_ref_number || ''}
                    onChange={e =>
                      handleChange('partner_ref_number', e.target.value)
                    }
                  />
                </Form.Group>
              </Col>
              <Col sm={12} md={6} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Sales Agent</Form.Label>
                  <Select
                    id={'tour_voucher_sales_agent_id'}
                    name={'sales_agent_id'}
                    styles={
                      ({
                        option: (styles, { isSelected }) => {
                          return {
                            ...styles,
                            ':active': {
                              ...styles[':active'],
                              backgroundColor: null,
                            },
                          };
                        },
                      },
                      !submitted && !formData.sales_agent_id
                        ? customStyleGray
                        : customStylesGreen)
                    }
                    value={
                      formData?.sales_agent_id && Array.isArray(allSalesAgents)
                        ? allSalesAgents.find(
                            i => i.id === formData.sales_agent_id,
                          )
                        : {}
                    }
                    isDisabled={account?.role === 'Sales Agent'}
                    options={allSalesAgents}
                    isClearable={true}
                    getOptionLabel={i => i.name}
                    getOptionValue={i => i.id}
                    onChange={ev => {
                      if (ev) {
                        handleChange('sales_agent_id', ev.id);
                      } else {
                        handleChange('sales_agent_id', null);
                      }
                    }}
                  />
                </Form.Group>
              </Col>
              <Col sm={12} md={6} lg={id ? 3 : 4}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Passenger Name
                    <span style={{ color: 'red' }}> *</span>
                  </Form.Label>
                  <Form.Control
                    id="tour_voucher_passenger_name"
                    name="passenger_name"
                    maxLength={254}
                    value={formData?.passenger_name || ''}
                    required={true}
                    disabled={inputDisabled}
                    onChange={e =>
                      handleChange('passenger_name', e.target.value)
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    Required Field
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={12} md={6} lg={id ? 3 : 4}>
                <Form.Group className={!submitted ? 'mb-3' : null}>
                  <Form.Label>Nationality</Form.Label>
                  <Form.Control
                    id="tour_voucher_nationality"
                    name="nationality"
                    maxLength={254}
                    type="text"
                    value={formData.nationality || ''}
                    disabled={inputDisabled}
                    onChange={event =>
                      handleChange('nationality', event.target.value)
                    }
                  />
                </Form.Group>
              </Col>
              <Col sm={6} md={6} lg={id ? 3 : 4}>
                <Form.Group className="mb-3">
                  <Form.Label>Passenger Phone</Form.Label>
                  <PhoneInput
                    name="passenger_phone"
                    value={formData.passenger_phone || ''}
                    onChange={val => handleChange('passenger_phone', val)}
                    country={'gr'}
                    disabled={inputDisabled}
                    buttonStyle={submitted ? { borderColor: '#198754' } : null}
                    inputProps={{
                      name: 'passenger_phone',
                      id: 'tour_voucher_passenger_phone',
                    }}
                  />
                </Form.Group>
              </Col>
              <Col sm={6} md={6} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Accommodation</Form.Label>
                  <Form.Control
                    id="tour_voucher_accommodation"
                    name="accommodation"
                    maxLength={254}
                    type="text"
                    value={formData.accommodation || ''}
                    disabled={inputDisabled}
                    onChange={event =>
                      handleChange('accommodation', event.target.value)
                    }
                  />
                </Form.Group>
              </Col>
              <Col xs={4} sm={4} md={2} lg={2}>
                <Form.Group className="mb-3">
                  <Form.Label>Children</Form.Label>
                  <Form.Control
                    id="tour_voucher_child_members"
                    name="child_members"
                    maxLength={254}
                    type="number"
                    min="0"
                    value={formData.child_members || ''}
                    disabled={inputDisabled}
                    onChange={event =>
                      handleChange('child_members', event.target.value)
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formData.child_members && 'Invalid format'}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={4} sm={4} md={2} lg={2}>
                <Form.Group className="mb-3">
                  <Form.Label>Adults</Form.Label>
                  <Form.Control
                    id="tour_voucher_adult_members"
                    name="adult_members"
                    maxLength={254}
                    type="number"
                    min="0"
                    disabled={inputDisabled}
                    value={formData.adult_members || ''}
                    onChange={event =>
                      handleChange('adult_members', event.target.value)
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formData.adult_members && 'Invalid format'}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={4} sm={4} md={2} lg={2}>
                <Form.Group className="mb-3">
                  <Form.Label>Infants</Form.Label>
                  <Form.Control
                    id="tour_voucher_infant_members"
                    name="infant_members"
                    maxLength={254}
                    type="number"
                    disabled={inputDisabled}
                    min="0"
                    value={formData.infant_members || ''}
                    onChange={event =>
                      handleChange('infant_members', event.target.value)
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formData.infant_members && 'Invalid format'}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="d-flex align-items-center justify-content-between">
                  <p className={id ? 'select_preview_tour' : 'select__tour'}>
                    Select Tour
                  </p>
                  <div className="select__tour__line" />
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={6} md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Direction <FiFilter size={16} />
                  </Form.Label>
                  <Select
                    id={'tour_voucher_direction_name'}
                    name="direction_name"
                    value={
                      formData?.direction_id && Array.isArray(allDirections)
                        ? allDirections.find(
                            i => i.id === formData.direction_id,
                          )
                        : {}
                    }
                    options={Array.isArray(allDirections) ? allDirections : []}
                    isClearable={true}
                    getOptionLabel={i => i.direction_name}
                    getOptionValue={i => i.id}
                    isDisabled={inputDisabled}
                    onChange={ev => {
                      if (ev) {
                        if (formData.service_date) {
                          handleGetTours(formData.service_date, ev.id);
                        }
                        setFormData({
                          ...formData,
                          direction_id: ev.id,
                          tour_id: null,
                        });
                      } else {
                        handleChange('direction_id', null);
                        setFormData({
                          ...formData,
                          direction_id: null,
                          tour_id: null,
                        });
                      }
                    }}
                  />
                </Form.Group>
              </Col>
              <Col sm={6} md={4}>
                <Form.Label className="label-m-1r">
                  {' '}
                  Service Date <FiFilter size={16} />{' '}
                </Form.Label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={3} id={'tour_voucher_service_date'}>
                    <ThemeProvider theme={theme}>
                      <DesktopDatePicker
                        name="service_date"
                        className="month_year_picker"
                        views={['year', 'month', 'day']}
                        value={
                          formData?.service_date &&
                          dayjs(formData?.service_date)
                        }
                        disabled={inputDisabled}
                        // minDate={!id ? dayjs() : null}
                        onChange={newValue => {
                          if (newValue) {
                            if (formData.direction_id) {
                              handleGetTours(
                                dayjs(newValue).format('YYYY-MM-DD'),
                                formData.direction_id,
                              );
                            }
                            setFormData({
                              ...formData,
                              service_date:
                                dayjs(newValue).format('YYYY-MM-DD'),
                              tour_id: null,
                            });
                          } else {
                            setFormData({
                              ...formData,
                              service_date: null,
                              tour_id: null,
                            });
                          }
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            disabled={formData.send === 'true'}
                          />
                        )}
                      />
                    </ThemeProvider>
                  </Stack>
                </LocalizationProvider>
              </Col>
              <Col sm={12} md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Tour Name</Form.Label>
                  <Select
                    id={'tour_voucher_tour_name'}
                    name="tour_name"
                    value={
                      formData?.tour_id && Array.isArray(allTours)
                        ? allTours.find(i => i.id === formData?.tour_id)
                        : {}
                    }
                    isDisabled={
                      !formData.service_date ||
                      !formData.direction_id ||
                      formData.service_date === 'Invalid Date' ||
                      inputDisabled
                    }
                    isClearable={true}
                    options={allTours}
                    getOptionValue={i => i.id}
                      getOptionLabel={i => i.tour_name}
                    onChange={ev => {
                      if (ev) {
                        handleChange('tour_id', ev.id);
                      } else {
                        handleChange('tour_id', null);
                      }
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            {id && bookingInfo ? (
              <>
                <h5>
                  <strong>Original Booking Info</strong>
                </h5>
                <div className="info_div">
                  <pre>{bookingInfo}</pre>
                </div>
              </>
            ) : null}

            {id && historyArr?.length > 0 ? (
                <>
                  <h5>
                    <strong>Booking History</strong>
                  </h5>
                  <div className="history_info_div">
                      {historyArr && historyArr?.slice(0, showAll ? historyArr.length : 1)?.map((i, index) => {
                          let modifiedHistory = i.replace(/null|undefined|:\s*,|:\s*$/g, 'N/A');
                          return (
                              <>
                                <p key={index}
                                   dangerouslySetInnerHTML={{__html: modifiedHistory.replace(/;/g, '<br/>')}}></p>
                              </>
                          );
                      })}

                    {historyArr?.length > 1
                        ? <span style={{cursor: 'pointer'}} onClick={(ev) => toggleShowAll(ev)}>
                          {showAll && historyArr?.length > 0
                              ? (
                                  <strong style={{fontSize: '13px'}}>
                                      Show Less <MdOutlineKeyboardArrowUp />
                                  </strong>
                              )
                              : (
                                  <strong style={{fontSize: '13px'}}>
                                      Show More <MdOutlineKeyboardArrowDown/>
                                  </strong>
                              )}
                    </span> : null}
                  </div>
                </>
            ) : null}
          </Modal.Body>
        </div>
        <Modal.Footer>
          {!isEmpty(account) && account.role !== 'Sales Agent' && (
              <div className={id ? 'previewButtonsID' : 'previewButtons'}>
                <LoadingButton
                    size="small"
                    className="login_submit_btn btn btn-primary "
                loadingPosition="center"
                variant="contained"
                loading={actionStatus === 'pending'}
                disabled={disabled}
                type="submit"
              >
                <span>{actionStatus === 'pending' ? '' : 'Save'}</span>
              </LoadingButton>
              {id ? (
                <Button variant="secondary" onClick={() => handleEdit(id)}>
                  Reset
                </Button>
              ) : (
                <Button variant="secondary" onClick={handleClose}>
                  Cancel
                </Button>
              )}
            </div>
          )}
        </Modal.Footer>
      </Form>
    </>
  );
}

export default TourVouchersForm;
