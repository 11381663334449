import * as Yup from 'yup';

export const userValidation = Yup.object().shape({
  name: Yup.string().required('Required Field'),
  email: Yup.string()
    .notRequired()
    .matches(/[a-zA-Z0-9\._-]+@[a-zA-Z0-9.-_]+\.[a-z]{2,6}$/, {
      message: 'Invalid Email Address',
      excludeEmptyString: true,
    })
    .email('Invalid Email Address')
    .nullable(),

  phone_number: Yup.string()
    .nullable()
    .notRequired()
    .matches(/^\+?[()\d -]{6,25}/, {
      message: 'Invalid format',
      excludeEmptyString: true,
    }),
  user_name: Yup.string(),
  old_password: Yup.string(),
  password: Yup.string().when(['old_password', 'confirm_password'], {
    is: (old_password, confirm_password) =>
      !!old_password || !!confirm_password,
    then: schema => schema.required('Required Field'),
    otherwise: schema => schema.notRequired(),
  }),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .when('old_password', {
      is: old_password => !!old_password,
      then: schema => schema.required('Required Field'),
      otherwise: schema => schema.notRequired(),
    }),
});

export const supplierValidation = Yup.object().shape({
  name: Yup.string().required('Required Field'),
  email: Yup.string()
    .required('Required Field')
    .matches(/[a-zA-Z0-9\._-]+@[a-zA-Z0-9.-_]+\.[a-z]{2,6}$/, {
      message: 'Invalid Email Address',
      excludeEmptyString: true,
    })
    .email('Invalid Email Address'),
  phone_number: Yup.string()
    .required('Required Field')
    .matches(/^\+?[()\d -]{6,25}/, {
      message: 'Invalid format',
      excludeEmptyString: true,
    }),
  user_name: Yup.string(),
  old_password: Yup.string(),
  password: Yup.string().when(['old_password', 'confirm_password'], {
    is: (old_password, confirm_password) =>
      !!old_password || !!confirm_password,
    then: schema => schema.required('Required Field'),
    otherwise: schema => schema.notRequired(),
  }),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .when('old_password', {
      is: old_password => !!old_password,
      then: schema => schema.required('Required Field'),
      otherwise: schema => schema.notRequired(),
    }),
});

export const driverValidation = Yup.object().shape({
  name: Yup.string().required('Required Field'),
  passport_details: Yup.string().required('Required Field'),
  driving_license_number: Yup.string().required('Required Field'),
  vehicle_type: Yup.string().required('Required Field'),
  registration_number: Yup.string().required('Required Field'),
  user_name: Yup.string(),
  old_password: Yup.string(),
  password: Yup.string().when(['old_password', 'confirm_password'], {
    is: (old_password, confirm_password) =>
      !!old_password || !!confirm_password,
    then: schema => schema.required('Required Field'),
    otherwise: schema => schema.notRequired(),
  }),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .when('old_password', {
      is: old_password => !!old_password,
      then: schema => schema.required('Required Field'),
      otherwise: schema => schema.notRequired(),
    }),
});
