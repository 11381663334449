import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import { DataGridPro, GridLoadingOverlay } from '@mui/x-data-grid-pro';
import LoadingCircle from './loading/LoadingCircle';

export default function Table({
  rows,
  columns,
  status,
  setSort,
  setSelectedRows,
  selectedRows,
  checkboxSelection,
}) {
  const [columnState, setColumnState] = useState(columns);

  const handleColumnResize = params => {
    const newColumnState = columnState.map(col => {
      if (col.field === params.colDef.field) {
        return {
          ...col,
          width: params.width,
        };
      }
      return col;
    });
    setColumnState(newColumnState);
  };

  return (
    <>
      <DataGridPro
        checkboxSelection={checkboxSelection}
        autoHeight
        className="web_table"
        disableVirtualization
        rows={rows || []}
        columns={columnState}
        initialState={{ pinnedColumns: { right: ['actions'] } }}
        sortingMode="server"
        onSortModelChange={model => {
          setSort(model);
        }}
        rowHeight={40}
        components={{
          LoadingOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              <LoadingCircle rows={rows} status={status} />
            </Stack>
          ),
          NoRowsOverlay: () => (
            <span style={{ color: 'var(--text_main)' }}>
              No Data to Display
            </span>
          ),
        }}
        loading={status}
        selectionModel={selectedRows}
        onSelectionModelChange={newSelection => {
          setSelectedRows(newSelection);
        }}
        onColumnResize={handleColumnResize}
        columnResizeMode="onChange"
      />
    </>
  );
}
