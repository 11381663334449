import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import './template.scss';
import moment from 'moment';
const { REACT_APP_API_URL } = process.env;

export default function TransferTemplate({ data }) {
  const {
    adult_members,
    ag_ref_number,
    child,
    notes,
    duration,
    end_time,
    flight_time,
    from_location,
    transfer_type,
    driver,
    pick_up_point,
    including_service_info,
    including_service_type,
    infant,
    service_date,
    original_point_of_rental,
    passenger_passport_details,
    passenger_name,
    payment_amount,
    phone,
    currencyId,
    pick_up_time,
    reference_number,
    emergency_number,
    reservation_date,
    show_reference_number,
    start_time,
    to_location,
    flight_number,
    pre_paid_package,
    terms,
  } = data;

  return (
    <div className="pdf_template">
      <Grid container spacing={2}>
        {/*<Grid item className="" style={{ background: '#c4c4c4' }} xs={12}>*/}
        <Grid
          item
          className=""
          style={{ background: '#f88f35', fontSize: '18px' }}
          xs={12}
        >
          {/*Voucher - {transfer_type?.type} Transfers*/}
          Transfer Package
        </Grid>
        {show_reference_number && (
          <Grid item style={{ background: '#f88f35' }} xs={4}>
            Reference Number
          </Grid>
        )}
        <Grid
          item
          style={{ background: '#f88f35' }}
          xs={show_reference_number ? 4 : 6}
        >
          Agency Reference No
        </Grid>
        <Grid
          item
          style={{ background: '#f88f35' }}
          xs={show_reference_number ? 4 : 6}
        >
          Emergency Number
        </Grid>
        {show_reference_number && (
          <Grid item style={{ background: '#f9e1cc' }} xs={4}>
            {reference_number}
          </Grid>
        )}
        <Grid
          item
          style={{ background: '#f9e1cc' }}
          xs={show_reference_number ? 4 : 6}
        >
          {ag_ref_number}
        </Grid>
        <Grid
          item
          style={{ background: '#f9e1cc' }}
          xs={show_reference_number ? 4 : 6}
        >
          {emergency_number}
        </Grid>
        <Grid item style={{ background: '#f88f35' }} xs={2.4}>
          Driver Name
        </Grid>
        <Grid item style={{ background: '#f88f35' }} xs={2.4}>
          Passport/ID No
        </Grid>
        <Grid item style={{ background: '#f88f35' }} xs={2.4}>
          Driving License No
        </Grid>
        <Grid item style={{ background: '#f88f35' }} xs={2.4}>
          Vehicle Type
        </Grid>
        <Grid item style={{ background: '#f88f35' }} xs={2.4}>
          Registration No
        </Grid>
        <Grid item style={{ background: '#f9e1cc' }} xs={2.4}>
          {driver && driver?.full_name}
        </Grid>
        <Grid item style={{ background: '#f9e1cc' }} xs={2.4}>
          {driver && driver?.passport_details}
        </Grid>
        <Grid item style={{ background: '#f9e1cc' }} xs={2.4}>
          {driver && driver?.driving_license_number}
        </Grid>
        <Grid item style={{ background: '#f9e1cc' }} xs={2.4}>
          {driver && driver?.vehicle_type}
        </Grid>
        <Grid item style={{ background: '#f9e1cc' }} xs={2.4}>
          {driver && driver?.registration_number}
        </Grid>
        <Grid item style={{ background: '#f88f35' }} xs={4}>
          Passenger Name
        </Grid>
        <Grid item style={{ background: '#f88f35' }} xs={4}>
          Passport/ID No
        </Grid>
        <Grid item style={{ background: '#f88f35' }} xs={4}>
          Mobile Number
        </Grid>
        <Grid item style={{ background: '#f9e1cc' }} xs={4}>
          {passenger_name}
        </Grid>
        <Grid item style={{ background: '#f9e1cc' }} xs={4}>
          {passenger_passport_details}
        </Grid>
        <Grid item style={{ background: '#f9e1cc' }} xs={4}>
          {phone}
        </Grid>
        <Grid item style={{ background: '#f88f35' }} xs={3}>
          Children
        </Grid>
        <Grid item style={{ background: '#f88f35' }} xs={3}>
          Adults
        </Grid>
        <Grid item style={{ background: '#f88f35' }} xs={3}>
          Infants
        </Grid>
        <Grid item style={{ background: '#f88f35' }} xs={3}>
          Total Passengers
        </Grid>
        <Grid item style={{ background: '#f9e1cc' }} xs={3}>
          {child || 0}
        </Grid>
        <Grid item style={{ background: '#f9e1cc' }} xs={3}>
          {adult_members || 0}
        </Grid>
        <Grid item style={{ background: '#f9e1cc' }} xs={3}>
          {infant || 0}
        </Grid>
        <Grid item style={{ background: '#f9e1cc' }} xs={3}>
          {+child + +adult_members + +infant}
        </Grid>
        <Grid item style={{ background: '#f88f35' }} xs={3}>
          Original Point Of Rental
        </Grid>
        <Grid item style={{ background: '#f9e1cc' }} xs={9}>
          {original_point_of_rental}
        </Grid>
        <Grid item style={{ background: '#f88f35' }} xs={2.4}>
          Reservation Date
        </Grid>
        <Grid item style={{ background: '#f88f35' }} xs={2.4}>
          Service Date
        </Grid>
        <Grid item style={{ background: '#f88f35' }} xs={2.4}>
          Start Time
        </Grid>
        <Grid item style={{ background: '#f88f35' }} xs={2.4}>
          End Time
        </Grid>
        <Grid item style={{ background: '#f88f35' }} xs={2.4}>
          Duration
        </Grid>
        <Grid item style={{ background: '#f9e1cc' }} xs={2.4}>
          {moment(reservation_date).format('MM/DD/YYYY')}
        </Grid>
        <Grid item style={{ background: '#f9e1cc' }} xs={2.4}>
          {moment(service_date).format('MM/DD/YYYY')}
        </Grid>
        <Grid item style={{ background: '#f9e1cc' }} xs={2.4}>
          {start_time}
        </Grid>
        <Grid item style={{ background: '#f9e1cc' }} xs={2.4}>
          {end_time}
        </Grid>
        <Grid item style={{ background: '#f9e1cc' }} xs={2.4}>
          {duration}
        </Grid>
        <Grid item style={{ background: '#f88f35' }} xs={2.4}>
          From
        </Grid>
        <Grid item style={{ background: '#f88f35' }} xs={2.4}>
          To
        </Grid>
        <Grid item style={{ background: '#f88f35' }} xs={2.4}>
          Flight No
        </Grid>
        <Grid item style={{ background: '#f88f35' }} xs={2.4}>
          Flight time
        </Grid>
        <Grid item style={{ background: '#f88f35' }} xs={2.4}>
          Pick up time
        </Grid>
        <Grid item style={{ background: '#f9e1cc' }} xs={2.4}>
          {from_location}
        </Grid>
        <Grid item style={{ background: '#f9e1cc' }} xs={2.4}>
          {to_location}
        </Grid>
        <Grid item style={{ background: '#f9e1cc' }} xs={2.4}>
          {flight_number}
        </Grid>
        <Grid item style={{ background: '#f9e1cc' }} xs={2.4}>
          {flight_time}
        </Grid>
        <Grid item style={{ background: '#f9e1cc' }} xs={2.4}>
          {pick_up_time}
        </Grid>
        <Grid
          item
          style={{ background: '#f88f35', borderRight: '4px solid white' }}
          xs={12}
        >
          Included services: {including_service_type}
        </Grid>
        <Grid
          item
          style={{
            background: '#f9e1cc',
            display: 'flex',
            borderRight: '4px solid white',
            textAlign: 'left',
            padding: '2px 1%',
          }}
          xs={12}
        >
          {including_service_info ? (
            <div
              style={{
                fontSize: '0.9em',
                fontWeight: '400',
                lineHeight: '1.3em',
              }}
              dangerouslySetInnerHTML={{
                __html: JSON.stringify(including_service_info?.trim())
                  .replace(/\\n/g, '<br>')
                  .replaceAll('\\t', '   ')
                  .replace(/\"/g, ''),
              }}
            />
          ) : (
            ''
          )}
        </Grid>
        <Grid
          item
          style={{ background: '#f88f35', borderLeft: '4px solid white' }}
          xs={12}
        >
          Special notes
        </Grid>
        <Grid
          item
          style={{
            background: '#f9e1cc',
            display: 'flex',
            minHeight: '50px',
            padding: '2px 1%',
            borderLeft: '4px solid white',
            textAlign: 'left',
            fontWeight: '400',
          }}
          xs={12}
        >
          {notes ? (
            <div
              style={{
                fontSize: '0.9em',
                fontWeight: '400',
                lineHeight: '1.3em',
              }}
              dangerouslySetInnerHTML={{
                __html: JSON.stringify(notes?.trim())
                  .replace(/\\n/g, '<br>')
                  .replaceAll('\\t', '   ')
                  .replace(/\"/g, ''),
              }}
            />
          ) : (
            ''
          )}
        </Grid>
        {/*{(transfer_type && transfer_type.type === 'Private') && (*/}
        <Grid item style={{ padding: 0 }} xs={3}>
          <Grid
            item
            className="pdf_footer_item"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              background: '#f88f35',
            }}
            xs={12}
          >
            Pre-Paid Package
          </Grid>
          <Grid
            item
            className="pdf_footer_item"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              background: '#f88f35',
            }}
            xs={2}
          >
            Payment Amount
          </Grid>
        </Grid>
        <Grid item style={{ padding: 0 }} xs={2}>
          <Grid
            item
            style={{
              background: '#f9e1cc',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            xs={12}
          >
            {pre_paid_package ? 'Yes' : 'No'}
          </Grid>
          <Grid
            item
            style={{
              background: '#f9e1cc',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            xs={12}
          >
            {payment_amount && !pre_paid_package ? `${payment_amount}` : ''}
          </Grid>
        </Grid>
        <Grid
          item
          style={{ padding: 0, display: 'flex', height: '80px' }}
          xs={7}
        >
          <Grid
            item
            style={{
              border: '1px solid #000',
              height: '100%',
              padding: '0px 5px',
              width: '50%',
            }}
            xs={6}
          >
            Driver Signature
          </Grid>
          <Grid
            item
            style={{
              border: '1px solid #000',
              height: '100%',
              padding: '0px 5px',
              width: '50%',
            }}
            xs={6}
          >
            Customer Signature / Name
          </Grid>
        </Grid>
        {/*)}*/}
      </Grid>
    </div>
  );
}
