import {
  CHECK_TRANSFER_VOUCHER_FAIL,
  CHECK_TRANSFER_VOUCHER_REQUEST,
  CHECK_TRANSFER_VOUCHER_SUCCESS,
  DOWNLOAD_TRANSFER_VOUCHER_FAIL,
  DOWNLOAD_TRANSFER_VOUCHER_REQUEST,
  DOWNLOAD_TRANSFER_VOUCHER_SUCCESS,
  EXPORT_TRANSFER_VOUCHERS_FAIL,
  EXPORT_TRANSFER_VOUCHERS_REQUEST,
  EXPORT_TRANSFER_VOUCHERS_SUCCESS,
  FETCH_TRANSFER_VOUCHER_FAIL,
  FETCH_TRANSFER_VOUCHER_REQUEST,
  FETCH_TRANSFER_VOUCHER_SUCCESS,
  GET_ALL_TRANSFER_VOUCHERS_FAIL,
  GET_ALL_TRANSFER_VOUCHERS_REQUEST,
  GET_ALL_TRANSFER_VOUCHERS_SUCCESS,
  GET_SINGLE_TRANSFER_VOUCHER_REQUEST,
  GET_SINGLE_TRANSFER_VOUCHER_SUCCESS,
  GET_TRANSFER_PREVIEW_VOUCHERS_REQUEST,
  GET_TRANSFER_PREVIEW_VOUCHERS_SUCCESS,
  GET_TRANSFER_VOUCHERS_REQUEST,
  GET_TRANSFER_VOUCHERS_SUCCESS,
  UPDATE_TRANSFER_VOUCHER_REQUEST,
  UPDATE_TRANSFER_VOUCHER_SUCCESS,
  SHARE_MY_LOCATION_TRANSFER_REQUEST,
  SHARE_MY_LOCATION_TRANSFER_SUCCESS,
  SHARE_MY_LOCATION_TRANSFER_FAIL,
  ADD_TRANSFER_VOUCHER_REQUEST,
  ADD_TRANSFER_VOUCHER_SUCCESS,
  ADD_TRANSFER_VOUCHER_FAIL,
  DELETE_TRANSFER_VOUCHER_REQUEST,
  DELETE_TRANSFER_VOUCHER_SUCCESS,
  DELETE_TRANSFER_VOUCHER_FAIL,
  DELETE_SELECTED_TRANSFER_VOUCHERS_REQUEST,
  DELETE_SELECTED_TRANSFER_VOUCHERS_SUCCESS,
  DELETE_SELECTED_TRANSFER_VOUCHERS_FAIL,
  UPDATE_TRANSFER_VOUCHER_FAIL,
  ADD_COMMENT_TRANSFER_VOUCHER_REQUEST,
  ADD_COMMENT_TRANSFER_VOUCHER_SUCCESS,
  ADD_COMMENT_TRANSFER_VOUCHER_FAIL,
  CHANGE_TRANSFER_VOUCHER_STATUS_REQUEST,
  CHANGE_TRANSFER_VOUCHER_STATUS_SUCCESS,
  CHANGE_TRANSFER_VOUCHER_STATUS_FAIL,
  COPY_TRANSFER_VOUCHER_REQUEST,
  COPY_TRANSFER_VOUCHER_SUCCESS,
  COPY_TRANSFER_VOUCHER_FAIL,
  SEND_EMAIL_TRANSFER_VOUCHER_REQUEST,
  SEND_EMAIL_TRANSFER_VOUCHER_SUCCESS,
  SEND_EMAIL_TRANSFER_VOUCHER_FAIL,
  DOWNLOAD_TRANSFER_VOUCHERS_REPORT_REQUEST,
  DOWNLOAD_TRANSFER_VOUCHERS_REPORT_SUCCESS,
  DOWNLOAD_TRANSFER_VOUCHERS_REPORT_FAIL,
  DOWNLOAD_TRANSFER_VOUCHERS_KPI_REPORT_REQUEST,
  DOWNLOAD_TRANSFER_VOUCHERS_KPI_REPORT_SUCCESS,
  DOWNLOAD_TRANSFER_VOUCHERS_KPI_REPORT_FAIL,
  GET_TRANSFER_VOUCHERS_FOR_KPI_REPORT_REQUEST,
  GET_TRANSFER_VOUCHERS_FOR_KPI_REPORT_SUCCESS, GET_TRANSFER_VOUCHERS_FOR_KPI_REPORT_FAIL,
} from '../actions/transferVouchers';


const initialState = {
  transferVouchers: [],
  singleTransferVoucher: {},
  searchTransferVoucher: {},
  downloadStatus: '',
  downloadStatusId: null,
  status: '',
  fetchStatus: '',
  shareLocationStatus: '',
  tableTitle: '',
  exportExcelStatus: '',
  transfersWithLocation: [],
  coordinates: {},
  actionStatus: '',
  downloadReportStatus: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_TRANSFER_VOUCHERS_REQUEST: {
      return {
        ...state,
        status: 'pending',
      };
    }
    case GET_TRANSFER_PREVIEW_VOUCHERS_REQUEST: {
      return {
        ...state,
        status: 'pending',
      };
    }
    case GET_TRANSFER_VOUCHERS_SUCCESS: {
      return {
        ...state,
        status: 'success',
        transferVouchers: action?.payload?.data?.result,
        count: action?.payload?.data?.count,
      };
    }
    case GET_TRANSFER_PREVIEW_VOUCHERS_SUCCESS: {
      return {
        ...state,
        status: 'success',
        transferVouchers: action?.payload?.data?.result,
        count: action?.payload?.data?.count,
        tableTitle: action?.payload?.data?.transfer?.transfer_name,
        coordinates: action?.payload?.data?.coordinates,
        transfersWithLocation:
          action?.payload?.data?.transferVouchersWithLocation,
      };
    }
    case GET_SINGLE_TRANSFER_VOUCHER_REQUEST: {
      return {
        ...state,
        status: 'pending',
      };
    }
    case GET_SINGLE_TRANSFER_VOUCHER_SUCCESS: {
      return {
        ...state,
        singleTransferVoucher: action.payload.data?.result,
        status: 'success',
      };
    }
    case EXPORT_TRANSFER_VOUCHERS_REQUEST: {
      return {
        ...state,
        exportExcelStatus: 'pending',
      };
    }
    case EXPORT_TRANSFER_VOUCHERS_SUCCESS: {
      return {
        ...state,
        exportExcelStatus: 'success',
      };
    }
    case EXPORT_TRANSFER_VOUCHERS_FAIL: {
      return {
        ...state,
        exportExcelStatus: 'fail',
      };
    }
    case UPDATE_TRANSFER_VOUCHER_REQUEST: {
      return {
        ...state,
        actionStatus: 'pending',
      };
    }
    case UPDATE_TRANSFER_VOUCHER_SUCCESS: {
      return {
        ...state,
        actionStatus: 'success',
      };
    }
    case ADD_COMMENT_TRANSFER_VOUCHER_REQUEST: {
      return {
        ...state,
        actionStatus: 'pending',
      };
    }
    case ADD_COMMENT_TRANSFER_VOUCHER_SUCCESS: {
      return {
        ...state,
        actionStatus: 'success',
      };
    }
    case ADD_COMMENT_TRANSFER_VOUCHER_FAIL: {
      return {
        ...state,
        actionStatus: 'fail',
      };
    }

    case CHANGE_TRANSFER_VOUCHER_STATUS_REQUEST: {
      return {
        ...state,
        actionStatus: 'pending',
      };
    }
    case CHANGE_TRANSFER_VOUCHER_STATUS_SUCCESS: {
      return {
        ...state,
        actionStatus: 'success',
      };
    }
    case CHANGE_TRANSFER_VOUCHER_STATUS_FAIL: {
      return {
        ...state,
        actionStatus: 'fail',
      };
    }

    case COPY_TRANSFER_VOUCHER_REQUEST: {
      return {
        ...state,
        actionStatus: 'pending',
      };
    }
    case COPY_TRANSFER_VOUCHER_SUCCESS: {
      return {
        ...state,
        actionStatus: 'success',
      };
    }
    case COPY_TRANSFER_VOUCHER_FAIL: {
      return {
        ...state,
        actionStatus: 'fail',
      };
    }

    case SEND_EMAIL_TRANSFER_VOUCHER_REQUEST: {
      return {
        ...state,
        actionStatus: 'pending',
      };
    }
    case SEND_EMAIL_TRANSFER_VOUCHER_SUCCESS: {
      return {
        ...state,
        actionStatus: 'success',
      };
    }
    case SEND_EMAIL_TRANSFER_VOUCHER_FAIL: {
      return {
        ...state,
        actionStatus: 'fail',
      };
    }

    case UPDATE_TRANSFER_VOUCHER_FAIL: {
      return {
        ...state,
        actionStatus: 'fail',
      };
    }
    case GET_ALL_TRANSFER_VOUCHERS_REQUEST: {
      return {
        ...state,
        status: 'pending',
      };
    }
    case GET_ALL_TRANSFER_VOUCHERS_SUCCESS: {
      return {
        ...state,
        allTransferVouchers: action?.payload?.data?.result,
        status: 'success',
      };
    }
    case GET_ALL_TRANSFER_VOUCHERS_FAIL: {
      return {
        ...state,
        status: 'fail',
      };
    }
    case CHECK_TRANSFER_VOUCHER_REQUEST: {
      return {
        ...state,
        status: 'pending',
      };
    }
    case CHECK_TRANSFER_VOUCHER_SUCCESS: {
      return {
        ...state,
        status: 'success',
        searchTransferVoucher: action?.payload?.data?.result,
      };
    }
    case CHECK_TRANSFER_VOUCHER_FAIL: {
      return {
        ...state,
        status: 'fail',
      };
    }
    case DOWNLOAD_TRANSFER_VOUCHER_REQUEST: {
      return {
        ...state,
        downloadStatus: 'pending',
        downloadStatusId: action.payload.id,
      };
    }
    case DOWNLOAD_TRANSFER_VOUCHER_SUCCESS: {
      return {
        ...state,
        downloadStatus: 'success',
      };
    }
    case DOWNLOAD_TRANSFER_VOUCHER_FAIL: {
      return {
        ...state,
        downloadStatus: 'fail',
      };
    }
    case FETCH_TRANSFER_VOUCHER_REQUEST: {
      return {
        ...state,
        // tourVouchers: [],
        status: 'pending',
        fetchStatus: 'pending',
      };
    }
    case FETCH_TRANSFER_VOUCHER_SUCCESS: {
      return {
        ...state,
        status: 'success',
        fetchStatus: 'success',
      };
    }
    case FETCH_TRANSFER_VOUCHER_FAIL: {
      return {
        ...state,
        status: 'fail',
        fetchStatus: 'fail',
      };
    }
    case SHARE_MY_LOCATION_TRANSFER_REQUEST: {
      return {
        ...state,
        shareLocationStatus: 'pending',
      };
    }
    case SHARE_MY_LOCATION_TRANSFER_SUCCESS: {
      return {
        ...state,
        shareLocationStatus: 'success',
      };
    }
    case SHARE_MY_LOCATION_TRANSFER_FAIL: {
      return {
        ...state,
        shareLocationStatus: 'fail',
      };
    }
    case ADD_TRANSFER_VOUCHER_REQUEST: {
      return {
        ...state,
        actionStatus: 'pending',
      };
    }
    case ADD_TRANSFER_VOUCHER_SUCCESS: {
      return {
        ...state,
        actionStatus: 'success',
      };
    }
    case ADD_TRANSFER_VOUCHER_FAIL: {
      return {
        ...state,
        actionStatus: 'fail',
      };
    }
    case DELETE_TRANSFER_VOUCHER_REQUEST: {
      return {
        ...state,
        actionStatus: 'pending',
      };
    }
    case DELETE_TRANSFER_VOUCHER_SUCCESS: {
      return {
        ...state,
        actionStatus: 'success',
      };
    }
    case DELETE_TRANSFER_VOUCHER_FAIL: {
      return {
        ...state,
        actionStatus: 'fail',
      };
    }
    case DELETE_SELECTED_TRANSFER_VOUCHERS_REQUEST: {
      return {
        ...state,
        actionStatus: 'pending',
      };
    }
    case DELETE_SELECTED_TRANSFER_VOUCHERS_SUCCESS: {
      return {
        ...state,
        actionStatus: 'success',
      };
    }
    case DELETE_SELECTED_TRANSFER_VOUCHERS_FAIL: {
      return {
        ...state,
        actionStatus: 'fail',
      };
    }
    case DOWNLOAD_TRANSFER_VOUCHERS_REPORT_REQUEST: {
      return {
        ...state,
        downloadReportStatus: 'pending',
      };
    }
    case DOWNLOAD_TRANSFER_VOUCHERS_REPORT_SUCCESS: {
      return {
        ...state,
        downloadReportStatus: 'success',
      };
    }
    case DOWNLOAD_TRANSFER_VOUCHERS_REPORT_FAIL: {
      return {
        ...state,
        downloadReportStatus: 'fail',
      };
    }
    case GET_TRANSFER_VOUCHERS_FOR_KPI_REPORT_REQUEST: {
      return {
        ...state,
        status: 'pending',
      };
    }
    case GET_TRANSFER_VOUCHERS_FOR_KPI_REPORT_SUCCESS: {
      return {
        ...state,
        status: 'success',
      };
    }
    case GET_TRANSFER_VOUCHERS_FOR_KPI_REPORT_FAIL: {
      return {
        ...state,
        status: 'fail',
      };
    }
    case DOWNLOAD_TRANSFER_VOUCHERS_KPI_REPORT_REQUEST: {
      return {
        ...state,
        downloadReportStatus: 'pending',
      };
    }
    case DOWNLOAD_TRANSFER_VOUCHERS_KPI_REPORT_SUCCESS: {
      return {
        ...state,
        downloadReportStatus: 'success',
      };
    }
    case DOWNLOAD_TRANSFER_VOUCHERS_KPI_REPORT_FAIL: {
      return {
        ...state,
        downloadReportStatus: 'fail',
      };
    }
    default: {
      return state;
    }
  }
}
