import React, {useEffect, useState} from 'react';
import {Button, Modal, Form, Row, Col} from 'react-bootstrap';
import {Controller, useForm} from 'react-hook-form';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import Stack from '@mui/material/Stack';
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs from 'dayjs';
import {getAllSalesAgentAction, getAllSuppliers} from '../../store/actions/users';
import {useDispatch} from 'react-redux';
import Select from 'react-select';
import {LoadingButton} from '@mui/lab';
import InputErrorMessages from "../inputErrorMessages/InputErrorMessages";
import {getTourVouchersForKPIReport} from "../../store/actions/tourVouchers";
import {range} from 'lodash';

const customStyles = {
    control: provided => ({
        ...provided, fontSize: '14px',
    }), option: provided => ({
        ...provided, fontSize: '14px',
    }),
};

function GenerateVouchersReportModal({
                                         show,
                                         handleClose,
                                         actionStatus,
                                         downloadReport,
                                         downloadKPIReport
                                     }) {

    const {watch, reset, trigger, control, handleSubmit, register, setValue, formState: {errors}} = useForm({
        defaultValues: {
            report_type: 'generate_report',
            year: {value: dayjs().format('YYYY')},
        }
    });
    const [yearOptions, setYearOptions] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getTourVouchersForKPIReport((err, data) => {
            if (data) {
                if (data?.maxYear && data.minYear) {
                    setYearOptions(range(+data.minYear, +data.maxYear + 1).reverse().map(year => ({
                        value: year, label: year.toString()
                    })));
                }
            }
        }))
    }, []);


    const handleGroupByChange = (selectedOption) => {
        setValue('group_field', selectedOption);
        if (selectedOption.id === 'tour.supplier_user_id') {
            dispatch(getAllSuppliers((err, data) => {
                if (data?.result) {
                    setAllUsers(watch('report_type') === 'generate_report' ? [{id: 'all', name: 'All'}, {
                        id: 'none',
                        name: 'None'
                    }, ...data.result] : [{id: 'all', name: 'All'}, ...data.result]);
                    setValue('user_id', {id: 'all', name: 'All'});
                    trigger('user_id');
                }
            }));
        } else if (selectedOption.id === 'sales_agent_id') {
            dispatch(getAllSalesAgentAction((err, data) => {
                if (data?.result) {
                    setAllUsers(watch('report_type') === 'generate_report' ? [{id: 'all', name: 'All'}, {
                        id: 'none',
                        name: 'None'
                    }, ...data.result] : [{id: 'all', name: 'All'}, ...data.result]);
                    setValue('user_id', {id: 'all', name: 'All'});
                    trigger('user_id');
                }
            }));
        }
    };

    const onSubmit = (data) => {
        if (watch("report_type") === 'generate_report') {
            downloadReport(data);
        } else {
            downloadKPIReport(data);
        }
        reset();
        handleClose();
        setAllUsers([])
        setValue('user_id', null)
        setValue('group_field', null)
        setValue('service_date_from', null)
        setValue('service_date_to', null)
    };

    return (<Modal
        size="lg"
        show={show}
        onHide={() => {
            reset()
            handleClose();
            setAllUsers([])
            setValue('user_id', null)
            setValue('group_field', null)
            setValue('service_date_from', null)
            setValue('service_date_to', null)
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >
        <Modal.Header
            style={{background: `var(--primary_main)`}}
            className="modal_header"
            closeButton
        >
            <Modal.Title className="modal_title">Generate Report</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
            <div className="form-block">
                <Modal.Body>
                    <div className="deleteConfirmText">
                        <Row>
                            <Col sm={6} className="mb-2">
                                <Form.Check
                                    className="report_radio_button"
                                    type="radio"
                                    label="Generate Report"
                                    value="generate_report"
                                    name="generateReport"
                                    id="generateReport"
                                    defaultChecked={true}
                                    defaultValue={"generate_report"}
                                    checked={watch("report_type") === "generate_report"}
                                    {...register("report_type")}
                                    onChange={(ev) => {
                                        reset()
                                        setValue("report_type", ev.target.value)
                                    }}
                                />
                            </Col>
                            <Col sm={6} className="mb-2">
                                <Form.Check
                                    className="report_radio_button"
                                    type="radio"
                                    label="KPI Report"
                                    value="kpi_report"
                                    name="kpiReport"
                                    id="kpiReport"
                                    checked={watch("report_type") === "kpi_report"}
                                    {...register("report_type")}
                                    onChange={(ev) => {
                                        reset()
                                        setValue("report_type", ev.target.value)
                                    }}
                                />
                            </Col>
                        </Row>
                        {watch("report_type") === 'generate_report' && (<>
                            <Row>
                                <Col sm={6} className="mb-2">
                                    <Form.Label>Group By <span style={{color: 'red'}}> *</span></Form.Label>
                                    <Controller
                                        name="group_field"
                                        control={control}
                                        rules={{required: "Required Field"}}
                                        render={({field, fieldState}) => (<Select
                                            {...field}
                                            options={[{
                                                id: 'tour.supplier_user_id', name: 'Supplier'
                                            }, {id: 'sales_agent_id', name: 'Sales Agent'}]}
                                            classNamePrefix="react-select"
                                            className={fieldState.invalid ? 'border-red' : ''}
                                            getOptionLabel={option => option.name}
                                            getOptionValue={option => option.id}
                                            onChange={(option) => {
                                                field.onChange(option);
                                                trigger('group_field');
                                                handleGroupByChange(option);
                                            }}
                                        />)}
                                    />
                                    <InputErrorMessages message={errors?.group_field?.message}/>
                                </Col>
                                <Col sm={6} className="mb-2">
                                    <Form.Label>Name <span style={{color: 'red'}}> *</span></Form.Label>
                                    <Controller
                                        name="user_id"
                                        control={control}
                                        rules={{required: "Required Field"}}
                                        render={({field, fieldState}) => (<Select
                                            {...field}
                                            options={allUsers}
                                            classNamePrefix="react-select"
                                            className={fieldState.invalid ? 'border-red' : ''}
                                            getOptionLabel={option => option.name}
                                            getOptionValue={option => option.id}
                                            onChange={(option) => {
                                                field.onChange(option)
                                                trigger('user_id');
                                            }}
                                        />)}
                                    />
                                    <InputErrorMessages message={errors?.user_id?.message}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6}>
                                    <Form.Label>Service Date From</Form.Label>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <Stack spacing={3} id={'tour_voucher_service_date_from'}>
                                            <DesktopDatePicker
                                                name="service_date_from"
                                                className="month_year_picker"
                                                views={['year', 'month', 'day']}
                                                {...register('service_date_from')}
                                                onChange={newValue => setValue('service_date_from', dayjs(newValue).format('YYYY-MM-DD'))}
                                                maxDate={
                                                    watch("service_date_to")
                                                        ? dayjs(watch("service_date_to"))
                                                        : null
                                                }
                                            />
                                        </Stack>
                                    </LocalizationProvider>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Service Date To</Form.Label>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <Stack spacing={3} id={'tour_voucher_service_date'}>
                                                <DesktopDatePicker
                                                    name="service_date_to"
                                                    className="month_year_picker"
                                                    views={['year', 'month', 'day']}
                                                    {...register('service_date_to')}
                                                    onChange={newValue => setValue('service_date_to', dayjs(newValue).format('YYYY-MM-DD'))}
                                                    minDate={
                                                        watch("service_date_from")
                                                            ? dayjs(watch("service_date_from"))
                                                            : null
                                                    }
                                                />
                                            </Stack>
                                        </LocalizationProvider>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </>)}
                        {watch("report_type") === 'kpi_report' && (<>
                            <Row>
                                <Col sm={5} className="mb-2">
                                    <Form.Label>Criteria <span style={{color: 'red'}}> *</span></Form.Label>
                                    <Controller
                                        name="group_field"
                                        control={control}
                                        rules={{required: "Required Field"}}
                                        render={({field, fieldState}) => (<Select
                                            {...field}
                                            options={[{
                                                id: 'tour.supplier_user_id', name: 'Supplier'
                                            }, {id: 'sales_agent_id', name: 'Sales Agent'}]}
                                            classNamePrefix="react-select"
                                            className={fieldState.invalid ? 'border-red' : ''}
                                            getOptionLabel={option => option.name}
                                            getOptionValue={option => option.id}
                                            onChange={(option) => {
                                                field.onChange(option);
                                                trigger('group_field');
                                                handleGroupByChange(option);
                                            }}
                                        />)}
                                    />
                                    <InputErrorMessages message={errors?.group_field?.message}/>
                                </Col>
                                <Col sm={5} className="mb-2">
                                    <Form.Label>Name <span style={{color: 'red'}}> *</span></Form.Label>
                                    <Controller
                                        name="user_id"
                                        control={control}
                                        rules={{required: "Required Field"}}
                                        render={({field, fieldState}) => (<Select
                                            {...field}
                                            options={allUsers}
                                            classNamePrefix="react-select"
                                            className={fieldState.invalid ? 'border-red' : ''}
                                            getOptionLabel={option => option.name}
                                            getOptionValue={option => option.id}
                                            onChange={(option) => {
                                                field.onChange(option)
                                                trigger('user_id');

                                            }}
                                        />)}
                                    />
                                    <InputErrorMessages message={errors?.user_id?.message}/>
                                </Col>
                                <Col sm={2}>
                                    <Form.Label>Year</Form.Label>
                                    <Select
                                        id={'year'}
                                        size="small"
                                        className="row_control"
                                        defaultValue={{value: dayjs().format('YYYY')}}
                                        getOptionLabel={(i) => i.value}
                                        getOptionValue={(i) => i.value}
                                        {...register('year')}
                                        options={yearOptions}
                                        isSearchable={false}
                                        styles={customStyles}
                                        hideSelectedOptions={true}
                                        onChange={(newValue) => {
                                            setValue('year', newValue)
                                            trigger('year');
                                        }}
                                    />
                                </Col>
                            </Row>
                        </>)}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="previewButtons">
                        <LoadingButton
                            size="small"
                            className="login_submit_btn btn btn-primary "
                            loadingPosition="center"
                            variant="contained"
                            loading={actionStatus === 'pending'}
                            type="submit"
                        >
                            <span>{actionStatus === 'pending' ? '' : 'Confirm'}</span>
                        </LoadingButton>
                        <Button
                            variant="secondary"
                            onClick={() => {
                                reset()
                                handleClose();
                                setAllUsers([])
                                setValue('user_id', null)
                                setValue('group_field', null)
                                setValue('service_date_from', null)
                                setValue('service_date_to', null)
                            }}
                        >
                            Cancel
                        </Button>
                    </div>
                </Modal.Footer>
            </div>
        </Form>
    </Modal>);
}

export default GenerateVouchersReportModal;
