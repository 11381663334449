import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageTitle from '../../components/PageTitle.js';
import { useParams } from 'react-router-dom';
import { FadeLoader } from 'react-spinners';
import { getSingleTransferTypeAction } from '../../store/actions/transferTypes';
import TransferTypesForm from './TransferTypesForm.js';
import { blockPageAction } from '../../store/actions/users';

function PreviewTransferTypes() {
  const rowStatus = useSelector(store => store?.transferTypes?.status);
  const actionStatus = useSelector(store => store?.transferTypes?.actionStatus);
  const [formData, setFormData] = useState({});
  const [siteName, setSiteName] = useState('');
  const [formValidation, setFormValidation] = useState({});
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    handleEdit(id);
    return () => {
      dispatch(blockPageAction(''));
    };
  }, []);

  // Get the row data to edit
  const handleEdit = id => {
    dispatch(
      getSingleTransferTypeAction(id, (err, data) => {
        if (data) {
          setFormData(data?.result);
          setSiteName(
            data?.result?.type
              ? `ID: ${data?.result?.id} ${data?.result?.type}`
              : `ID: ${data?.result?.id}`,
          );
        }
        // setFormValidation(!!formValidation);
      }),
    );
    return () => {};
  };

  // Close Add/Edit modal
  const handleClose = () => {
    setFormData({});
  };

  const handleChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  return (
    <>
      <PageTitle
        parentTitle={'Transfer Types'}
        childTitle={siteName}
        parentLink={'/admin/setup/transfer_types'}
      />
      <div className="form-block">
        {rowStatus === 'success' ? (
          <TransferTypesForm
            title="edit"
            formValidation={formValidation}
            handleClose={handleClose}
            singleTransferType={formData}
            handleChange={handleChange}
            handleEdit={handleEdit}
            actionStatus={actionStatus}
          />
        ) : (
          <div className="form-loading">
            <FadeLoader
              color={`var(--primary_main)`}
              loading={rowStatus === 'pending'}
              size={10}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )}
      </div>
    </>
  );
}

export default PreviewTransferTypes;
