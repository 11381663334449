import { GridActionsCellItem } from '@mui/x-data-grid';
import { MdDelete, MdEdit } from 'react-icons/md';
import { FaToggleOff, FaToggleOn } from 'react-icons/fa';
import moment from 'moment';

export const getColumns = ({
  rows,
  accessList,
  redirectToPreview,
  handleDelete,
  handleUpdateStatus,
}) => {
  return [
    {
      field: 'id',
      headerName: '#',
      width: 70,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 100,
    },
    {
      field: 'type',
      headerName: 'Transfer Type',
      width: 250,
    },
    {
      field: 'created_at',
      headerName: 'Created On',
      width: 200,
      renderCell: params =>
        moment(params?.created_at || params?.row.created_at).format(
          'MM/DD/YYYY HH:mm:ss',
        ),
    },
    {
      field: 'createdBy.name',
      headerName: 'Created By',
      width: 150,
      renderCell: ({ row }) => row?.createdBy?.name || '',
    },
    {
      field: 'updated_at',
      headerName: 'Modified On',
      width: 200,
      renderCell: params =>
        moment(params?.updated_at || params?.row?.updated_at).format(
          'MM/DD/YYYY HH:mm:ss',
        ),
    },
    {
      field: 'updatedBy.name',
      headerName: 'Modified By',
      width: 150,
      renderCell: ({ row }) => row?.updatedBy?.name || '',
    },
    {
      headerName: 'Actions',
      field: 'actions',
      width: 200,
      cellClassName: 'actions',
      type: 'actions',
      getActions: params => [
        <GridActionsCellItem
          onClick={() => redirectToPreview(params.row?.id || params?.id)}
          className="test1"
          icon={<MdEdit />}
          label="Edit"
          title="View/Edit"
        />,
        <GridActionsCellItem
          onClick={ev => {
            handleUpdateStatus(ev, params?.row || params);
          }}
          icon={
            params?.row?.status === 'Active' || params?.status === 'Active' ? (
              <FaToggleOn />
            ) : (
              <FaToggleOff />
            )
          }
          label="Status"
          title="Change Status"
        />,
        <GridActionsCellItem
          icon={<MdDelete />}
          label="Delete"
          title="Delete"
          onClick={ev => handleDelete(ev, params?.row || params)}
        />,
      ],
    },
  ];
};
