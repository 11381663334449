import { api } from './Api';
const { REACT_APP_API_URL } = process.env;

export default class Tours {
  static addTours(formData) {
    return api.post(`/tours/`, formData);
  }

  static getTours(page, limit, search, filter, [{ sort, field }]) {
    return api.get(`/tours/`, {
      params: {
        search,
        filter,
        page,
        limit,
        sort,
        field,
      },
    });
  }
  static getAllTours() {
    return api.get(`/tours/all`);
  }
  static getSelectTours(service_date, direction_id) {
    return api.get(`/tours/select`, {
      params: {
        service_date,
        direction_id,
      },
    });
  }
  static deleteTours(id) {
    return api.delete(`/tours/${id}`);
  }
  static changeTourStatus(id) {
    return api.put(`/tours/status/${id}`);
  }
  static updateTours(formData) {
    return api.put(`/tours/${formData.id}`, formData);
  }
  static updateTourPickUp(formData) {
    return api.put(`/tours/pickup/${formData.id}`, formData);
  }
  static getSingleTours(id) {
    return api.get(`/tours/single/${id}`);
  }

  static deleteSelectedTours(selectedRows) {
    return api.post(`/tours/delete_selected_Tours`, {
      selectedRows,
    });
  }

  static handleExportToursExcel(page, search, filter, [{ sort, field }]) {
    let service_date = {};
    if (filter.service_date) {
      if (filter.service_date['$gte']) {
        service_date.startDate = filter.service_date['$gte'];
      }
      if (filter.service_date['$lte']) {
        service_date.endDate = filter.service_date['$lte'];
      }
    }
    return api({
      url: `/tours/excel`,
      method: 'GET',
      responseType: 'blob',
      params: {
        search,
        service_date,
        page,
        sort,
        field,
        filter,
      },
    });
  }
}
