import { GridActionsCellItem } from '@mui/x-data-grid';
import { MdDelete, MdEdit } from 'react-icons/md';
import React from 'react';
import moment from 'moment';

export const getColumns = ({
  handleUpdateStatus,
  redirectToPreview,
  handleDelete,
  navigate,
}) => {
  return [
    {
      field: 'id',
      headerName: '#',
      width: 70,
    },
    {
      field: 'email_setup_name',
      headerName: 'Name',
      width: 200,
    },
    {
      field: 'email_subject',
      headerName: 'Email Subject',
      width: 250,
    },
    {
      field: 'email_body',
      headerName: 'Email Body',
      width: 200,
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 200,
    },
    {
      field: 'email_to_send',
      headerName: 'Email',
      width: 200,
    },
    {
      field: 'email_cc',
      headerName: 'CC',
      width: 200,
    },
    {
      field: 'email_bcc',
      headerName: 'BCC',
      width: 200,
    },

    {
      field: 'created_at',
      headerName: 'Created Date',
      width: 200,
      renderCell: params =>
        moment(params?.created_at || params?.row.created_at).format(
          'MM/DD/YYYY HH:mm:ss',
        ),
    },
    {
      field: 'createdBy.name',
      headerName: 'Created By',
      width: 150,
      renderCell: ({ row }) => row?.createdBy?.name || '',
    },
    {
      field: 'updated_at',
      headerName: 'Modified Date',
      width: 200,
      renderCell: params =>
        moment(params?.updated_at || params?.row.updated_at).format(
          'MM/DD/YYYY HH:mm:ss',
        ),
    },
    {
      field: 'updatedBy.name',
      headerName: 'Modified By',
      width: 150,
      renderCell: ({ row }) => row?.updatedBy?.name || '',
    },
    {
      headerName: 'Actions',
      field: 'actions',
      width: 150,
      cellClassName: 'actions',
      type: 'actions',
      getActions: params => [
        <GridActionsCellItem
          onClick={() => redirectToPreview(params.row?.id || params?.id)}
          className="test1"
          icon={<MdEdit />}
          label="Edit"
          title="View/Edit"
        />,
        <GridActionsCellItem
          icon={<MdDelete />}
          label="Delete"
          title="Delete"
          onClick={ev => handleDelete(params)}
        />,
      ],
    },
  ];
};
