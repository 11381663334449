import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageTitle from '../../components/PageTitle.js';
import { useParams } from 'react-router-dom';
import { FadeLoader } from 'react-spinners';
import UsersForm from './userForms/UsersForm';
import { getSingleUserAction } from '../../store/actions/users';
import { isEmpty } from 'lodash';
import DriversForm from './userForms/DriversForm';
import SuppliersForm from './userForms/SuppliersForm';
import SalesAgentForm from './userForms/SalesAgentForm';

function PreviewUsers() {
  const rowStatus = useSelector(store => store.users.status);
  const actionStatus = useSelector(store => store.users.actionStatus);
  const [formData, setFormData] = useState({});
  const [formValidation, setFormValidation] = useState({});
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    handleGetSingle(id);
  }, []);

  const handleGetSingle = id => {
    dispatch(
      getSingleUserAction(id, (err, data) => {
        if (data) {
          setFormData({ ...data.result });
        }
      }),
    );
  };

  const handleClose = () => {
    setFormData({});
  };
  return (
    <>
      <PageTitle
        parentTitle={'Users'}
        childTitle={formData.user_name || ''}
        parentLink={'/admin/users'}
      />
      <div className="form-block">
        {rowStatus === 'success' ? (
          <>
            {!isEmpty(formData) && formData.role === 'Driver' ? (
              <DriversForm
                singleDriver={formData}
                actionStatus={actionStatus}
              />
            ) : formData.role === 'Sales Agent' ? (
              <SalesAgentForm
                singleSalesAgent={formData}
                actionStatus={actionStatus}
              />
            ) : (
              <SuppliersForm single={formData} actionStatus={actionStatus} />
            )}
          </>
        ) : (
          <div className="form-loading">
            <FadeLoader
              color={`var(--primary_main)`}
              loading={rowStatus === 'pending'}
              size={10}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )}
      </div>
    </>
  );
}

export default PreviewUsers;
