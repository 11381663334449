import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import {
  addDirectionAction,
  getDirectionsAction,
  getSingleDirection,
  updateDirection,
} from '../../store/actions/directions';
import { LoadingButton } from '@mui/lab';
import { blockPageAction } from '../../store/actions/users';

function DirectionsForm({
  popupTitle,
  handleClose,
  singleDirection,
  actionStatus,
}) {
  const searchConfig = useSelector(store => store.search);
  const blockedPage = useSelector(store => store.search.blockedPage);
  const [formValidation, setFormValidation] = useState({});
  const [formData, setFormData] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [validated, setValidated] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isEmpty(singleDirection)) {
      setFormData(singleDirection);
    }
  }, [singleDirection]);

  useEffect(() => {
    if (
      id &&
      typeof formData === typeof singleDirection &&
      !isEmpty(singleDirection)
    ) {
      if (
        JSON.stringify(formData) !== JSON.stringify(singleDirection) &&
        !blockedPage
      ) {
        dispatch(blockPageAction('/user-management/users/41'));
      }
      if (
        JSON.stringify(formData) === JSON.stringify(singleDirection) &&
        singleDirection
      ) {
        dispatch(blockPageAction(''));
      }
    }
  }, [formData]);

  const handleChange = (key, val) => {
    setFormData({ ...formData, [key]: val });
    setFormValidation({ ...formValidation, [key]: val });
  };

  const handleEdit = params => {
    setFormValidation(!!formValidation);
    dispatch(
      getSingleDirection(params, (err, data) => {
        if (data && data.result) {
          setFormData({ ...data.result });
        }
      }),
    );
  };

  const onSubmit = event => {
    event.preventDefault();
    setSubmitted(true);
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      setDisabled(true);
      dispatch(
        addDirectionAction(formData, (err, data) => {
          if (data) {
            toast.success('Successfully Created', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });
            setValidated(false);
            setFormData({});
            dispatch(getDirectionsAction(searchConfig));
            setDisabled(false);
            handleClose();
            form.reset();
          } else if (err) {
            toast.error(err, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });
          }
        }),
      );
      setTimeout(() => {
        setDisabled(false);
      }, 1500);
    }
  };

  const onEdit = ev => {
    ev.preventDefault();
    setSubmitted(true);
    const form = ev.currentTarget;
    if (form.checkValidity() === false) {
      ev.preventDefault();
      ev.stopPropagation();
      setValidated(true);
    } else {
      setDisabled(true);
      setValidated(true);
      dispatch(
        updateDirection(formData, (err, data) => {
          if (err) {
            toast.error(err, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });
            setValidated(false);
          } else {
            if (data) {
              toast.success('Successfully Updated', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 10000,
              });
              setDisabled(false);
              form.reset();
              navigate('/admin/directions');
            }
          }
        }),
      );
      setTimeout(() => {
        setDisabled(false);
      }, 1500);
    }
  };

  const handleRemoveImg = key => {
    if (key === 'company_logo') {
      setFormData({ ...formData, logo: null, logo_file: null });
    }
  };

  return (
    <>
      <Form
        onSubmit={event => {
          if (id) {
            onEdit(event);
          } else {
            onSubmit(event);
          }
        }}
        noValidate
        validated={validated}
      >
        <Modal.Header closeButton={!id}>
          <Modal.Title>{!id && popupTitle + ' ' + 'Direction'}</Modal.Title>
        </Modal.Header>
        <div className="form-block">
          <Modal.Body>
            <Row>
              <Col sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Direction Name
                    <span style={{ color: 'red' }}> *</span>
                  </Form.Label>
                  <Form.Control
                    id="direction_name"
                    name="direction_name"
                    maxLength={254}
                    value={formData?.direction_name || ''}
                    required={true}
                    onChange={e =>
                      handleChange('direction_name', e.target.value)
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    Required Field
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <Form.Group className={'mb-3'}>
                  <Form.Label>Notes</Form.Label>
                  <Form.Control
                    id="direction_notes"
                    name="direction_notes"
                    as="textarea"
                    rows={5}
                    className="text_area_input"
                    value={formData.direction_notes || ''}
                    onChange={event =>
                      handleChange('direction_notes', event.target.value)
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
        </div>
        <Modal.Footer>
          <div className={id ? 'previewButtonsID' : 'previewButtons'}>
            <LoadingButton
              size="small"
              className="login_submit_btn btn btn-primary "
              loadingPosition="center"
              variant="contained"
              loading={actionStatus === 'pending'}
              disabled={disabled}
              type="submit"
            >
              <span>{actionStatus === 'pending' ? '' : 'Save'}</span>
            </LoadingButton>
            {id ? (
              <Button variant="secondary" onClick={() => handleEdit(id)}>
                Reset
              </Button>
            ) : (
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Form>
    </>
  );
}

export default DirectionsForm;
