import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { FormControlLabel } from '@material-ui/core';
import { Checkbox } from '@mui/material';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import UsersForm from './UsersForm';
import { driverValidation } from './validationSchema';
import { registerRequest, updateUser } from '../../../store/actions/users';
import { isEmpty } from 'lodash';
import { LoadingButton } from '@mui/lab'; // Adjust the path as needed

function DriversForm({
  popupTitle,
  handleClose,
  singleDriver,
  getUsersList,
  add_new_modal,
  actionStatus,
}) {
  const { id } = useParams();
  const searchConfig = useSelector(store => store.search);
  const blockedPage = useSelector(store => store.search.blockedPage);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(driverValidation),
    defaultValues: singleDriver || { role: 'Driver' },
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors, isValid, isDirty },
    reset,
    setError,
  } = methods;

  useEffect(() => {
    if (id && singleDriver) {
      reset({
        ...singleDriver,
        add_user: !!singleDriver.user_name,
        new_password: !!singleDriver.user_name,
      });
    }
  }, [id, singleDriver, reset]);

  const onSubmit = data => {
    setDisabled(true);
    dispatch(
      registerRequest(data, (err, data) => {
        if (err) {
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 10000,
          });
        } else {
          toast.success('Successfully Created', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 10000,
          });
          getUsersList(searchConfig);
          handleClose();
        }
      }),
    );

    setTimeout(() => setDisabled(false), 1500);
  };

  const onEdit = data => {
    dispatch(
      updateUser(data, (err, data) => {
        if (err) {
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 10000,
          });
        } else {
          if (data && data?.status === 200) {
            toast.success('Successfully Updated', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 10000,
            });
            navigate('/admin/users');
          }
        }
      }),
    );
  };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(id ? onEdit : onSubmit)} noValidate>
        {!id && (
          <Modal.Header closeButton={!id}>
            {/*<Modal.Title>{!id && popupTitle + ' Driver'}</Modal.Title>*/}
            <Modal.Title>Add New Driver</Modal.Title>
          </Modal.Header>
        )}
        <div className="form-block">
          <Modal.Body>
            <Row>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Full Name<span style={{ color: 'red' }}> *</span>
                  </Form.Label>
                  <Form.Control
                    id="driver_name"
                    name="name"
                    type="text"
                    {...register('name')}
                    isInvalid={errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Passport Details<span style={{ color: 'red' }}> *</span>
                  </Form.Label>
                  <Form.Control
                    id="driver_passport_details"
                    name="passport_details"
                    type="text"
                    {...register('passport_details')}
                    isInvalid={errors.passport_details}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.passport_details?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={4}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    License Number<span style={{ color: 'red' }}> *</span>
                  </Form.Label>
                  <Form.Control
                    id="driver_driving_license_number"
                    name="driving_license_number"
                    type="text"
                    {...register('driving_license_number')}
                    isInvalid={errors.driving_license_number}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.driving_license_number?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={4}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Vehicle Type<span style={{ color: 'red' }}> *</span>
                  </Form.Label>
                  <Form.Control
                    id="driver_vehicle_type"
                    name="vehicle_type"
                    type="text"
                    {...register('vehicle_type')}
                    isInvalid={errors.vehicle_type}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.vehicle_type?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={4}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Registration Number<span style={{ color: 'red' }}> *</span>
                  </Form.Label>
                  <Form.Control
                    id="driver_registration_number"
                    name="registration_number"
                    type="text"
                    {...register('registration_number')}
                    isInvalid={errors.registration_number}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.registration_number?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <Controller
                  name="add_user"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="driver_create_account"
                          {...field}
                          disabled={id && singleDriver?.user_name}
                          defaultChecked={id && singleDriver?.user_name}
                          checked={field.value}
                          onChange={ev => {
                            setValue('add_user', ev.target.checked);
                            setValue('password', '');
                            setValue('user_name', '');
                          }}
                        />
                      }
                      label="Create Account ?"
                    />
                  )}
                />
              </Col>
            </Row>
            {watch('add_user') ||
            (!add_new_modal && singleDriver?.user_name) ? (
              <UsersForm
                setValue={setValue}
                control={control}
                singleUser={singleDriver}
              />
            ) : null}
          </Modal.Body>
        </div>
        <Modal.Footer>
          {id ? (
            <div className="previewButtonsID">
              <LoadingButton
                size="small"
                className="login_submit_btn btn btn-primary "
                loadingPosition="center"
                variant="contained"
                loading={actionStatus === 'pending'}
                type={'submit'}
              >
                <span>{actionStatus === 'pending' ? '' : 'Save'}</span>
              </LoadingButton>
              <Button
                variant="secondary"
                onClick={() => reset({ ...singleDriver })}
              >
                Reset
              </Button>
            </div>
          ) : (
            <>
              <div className="previewButtons">
                <LoadingButton
                  size="small"
                  className="login_submit_btn btn btn-primary "
                  loadingPosition="center"
                  variant="contained"
                  loading={actionStatus === 'pending'}
                  type={'submit'}
                >
                  <span>{actionStatus === 'pending' ? '' : 'Save'}</span>
                </LoadingButton>
                <Button variant="secondary" onClick={handleClose}>
                  Cancel
                </Button>
              </div>
            </>
          )}
        </Modal.Footer>
      </Form>
    </FormProvider>
  );
}

export default DriversForm;
