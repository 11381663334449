import { takeLatest, call, put } from 'redux-saga/effects';
import {
  GET_TOURS_TRANSFERS_REQUEST,
  DELETE_TOUR_TRANSFERS_REQUEST,
  STATUS_TOUR_TRANSFERS_REQUEST,
  ADD_TOUR_TRANSFERS_REQUEST,
  GET_SINGLE_TOUR_TRANSFERS_SUCCESS,
  UPDATE_TOUR_TRANSFERS_REQUEST,
  GET_TOURS_TRANSFERS_SUCCESS,
  GET_TOURS_TRANSFERS_FAIL,
  DELETE_TOUR_TRANSFERS_SUCCESS,
  DELETE_TOUR_TRANSFERS_FAIL,
  STATUS_TOUR_TRANSFERS_SUCCESS,
  STATUS_TOUR_TRANSFERS_FAIL,
  GET_SINGLE_TOUR_TRANSFERS_FAIL,
  UPDATE_TOUR_TRANSFERS_SUCCESS,
  UPDATE_TOUR_TRANSFERS_FAIL,
  ADD_TOUR_TRANSFERS_SUCCESS,
  ADD_TOUR_TRANSFERS_FAIL,
  GET_SINGLE_TOUR_TRANSFERS_REQUEST,
  ADD_COMMENT_TOUR_TRANSFERS_SUCCESS,
  ADD_COMMENT_TOUR_TRANSFERS_FAIL,
  ADD_COMMENT_TOUR_TRANSFERS_REQUEST,
  COPY_TOUR_TRANSFER_REQUEST,
  COPY_TOUR_TRANSFER_SUCCESS,
  COPY_TOUR_TRANSFER_FAIL,
  SEND_EMAIL_TOUR_TRANSFER_REQUEST,
  SEND_EMAIL_TOUR_TRANSFER_SUCCESS,
  SEND_EMAIL_TOUR_TRANSFER_FAIL,
  CHECK_TRANSFER_REQUEST,
  CHECK_TRANSFER_SUCCESS,
  CHECK_TRANSFER_FAIL,
  DOWNLOAD_TRANSFER_REQUEST,
  DOWNLOAD_TRANSFER_SUCCESS,
  DOWNLOAD_TRANSFER_FAIL,
  EXPORT_TOUR_TRANSFERS_REQUEST,
  EXPORT_TOUR_TRANSFERS_SUCCESS,
  EXPORT_TOUR_TRANSFERS_FAIL, GET_SELECT_TRANSFERS_REQUEST, GET_SELECT_TRANSFERS_SUCCESS, GET_SELECT_TRANSFERS_FAIL,
} from '../actions/toursTransfers';
import TourTransfers from '../../api/toursTransfers';
import { saveAs } from 'file-saver';
import {GET_SELECT_TOURS_FAIL, GET_SELECT_TOURS_REQUEST, GET_SELECT_TOURS_SUCCESS} from "../actions/tours";
import Tours from "../../api/tours";

export default function* watcher() {
  yield takeLatest(GET_TOURS_TRANSFERS_REQUEST, handleGetToursTransfers);
  yield takeLatest(DELETE_TOUR_TRANSFERS_REQUEST, deleteTourTransfers);
  yield takeLatest(STATUS_TOUR_TRANSFERS_REQUEST, handleChangeStatus);
  yield takeLatest(GET_SELECT_TRANSFERS_REQUEST, getSelectTransfers);
  yield takeLatest(ADD_TOUR_TRANSFERS_REQUEST, addTourTransfer);
  yield takeLatest(GET_SINGLE_TOUR_TRANSFERS_REQUEST, getSingleTourTransfer);
  yield takeLatest(UPDATE_TOUR_TRANSFERS_REQUEST, updateTourTransfer);
  yield takeLatest(ADD_COMMENT_TOUR_TRANSFERS_REQUEST, addCommentTransfer);
  yield takeLatest(COPY_TOUR_TRANSFER_REQUEST, copyTourTransfer);
  yield takeLatest(SEND_EMAIL_TOUR_TRANSFER_REQUEST, handleSendEmail);
  yield takeLatest(CHECK_TRANSFER_REQUEST, checkTransfer);
  yield takeLatest(DOWNLOAD_TRANSFER_REQUEST, downloadTransfer);
  yield takeLatest(EXPORT_TOUR_TRANSFERS_REQUEST, handleExportExcel);
}

function* addTourTransfer(action) {
  try {
    const { formData } = action.payload;
    const { data } = yield call(TourTransfers.addTourTransfers, formData);
    yield put({
      type: ADD_TOUR_TRANSFERS_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    console.log(e);
    yield put({
      type: ADD_TOUR_TRANSFERS_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}

function* handleGetToursTransfers(action) {
  try {
    const { page, limit, sort, search, filter } = action.payload?.searchConfig;
    const { data } = yield call(
      TourTransfers.getTourTransfers,
      page,
      limit,
      search,
      filter,
      sort,
    );
    yield put({
      type: GET_TOURS_TRANSFERS_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: GET_TOURS_TRANSFERS_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}

function* deleteTourTransfers(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(TourTransfers.deleteTourTransfers, id);
    yield put({
      type: DELETE_TOUR_TRANSFERS_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    console.log(e);
    yield put({
      type: DELETE_TOUR_TRANSFERS_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}

function* getSelectTransfers(action) {
  try {
    const { service_date } = action.payload;
    const { data } = yield call(
        TourTransfers.getSelectTransfers,
        service_date,
    );
    yield put({
      type: GET_SELECT_TRANSFERS_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: GET_SELECT_TRANSFERS_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}

function* handleChangeStatus(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(TourTransfers.changeTourTransferStatus, id);
    yield put({
      type: STATUS_TOUR_TRANSFERS_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    console.log(e);
    yield put({
      type: STATUS_TOUR_TRANSFERS_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}

// function* deleteSelectedCompanies(action) {
//   try {
//     const { selectedRows } = action.payload;
//     const { data } = yield call(TourTransfers.deleteSelectedTours, selectedRows);
//     yield put({
//       type: DELETE_SELECTED_COMPANIES_SUCCESS,
//       payload: {
//         data: data.company,
//       },
//     });
//     if (action.payload.cb) {
//       action.payload.cb(null, data);
//     }
//   } catch (e) {
//     console.log(e);
//     yield put({
//       type: DELETE_SELECTED_COMPANIES_FAIL,
//       payload: {
//         massage: e.massage,
//       },
//     });
//     if (action.payload.cb) {
//       action.payload.cb(e, null);
//     }
//   }
// }

function* getSingleTourTransfer(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(TourTransfers.getSingleTourTransfers, id);
    yield put({
      type: GET_SINGLE_TOUR_TRANSFERS_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    console.log(e);
    yield put({
      type: GET_SINGLE_TOUR_TRANSFERS_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}

function* updateTourTransfer(action) {
  try {
    const { formData } = action.payload;
    const { data } = yield call(TourTransfers.updateTourTransfers, formData);
    yield put({
      type: UPDATE_TOUR_TRANSFERS_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: UPDATE_TOUR_TRANSFERS_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}

function* handleExportExcel(action) {
  try {
    const { page, search, filter, sort } = action.payload?.searchConfig;
    const { data } = yield call(
      TourTransfers.handleExportExcel,
      page,
      search,
      filter,
      sort,
    );
    yield put({
      type: EXPORT_TOUR_TRANSFERS_SUCCESS,
    });
    const blob = new Blob([data], {
      type: 'application/vnd.ms-excel',
    });
    saveAs(blob, `Transfers.xlsx`);
  } catch (e) {
    console.log(e);
    yield put({
      type: EXPORT_TOUR_TRANSFERS_FAIL,
      message: e.message,
    });
    if (action.payload.cb) {
      action.payload.cb(e.message, null);
    }
  }
}

function* addCommentTransfer(action) {
  try {
    const { hidden_note, id } = action.payload;
    const { data } = yield call(TourTransfers.addComment, id, hidden_note);
    yield put({
      type: ADD_COMMENT_TOUR_TRANSFERS_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    console.log(e);
    yield put({
      type: ADD_COMMENT_TOUR_TRANSFERS_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}

function* copyTourTransfer(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(TourTransfers.copyTourTransfer, id);
    yield put({
      type: COPY_TOUR_TRANSFER_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    console.log(e);
    yield put({
      type: COPY_TOUR_TRANSFER_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}

function* handleSendEmail(action) {
  try {
    const { id, formData } = action.payload;
    const { data } = yield call(
      TourTransfers.sendTourTransferEmail,
      id,
      formData,
    );
    yield put({
      type: SEND_EMAIL_TOUR_TRANSFER_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    console.log(e);
    yield put({
      type: SEND_EMAIL_TOUR_TRANSFER_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}

function* checkTransfer(action) {
  try {
    const { formData } = action.payload;
    const { data } = yield call(TourTransfers.checkTourTransfer, formData);
    yield put({
      type: CHECK_TRANSFER_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    console.log(e);
    yield put({
      type: CHECK_TRANSFER_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}

function* downloadTransfer(action) {
  try {
    const { id, ref_number, page } = action.payload;
    const { data } = yield call(TourTransfers.downloadTransfer, id);
    yield put({
      type: DOWNLOAD_TRANSFER_SUCCESS,
      payload: {
        data: data,
      },
    });
    const blob = new Blob([data], { type: 'application/pdf; charset=utf-8' });
    saveAs(blob, `${page}_${ref_number}.pdf`);
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    console.log(e);
    yield put({
      type: DOWNLOAD_TRANSFER_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}
